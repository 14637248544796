import { cloudStorage, FreeData } from "firebaseUtil/firebaseUtil";
import { dbDrills } from "./storage";
import { v4 as uuid } from "uuid";

let downloadCount: number = 0;
let uniqueToDownload: string[] = [];

// const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
const iOS = true;

export const downloadSound = (lessonDoc: Document) => {
  const speakables = lessonDoc.querySelectorAll("[eid]");

  for (let g = 0; g < speakables.length; g++) {
    const item = speakables[g].getAttribute("eid");
    if (item) {
      uniqueToDownload.push(
        "gs://netenglishm.appspot.com/lines/" + item + ".mp3"
      );
    }
  }

  uniqueToDownload = [...new Set(uniqueToDownload)];

  while (downloadCount < 10 && uniqueToDownload.length > 0) {
    downloadFile(uniqueToDownload[0]);
    downloadCount++;
    uniqueToDownload.shift();
  }
};

export const downloadFile = async (
  origpath: string,
  setSrcUrl: any = () => {},
  currentLine: number = 0
) => {
  if (!origpath) {
    return;
  }

  let src: FreeData;

  try {
    src = await dbDrills
      .table("files")
      .where("origpath")
      .equals(origpath)
      .first();
  } catch (error) {
    console.log("Error in dbDrills");
    return false;
  }

  if (src) {
    if (iOS) {
      src.file = arrayBufferToBlob(src.file, getMime(origpath));
    }

    var urlCreator = window.URL || window.webkitURL;
    var fileUrl = urlCreator.createObjectURL(src.file);

    setSrcUrl(fileUrl);
    downloadCount--;
    // if (uniqueToDownload.length === 0) {
    //   store.dispatch(setDownload(-1));
    // }

    while (uniqueToDownload.length > 0 && downloadCount < 10) {
      downloadFile(uniqueToDownload[0]);
      downloadCount++;
      uniqueToDownload.shift();
    }
    return fileUrl;
  }

  let url: any;

  try {
    url = await cloudStorage
      .refFromURL(origpath.replace("%20", " "))
      .getDownloadURL();
  } catch (error) {
    setSrcUrl("mp3/1.mp3");
    console.log("CloudStorage error", error);
    return "";
  }

  console.log("url", url);

  setSrcUrl(url);

  var xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onerror = function (error) {
    console.log("xhrerror");
  };

  xhr.onload = async function (event) {
    let buffer: ArrayBuffer | null = null;
    const blob = xhr.response;

    try {
      buffer = (await blobToArrayBuffer(blob)) as ArrayBuffer;
    } catch (e) {
      // alert("buffi");
      return "";
    }

    try {
      await dbDrills.table("files").put({
        origpath: origpath.replace("%20", " "),
        file: iOS ? buffer : blob,
      });
    } catch (e) {
      console.log("error saving file to IDB", origpath, e);
      return "";
    }

    downloadCount--;

    // if (uniqueToDownload.length === 0) {
    //   store.dispatch(setDownload(-1));
    // }

    while (uniqueToDownload.length > 0 && downloadCount < 10) {
      downloadFile(uniqueToDownload[0]);
      downloadCount++;
      uniqueToDownload.shift();
    }
  };
  xhr.open("GET", url);
  xhr.send();
  console.log("xhr sent");
};

function blobToArrayBuffer(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      resolve(reader.result);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(blob);
  });
}

function arrayBufferToBlob(buffer: ArrayBuffer, type: string) {
  return new Blob([buffer], { type: type });
}

function getMime(origpath: string) {
  switch (origpath.slice(-3)) {
    case "png":
      return "image/png";
    case "mp3":
      return "audio/mpeg";
    default:
      return "";
  }
}

export const getProfileUrl = async (pic: string) => {
  let url;

  try {
    url = await cloudStorage
      .refFromURL("gs://netenglishm.appspot.com/profilepics/" + pic + ".png")
      .getDownloadURL();

    return url;
  } catch (error) {
    return "";
  }
};
