import store from "Redux/store";

var iOSSafari = /iPad|iPhone|iPod/.test(navigator.userAgent);

let hasMediaRecorder: any = undefined;

try {
  hasMediaRecorder = new MediaSource();
  // do something if MediaSource is available
} catch (e) {
  console.log("no mediarecorder");
}

export const validate = (type: string, settings: any = undefined) => {
  settings = settings || store.getState().settings;
  const lessonStatus = store.getState().lessonStatus;

  if (!type) {
    return false;
  }

  if (!settings.testReview && type === "qual") {
    return false;
  }

  // @ts-ignore
  if (
    (!settings.pronunciation || !hasMediaRecorder || iOSSafari) &&
    type === "sound"
  ) {
    // console.log(settings.pronunciation, hasMediaRecorder, iOSSafari);
    return false;
  }

  if (!settings.writing && type === "writer") {
    return false;
  }

  if (lessonStatus.browsing && ["qual", "lessonend"].includes(type)) {
    return false;
  }

  return true;
};

export const nextValid = (startPos: number, settings: any = undefined) => {
  const parts = store.getState().lessonData.getElementsByTagName("part");
  if (!parts) {
    return -1;
  }
  startPos++;
  while (
    startPos < parts.length &&
    !validate(parts[startPos]?.getAttribute("type") || "", settings)
  ) {
    startPos++;
  }
  if (startPos === parts.length) {
    return -1;
  }
  return startPos;
};

export const prevValid = (startPos: number, settings: any = undefined) => {
  const parts = store.getState().lessonData.getElementsByTagName("part");
  if (!parts) {
    return -1;
  }
  startPos--;
  while (
    startPos > -1 &&
    !validate(parts[startPos]?.getAttribute("type") || "", settings)
  ) {
    startPos--;
  }
  if (startPos === -1) {
    return -1;
  }
  return startPos;
};
