import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const currentWordSlice = createSlice({
  initialState: {} as any,
  name: "currentWord",
  reducers: {
    setCurrentWord: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setCurrentWord } = currentWordSlice.actions;

export default currentWordSlice.reducer;
