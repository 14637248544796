import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";

// @material-ui/core components
import {
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Checkbox,
} from "@material-ui/core";

import { phaseBodyIn, phaseIn } from "../utils/utils";
import ContactLink from "./contact.link";
import ReactHtmlParser from "html-react-parser";
import { getProfileUrl } from "../utils/downloader";
import { tx } from "../utils/globalize";
import { inIframe } from "../app/App.utils";
import { updateSetting } from "../firebaseUtil/firebaseUtil.firestore";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--toolbar-background)",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    subHeaderTop: {
      paddingBottom: "5px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
    },
    subHeader: {
      paddingTop: "15px",
      paddingBottom: "5px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
    },
    oneLine: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingTop: "10px",
    },
    buttonDiv: { marginTop: "20px", textAlign: "right" },

    button: {
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
    },
    disabled: {
      opacity: 0.5,
    },
    spacer: {
      height: "20px",
    },

    butt: {
      textAlign: "center",
      marginBottom: "15px",
    },
    jss238: {
      color: "rgba(0, 0, 0, 0.87)",
      width: "100%",
      border: "0",
      display: "flex",
      position: "relative",
      fontSize: "18px",
      minWidth: "0",
      wordWrap: "break-word",
      background: "#FFF",
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
      marginTop: "45px",
      borderRadius: "6px",
      marginBottom: "30px",
      flexDirection: "column",
    },
    jss240: {
      marginTop: "45px",
      textAlign: "center",
    },
    jss835: {
      margin: "-50px auto 0",
      padding: "0",
      overflow: "hidden",
      maxWidth: "130px",
      boxShadow:
        "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
      maxHeight: "130px",
      borderRadius: "50%",
    },
    jss267: {
      flex: "1 1 auto",
      padding: "0.9375rem 1.875rem",
      webkitBoxFlex: "1",
    },
    jss808: {
      marginTop: "10px",
      fontSize: ".75rem",
      textTransform: "uppercase",
      fontWeight: 500,
      marginBottom: "10x",
    },
    jss813: {
      color: "#999",
    },
    jss780: {
      color: "#3C4858",
      marginTop: ".625rem",
      minHeight: "auto",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      marginBottom: "0.75rem",
      textDecoration: "none",
      fontSize: "23px",
      lineHeight: "1.5em",
    },
    contacts: {
      padding: "20px",
    },
  })
);

const Contact = (props: any) => {
  const classes = useStyles();

  useSelector<RootState, any>((state) => state.appTransParts);

  const [url, setUrl] = useState("/load1.gif");

  const customize = useSelector<RootState, any>((state) => state.customize);
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const settings = useSelector<RootState, any>((state) => state.settings);

  useEffect(() => {
    const loadName = async () => {
      setUrl(
        await getProfileUrl(
          (customize?.teacher?.pic || "") + (inIframe() ? "dev" : "")
        )
      );

      document.getElementById("ipaper")?.scrollTo(0, 0);
      setTimeout(() => {
        phaseBodyIn();
        phaseIn();
      }, 0);
    };

    console.log("bold");

    if (currentUser?.ccode) {
      loadName();
    }
  }, [currentUser, customize]);

  const changeShowHead = () => {
    updateSetting({ head: !settings.head });
  };

  return (
    <React.Fragment>
      <div className={classes.jss238 + " " + classes.jss240}>
        <div className={classes.jss835}>
          <a href="#pablo">
            <img src={url} style={{ width: "130px" }} alt="..." />
          </a>
        </div>
        <div className={classes.jss267}>
          <div className={classes.jss780}>{customize.teacher?.name}</div>
          <p className={classes.jss813}>
            {ReactHtmlParser(
              customize.teacher?.description?.replace(/\n/g, "<br/>") || ""
            )}
          </p>
        </div>
        <div className={classes.contacts}>
          <ContactLink
            name={tx("agat1", "E-mail")}
            res={customize.teacher?.email}
            href={`mailto:${customize.teacher?.email}`}
          />
          <ContactLink
            name={tx("agat", "Phone")}
            res={customize.teacher?.phone}
            href={`tel:${customize.teacher?.phone}`}
          />
          <ContactLink
            name="Skype"
            res={customize.teacher?.skype}
            href={`skype:${customize.teacher?.skype}`}
          />
          <ContactLink
            name="Facebook"
            res={customize.teacher?.facebook}
            href={`fb://${customize.teacher?.facebook}`}
          />
        </div>
        {customize.teacher?.onScreen ? (
          <div
            style={{
              paddingBottom: "15px",
              textAlign: "right",
              marginLeft: "auto",
            }}
          >
            <FormControlLabel
              style={{ color: "rgba(0,0,0,0.5)" }}
              control={
                <Checkbox
                  checked={settings.head}
                  onChange={changeShowHead}
                  name="checkedA"
                  style={{ color: "rgba(0,0,0,0.5)" }}
                />
              }
              label={tx(
                "46AF3449-BC2A-4asdf45D-9C61-C70BCC236514543",
                "Show head on main screen"
              )}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default Contact;
