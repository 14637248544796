import "../css/languageCard.Card.css";

import { FreeData } from "firebaseUtil/firebaseUtil";
import React, { useRef } from "react";
import ReactCardFlip from "react-card-flip";
import { useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
// @ts-ignore
import { virtualize } from "react-swipeable-views-utils";
import { RootState } from "Redux/reducers/_index";
import { SettingsType } from "Redux/reducers/reducer.settings";

import {
  Badge,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import FastForwardIcon from "@material-ui/icons/FastForward";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";

import { formatWord, getWordWithPOS, updateSetting } from "../words.utils";
import CardFinalResult from "./wordCard.finalRresult";
import HelpCard from "help/topics/cards-h";
import HelpOutline from "@material-ui/icons/HelpOutlineTwoTone";
import store from "Redux/store";
import { setHelpSrc, setHelpOpen } from "Redux/reducers/reducer.help";
import HelpGrammar from "../../help/topics/grammar-h";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const cardStyle = {
  border: "1px solid var(--toolbar-background)",
  textAlign: "center",
  height: "calc(100vh - 180px)",
  maxHeight: "230px",
  borderRadius: "3px",
  backgroundColor: "white",
  position: "absolute",
  top: 0,
  left: 1,
  right: 1,
  fontFamily: "var(--font-normal)",
};

const finishStyle = {
  textAlign: "center",
  height: "calc(100vh - 180px)",
  maxHeight: "230px",
  borderRadius: "3px",
  backgroundColor: "white",
  position: "relative",
  marginTop: "5px",
  marginBottom: "5px",
  animation: "1s showup",
  fontFamily: "var(--font-normal)",
};

const general = {
  flexHorizontal: {
    display: "flex",
    flexDirection: "rows",
    width: "100%",
    alignItems: "center",
  },
  flexExpanding: {
    flex: "1 1 20px",
  },
  flexFixIcon: {
    flex: "0 0 20px",
    lineHeight: 0,
    paddingLeft: "14px",
  },
  cardHeaderText: {
    paddingLeft: "14px",
    fontSize: "17px",
    fontWeight: "400",
  },
};

const styles = (theme: any) => ({
  ...general,
  cardCategory: {
    marginTop: "10px",
    "& svg": {
      position: "relative",
      top: "8px",
    },
  },
  cardTitle1: {
    ...cardStyle,
  },
  cardTitle2: {
    ...cardStyle,
  },
  finishStyle: {
    ...finishStyle,
  },
  cardTop: {
    backgroundColor: "lightgrey",
  },
  flag: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  status: {
    position: "absolute",
    bottom: 0,
    left: 5,
  },
  narrow: { minHeight: "51px" },
  centeredDiv: {
    transform: "translateY(-50%)",
    top: "50%",
    left: "25px",
    right: "25px",
    position: "absolute",
    userSelect: "none",
    whiteSpace: "pre-wrap",
  },
  smallerPadding: {
    paddingLeft: "4px",
    paddingRight: "0px",
  },
  noLeftPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  marginZero: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
  stats: {
    display: "flex",
    fontSize: "10px",
    paddingRight: "6px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "0px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "10px",
      lineHeight: "10px",
    },
    "& svg": {
      position: "relative",
      top: "0px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "10px",
      height: "10px",
    },
  },
  cardHeader: {
    padding: "0px",
    background: "var(--toolbar-background)",
    color: "var(--toolbar-color)",
    fontFamily: "var(--font-normal)",
    fontWeight: 500,
    fontSize: "17px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  card: {
    marginTop: "-20px",
    marginBottom: "0px",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  cardBody: {
    maxHeight: "300px",
    padding: "3px 8px 7px 8px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 20px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
    "& > div:first-child": { overflow: "visible !important" },
  },
  badge: {
    transform: "scale(1) translate(100%, 0%)",
    background: "none",
  },
  legend: {
    position: "absolute",
    paddingTop: "3px",
    paddingLeft: "8px",
    color: "var(--text-color)",
    fontFamily: "var(--font-normal)",
    fontSize: "14px",
    textAlign: "left",
    lineHeight: "150%",
  },
  titlepos: {
    fontSize: "13px",
  },
  auto: {
    position: "absolute",
    bottom: 10,
    right: 10,
    fontSize: "13px",
    color: "lightgrey",
  },
  help: {
    cursor: "pointer",
    position: "absolute",
    bottom: 10,
    left: 10,
    fontSize: "13px",
    color: "grey",
  },
  badge1: {
    transform: "scale(0.7) translate(50%, -50%)",
    backgroundColor: "rgba(0,0,0,0.75)",
  },
  badgeNone: {
    display: "none",
  },
});

const useStyles = makeStyles(styles as any);

interface ContentType {
  content: FreeData;
  doneRef: any;
  doneSlide: Function;
  engPosition: number;
  englishFirst: boolean;
  fastForward: Function;
  finishSlides: boolean;
  getNext: any;
  isFlipped: boolean;
  loadingWord: boolean;
  redo: any;
  resetStanding: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  setActiveSentence: any;
  setCardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEngPosition: React.Dispatch<React.SetStateAction<number>>;
  setEnglishFirst: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
  showNextSlide: Function;
  tellEnglish: Function;
  textIdRef: any;
  title: string;
  wcontent: any;
}

const StudyCard: React.FC<ContentType> = (props) => {
  const classes = useStyles();
  const mouseRef = useRef<boolean>(false);
  const intRef = useRef<any>();
  const settings = useSelector<RootState, SettingsType>(
    (state) => state.settings
  );

  const currentUserLang = useSelector<RootState, any>(
    (state) => state.currentUser?.language
  );

  const flipCard = () => {
    props.setIsFlipped(!props.isFlipped);
  };

  // @ts-ignore
  const doneItemCount = props.content.filter((item) => item.done).length;
  const moreItemCount = props.content.length - doneItemCount;
  const engItem = props.content[props.engPosition];

  function slideRenderer(param: { index: number }) {
    if (!props.content || param.index < 0) {
      return <div key={param.index}></div>;
    }

    const active = param.index === props.engPosition;

    const engSubItem = props.content[param.index];
    if (!engSubItem) {
      return <div key={param.index}></div>;
    }
    const engBackground = engSubItem.done ? { backgroundColor: "#ddffdd" } : {};

    return (
      <ReactCardFlip
        key={param.index}
        isFlipped={active ? props.isFlipped : !props.englishFirst}
        flipDirection="horizontal"
        containerStyle={{
          overflow: active ? "visible" : "hidden",
          opacity: active ? 1 : 0,
          transition: "opacity 0.6s",
        }}
        flipSpeedBackToFront={active ? 0.6 : 0}
        flipSpeedFrontToBack={active ? 0.6 : 0}
      >
        <h4
          onClick={flipCard}
          className={classes.cardTitle1}
          style={{
            marginTop: "5px",
            ...engBackground,
            pointerEvents: active ? "auto" : "none",
            fontFamily: "Raleway, Roboto, Helvetica",
          }}
        >
          <div
            className={classes.help}
            onClick={() =>
              runHelp(
                !store.getState().reader.currentList.id.indexOf("gr") &&
                  store.getState().status === "reader" ? (
                  <HelpGrammar />
                ) : (
                  <HelpCard />
                )
              )
            }
          >
            <HelpOutline />
          </div>
          <div className={classes.auto}>
            {props.engPosition + 1 + "/" + props.content.length}
          </div>
          {formatWord(props.title, classes, props.wcontent.ipa)}
          <div className={classes.centeredDiv}>
            {props.loadingWord ? (
              <CircularProgress disableShrink />
            ) : (
              engSubItem.english.replace(/\|/g, "\n")
            )}
          </div>
          <img
            className={classes.flag}
            src="/flags/uk.png"
            width="20px"
            height="auto"
            alt="Great Britain Flag"
          />
        </h4>
        <h4
          onClick={flipCard}
          className={classes.cardTitle1}
          style={{
            marginTop: "5px",
            ...engBackground,
            pointerEvents: active ? "auto" : "none",
            fontFamily: "Raleway, Roboto, Helvetica",
          }}
        >
          <div className={classes.help} onClick={() => runHelp(<HelpCard />)}>
            <HelpOutline />
          </div>
          <div className={classes.auto}>
            {props.engPosition + 1 + "/" + props.content.length}
          </div>
          <div className={classes.centeredDiv}>
            {props.loadingWord ? (
              <CircularProgress disableShrink />
            ) : (
              engSubItem.foreign.replace(/\|/g, "\n")
            )}
          </div>
          <img
            className={classes.flag}
            src={"/flags/" + currentUserLang + ".png"}
            width="20px"
            height="auto"
            alt="Flag"
          />
        </h4>
      </ReactCardFlip>
    );
  }

  // const switchLanguage = () => {
  //   props.setEnglishFirst(!props.englishFirst);
  //   props.setIsFlipped(props.englishFirst);
  //   props.resetStanding();
  // };

  const mouseDown = () => {
    if (intRef.current) {
      clearTimeout(intRef.current);
    }
    mouseRef.current = true;

    intRef.current = setTimeout(() => {
      updateSetting("autoRead", !settings.autoRead);
      mouseRef.current = false;
    }, 700);
  };

  const mouseUp = (e: any) => {
    e.stopPropagation();
    if (intRef.current) {
      clearTimeout(intRef.current);
      intRef.current = null;
    }

    if (mouseRef.current) {
      props.tellEnglish(engItem.english);
    }
  };

  const runHelp = (helpFile: any) => {
    store.dispatch(setHelpSrc(helpFile));
    store.dispatch(setHelpOpen(true));
  };

  return (
    <div className={classes.card}>
      <div color="primary" className={classes.cardHeader}>
        <div className={classes.flexHorizontal}>
          <div
            id="xtitle"
            className={classes.flexExpanding + " " + classes.cardHeaderText}
          >
            {getWordWithPOS(props.title, classes)}
          </div>
          <Toolbar className={classes.narrow}>
            <IconButton
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onMouseDown={mouseDown}
              onMouseUp={mouseUp}
              onTouchStart={mouseDown}
              onTouchEnd={mouseUp}
            >
              <Badge
                badgeContent="AUTO"
                classes={{
                  badge: settings.autoRead ? classes.badge1 : classes.badgeNone,
                }}
                color="primary"
              >
                <RecordVoiceOver />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="close dialog"
              aria-haspopup="true"
              color="inherit"
              onClick={() => {
                props.setCardOpen(false);
                props.doneRef.current = [];
                props.textIdRef.current = "";
                props.setActiveSentence({
                  s: "",
                  sOrig: "",
                  w: [0, 0],
                  fileId: "",
                });
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </div>
      </div>

      <div className={classes.cardBody}>
        <React.Fragment>
          {props.finishSlides ? (
            <div className={classes.finishStyle}>
              <CardFinalResult
                getNext={props.getNext}
                redo={props.redo}
                title={props.title}
                wcontent={props.wcontent}
              />
            </div>
          ) : (
            <VirtualizeSwipeableViews
              index={props.engPosition}
              onChangeIndex={(index: number) => {
                props.setEngPosition(index);
                props.setIsFlipped(!props.englishFirst);
              }}
              slideRenderer={slideRenderer}
              slideCount={props.content.length}
              hysteresis={0.4}
              containerStyle={{ color: "green", overflowY: "visible" }}
              slideStyle={{
                color: "blue",
                overflowY: "visible",
                overflowX: "visible",
              }}
              resistance={true}
              overscanSlideBefore={2}
              onTransitionEnd={(index: number) => {
                if (
                  settings.autoRead &&
                  props.englishFirst &&
                  !props.loadingWord
                ) {
                  props.tellEnglish(props.content[props.engPosition].english);
                }
              }}
            />
          )}

          <Grid container className={classes.marginZero}>
            <Grid item xs={6} className={classes.noLeftPadding}>
              <Button
                style={{
                  background: "var(--toolbar-background)",
                  color: "var(--toolbar-color)",
                }}
                variant="contained"
                fullWidth
                onClick={props.fastForward as any}
                disabled={props.finishSlides}
              >
                <FastForwardIcon />
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.smallerPadding}>
              <Button
                style={{
                  background: "var(--toolbar-background)",
                  color: "var(--toolbar-color)",
                  opacity: 0.65,
                }}
                variant="contained"
                disabled={props.finishSlides}
                onClick={
                  moreItemCount
                    ? (props.doneSlide as any)
                    : () => {
                        props.setCardOpen(false);
                        props.setActiveSentence({
                          s: "",
                          sOrig: "",
                          w: [0, 0],
                          fileId: "",
                        });
                      }
                }
                type="button"
                fullWidth
              >
                <NavigateNextIcon />
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      </div>
    </div>
  );
};

export default StudyCard;
