import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FreeData } from "firebaseUtil/firebaseUtil";

export interface TransView {
  english: string;
  foreign: string;
  id: string;
}

const initialState: FreeData = {};

const transPartsSlice = createSlice({
  initialState,
  name: "transParts",
  reducers: {
    setTransParts: (state: any, action: PayloadAction<FreeData>) => {
      return action.payload;
    }
  }
});

export const { setTransParts } = transPartsSlice.actions;

export default transPartsSlice.reducer;
