import Footer from "footer/footer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "Redux/reducers/_index";
import { UserType } from "Redux/reducers/reducer.currentUser";

import Condition from "utils/Conditional";

// @material-ui/core components
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import PayNow from "../buy/buy.payNow";
import Tutorial from "../firstSteps/firstSteps.tutorial";
import { tx } from "../utils/globalize";
import { phaseBodyIn, phaseIn } from "../utils/utils";
import DashBoardIntro from "./dashboard.intro";
import DashboardResults from "./dashboard.results";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "10px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    greytext: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      color: "grey",
    },

    line: {
      height: "0px",
      width: "100%",
      borderBottom: "1px solid var(--line-grey)",
      marginBottom: "18px",
    },
    table: {
      fontFamily: "var(--font-normal)",
      fontSize: "12px",
      paddingRight: "12px",
    },
    tableFirst: {
      marginBottom: "15px",
    },
    bgroup: {
      fontSize: "9px",
    },
    buttons: {
      float: "right",
    },
    loading: {
      fontFamily: "var(--font-normal)",
      fontSize: "12px",
    },
    spacer: { height: "25px" },
  })
);

const Dashboard = (props: any) => {
  const [statsInternal, setStatsInternal] = useState<any>({});
  const classes = useStyles();

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  const authLoaded = useSelector<RootState, boolean>(
    (state) => state.authLoaded
  );

  const tutorial = useSelector<RootState, boolean>((state) => state.tutorial);
  const showFirstPage = useSelector<RootState, boolean>(
    (state) => state.settings.showFirstPage
  );

  useEffect(() => {
    document.getElementById("ipaper")?.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (!currentUser) {
  //     return;
  //   }
  //   if (buyNow && authLoaded && currentUser?.subscriptionType !== 4) {
  //     store.dispatch(setBuyNow(false));

  //     store.dispatch(
  //       setDialogFull({
  //         open: true,
  //         content: <BuyNudge />,
  //         action: () => {},
  //         cancel: () => store.dispatch(setDialogFullOpen(false)),
  //       })
  //     );
  //   }
  // }, [currentUser, buyNow, authLoaded]);

  useEffect(() => {
    //   store.dispatch(
    //     setProceedAction({
    //       action: () => history.push("/lesson"),
    //       visibleAction: false,
    //       actionIcon: "forward",
    //     })
    //   );

    // firestore
    //   .doc(`/users/${currentUser?.id}/data/stats`)
    //   .get()
    //   .then((progress) => setProgress(progress.data()));

    if (authLoaded && currentUser) {
      setTimeout(() => {
        phaseIn();
        phaseBodyIn();
      }, 0);
    }
  }, [currentUser, authLoaded]);

  return (
    <React.Fragment>
      <Condition
        when={
          tutorial &&
          currentUser?.expiry >= Date.now() &&
          !currentUser?.suspension &&
          showFirstPage
        }
      >
        <Tutorial />
      </Condition>
      <Condition when={currentUser?.expiry < Date.now()}>
        <PayNow type={"expired"} />
      </Condition>
      <Condition when={currentUser?.suspension}>
        <PayNow type={"suspended"} />
      </Condition>

      <DashBoardIntro />
      <DashboardResults stats={statsInternal} setStats={setStatsInternal} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <Footer tx={tx} />
    </React.Fragment>
  );
};

export default Dashboard;
// <CircularProgress variant="static" value={66} />
