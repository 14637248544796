import store from "Redux/store";
import { firestore } from "firebaseUtil/firebaseUtil";
import { contains } from "./utils";

export const loadTranslationAsync = async (
  lesson: string,
  type: string,
  selectedLanguage = ""
) => {
  const currentUser = store.getState().currentUser;
  const language = selectedLanguage || currentUser?.language;

  if (!lesson) {
    return {};
  }
  const program = store.getState().program;

  if (!contains(window.location.href, "/trans")) {
    const gLesson = localStorage.getItem(language + lesson);

    if (gLesson) {
      const parsedLesson = JSON.parse(gLesson);
      if (parsedLesson.updatedTrans >= program[lesson]?.updatedTrans) {
        return parsedLesson;
      }
    }
  }

  const transColl = firestore
    .collection("translations")
    .where("type", "==", type)
    .where("lesson", "==", lesson)
    .where("language", "==", language || "en");

  let lessonTrans: any = [];

  try {
    lessonTrans = await transColl.get();
  } catch (e) {
    console.log(e);
    lessonTrans = [];
  }

  const returnDoc =
    lessonTrans.docs.length === 0 ? {} : lessonTrans.docs[0].data();

  localStorage.setItem(language + lesson, JSON.stringify(returnDoc));

  return returnDoc;
};

export const loadAppTranslation = async (language = "") => {
  const transColl = firestore
    .collection("translations")
    .where("type", "==", "application")
    .where("language", "==", language);

  let appTrans: any = [];

  try {
    appTrans = await transColl.get();
    let trans = {};
    appTrans.docs.forEach((doc: any) => {
      trans = { ...trans, ...doc.data().translations };
    });
    return trans;
  } catch (e) {
    console.log(e);
    return {};
  }
};
