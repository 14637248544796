import React, { useState } from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpReader() {
  const classes = useStyles();
  const [open1, setOpen1] = useState(false);

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>
          {tx("azarn10", `Magic Reader`)}
        </div>
        <div className={classes.helpBold}>
          {tx("azarn11", `What's this whole thing?`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarn12",
            `Read the text which you can also listen to at the same time.`
          )}{" "}
          {tx(
            "azarn13",
            `In the top bar you can see the controls which you can use to control playing the sound.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mp3controls.png`}
            className={classes.helpImg}
          />
        </div>

        <div className={classes.helpList}>
          {tx(
            "azarn14",
            `a) Here you can set the speed of playing the recording.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarn15",
            `b) Click here to rewind 5 seconds and start playing the sound.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx("azarn16", `c) Play and stop.`)}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarn17",
            `d) Fast forward 5 seconds and start playing the sound.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarn18",
            `Clicking on any word in the text you will see the definition of the
          word in the given context popping up in a little window.`
          )}{" "}
          {tx(
            "azarn19",
            `In this popup
          window you will also see three buttons.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/smalldef.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx("azarn20", `a) Closes the popup window.`)}
        </div>
        <div className={classes.helpList}>
          {tx("azarn21", `b) Add the word to your vocabulary list for study.`)}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarn22",
            `c) Click here to find out more about the word (pronuncation, sample
          sentences etc.)`
          )}
        </div>

        <div className={classes.helpText}>
          {tx(
            "azarn23",
            `If you select c) you will get all available data regarding the word.`
          )}
        </div>
        <div
          onClick={() => setOpen1(true)}
          className={open1 ? classes.helpLinkClosed : classes.helpLinkOpen}
        >
          {tx("azark18", `See details...`)}
        </div>
        <div className={open1 ? classes.open : classes.closed}>
          <div className={classes.helpText}>
            {tx("azark19", `The content of this dialog is self-explanatory.`)}{" "}
            {tx(
              "azark20",
              `If you click on one of the small speakers, the application will read aloud the selected sentence.`
            )}
          </div>
          <div className={classes.imgDiv}>
            <img
              alt="card35"
              src={`/help/${lang}/worddata.png`}
              className={classes.helpImg}
            />
          </div>
          <div className={classes.helpText}>
            {tx(
              "azark20a",
              `At the bottom of the dialog there are some additional options:`
            )}
          </div>
          <div className={classes.helpList}>
            {tx("azark21", `a) Learning words with practice cards.`)}{" "}
            {tx("azark22", `(See instructions under My Words.)`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark22a",
              `b) It translates the current sentence with Google Translate.`
            )}{" "}
            {tx("azark23", `A new window opens with the translation.`)}{" "}
            {tx("azark24", `When you are done close this window.`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark25",
              `c) You can look up the current word in a bigger dictionary.`
            )}{" "}
            {tx("azark26", `A new window opens with the bigger dictionary.`)}{" "}
            {tx("azark27", `When you are done close this window.`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark28",
              `d) You add the word to your vocabulary list to study and repeat later.`
            )}
          </div>
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarn50sd",
            `At the end of the text you have an option to say that you completed this text.`
          )}{" "}
          {tx(
            "azarn50sd1",
            `Click on the Done option and the text will be marked as done.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/done.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarn50sdwerw",
            `It is useful as the computer will offer you other texts automatically.`
          )}{" "}
          {tx(
            "azarn50sdwerww",
            `For example if you want to load the next text, just click on the Next button which is now visible.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/done1.png`}
            className={classes.helpImg}
          />
        </div>

        <div className={classes.helpText}>
          {tx(
            "azarn53",
            `If the texts are too easy or too difficult, click on the Level Select button in the lower left corner:`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/levellist.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx("azarn54", `Select a lower or a higher level from the list.`)}{" "}
          {tx(
            "azarn55",
            `You can also see here which level you are currently on.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarn55a",
            `If you want more options choose the List... option.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/selecttext.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarn56",
            `Here you will see all texts available to the given level.`
          )}{" "}
          {tx(
            "azarn57",
            `If you click on any of those, the text will open in the Reader.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarn57a",
            `If you want to choose a different level or category, click on the Up button and you will see the list of available categories.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/cats.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarn58",
            `Choose one of them, and then select the text you want from the list appearing.`
          )}
        </div>
        <div className={classes.helpBold}>
          {tx("azarn59", `Buttons at the bottom:`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/readerbottom.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarn60",
            `a) Level selector where you decide what texts or materials you want to study.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarn61",
            `b) Jump to the previous or the next text in the currently used level or category.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx("azarn62", `c) Opening the Reader.`)}
        </div>
        <div className={classes.helpList}>
          {tx("azarn63", `d) Open the list of Most used words.`)}
        </div>
        <div className={classes.helpList}>
          {tx("azarn64", `e) Open your Vocabulary list.`)}
        </div>
        <div className={classes.helpList}>
          {tx("azarn65", `f) Open the built in dictionary.`)}
        </div>
      </div>
    </React.Fragment>
  );
}
