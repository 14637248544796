import React from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpDashboard(props: any) {
  const classes = useStyles();

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>{tx("agaz0", "The Dashboard")}</div>
        <div className={classes.helpText}>
          {tx("agaz1", "What's this whole thing?")}
        </div>
        <div className={classes.helpText}>
          {tx(
            "agaz2",
            "This is where you can choose what you want to practice."
          )}
        </div>
        <div className={classes.helpBold}>{tx("agaz2a", "The options:")}</div>

        <div className={classes.helpText}>
          <b>{tx("agaz3", "My Words:")}</b>{" "}
          {tx(
            "agaz3a",
            "Opens your vocabulary book where you can practice words selected for study."
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mywords.png`}
            className={classes.helpImgSmall}
          />
        </div>

        <div className={classes.helpText}>
          <b>{tx("agaz4", "Reader:")}</b>{" "}
          {tx("agaz5", "You can read texts looking up words instantly.")}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/texts.png`}
            className={classes.helpImgSmall}
          />
        </div>

        <div className={classes.helpText}>
          <b>{tx("agaz6", "Videos:")}</b>{" "}
          {tx(
            "agaz7",
            "You can watch videos and read their transcripts looking up words in the text."
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/videos.png`}
            className={classes.helpImgSmall}
          />
        </div>

        <div className={classes.helpText}>
          <b>{tx("agaz8", "3000+ words:")}</b>{" "}
          {tx(
            "agaz9",
            "Study the most used 3000+ words of English if you want to."
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mostused.png`}
            className={classes.helpImgSmall}
          />
        </div>

        <div className={classes.helpText}>
          <b>{tx("agaz10", "Pronunciation")}</b>{" "}
          {tx(
            "agaz11",
            "(Not avaiable on iPhones and iPads) You can practice your pronunciation here with our special method."
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/pron.png`}
            className={classes.helpImgSmall}
          />
        </div>

        <div className={classes.helpText}>
          <b>{tx("aga12", "Writing:")}</b>{" "}
          {tx("agaz13", "You can practice writing here.")}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/write.png`}
            className={classes.helpImgSmall}
          />
        </div>

        <div className={classes.helpText}>
          <b>{tx("agaz13a", "Grammar:")}</b>{" "}
          {tx(
            "agaz14",
            "You'll find grammar exercises for various grammar rules."
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/grammar.png`}
            className={classes.helpImgSmall}
          />
        </div>

        <div className={classes.helpHeader} style={{ paddingTop: "15px" }}>
          {tx("agaz15f", "Progress")}
        </div>
        <div className={classes.helpText}>
          {tx(
            "agaz16a",
            "Going lower on the page you can have a look at your progress. How far are you?"
          )}
        </div>

        <div className={classes.helpText}>
          {tx(
            "agaz17",
            "Here you can see how much time you've spent studying so far, how many words you've checked and how many words you've studied so far."
          )}
        </div>

        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/progress.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "agaz18",
            "Click on the More... button so that you can see your progress on charts as well."
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
