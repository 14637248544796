import { FreeData } from "firebaseUtil/firebaseUtil";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { ProceedType } from "Redux/reducers/reducer.proceed";

import { createStyles, Fab, makeStyles, Theme } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Check from "@material-ui/icons/Check";
import Pause from "@material-ui/icons/Pause";
import PlayArrow from "@material-ui/icons/PlayArrow";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fabButton: {
      transition: "all 0.3s",
      transform: "scale(0)",
      position: "fixed",
      zIndex: 1950,
      bottom: theme.spacing(2),
      right: theme.spacing(2),

      "@media (min-width: 821px)": {
        bottom: 25 + theme.spacing(2),
        right: "calc(50vw - 406px + " + theme.spacing(2) + "px)",
      },
      color: "var(--toolbar-color)",
      backgroundColor: "var(--toolbar-background)",
      "@media (hover: hover)": {
        "&:hover:hover:hover": {
          color: "var(--toolbar-color)",
          backgroundColor: "var(--toolbar-background)",
        },
      },
      "&:focus:focus:focus": {
        color: "var(--toolbar-color)",
        backgroundColor: "var(--toolbar-background)",
      },
      "&:active:active:active": {
        color: "var(--toolbar-color)",
        backgroundColor: "var(--toolbar-background)",
      },
      "&.MuiFab-primary:hover": {
        color: "var(--toolbar-color)",
        backgroundColor: "var(--toolbar-background)",
      },
    },
    fabActive: {
      transform: "scale(1)",
      transition: "all 0.3s",
    },
  })
);

const icons: FreeData = {
  forward: <ArrowForward />,
  play: <PlayArrow />,
  pause: <Pause />,
  check: <Check />,
};

const NextButton = () => {
  const classes = useStyles();

  const proceed = useSelector<RootState, ProceedType>((state) => state.proceed);

  return (
    <React.Fragment>
      <Fab
        size="medium"
        color="secondary"
        aria-label="add"
        className={
          classes.fabButton +
          " " +
          (proceed.visibleAction ? classes.fabActive : "")
        }
        onClick={proceed.action === 1 ? undefined : proceed.action}
      >
        {icons[proceed.actionIcon] || "play"}
      </Fab>
    </React.Fragment>
  );
};

export default NextButton;
