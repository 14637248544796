import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'Redux/reducers/_index';

import { saveTiming, setUpTimer } from './timer';

const Timer = () => {
  const status = useSelector<RootState, any>((state) => state.status);

  // const status = useSelector<RootState, any>((state) => state.status);

  const location = useLocation();

  useEffect(() => {
    setUpTimer();
  }, []);

  useEffect(() => {
    saveTiming();
  }, [location, status]);

  return <div />;
};

export default Timer;
