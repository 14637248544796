//import { checkGoing } from "home/App.loadWarnings";
import React, { useEffect } from "react";

import { checkForUpdate } from "./App.upgrade";

const currentVersion = 57;

checkForUpdate(true);

const UpgradeChecker = (props: any) => {
  useEffect(() => {
    // checkGoing();

    const appVersion = localStorage.getItem("appVersion");

    if (!appVersion || currentVersion > parseInt(appVersion)) {
      localStorage.setItem("appVersion", currentVersion + "");
    }

    const vCheck = setInterval(checkForUpdate, 60000);

    return () => clearInterval(vCheck);
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default UpgradeChecker;
