import React, { useEffect, useState, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { firestore } from "../firebaseUtil/firebaseUtil";
import Terms from "./legal.terms";
import Privacy from "./legal.privacy";
import { prepareFormatTerms } from "../utils/readxml";
import { decodedStr } from "../utils/utils";
import Subscription from "./legal.subscription";
import Cookies from "./legal.cookies";
import Impressum from "./legal.impresszum";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import LegalFrame from "./legal.frame";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {},
    opaque: { opacity: 0, transition: "all 0.3s" },
    nonopaque: { opacity: 1, transition: "all 0.3s" },
  })
);

const Legal = (props: any) => {
  const [termsTranslation, setTermsTranslation] = useState<any>({});
  const [visible, setVisible] = useState(false);

  const customizeCorporate = useSelector<RootState, any>(
    (state) => state.customize.corporate
  );

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const language = useSelector<RootState, any>((state) => state.tempLanguage);

  const szv =
    document.location.hostname.includes("szv.gyakorlatias-angol.hu") ||
    currentUser?.ccode === "06ab17c7-b847-4c01-aa58-430cb94e0ac2";

  const classes = useStyles();

  useEffect(() => {
    const loadTermsTranslation = async () => {
      const transColl = firestore
        .collection("translations")
        .where("type", "==", "webpages")
        .where("purpose", "==", "legal")
        .where("language", "==", language);

      let termTrans: any = [];

      try {
        termTrans = await transColl.get();
        setTermsTranslation({
          ...termTrans.docs[0].data().translations,
          ...termTrans.docs[1].data().translations,
          ...termTrans.docs[2].data().translations,
        });

        setTimeout(() => setVisible(true), 0);
      } catch (e) {
        setTimeout(() => setVisible(true), 0);
        console.log(e);
        return {};
      }
    };

    if (language) {
      loadTermsTranslation();
    } else {
      setTimeout(() => setVisible(true), 0);
    }
  }, [language]);

  const tt = useCallback(
    (uuid: string, orig: string = "", avar: string = "", bvar: string = "") => {
      orig = termsTranslation[uuid || "bibibi"] || orig;
      orig = orig.replace(/xxx/g, avar);
      orig = orig.replace(/yyy/g, bvar);
      return prepareFormatTerms(decodedStr(orig));
    },
    [termsTranslation]
  );

  const getText = () => {
    switch (props.doc) {
      case "terms":
        return (
          <LegalFrame
            content={<Terms tt={tt} />}
            link={
              szv
                ? "gyakorlatias-angol.hu/altalanos-szerzodesi-feltetelek/"
                : ""
            }
          />
        );

      case "privacy":
        return (
          <LegalFrame
            content={<Privacy tt={tt} />}
            link={
              szv ? "gyakorlatias-angol.hu/adatkezelesi-tajekoztato-2/" : ""
            }
          />
        );
      case "subscription":
        return (
          <LegalFrame
            content={<Subscription tt={tt} />}
            link={customizeCorporate?.subscription || "/"}
          />
        );

      case "cookies":
        return <Cookies tt={tt} />;
      case "impress":
        return (
          <LegalFrame
            content={<Impressum tt={tt} />}
            link={szv ? customizeCorporate?.aboutUs : ""}
          />
        );
    }
  };

  return (
    <div id="bubu3" className={visible ? classes.nonopaque : classes.opaque}>
      {getText()}
    </div>
  );
};

export default Legal;
