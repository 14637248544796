import React, { useEffect, useState, useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Input,
  IconButton,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {},
  })
);

const Password = (props: any) => {
  const classes = useStyles();
  const [pwVisible, setPWVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { password, setPassword, setButtonForward } = props;

  useEffect(() => {
    if (verifyLength(password, 6)) {
      setButtonForward(true);
    } else {
      setButtonForward(false);
    }
  }, [password, setButtonForward]);

  const verifyLength = (value: string, length: number) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const changePassword = (e: any) => {
    const password = e.target.value;

    setPassword(password);
  };

  const checkForEnter = (event: any) => {
    if (event.key === "Enter") {
      if (verifyLength(password, 6)) {
        props.nextStep();
      }
    }
  };

  const setVisibility = () => {
    setPWVisible(!pwVisible);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  return (
    <React.Fragment>
      <div className={classes.input}>
        <input
          style={{ display: "none" }}
          type="email"
          defaultValue={props.email}
        />
        <Input
          inputRef={inputRef}
          autoFocus
          fullWidth
          value={password}
          placeholder=""
          inputProps={{
            "aria-label": "email",
            type: pwVisible ? "text" : "password",
            autoComplete: "new-password",
          }}
          onChange={changePassword}
          onKeyDown={checkForEnter}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={setVisibility}
              >
                {pwVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    </React.Fragment>
  );
};

export default Password;
