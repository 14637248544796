import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setProceedAction } from "Redux/reducers/reducer.proceed";
import store from "Redux/store";
import { tx } from "utils/globalize";
import { nextExercise } from "utils/progress/stepper";

// @material-ui/core components
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { UserType } from "../Redux/reducers/reducer.currentUser";
import { phaseBodyIn, phaseIn } from "../utils/utils";
import Languages from "./account.language";
import TextForm from "./account.text";

const { detect } = require("detect-browser");
const browser = detect();

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--toolbar-background)",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    subHeaderTop: {
      paddingBottom: "5px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
    },
    subHeader: {
      paddingTop: "15px",
      paddingBottom: "5px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
    },
    oneLine: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingTop: "10px",
    },
    buttonDiv: { marginTop: "20px", textAlign: "right" },

    button: {
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
    },
    disabled: {
      opacity: 0.5,
    },
    spacer: {
      height: "20px",
    },

    butt: {
      textAlign: "center",
      marginBottom: "15px",
    },
    os: {
      fontSize: "14px",
    },
    osTitle: { fontWeight: "bold" },
  })
);

const Account = (props: any) => {
  const [data, setData] = useState<any>({});
  const [, setChanges] = useState(false);

  const classes = useStyles();
  // const history = useHistory();

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  useSelector<RootState, any>((state) => state.appTransParts);

  useEffect(() => {
    setData(currentUser || {});
  }, [currentUser]);

  useEffect(() => {
    store.dispatch(
      setProceedAction({
        action: nextExercise,
        visibleAction: false,
        actionIcon: "forward",
      })
    );
    document.getElementById("ipaper")?.scrollTo(0, 0);
    setTimeout(() => {
      phaseBodyIn();
      phaseIn();
    }, 0);
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setChanges(true);

    setData({ ...data, [e.target.id]: e.target.value });
  };

  const changeLanguage = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setChanges(true);

    setData({
      ...data,
      language: e.target.value.toLowerCase(),
    });
  };

  return (
    <React.Fragment>
      <div className={classes.subHeaderTop}>{tx("basic0asllas", "Basics")}</div>
      <div className={classes.oneLine}>
        <TextForm
          data={data}
          label={tx("emailNameasda", "Email")}
          name={"email"}
          handleChange={handleChange}
        />
        <Languages language={data.language} handleChange={changeLanguage} />
      </div>
      <div className={classes.spacer} />
      <div className={classes.os}>
        <div className={classes.osTitle}>OS Data</div>
        <div>{browser.os}</div>
        <div>{browser.name}</div>
        <div>{localStorage.getItem("appVersion")}</div>
      </div>
    </React.Fragment>
  );
};

export default Account;
