export function isScrolledIntoView(el: Element | null) {
  if (!el) {
    return true;
  }
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;

  // Only completely visible elements return true:
  var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export const phaseBodyOut = () =>
  document.getElementById("dimmerlite")?.classList.add("mfhidden");
export const phaseBodyIn = () =>
  document.getElementById("dimmerlite")?.classList.remove("mfhidden");

export const phaseOut = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.add("mfhidden");
};

export const phaseIn = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.remove("mfhidden");
  document.getElementById("ipaper")?.scrollTo(0, 0);
};

export const phaseOut400 = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.add("mfhidden400");
};

export const phaseIn400 = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.remove("mfhidden400");
};

export function shuffleArray(arrayIn: any[]) {
  let array = [...arrayIn];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const stepPhased = (doThis: any) => {
  phaseOut400("qualquestion");
  setTimeout(() => {
    doThis();
    phaseIn400("qualquestion");
  }, 400);
};

export const disableAction = () => {
  const mainFrame = document.getElementById("mainFrame");
  if (mainFrame) {
    mainFrame.style.pointerEvents = "none";
  }
};

export const enableAction = () => {
  const mainFrame = document.getElementById("mainFrame");
  if (mainFrame) {
    mainFrame.style.pointerEvents = "auto";
  }
};

export const beginsWith = (needle: string, arr: string | string[]) => {
  if (typeof arr === "string") {
    arr = [arr];
  }
  for (let g = 0; g < arr.length; g++) {
    if (needle.indexOf(arr[g]) === 0) {
      return true;
    }
  }
  return false;
};

export const contains = (needle: string, arr: string | string[]) => {
  if (typeof arr === "string") {
    arr = [arr];
  }
  for (let g = 0; g < arr.length; g++) {
    if (needle.indexOf(arr[g]) > -1) {
      return true;
    }
  }
  return false;
};

export const encodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&/g, "&amp;")
    .replace(/>/g, "gt;")
    .replace(/</g, "lt;");
};

export const decodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&nbsp;/g, " ");
};

export const getTitle = () => {
  if (document.location.hostname === "szv.gyakorlatias-angol.hu") {
    return "SzókincsVarázsló";
  } else {
    return "WizzWords";
  }
};
