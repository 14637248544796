import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import { makeStyles, createStyles } from "@material-ui/styles";
import { phaseOut } from "../utils/utils";
import { getProfileUrl } from "../utils/downloader";
import { inIframe } from "../app/App.utils";
import Draggable from "react-draggable";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    fab: {
      background: "lightgrey",
      transition: "all 0.3s",
      zIndex: 500,
      position: "fixed",
      bottom: 70,
      right: 25,

      "@media (min-width: 821px)": {
        bottom: 70,
        right: "calc(50vw - 406px + " + 20 + "px)",
      },
    },
    avatar: { width: "46px", height: "46px" },
    fabDiv: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      border: "1px solid grey",
      boxShadow: "1px 1px 4px 1px grey",
    },
    img: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
  })
);

const FABThis = (props: any) => {
  const classes = useStyles();
  const dragging = useRef(false);
  const history = useHistory();
  const location = useLocation();
  const [url, setUrl] = useState("/load1.gif");

  const customize = useSelector<RootState, any>((state) => state.customize);
  const settings = useSelector<RootState, any>((state) => state.settings);
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const authLoaded = useSelector<RootState, any>((state) => state.authLoaded);

  useEffect(() => {
    const loadName = async () => {
      // setUrl("/images.png");
      const ts = await getProfileUrl(customize?.teacher?.pic || "");
      // @ts-ignore
      setUrl(ts || "/images/download.png");
    };

    if (authLoaded && currentUser?.ccode) {
      loadName();
    }
  }, [currentUser, authLoaded, customize]);

  return (
    <React.Fragment>
      {customize.teacher?.onScreen &&
      !location.pathname.includes("/contact") &&
      settings.head !== false ? (
        <Draggable
          onStop={(e: any) => {
            if (!dragging.current) {
              if (customize.teacher?.linkType === "internal") {
                phaseOut();
                setTimeout(() => history.push("/app/contact"), 150);
              } else {
                window.open("https://" + customize.teacher?.myUrl, "_speck");
              }
            }
            dragging.current = false;
          }}
          onDrag={() => {
            dragging.current = true;
          }}
        >
          {/* <Fab
            size="medium"
            color="secondary"
            aria-label="add"
            className={classes.fab}
            onClick={() => {
              if (customize.teacher?.linkType === "internal") {
                phaseOut();
                setTimeout(() => history.push("/app/contact"), 150);
              } else {
                window.open("https://" + customize.teacher?.myUrl, "_speck");
              }
            }}
          > */}
          <div
            className={classes.fabDiv + " " + classes.fab}
            style={{
              backgroundImage: "url('" + url + "')",
              backgroundSize: "50px 50px",
            }}
          >
            {/* <Avatar
              alt={customize.teacher?.name}
              className={classes.avatar}
              src={url}
            /> */}
            {/* </Fab> */}
          </div>
        </Draggable>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default FABThis;
