import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { DialogType, setDialogOpen } from "Redux/reducers/reducer.dialog";
import store from "Redux/store";
import React from "react";

import {
  ButtonBase,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/core components
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: { padding: "14px 20px" },
    text: {
      color: "black",
      paddingTop: "14px",
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      minHeight: "125px",
      minWidth: "295px",
    },

    button1: {
      color: "#898989",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      textTransform: "uppercase",
      marginLeft: "15px",
    },
    button2: {
      color: "var(--toolbar-background)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      textTransform: "uppercase",
      paddingLeft: "15px",
    },
    actions: {
      paddingBottom: "14px",
      paddingRight: "20px",
    },
    paper: {
      margin: "0px",
    },
  })
);

export default function BaseDialog(props: any) {
  const classes = useStyles();
  const dialog = useSelector<RootState, DialogType>((state) => state.dialog);

  const handleClose = () => {
    store.dispatch(setDialogOpen(false));
  };

  return (
    <Dialog
      className={classes.dialog}
      classes={{ paper: classes.paper }}
      open={dialog.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {dialog.title ? (
        <DialogTitle id="responsive-dialog-title">{dialog.title}</DialogTitle>
      ) : null}
      <DialogContent className={classes.text}>
        <DialogContentText>{dialog.text}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {dialog.button1Text ? (
          <ButtonBase
            onClick={dialog.button1action}
            className={classes.button1}
          >
            {dialog.button1Text}
          </ButtonBase>
        ) : null}
        <ButtonBase
          onClick={dialog.button2action}
          autoFocus
          className={classes.button2}
        >
          {dialog.button2Text}
        </ButtonBase>
      </DialogActions>
    </Dialog>
  );
}
