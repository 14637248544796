import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const dicSlice = createSlice({
  initialState: {} as any,
  name: "dic",
  reducers: {
    setDic: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setDicOne: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setDic, setDicOne } = dicSlice.actions;

export default dicSlice.reducer;
