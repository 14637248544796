import "../css/card.css";

import React from "react";

import { IconButton } from "@material-ui/core";
import { AddCircleOutline, Refresh } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";

export const KeyWordRow = React.memo(({ data, index, style }: any) => {
  const classes = data.classes;

  const thisMoment = Math.floor(Date.now() / 1000);

  if (!data.myWords.words[index]) {
    return <React.Fragment />;
  }

  let item = {
    ...data.words[data.myWords.words[index].id],
    word: data.myWords.words[index].word,
  };

  if (!item.id) {
    item = {
      ...data.myWords.words[index],
      e: data.myWords.words[index].word.split("+")[0].split("/")[0],
    };
  }

  let type = "";

  if (thisMoment >= item?.done) {
    type = "active";
  }

  if (thisMoment < item?.done) {
    type = "waiting";
  }

  if (item?.done === 50000 * 86400) {
    type = "done";
  }

  return (
    <div style={style}>
      <div className={classes.doneword + " " + type}>
        <div
          className={classes.wordpart}
          onClick={() => {
            data.showWord(item);

            data.doneRef.current = [];
            data.textIdRef.current = "";
            data.textTypeRef.current = "active";
            // setTimeout(() => data.getNext(item), 0);
          }}
        >
          <div className={item.o ? classes.engwordblue : classes.engword}>
            {item.e}
          </div>
          <div className={classes.hunword}>{item.d}</div>
        </div>
        <div className={classes.wordend}>
          {type === "active" || type === "waiting" ? (
            <IconButton
              onClick={() => data.addToListOfficial(item, "done")}
              className={classes.moregreen}
            >
              <CheckIcon />
            </IconButton>
          ) : (
            ""
          )}
          {type === "done" ? (
            <IconButton
              onClick={() => data.addToListOfficial(item, "add")}
              className={classes.more}
            >
              <Refresh />
            </IconButton>
          ) : (
            ""
          )}
          {type === "" ? (
            <React.Fragment>
              <IconButton
                onClick={() => data.addToListOfficial(item, "add")}
                className={classes.more}
              >
                <AddCircleOutline />
              </IconButton>
              <IconButton
                onClick={() => data.addToListOfficial(item, "done")}
                className={classes.moregreen}
              >
                <CheckIcon />
              </IconButton>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
});
