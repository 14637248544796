import firebase from "firebase/app";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { setStatus } from "Redux/reducers/reducer.status";
import store from "Redux/store";

import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { firestore } from "../../firebaseUtil/firebaseUtil";
import { RootState } from "../../Redux/reducers/_index";
import { updateReaderBulk } from "../words.utils";
import { tx } from "../../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    doneword: {
      background: "#eeeeee",
      marginBottom: "8px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      padding: "5px",
      paddingLeft: "15px",
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      width: "calc(100% - 20px)",
      "&.active": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
      "@media (min-width: 478px)": {
        width: "calc(100% - 45px)",
      },
    },
    wordpart: { flex: "1 1 auto", maxWidth: "calc(100% - 45px)" },
    engword: {
      flex: "1 1 auto",
      color: "black",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    engwordblue: {
      flex: "1 1 auto",
      color: "blue",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
    hunword: {
      flex: "1 1 auto",
      color: "grey",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    wordend: { flex: "0 0 30px" },
    date: {
      fontWeight: "bold",
      position: "absolute",
      bottom: "5px",
      width: "100%",
      display: "flex",
    },
    datePart: { flex: "1 1 auto" },
    countPartDone: { flex: "0 0 50px", color: "green" },
    countPartRepeat: { flex: "0 0 50px", color: "brown" },

    more: {
      padding: "5px",
    },
    titleblue: {
      color: "blue",
    },
    icon: { verticalAlign: "bottom" },
  })
);

class Row extends React.PureComponent<any> {
  showMenu = (e: any) => {
    this.props.data.menuRef.current = e.target;
    this.props.data.setMenuOpen(true);
    this.props.data.itemRef.current = this.props.data.myList[
      this.props.index
    ].id;
  };

  render() {
    const data = this.props.data;
    const classes = data.classes;

    const item = data.myList[this.props.index];

    if (!item.title) {
      return (
        <div style={this.props.style}>
          <div className={classes.date}>
            <div className={classes.datePart}>
              {new Date(item.date * 86400000).toLocaleDateString()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={this.props.style} className={"pad30"}>
        <div className={classes.doneword}>
          <div
            className={classes.wordpart}
            onClick={() => {
              this.props.data.setNewText(item);
            }}
          >
            <div className={classes.engword}>{item.title}</div>
            <div className={classes.hunword}>{item.cat}</div>
          </div>
          <div className={classes.wordend}>
            <IconButton className={classes.more} onClick={this.showMenu}>
              <MoreHorizIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

// const aeq = (prev: any, next: any) => {
//   console.log(prev);
//   console.log("getnext", prev.getNext === next.getNext);
//   console.log("dicList", prev.dicList === next.dicList);
//   console.log("setdicList", prev.setDicList === next.setDicList);
//   console.log("markWorkDone", prev.markWorkDone === next.markWorkDone);
//   return true;
// };

const WordsHistory = React.memo((props: any) => {
  const classes = useStyles();
  const listRef = useRef<any>();
  const menuRef = useRef<any>();
  const itemRef = useRef<any>();
  const [menuOpen, setMenuOpen] = useState(false);
  const [myList, setMyList] = useState<any>([]);

  const reader = useSelector<RootState, any>((state) => state.reader);

  useEffect(() => {
    const newObj: any = {};

    if (!reader?.history?.length) {
      setMyList([]);
      return;
    }

    for (let g = 0; g < reader.history.length; g++) {
      const item = reader.history[g];

      const day = item.date + "";

      if (newObj[day]) {
        newObj[day].push(item);
      } else {
        newObj[day] = [item];
      }
    }

    const days = Object.keys(newObj);

    const finalList: any[] = [];

    for (let g = 0; g < days.length; g++) {
      let newList = [];

      const dayList = newObj[days[g]];

      for (let n = 0; n < dayList.length; n++) {
        const item = dayList[n];

        newList.push(item);
      }

      finalList.push({
        date: parseInt(days[g]),
      });

      newList.forEach((item) => {
        finalList.push(item);
      });
    }

    setMyList(finalList);
  }, [reader]);

  const setNewText = useCallback(
    async (item: any) => {
      let newText: any;

      if (item.listId === "own") {
        newText = await firestore
          .doc(`/users/${store.getState().currentUser?.id}/texts/${item.id}`)
          .get();
      } else {
        newText = await firestore.doc(`/texts/${item.id}`).get();
      }
      const newList = await firestore.doc(`/textlists/${item.listId}`).get();

      let updateValue: any = {
        currentText: {
          ...(newText.data() || { title: "", text: "", id: "" }),
          list: item.cat,
          listId: item.listId,
          id: newText.data()?.id,
        },
        currentList: { ...newList.data(), id: item.listId },
      };

      if (!reader.history.find((item: any) => item.id === newText.data()?.id)) {
        updateValue.history = firebase.firestore.FieldValue.arrayUnion({
          id: newText.data()?.id,
          title: newText.data()?.title,
          date: Math.floor(Date.now() / 86400000),
          cat: reader.currentList.title,
        });
      }

      // console.log(updateValue);

      updateReaderBulk(updateValue);
      setTimeout(() => store.dispatch(setStatus("reader")), 300);
    },
    [reader]
  );

  const deleteHistory = () => {
    const newHistory = reader.history.filter(
      (item: any) => item.id !== itemRef.current
    );

    const texts = reader.currentList.texts?.map((item: any) => {
      if (item.id !== itemRef.current) {
        return item;
      } else {
        return { ...item, done: false };
      }
    });

    updateReaderBulk({ history: newHistory, currentList: { texts } });
  };

  return (
    <React.Fragment>
      <Menu
        id="simple-menu"
        anchorEl={menuRef.current}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onClick={() => setMenuOpen(false)}
      >
        <MenuItem onClick={deleteHistory}>{tx("dala1", "Delete")}</MenuItem>
      </Menu>
      <AutoSizer>
        {({ height, width }: any) => (
          <List
            ref={listRef}
            className="List"
            height={height}
            itemCount={myList.length || 0}
            itemSize={62}
            width={width}
            style={{ margin: "auto" }}
            itemData={{
              myList,
              classes,
              menuRef,
              menuOpen,
              setMenuOpen,
              itemRef,
              setNewText,
            }}
          >
            {Row as any}
          </List>
        )}
      </AutoSizer>
    </React.Fragment>
  );
});

export default WordsHistory;
