import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const HistoryCheck = (props: any) => {
  const history = useHistory();
  useEffect(() => {
    //
  }, [history]);

  return <React.Fragment></React.Fragment>;
};

export default HistoryCheck;
