import React, { useEffect, useState } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import WordsWord from "./textViewer.word";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {},
  })
);

const Sentence = React.memo((props: any) => {
  // const classes = useStyles();
  const [words, setWords] = useState<any[]>([]);

  useEffect(() => {
    const temp = props.sentence
      .replace(/\s{2,}/gi, " ")
      .trim()
      .split(/[ —]/);

    setWords(temp);
  }, [props.sentence]);

  return (
    <React.Fragment>
      {words.map((word, index) => (
        <WordsWord
          word={word}
          sIndex={props.index}
          index={index}
          key={index}
          sentence={props.sentence}
          splitter={props.splitter}
          lookupWord={props.lookupWord}
          stemmer={props.stemmer}
          longPressAction={props.longPressAction}
        />
      ))}
    </React.Fragment>
  );
});

export default Sentence;
