import React, { useEffect, useRef, useState } from "react";
import { phaseBodyIn, phaseIn } from "utils/utils";
import { tx } from "utils/globalize";
import { makeStyles, createStyles, ButtonBase } from "@material-ui/core";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import { prepareFormat } from "utils/readxml";
import { downloadFile } from "utils/downloader";
import { Howl } from "howler";
import { Close } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { firestore } from "firebaseUtil/firebaseUtil";

const Diff = require("diff");
let sound;

export const useStyles = makeStyles((theme) =>
  createStyles({
    instructions: { cursor: "pointer", color: "blue" },
    flexi: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    help: {},
    horizontal: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      paddingTop: "10px",
    },
    left: { flex: "0 0 auto" },
    right: { flex: "1 1 auto", textAlign: "right" },
    bigtitle: {
      paddingBottom: "10px",
      marginTop: "7px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      lineHeight: "120%",
      flex: "1 1 auto",
    },
    text: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    line: {
      height: "20px",
      borderBottom: "1px solid var(--line-grey)",
      marginLeft: "-20px",
      marginRight: "-20px",
      marginBottom: "15px",
      "@media (min-width: 821px)": {
        marginLeft: "-35px",
        marginRight: "-35px",
      },
    },
    english: {
      fontSize: "18px",
      lineHeight: "135%",
      fontFamily: "var(--font-normal)",
      background: "#e5e5e5",
      border: "1px solid grey",
      borderRadius: "3px",
      padding: "15px",
      marginTop: "25px",
      marginBottom: "15px",
      transition: "all 0.2s",
      opacity: 0,
    },
    correct: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: "12px",
      background: "#EAFFEF",
      fontFamily: "var(--font-normal)",
      fontSize: "16px",
    },
    incorrect: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: "12px",
      background: "#FFEEEE",
    },
    corrected: {
      fontSize: "16px",
      fontFamily: "var(--font-normal)",
      paddingBottom: "8px",
      paddingTop: "8px",
    },
    statusCorrect: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      color: "#4CAF50",
      paddingBottom: "5px",
    },
    statusWrong: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      color: "#ED4831",
      paddingBottom: "5px",
    },
    status: {
      width: "100%",
      textAlign: "center",
      marginBottom: "15px",
    },
    chip: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "16px",
    },
    button: {
      borderRadius: "50%",
      color: "var(--toolbar-color)",
      background: "var(--toolbar-background)",
      marginLeft: "8px",
      padding: "9px",
    },
    textarea: {
      width: "100%",
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      resize: "none",
      padding: "12px",
      border: "none",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    shown: {
      opacity: 1,
      transition: "all 0.2s",
    },
    area: {
      marginTop: "10px",
      border: "1px solid #b3b3b3",
      marginBottom: "8px",
      height: "85px",
      position: "relative",
      borderRadius: "3px",
      overflowY: "auto",
    },
    areashown: {
      marginTop: "10px",
      border: "1px solid #b3b3b3",
      marginBottom: "8px",
      position: "relative",
      borderRadius: "3px",
      overflowY: "auto",
      height: "200px",
    },
    trans: {
      marginBottom: "15px",
      background: "#eeeeee",
      padding: "15px",
      border: "1px solid lightgrey",
      paddingBottom: "15px",
    },
  })
);

const Writer = () => {
  const audioRef = useRef();
  const [sentences, setSentences] = useState([
    { english: "", foreign: "", eid: "xxx" },
  ]);
  const [answer, setAnswer] = useState("");
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [shown, setShown] = useState(false);
  const [compareLine, setCompareLine] = useState("");
  const [playing, setPlaying] = useState(false);
  const [sentenceCount, setSentenceCount] = useState(0);
  const [first, setFirst] = useState(true);
  const nextRef = useRef();
  const textAreaRef = useRef();

  const classes = useStyles();

  const currentUser = useSelector((state) => state.currentUser);
  const init = useSelector((state) => state.appTransParts.init);

  useEffect(() => {
    if (!first || !currentUser) {
      return;
    }
    setFirst(false);
    firestore
      .doc("/other/lines" + (currentUser.language || "hu"))
      .get()
      .then((res) => {
        setSentences(res.data().lines);

        let tempIndex = parseInt(Math.random() * (res.data().lines.length - 1));

        while (res.data().lines[tempIndex].english.includes("&")) {
          tempIndex = parseInt(Math.random() * (res.data().lines.length - 1));
        }

        setSentenceIndex(tempIndex);

        setTimeout(() => {
          // console.log("aaaa" + res.data().lines[tempIndex].eid);
          downloadFile(
            "gs://netenglishm.appspot.com/linesnew/" +
              res.data().lines[tempIndex].eid +
              ".mp3",
            (src) => {
              sound = new Howl({
                src: [src],
                format: ["mp3"],
                onplay: () => setPlaying(true),
                onload: () => {
                  setPlaying(false);
                },

                onend: () => {
                  if (textAreaRef.current) {
                    textAreaRef.current.focus();
                  }
                  setPlaying(false);
                },
              });
            },
            ""
          );
        }, 0);
      });
  }, [currentUser, sentences, sentenceIndex, first]);

  useEffect(() => {
    setTimeout(() => {
      if (!init) {
        phaseIn();
        phaseBodyIn();
      }
    }, 150);
  }, [init]);

  // useEffect(() => {
  //   setSentences(g);
  // }, []);

  // const showInstructions = () => {
  //   store.dispatch(setHelpSrc(<HelpPron />));
  //   store.dispatch(setHelpOpen(true));
  // };

  const newSentence = () => {
    // setInit(true);

    let tempIndex = parseInt(Math.random() * (sentences.length - 1));

    while (sentences[tempIndex].english.includes("&")) {
      tempIndex = parseInt(Math.random() * (sentences.length - 1));
    }

    setSentenceIndex(tempIndex);
    setAnswer("");
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.value = "";
        textAreaRef.current.focus();
      }
    }, 0);
    setShown(false);
    setSentenceCount(sentenceCount + 1);

    setTimeout(() => {
      downloadFile(
        "gs://netenglishm.appspot.com/linesnew/" +
          sentences[tempIndex].eid +
          ".mp3",
        (src) => {
          sound = new Howl({
            src: [src],
            format: ["mp3"],
            onplay: () => setPlaying(true),
            onload: () => setPlaying(false),
            onend: () => {
              setPlaying(false);
              if (textAreaRef.current) {
                textAreaRef.current.focus();
              }
            },
          });
        },
        ""
      );
    }, 0);
  };

  const closeCheck = () => {
    setShown(false);
    setTimeout(() => textAreaRef.current.focus(), 0);
  };

  const playSentence = () => {
    if (playing) {
      return;
    }

    // setInit(true);
    if (sound) {
      setPlaying(true);

      sound.play();
    }
  };

  const changeAnswer = (event) => {
    setAnswer(event.target.value);
    // setTimeout(() => setIcon(), 0);
  };

  const checkText = () => {
    setShown(true);
    const comp = compare(
      answer.replace(/’/g, "'"),
      sentences[sentenceIndex].english.replace(/’/g, "'")
    );

    setCompareLine(prepareFormat(comp));
  };

  const currentSentence = sentences[sentenceIndex].english;

  useEffect(() => {
    if (!sentences[sentenceIndex] || !audioRef.current) {
      return;
    }

    sound = null;
  }, [sentences, sentenceIndex]);

  useEffect(() => {});

  const textAreaKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (event.target.value === "") {
        playSentence();
        return;
      }

      checkText();
      nextRef.current.focus();
    }
  };

  const onBlur = () => {
    // setTimeout(() => setIcon(), 0);
  };

  const onFocus = () => {
    // setTimeout(() => setIcon(), 0);
  };

  const correct =
    compareLine.length === sentences[sentenceIndex].english.length;

  // const correct = compareLine.length === 1 && !compareLine[0].type;

  return (
    <React.Fragment>
      <div className={classes.flexi}>
        <div className={classes.bigtitle}>
          {tx("llalalalalalwd", "Writing drill")}
        </div>
      </div>
      <audio ref={audioRef} />

      <div className={shown ? classes.areashown : classes.area}>
        {shown ? (
          <div className={correct ? classes.correct : classes.incorrect}>
            {correct ? (
              <div className={classes.statusCorrect}>
                {tx("corresdsd", "Correct")}
              </div>
            ) : (
              <div className={classes.statusWrong}>
                {tx("incorresdsd", "Incorrect")}
              </div>
            )}
            {!correct ? (
              <div className={classes.corrected}>
                {tx("aweoojoz", "Corrected:")}
              </div>
            ) : (
              ""
            )}
            <div>{compareLine}</div>
            {!correct ? (
              <React.Fragment>
                <div className={classes.corrected}>
                  {tx("asdfasdfasdw", "Correctly:")}
                </div>
                <div>{currentSentence}</div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        ) : (
          <React.Fragment>
            <textarea
              ref={textAreaRef}
              autoCapitalize="off"
              autoComplete="off"
              spellCheck="false"
              autoCorrect="off"
              onChange={changeAnswer}
              onKeyDown={textAreaKeyDown}
              value={answer}
              className={classes.textarea}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </React.Fragment>
        )}
      </div>

      <div className={classes.trans}>{sentences[sentenceIndex].foreign}</div>

      <div className={classes.horizontal}>
        <div className={classes.left}>
          {tx("praclkl", "Practiced now:") + " " + sentenceCount}
        </div>
        <div className={classes.right}>
          <ButtonBase className={classes.button} onClick={playSentence}>
            <PlayArrow />
          </ButtonBase>
          <ButtonBase
            ref={nextRef}
            variant="outlined"
            className={classes.button}
            color="secondary"
            onClick={newSentence}
          >
            <AddIcon />
          </ButtonBase>
          {shown ? (
            <ButtonBase
              variant="outlined"
              className={classes.button}
              color="secondary"
              onClick={closeCheck}
            >
              <Close />
            </ButtonBase>
          ) : (
            <ButtonBase
              variant="outlined"
              className={classes.button}
              color="secondary"
              onClick={checkText}
            >
              <Check />
            </ButtonBase>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Writer;

const compare = (oldString, newString) => {
  var diff = Diff.diffChars(oldString, newString);
  let result = "";

  diff.forEach(function (part) {
    // green for additions, red for deletions
    // grey for common parts
    result =
      result +
      (part.added
        ? `<span style='color: blue; font-weight: bold;'>${part.value}</span>`
        : part.removed
        ? `<span style='color: red; font-weight: bold; text-decoration: line-through'>${part.value}</span>`
        : part.value);
  });
  return result;
};

//<div className={classes.text}>
//        {tx(
//          "wlwwwwwllkcccc",
//          "Practice writing by typing in the sentence you hear."
//        )}{" "}
//        {tx("1wlwwwwwllkcccc", "Press play to hear the sentence.")}
//      </div>

//<div className={classes.line} />
