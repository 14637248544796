import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const creationSlice = createSlice({
  initialState: {} as any,
  name: "creation",
  reducers: {
    setCreation: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setCreationOne: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setCreation, setCreationOne } = creationSlice.actions;

export default creationSlice.reducer;
