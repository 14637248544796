import { updatePosition } from "firebaseUtil/firebaseUtil.firestore";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { LessonStatusType } from "Redux/reducers/reducer.lessonStatus";
import { setPositionCurrentPosition } from "Redux/reducers/reducer.position";
import store from "Redux/store";
import { phaseOut } from "utils/utils";

import {
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Theme,
  Typography,
} from "@material-ui/core";

import { validate } from "../utils/findNextExercise";
import { tx } from "../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedDial: {
      transition: "all 0.3s",
      zIndex: 500,
      position: "fixed",
      bottom: theme.spacing(2),
      left: theme.spacing(2),

      "@media (min-width: 821px)": {
        bottom: 25 + theme.spacing(2),
        left: "calc(50vw - 406px + " + theme.spacing(2) + "px)",
      },
    },
    themeColored: {
      stroke: "var(--toolbar-background)",
      fill: "var(--toolbar-background)",
    },
    greyColored: { stroke: "grey", fill: "white" },
    whiteIcon: {
      stroke: "white",
      fill: "white",
      fontSize: "0.75rem",
    },
    blackIcon: { fill: "black", fontSize: "0.75rem" },
    invisible: { opacity: 0, transition: "all 0.3s" },
    iconMenuItem: { paddingTop: "0px", paddingBottom: "0px" },
    small: { maxHeight: "calc(var(--vh) - 32px)" },
    speedDialMoved: {
      transition: "all 0.3s",
      zIndex: 2100,
      position: "fixed",
      bottom: theme.spacing(2),
      left: theme.spacing(2) + 75,

      "@media (min-width: 821px)": {
        bottom: 25 + theme.spacing(2),
        left: "calc(50vw - 406px + " + theme.spacing(2) + "px + 75px)",
      },
    },
    active: {
      color: "var(--toolbar-background)",
    },
    menuList: {
      marginTop: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      minWidth: "210px",
    },
    paper: { marginTop: "20px" },
    fabButton: {
      color: "#898989",
      backgroundColor: "#fefefe",
      "@media (hover: hover)": {
        "&:hover:hover:hover": {
          color: "#898989",
          backgroundColor: "#fefefe",
        },
      },
      "&:focus:focus:focus": {
        color: "#898989",
        backgroundColor: "#fefefe",
      },
      "&:active:active:active": {
        color: "#898989",
        backgroundColor: "#fefefe",
      },
      "&.MuiFab-primary:hover": {
        backgroundColor: "#fafafa",
      },
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    one: {
      flex: "1 0 65px",
    },
  })
);

const NavMenu = (props: any) => {
  const classes = useStyles();

  const lessonStatus = useSelector<RootState, LessonStatusType>(
    (state) => state.lessonStatus
  );
  const lessonData = useSelector<RootState, Document>(
    (state) => state.lessonData
  );

  const lessonParts = lessonData?.getElementsByTagName("part");
  const menuParts: any[] = [];

  const counter: any = {};

  if (lessonParts) {
    for (let g = 0; g < lessonParts.length; g++) {
      let thisone = lessonParts[g].getAttribute("type") || "";
      if (!validate(thisone)) {
        continue;
      }
      counter[thisone] = (counter[thisone] || 0) + 1;
      switch (thisone) {
        case "text":
          menuParts.push({
            text: tx("wwewe1", "Theory"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
        case "card":
          menuParts.push({
            text: tx("wwewe2", "Practice cards"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
        case "quiz":
          menuParts.push({
            text: tx("wwewe3", "Quiz"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
        case "qual":
          menuParts.push({
            text: tx("wwewe4", "Pre-testing"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
        case "lessonend":
          menuParts.push({
            text: tx("wweweww4", "Completion"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
        case "lessonintro":
          menuParts.push({
            text: tx("wwewcwsewdeww4", "Introduction"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
        case "sound":
          menuParts.push({
            text: tx("wweweww433", "Pronunciation"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
        case "writer":
          menuParts.push({
            text: tx("wweweww433333", "Writing drill"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
        case "conversation":
          menuParts.push({
            text: tx("wwewe5", "Conversation"),
            index: g,
            active: lessonStatus.step === g,
            type: thisone,
            count: counter[thisone],
          });
          break;
      }
    }

    menuParts.forEach((item) => {
      if (counter[item.type] > 1) {
        item.text = item.text + " (" + item.count + ")";
      }
    });
  }

  const selectPart = (index: number) => {
    props.setOpen(false);
    if (index !== store.getState().position.currentPosition) {
      phaseOut();
      setTimeout(() => {
        if (store.getState().lessonStatus.browsing) {
          store.dispatch(setPositionCurrentPosition(index));
        } else {
          updatePosition({ currentPosition: index });
        }
      }, 150);
    }
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <React.Fragment>
      <Menu
        open={props.open}
        MenuListProps={{
          style: { paddingTop: "0px", paddingBottom: "0px" },
          className: classes.menuList,
        }}
        classes={{ paper: classes.small }}
        PaperProps={{
          style: { marginTop: "25px", maxHeight: "calc(100% - 60px)" },
        }}
        onClose={handleClose}
        anchorEl={props.lessonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        hideBackdrop={false}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
      >
        <MenuList>
          {menuParts?.map((item: any, index: number) => (
            <MenuItem
              style={{ paddingRight: "25px" }}
              key={item.index}
              onClick={() => selectPart(item.index)}
            >
              <div
                className="MuiListItemIcon-root"
                style={{
                  fontFamily: "var(--font-normal)",
                  fontSize: "12px",
                  minWidth: "30px",
                  paddingTop: "4px",
                  marginRight: "5px",
                  marginLeft: "-4px",
                }}
              >
                <svg
                  className={
                    "MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active " +
                    (item.active ? classes.themeColored : classes.greyColored)
                  }
                  focusable="false"
                  viewBox="-1 -1 26 26"
                  aria-hidden="true"
                >
                  <circle cx="12" cy="12" r="12"></circle>
                  <text
                    className={
                      item.active ? classes.whiteIcon : classes.blackIcon
                    }
                    x="12"
                    y="16"
                    textAnchor="middle"
                  >
                    {index + 1}
                  </text>
                </svg>
              </div>
              <Typography
                variant="inherit"
                className={item.active ? classes.active : ""}
              >
                {item.text}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
};

export default NavMenu;
