import React from "react";
import { makeStyles, Theme, createStyles, ButtonBase } from "@material-ui/core";
import { tx } from "../utils/globalize";
import { useHistory } from "react-router-dom";
import { phaseOut } from "../utils/utils";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: "-20px -20px 20px -20px",
      padding: "0px 10px 0px 20px",
      alignItems: "center",
      height: "50px",
      background: "var(--theme-color-bg)",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      display: "flex",
      flexDirection: "row",
      "@media (min-width: 821px)": {
        margin: "-35px -35px 35px -35px",
      },
    },
    button: {
      background: "var(--theme-color)",
      color: "white",
      padding: "8px 14px 8px 14px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      textTransform: "none",
      borderRadius: "3px",
    },
    flexleft: { flex: "1 1 auto" },
    flexright: { flex: "0 0 auto" },
  })
);

const PayNow = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const showBuy = () => {
    phaseOut();
    setTimeout(() => {
      history.push(props.type === "expired" ? "/app/checkout" : "/app/account");
    }, 150);
  };

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.flexleft}>
          {props.type === "expired"
            ? tx("membelklk", "Your membership has expired.")
            : tx("membelklk1", "You suspended your membership.")}
        </div>
        <div className={classes.flexright}>
          <ButtonBase onClick={showBuy} className={classes.button}>
            {tx("staasdfrtxxxx", "Options")}
          </ButtonBase>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PayNow;
