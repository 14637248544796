import React, { useEffect } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { allLanguages } from "../utils/languages";
import store from "Redux/store";
import { setTempLanguage } from "Redux/reducers/reducer.tempLanguage";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    wrapperTable: {
      background: "var(--table-color)",
      display: "flex",
      flexDirection: "column",
      padding: "6px 0px 6px 0px",
      fontSize: "13px",
      textAlign: "center",
      alignItems: "flex-center",
      borderRadius: "3px",
      minWidth: "75px",
      maxWidth: "86px",
      flex: "1 1 auto",
      marginLeft: "5px",
      marginBottom: "4px",
      marginRight: "3px",
      cursor: "pointer",
      "&&:empty": {
        height: 0,
        border: "none",
        padding: 0,
      },
    },
  })
);

const Language = (props: any) => {
  const classes = useStyles();

  const { language, setButtonForward } = props;

  useEffect(() => {
    if (language) {
      setButtonForward(true);
    } else {
      setButtonForward(false);
    }
  }, [language, setButtonForward]);

  const changeLanguage = (lang: any) => {
    store.dispatch(setTempLanguage(lang));
  };

  return (
    <React.Fragment>
      <div className={classes.flex}>
        {allLanguages.map((country: any) => (
          <div
            className={classes.wrapperTable}
            key={country.code}
            onClick={() => changeLanguage(country.code)}
            style={{
              outline:
                country.code === language
                  ? "1px solid var(--toolbar-background)"
                  : "none",
            }}
          >
            <div>
              <img
                src={"/flags/" + country.code + ".png"}
                alt={country.ownName}
                width="71px"
              />
            </div>
            <div>{country.ownName}</div>
          </div>
        ))}
        <div className={classes.wrapperTable} />
        <div className={classes.wrapperTable} />
        <div className={classes.wrapperTable} />
        <div className={classes.wrapperTable} />
        <div className={classes.wrapperTable} />
        <div className={classes.wrapperTable} />
        <div className={classes.wrapperTable} />
        <div className={classes.wrapperTable} />
      </div>
    </React.Fragment>
  );
};

export default Language;
