import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const textPosSlice = createSlice({
  initialState: {} as any,
  name: "textPos",
  reducers: {
    setTextPos: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setTextPosOne: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setTextPos, setTextPosOne } = textPosSlice.actions;

export default textPosSlice.reducer;
