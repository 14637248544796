import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "Redux/reducers/_index";
import { setProceedAction } from "Redux/reducers/reducer.proceed";
import { setTutorial } from "Redux/reducers/reducer.tutorial";
import store from "Redux/store";
import { tx } from "utils/globalize";
import { nextExercise } from "utils/progress/stepper";

// @material-ui/core components
import { ButtonBase, createStyles, makeStyles, Theme } from "@material-ui/core";

import { phaseBodyIn, phaseIn, phaseOut } from "../utils/utils";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--toolbar-background)",
      fontSize: "21px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    smalltitle: {
      paddingTop: "15px",
      paddingBottom: "10px",
      color: "black",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      flex: "1 1 auto",
    },
    greytext: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      color: "grey",
    },
    playme: {
      textAlign: "center",
    },

    line: {
      height: "0px",
      width: "100%",
      borderBottom: "1px solid var(--line-grey)",
      marginBottom: "18px",
    },
    version: {
      fontSize: "12px",
      fontFamily: "var(--font-normal)",
    },
    important: {
      background: "var(--toolbar-background-bg)",
      padding: "5px 15px 5px 15px",
      borderRadius: "3px",
      border: "1px solid var(--toolbar-background)",
      marginBottom: "20px",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      fontSize: "16px",
      fontFamily: "var(--font-normal)",
      lineHeight: "130%",
    },
    flexright: { flex: "0 0 50px" },
    flexleft: { flex: "1 1 auto" },
    buttonDiv: { marginTop: "20px", textAlign: "right", marginBottom: "30px" },
    playIcon: {
      color: "var(--toolbar-background)",
      padding: "9px",
    },

    button: {
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginLeft: "10px",
    },
    video: {
      width: "100%",
      "@media (max-width: 750px)": { width: "100%" },
    },
    videoXWrapper: {
      marginTop: "25px",
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      lineHeight: 0,
      paddingLeft: "20px",
      paddingRight: "20px",
      marginBottom: "35px",
    },
    videoWrapper: {
      maxWidth: "450px",
      border: "5px solid lightgrey",
      lineHeight: 0,
      marginLeft: "auto",
      marginRight: "auto",
    },
    videoContainer: {
      position: "relative",
      width: "100%",
      paddingBottom: "56.25%",
      height: 0,
      marginBottom: "0px",
      zIndex: 10000,
      transform: "scale(1)",
    },
    videoFrame: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
    },
  })
);

// const { detect } = require("detect-browser");
// const browser = detect();

// const standalone = window.matchMedia("(display-mode: standalone)").matches;

const FirstSteps = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  // const showFirstPage = useSelector<RootState, boolean>(
  //   (state) => state.settings.showFirstPage
  // );

  const loaded = useSelector<RootState, any>(
    (state) => state.appTransParts.init
  );

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  useEffect(() => {
    store.dispatch(setTutorial(false));
  }, []);

  useEffect(() => {
    store.dispatch(
      setProceedAction({
        action: nextExercise,
        visibleAction: false,
        actionIcon: "forward",
      })
    );
    document.getElementById("ipaper")?.scrollTo(0, 0);
    if (!loaded) {
      setTimeout(() => {
        phaseBodyIn();
        phaseIn();
      }, 0);
    }
  }, [loaded]);

  // const installer = () => {
  //   if (
  //     !standalone &&
  //     browser.os === "Android OS" &&
  //     beginsWith(browser.name, ["samsung", "chrome"])
  //   ) {
  //     return (
  //       <React.Fragment>
  //         <div className={classes.important}>
  //           <div className={classes.smalltitle}>
  //             {tx("firststeps41", "Icon to your home screen")}
  //           </div>
  //           <div className={classes.flex}>
  //             <div className={classes.text}>
  //               {tx(
  //                 "firststeps331",
  //                 "Run NetEnglish from your home screen with a click. See help for instructions."
  //               )}
  //             </div>
  //             <div className={classes.flexright}>
  //               <IconButton onClick={showHelpAndroid}>
  //                 <PlayCircleOutlineIcon />
  //               </IconButton>
  //             </div>
  //           </div>
  //         </div>
  //       </React.Fragment>
  //     );
  //   }

  //   if (
  //     !standalone &&
  //     browser.os === "iOS" &&
  //     beginsWith(browser.name, ["ios"])
  //   ) {
  //     return (
  //       <React.Fragment>
  //         <div className={classes.important}>
  //           <div className={classes.smalltitle}>
  //             {tx("firststeps2", "Icon to your home screen")}
  //           </div>
  //           <div className={classes.flex}>
  //             <div className={classes.text}>
  //               {tx(
  //                 "firststeps51",
  //                 "Run NetEnglish from your home screen with a click. See help for instructions."
  //               )}
  //             </div>
  //           </div>
  //           <div className={classes.playme}>
  //             <IconButton onClick={showHelpIPhone}>
  //               <PlayCircleOutlineIcon />
  //             </IconButton>
  //           </div>
  //         </div>
  //       </React.Fragment>
  //     );
  //   }

  //   if (
  //     !standalone &&
  //     browser.os === "iOS" &&
  //     beginsWith(browser.name, ["ios-webview", "chrome", "facebook"])
  //   ) {
  //     return (
  //       <React.Fragment>
  //         <div className={classes.important}>
  //           <div className={classes.smalltitle}>
  //             {tx("firststeps22", "Use another browser")}
  //           </div>
  //           <div className={classes.flex}>
  //             <div className={classes.text}>
  //               {tx(
  //                 "firststeps12",
  //                 "Please use NetEnglish from the Safari browser on iOS for the best user experience."
  //               )}
  //             </div>
  //             <div className={classes.flexright}>
  //               <IconButton>
  //                 <PlayCircleOutlineIcon />
  //               </IconButton>
  //             </div>
  //           </div>
  //         </div>
  //       </React.Fragment>
  //     );
  //   }

  //   if (
  //     !standalone &&
  //     browser.os === "Android OS" &&
  //     beginsWith(browser.name, ["chromium-webview", "facebook"])
  //   ) {
  //     return (
  //       <React.Fragment>
  //         <div className={classes.smalltitle}>
  //           {tx("firststeps7", "Use another browser")}
  //         </div>
  //         <div className={classes.flex}>
  //           <div className={classes.text}>
  //             {tx(
  //               "firststeps6",
  //               "Please use NetEnglish from the Chrome browser on Android phones for the best user experience."
  //             )}
  //           </div>
  //           <div className={classes.flexright}>
  //             <IconButton>
  //               <PlayCircleOutlineIcon />
  //             </IconButton>
  //           </div>
  //         </div>
  //       </React.Fragment>
  //     );
  //   }
  //   return "";
  // };

  // const showHelpAndroid = () => {
  //   store.dispatch(setHelpSrc(<HelpAndroid />));
  //   store.dispatch(setHelpOpen(true));
  // };

  // const showHelpIPhone = () => {
  //   store.dispatch(setHelpSrc(<HelpIPhone />));
  //   store.dispatch(setHelpOpen(true));
  // };

  // const showHelpGeneral = () => {
  //   store.dispatch(setHelpSrc(<HelpDashboard />));
  //   store.dispatch(setHelpOpen(true));
  //   updateHelpTopics({ dashboard: 5 });
  // };

  // const changeShowFirstPage = () => {
  //   updateSetting({ showFirstPage: !showFirstPage });
  // };

  const gotoDashboard = () => {
    phaseOut();
    setTimeout(() => {
      history.push("/add/dashboard");
    }, 150);
  };

  return (
    <React.Fragment>
      <div className={classes.bigtitle}>
        {tx("134settingsaaaawewe", "Important First Steps")}
      </div>
      <div className={classes.text}>
        {tx(
          "firststeps1werasd",
          "Welcome to WizzWords! Please watch the following video to get some immediate instructions on how to start."
        )}
      </div>
      <div className={classes.text}>
        {tx(
          "firststeps1asda",
          "Otherwise you can always get help by clicking on the little question mark in the top right corner."
        )}{" "}
        {tx(
          "firststeps1asda3",
          "The more thoroughly you read the instructions the faster you will learn and the more fun you will have!"
        )}
      </div>

      <div className={classes.videoXWrapper}>
        <div className={classes.videoWrapper}>
          <video
            className={classes.video}
            id="thisvideo"
            playsInline
            controls
            controlsList="nodownload"
            poster={`https://app.wizzwords.net/images/${currentUser?.language}/covershot.png`}
          >
            <source
              src={`https://wizzwords.net/images/${currentUser?.language}/SZKV-utmutato-s.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      {/* {installer()} */}
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={showFirstPage}
            onChange={changeShowFirstPage}
            name="checkedA"
          />
        }
        label={tx("donotshowlslls1", "Alerts on the dashboard.")}
      /> */}

      {/* <div className={classes.smalltitle}>
        {tx("firststeps5sd6", "NetEnglish Basics")}
      </div>
      <div className={classes.flex}>
        <div className={classes.text}>
          {tx("firstst11epsas", "How this tool works")}
        </div>
      </div>
      <div className={classes.playme}>
        <IconButton className={classes.playIcon} onClick={showHelpGeneral}>
          <PlayCircleOutlineIcon />
        </IconButton>
      </div>
      <div className={classes.smalltitle}>
        {tx("firststepswsd", "NetEnglish help")}
      </div> */}

      <div className={classes.buttonDiv}>
        <ButtonBase className={classes.button} onClick={gotoDashboard}>
          {tx("ocoasdntinunextsdjwweel", "Let's have fun!")}
        </ButtonBase>
      </div>
    </React.Fragment>
  );
};

export default FirstSteps;
