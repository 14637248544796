import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setStatus } from "Redux/reducers/reducer.status";
import store from "Redux/store";

import {
  Divider,
  IconButton,
  List,
  ListItemSecondaryAction,
  Menu,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreHorizTwoTone,
  OndemandVideoTwoTone,
  SubjectTwoTone,
} from "@material-ui/icons";

import { firestore } from "../../firebaseUtil/firebaseUtil";
import { phaseIn, phaseOut, updateReaderBulk } from "../words.utils";
import { tx } from "../../utils/globalize";

const ToolbarReader = (props: any) => {
  const { classes } = props;
  const textIconRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const reader = useSelector<RootState, any>((state) => state.reader);
  const status = useSelector<RootState, string>((state) => state.status);

  const setText = useCallback(
    async (count: number) => {
      phaseOut("xreader");

      const thisId = reader.currentText.id;
      setAnchorEl(null);

      const itemIndex = reader.currentList.texts.findIndex(
        (item: any) => item.id === thisId
      );
      const item = reader.currentList.texts[itemIndex + count];

      // store.dispatch(setWordbarOne({ id: "player", value: null }));

      let newText: any;

      if (reader.currentList.own) {
        newText = await firestore
          .doc(
            `/users/${store.getState().currentUser?.id || "a"}/texts/${item.id}`
          )
          .get();
      } else {
        newText = await firestore.doc(`/texts/${item.id}`).get();
      }

      let add = {};

      if (reader.currentList.id === "v1") {
        add = {
          lastVideo: {
            listId: reader.currentList.id,
            textId: newText.data()?.id,
          },
        };
      } else {
        if (reader.currentList.id.indexOf("gr")) {
          add = {
            lastText: {
              listId: reader.currentList.id,
              textId: newText.data()?.id,
            },
          };
        }
      }

      let updateValue: any = {
        currentText: {
          ...(newText.data() || { title: "", text: "", id: "" }),
          text: (newText.data().text || "").replace("|", "\n"),
          list: reader.currentList.title,
          listId: reader.currentList.id,
          id: newText.data()?.id,
        },
        ...add,
      };

      updateReaderBulk(updateValue);
      const xreader = document.getElementById("xreader");
      if (xreader) {
        xreader.scrollTop = 0;
      }
      setTimeout(() => phaseIn("xreader"), 150);
    },
    [reader]
  );

  const textIndex = reader.currentList.texts.findIndex(
    (item: any) => item.id === reader.currentText.id
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMenu = (e: any) => {
    setAnchorEl(e.target);
  };

  const showList = (e: any) => {
    setAnchorEl(null);
    store.dispatch(setStatus("loader"));
  };

  const loadListNext = useCallback(
    async (itemId: any) => {
      let gone: any = [];

      setAnchorEl(null);

      gone = await firestore.doc(`/textlists/${itemId}`).get();
      if (gone.data()) {
        const texts = gone.data().texts?.map((item: any) => ({
          ...item,
          done: !!reader.history.find((hist: any) => hist.id === item.id),
        }));

        let firstItem = texts.find((item: any) => !item.done);

        if (!firstItem) {
          firstItem = texts[0];
        }

        const firstText = (
          await firestore.doc(`/texts/${firstItem.id}`).get()
        ).data();

        const xreader = document.getElementById("xreader");
        if (xreader) {
          xreader.scrollTop = 0;
        }

        let add = {};

        if (itemId === "v1") {
          add = { lastVideo: { listId: itemId, textId: firstText?.id } };
        } else {
          if (itemId.indexOf("gr")) {
            add = { lastText: { listId: itemId, textId: firstText?.id } };
          }
        }

        updateReaderBulk({
          currentList: {
            ...gone.data(),
            texts,
            title: gone.data().title,
            id: itemId,
            own: false,
          },
          currentText: {
            ...firstText,
            text: firstText?.text.replace("|", "\n"),
            list: gone.data().title,
            listId: itemId,
            id: firstText?.id,
          },
          ...add,
        });
      }
    },
    [reader.history]
  );

  return (
    <React.Fragment>
      {status === "reader" ? (
        <React.Fragment>
          <IconButton
            className={classes.icon1text}
            onClick={showMenu}
            ref={textIconRef}
          >
            {reader.currentList.id?.substr(0, 2).toUpperCase()}
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{ list: classes.list }}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <List className={classes.list}>
              <ListItem onClick={showList} style={{ cursor: "pointer" }}>
                <ListItemText
                  className={classes.listItem}
                  primary={tx("dala1x", "See list")}
                />
                <ListItemSecondaryAction className={classes.mIcon}>
                  <MoreHorizTwoTone />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />

              <ListItem
                selected={reader.currentText.listId === "v1"}
                onClick={() => loadListNext("v1")}
                style={{ cursor: "pointer" }}
              >
                <ListItemText primary={tx("dala34", "Videos")} />
                <ListItemSecondaryAction className={classes.mIcon}>
                  <OndemandVideoTwoTone />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />

              <ListItem
                selected={reader.currentText.listId === "l4"}
                onClick={() => loadListNext("l4")}
                style={{ cursor: "pointer" }}
              >
                <ListItemText primary={tx("dala1l4", "Level 4")} />
                <ListItemSecondaryAction className={classes.mIcon}>
                  <SubjectTwoTone />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                selected={reader.currentText.listId === "l3"}
                onClick={() => loadListNext("l3")}
                style={{ cursor: "pointer" }}
              >
                <ListItemText primary={tx("dala1l3", "Level 3")} />
                <ListItemSecondaryAction className={classes.mIcon}>
                  <SubjectTwoTone />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                selected={reader.currentText.listId === "l2"}
                onClick={() => loadListNext("l2")}
                style={{ cursor: "pointer" }}
              >
                <ListItemText primary={tx("dala1l2", "Level 2")} />
                <ListItemSecondaryAction className={classes.mIcon}>
                  <SubjectTwoTone />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                selected={reader.currentText.listId === "l1"}
                onClick={() => loadListNext("l1")}
                style={{ cursor: "pointer" }}
              >
                <ListItemText primary={tx("dala1l1", "Level 1")} />
                <ListItemSecondaryAction className={classes.mIcon}>
                  <SubjectTwoTone />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                selected={reader.currentText.listId === "l0"}
                onClick={() => loadListNext("l0")}
                style={{ cursor: "pointer" }}
              >
                <ListItemText primary={tx("dala1l0", "Level 0")} />
                <ListItemSecondaryAction className={classes.mIcon}>
                  <SubjectTwoTone />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Menu>

          <IconButton
            className={classes.icon2}
            disabled={textIndex === 0}
            onClick={() => setText(-1)}
          >
            <KeyboardArrowLeft />
          </IconButton>

          <IconButton
            className={classes.icon3}
            disabled={textIndex === reader.currentList.texts.length - 1}
            onClick={() => {
              setText(1);
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default ToolbarReader;
