import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import React from "react";

export interface DialogType {
  open: boolean;
  title: string;
  text: string | React.ReactNode;
  button1Text: string;
  button2Text: string;
  button1action: any;
  button2action: any;
}

const initialState: DialogType = {
  open: false,
  title: "",
  text: "",
  button1Text: "",
  button2Text: "",
  button1action: () => {},
  button2action: () => {},
};

const dialogSlice = createSlice({
  initialState,
  name: "dialog",
  reducers: {
    setDialog: (state: DialogType, action: PayloadAction<DialogType>) => {
      return action.payload;
    },
    setDialogOpen: (state: DialogType, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { setDialog, setDialogOpen } = dialogSlice.actions;

export default dialogSlice.reducer;
