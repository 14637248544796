import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QuizResultsSubType {
  quiz: any;
  answers: number[];
}

export type QuizResultsType = QuizResultsSubType | null;

const quizResultSlice = createSlice({
  initialState: null as QuizResultsType,
  name: "quizResult",
  reducers: {
    setQuizResult: (
      state: QuizResultsType,
      action: PayloadAction<QuizResultsType>
    ) => {
      return action.payload;
    }
  }
});

export const { setQuizResult } = quizResultSlice.actions;

export default quizResultSlice.reducer;
