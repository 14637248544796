import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      padding: "25px",
      paddingTop: "0px",
      maxWidth: "900px",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "45px",
    },
    text: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      marginBottom: "10px",
      lineHeight: "130%",
    },
    mainHeader: {
      fontSize: "26px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      marginTop: "25px",
      marginBottom: "15px",
      lineHeight: 1.3,
    },
    header: {
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      marginTop: "25px",
      marginBottom: "15px",
      lineHeight: 1.3,
    },
  })
);

const Subscription = (props: any) => {
  const classes = useStyles();
  const { tt } = props;

  useEffect(() => {
    if (props.subscription) {
      document.getElementById("subscription")?.scrollIntoView();
    }
  }, [props.subscription]);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.mainHeader}>
          {tt("sst1a", "Subscription Terms")}
        </div>
        <div className={classes.text}>
          {tt(
            "sst2a",
            "These subscription terms (“Terms”, “Agreement”) are an agreement between NetEnglish Solutions OÜ Hungarian Permanent Establishment (“NetEnglish Solutions OÜ”, “us”, “we” or “our”) and you (“User”, “you” or “your”)."
          )}{" "}
          {tt(
            "sst3a",
            "This Agreement sets forth the general terms and conditions of your subscription on the netenglish.com website."
          )}
        </div>
        <div className={classes.header}>
          {tt("st13a", "Company and contact data of Service Provider")}
        </div>
        <div className={classes.text}>
          {tt(
            "t214acx",
            "*Name:* NetEnglish Solutions OÜ Hungarian Permanent Establishment."
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "t214aacx",
            "*Address:* 9700 Hungary, Szombathely, Deák Ferenc utca 29."
          )}
        </div>
        <div className={classes.text}>
          {tt("t215acw", "*Website:* https://netenglish.com")}
        </div>
        <div className={classes.text}>
          {tt("t216acw", "*Tax Number:* 27972984-2-18")}
        </div>
        <div className={classes.text}>
          {tt("t217acw", "*E-mail:* support@netenglish.com")}
        </div>
        <div className={classes.text}>
          {tt("t218acw", "*Company Registry Number:* 18-17-000025")}
        </div>
        <div className={classes.text}>
          {tt(
            "t219acw",
            "*Hosted by:* Google Cloud Hosting (support@google.com)"
          )}
        </div>
        <div className={classes.header}>
          {tt("st20a", "Subscription Terms")}
        </div>
        <div className={classes.text}>
          {tt(
            "st21a",
            "You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable."
          )}{" "}
          {tt(
            "st21b",
            "You can make subscriptions with Visa and Mastercard cards or PayPal through third-party payment gateways."
          )}{" "}
        </div>

        <div id="subscription" className={classes.header}>
          {tt("st20n", "Subscriptions")}
        </div>
        <div className={classes.text}>
          {tt(
            "st23o",
            "If you select monthly or semiannual subscription, you will be charged automatically in accordance with the term you selected. You specifically have to agree to this by selecting these subscription services and approving your choice through our payment gateway."
          )}{" "}
          {tt(
            "st23oa",
            "We charge your approved means of payment (bank card or PayPal account) on the same day of the month each month (if you have a monthly subscription) or every six month (if you have a semiannual subscription)."
          )}{" "}
          {tt("st23oaa", "We will send you an invoice of each payment.")}{" "}
          {tt(
            "st24pa",
            "We reserve the right to change products and product pricing at any time."
          )}{" "}
          {tt(
            "s24q",
            "However we never change prices on subscriptions you already started without prior authorization from you."
          )}{" "}
          {tt(
            "st26r",
            "You can always cancel your subscription without notice under Account in the menu by clicking on the Cancel button in the Subscription panel."
          )}{" "}
          {tt(
            "st26s",
            "If you cancel your subscription you will not be charged again unless you specifically autorize us again by creating a new subscription."
          )}{" "}
          {tt(
            "sst26t",
            "If you cancel your subscription you can continue to use NetEnglish for the balance of your last subscription period."
          )}
        </div>

        <div className={classes.header}>
          {tt("st127a", "Suspension of Subscription")}
        </div>
        <div className={classes.text}>
          {tt(
            "st1128a",
            "If you are not on an unlimited plan you can suspend your membership once every 30 days. This means that your remaining days till the expiration of your membership or till the next billing will be preserved until you unsuspend your account."
          )}{" "}
          {tt(
            "st1129d",
            "For example if you had 20 days left till the next billing and you suspend your account for two months, then upon restarting it you will still have 20 days to go."
          )}{" "}
          {tt(
            "st1130d",
            "The next billing (if you have a running subscription) will only occur when the 20 days run out."
          )}{" "}
        </div>
        <div className={classes.text}>
          {tt(
            "st1131d",
            "*Note:* If you suspend your account and then unsuspend it you can only suspend it again when 30 days passed since the last unsuspension."
          )}{" "}
        </div>

        <div className={classes.header}>
          {tt("st77d", "Acceptance of these terms")}
        </div>
        <div className={classes.text}>
          {tt(
            "st78d",
            "You acknowledge that you have read this Agreement and agree to all its terms and conditions."
          )}{" "}
          {tt(
            "st79d",
            "By subscribing to our Services you agree to be bound by this Agreement."
          )}{" "}
          {tt(
            "st80d",
            "If you do not agree to abide by the terms of this Agreement, select a different option."
          )}
        </div>
        <div className={classes.header}>{tt("st81d", "Contacting us")}</div>
        <div className={classes.text}>
          {tt(
            "st82d",
            "If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to support@netenglish.com"
          )}
        </div>
        <div className={classes.text}>
          {tt("t83dxx", "This document was last updated on July 6, 2020")}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subscription;
