import React from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpGrammar() {
  const classes = useStyles();

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>{tx("azarn10g", `Grammar`)}</div>
        <div className={classes.helpBold}>
          {tx("azarn11", `What's this whole thing?`)}
        </div>
        <div className={classes.helpText}>
          {tx("gazarn12", `You can practice particular grammar rules here.`)}{" "}
          {tx(
            "gazarn13",
            `If you select a topic you will see a button marked Practice and below that English sentences translated in [yourlang].`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mp3controls.png`}
            className={classes.helpImg}
          />
        </div>

        <div className={classes.helpText}>
          {tx(
            "gazarn14",
            `Those sentences are using the grammar structure you want to practice.`
          )}{" "}
          {tx(
            "gazarn15",
            `If you click on the Practice button above the sentences, you will see a card showing up in a dialog.`
          )}
        </div>

        <div className={classes.helpText}>
          {tx(
            "azarga3",
            `Practice cards give you a simple but effective way to practice words, phrases or any grammar rules for that matter.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx("gazarga3a", `The card look like this:`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/cardgrammar.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx("gazarga6", `Important parts are:`)}
        </div>

        <div className={classes.helpList}>
          {tx("gazarga7", `a) You can close the cards if you click here.`)}
        </div>

        <div className={classes.helpList}>
          {tx(
            "gazarga8",
            `b) Click here to hear the current English text visible on the card.`
          )}
        </div>

        <div className={classes.helpList}>
          {tx("gazarga11", `c) This is the English text on the card.`)}
        </div>
        <div className={classes.helpText}>
          {tx("gazarga12", `The idea is simple.`)}{" "}
          {tx(
            "gazarga31",
            `You look at the English sentence and you translate it to [yourlang].`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga13",
            `If you are not sure about the solution or want to check whether you're right click on the card itself and it turns around.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/card2.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga14",
            `On the other side you'll see the translated version of the card.`
          )}{" "}
          {tx(
            "azarga37",
            `If you click on the card again it turns back to the English text.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga15",
            `When you're done with a card you have two buttons at the bottom:`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/card3.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarga16",
            `a) Click here to bring up the next card in the sequence.`
          )}{" "}
          {tx(
            "azarga38",
            `(In the lower right corner you'll see the number of cards still left for the given word.)`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "gazarga17",
            `b) Click here if you think you have practiced this structure in enough sentences and want to finish practicing it from English to [yourlang].`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga18",
            `If you click b) or you get to the last card the same cards will show up again however now with their translated side first.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx("gazarga19", `Now translate the text back to English.`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga20",
            `You can check your solution if you turn around the card by clicking on it.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga21",
            `Again, you can go to the next card with the right side button or you can finish the cards with the left side button.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "gazarga22",
            `When you finish the last card from [yourlang] to English the cards will close.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "gazarga23",
            `In some files there are more than one practices so look further in the file if there are any more practice assignments.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/grami.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "gazarga24",
            `If there are, click on the Practice button and practice those as well.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "gazarga25",
            `If you click on the close button you will get back to the list of topic for grammar practice.`
          )}{" "}
          {tx(
            "gazarga26",
            `If you click on the close button there you will get back to the Dashboard.`
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
