import React from "react";
// @material-ui/core components

import Dialog from "@material-ui/core/Dialog";

import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { DialogFullType } from "Redux/reducers/reducer.dialogFull";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { margin: "10px" },
  })
);

export default function FullDialog(props: any) {
  const classes = useStyles();
  const dialogFull = useSelector<RootState, DialogFullType>(
    (state) => state.dialogFull
  );

  return (
    <Dialog
      classes={{ paper: classes.container }}
      open={dialogFull.open}
      onClose={dialogFull.cancel}
      aria-labelledby="responsive-dialog-title"
    >
      {dialogFull.content}
    </Dialog>
  );
}
