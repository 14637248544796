import { signInWithEmail, signOut } from "firebaseUtil/firebaseUtil";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { tx } from "utils/globalize";
import { phaseBodyOut } from "utils/utils";

// @material-ui/core components
import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
  Theme,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { phaseBodyIn, phaseIn, phaseOut, getTitle } from "../../utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";
import LanguageList from "./header.language";
import { inIframe, removeListeners } from "../../app/App.utils";
import { removeReaderListener } from "../../words/words.utils";
import store from "Redux/store";
import { setAuthLoaded } from "Redux/reducers/reducer.authLoaded";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "10px",
      color: "black",
      fontSize: "19px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      maxHeight: "47px",
    },
    cardExtra: { background: "white" },
    checked: { color: "var(--toolbar-background)" },
    logoWrapper: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "end",
      flex: "0.5 1 auto",
      transition: "all 1s",
      minHeight: "var(--logoHeight)",
      paddingTop: "var(--logoPadding)",
    },
    placeholder: {
      flex: "0.5 1 5px",
    },
    logo: {
      marginBottom: "5px",
    },
    note: {
      fontSize: "14px",
      width: "223px",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center",
      marginBottom: "10px",
    },
    linkLike: { textDecoration: "underline", cursor: "pointer" },
    firm: {
      paddingBottom: "10px",
      marginTop: "0px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    textCenter: {
      textAlign: "center",
    },
    forgotPassword: {
      fontSize: "90%",
      paddingTop: "16px",
      color: "var(--toolbar-background)",
      "& a": { color: "var(--toolbar-background)" },
    },
    useful: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxHeight: "var(--vh)",
      transition: "all 1s",
      position: "relative",
    },
    lang: {
      position: "absolute",
      top: 5,
      right: 5,
    },
    copyright: {
      justifySelf: "end",
      flex: "3 1 auto",
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "var(--font-normal)",
      color: "#888888",
      fontSize: "15px",
      textAlign: "center",
      margin: "0 auto",
      paddingTop: "15px",
      lineHeight: "130%",
    },

    dialogactions: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
      transition: "all 1s",
    },
    dialogstart: {
      flex: "0 0 60%",
    },
    dialogend: {
      flex: "0 0 40%",
    },
    fields: { flex: "0 0 auto", transition: "all 1s" },

    buttonStart: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "16px",
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      padding: "8px 0px 8px 0px",
      width: "100%",
      marginTop: "0px",
      minHeight: "50px",
      borderRadius: "3px",
      "@media (hover: hover)": {
        "&:hover": {
          background: "var(--toolbar-background)",
          boxShadow: "none",
        },
      },
      "&:hover": {
        background: "var(--toolbar-background)",
        boxShadow: "none",
      },
      "&:disabled": {
        color: "var(--theme-disabled)",
        border: "1px solid var(--theme-disabled)",
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:active": {
        background: "var(--toolbar-background)",
        boxShadow: "none",
      },
      "&:focus": {
        background: "var(--toolbar-background)",
        boxShadow: "none",
      },
      "&:focus:active": {
        background: "var(--toolbar-background)",
        boxShadow: "none",
      },
    },
    buttonCancel: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      color: "var(--secondary-color)",
      padding: "0px 0px 14px 0px",
      marginTop: "0px",
      marginRight: "30px",
      minHeight: "50px",

      "@media (hover: hover)": {
        "&:hover": {
          color: "var(--toolbar-background-dark)",
          boxShadow: "none",
        },
      },
      "&:disabled": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "white",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "white",
        boxShadow: "none",
      },
      "&:focus:active": {
        backgroundColor: "white",
        boxShadow: "none",
      },
    },
    loginError: {
      height: "0px",
      fontSize: "16px",
      lineHeight: "38px",
      margin: "0px auto 7px",
      fontFamily: "var(--font-normal)",
      textAlign: "center",
      color: "red",
      transition: "all 0.3s",
      "&.active": {
        transition: "all 0.3s",
        height: "45px",
      },
    },
    formControl: {
      border: "1px solid grey",
    },
    margin: {
      marginTop: theme.spacing(2),
    },
    checkbox: {
      color: "grey",
    },
    label: {
      fontSize: "14px",
      fontFamily: "var(--font-normal)",
    },
    checkboxDiv: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    signup: {
      color: "#a3a3a3",
      fontSize: "19px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      float: "right",
      cursor: "pointer",
      opacity: 0,
    },
  })
);

const inFrame = inIframe();
const gyakAngol = document.location.href.includes("gyakorlatias-angol");

const LoginCard = () => {
  const [loggingIn, setLoggingIn] = useState(false);
  const userEmail = useRef<HTMLInputElement>();
  const password = useRef<HTMLInputElement>();

  const [loginEmail, setloginEmail] = React.useState("");
  const [loginEmailState, setloginEmailState] = React.useState("");
  const [loginPassword, setloginPassword] = React.useState("");
  const [pwVisible, setPwVisible] = useState(false);

  const [loginPasswordState, setloginPasswordState] = React.useState("");
  // const currentUser = useSelector<RootState, any>(state => state.currentUser);
  const [loginError, setLoginError] = useState("");
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const [language, setLanguage] = useState("");

  const location = useLocation();
  const history = useHistory();

  const loaded = useSelector<RootState, any>(
    (state) => state.appTransParts.init
  );
  const authLoaded = useSelector<RootState, any>((state) => state.authLoaded);

  useSelector<RootState, any>((state) => state.appTransParts);
  const tempLanguage = useSelector<RootState, any>(
    (state) => state.tempLanguage
  );

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  useEffect(() => {
    if (currentUser && !loggingIn) {
      removeListeners();
      removeReaderListener();
      signOut();
    }
  }, [currentUser, loggingIn]);

  useEffect(() => {
    setLanguage(tempLanguage);
  }, [tempLanguage]);

  const classes = useStyles();

  useEffect(() => {
    let thisEmail =
      location.search?.match(/email=.*/)?.[0].replace("email=", "") || "";

    thisEmail = thisEmail.replace(/%40/g, "@").replace(/%2F/g, ".");

    setloginEmail(thisEmail);
    setTimeout(() => {
      if (verifyEmail(thisEmail)) {
        setloginEmailState("success");
        setTimeout(() => {
          password.current?.focus();
        }, 300);
      } else {
        setloginEmailState("error");
      }
    }, 0);
  }, [location.search]);

  useEffect(() => {
    if (!loaded) {
      phaseIn();
      phaseBodyIn();
    }
  }, [loaded, authLoaded]);

  const signIn = () => {
    // setButtonPressed(true);
    setLoggingIn(true);
    setLoginError("");

    if (loginEmail && loginPassword) {
      store.dispatch(setAuthLoaded(false));
      console.log("logging in");
      setTimeout(
        () =>
          signInWithEmail(loginEmail, loginPassword, stayLoggedIn, {
            success: () => {
              phaseOut();
              phaseBodyOut();
              setTimeout(() => {
                let newPath = location.pathname.replace("/login/login", "");
                newPath = newPath ? newPath : "/";
                if (!newPath) {
                  newPath = "/app/dashboard";
                }
                history.push(newPath);
              }, 150);
            },
            failure: (error: string) => {
              setLoginError(error);
              setLoggingIn(false);
              setAuthLoaded(true);
            },
          }),
        0
      );
    }
  };

  const checkForEnter = (event: any) => {
    if (event.key === "Enter") {
      if (loginEmailState === "success" && loginPasswordState === "success") {
        signIn();
      }
    }
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value: string, length: number) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyEmail = (value: any) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const setVisibility = () => {
    setPwVisible(!pwVisible);
    password.current?.focus();
  };

  const changeStayLoggedIn = (event: any) => {
    setStayLoggedIn(event.target.checked);
  };

  const forgotPassword = () => {
    phaseBodyOut();
    setTimeout(() => {
      history.push("/login/forgotten/?email=" + loginEmail);
    }, 300);
  };

  const signUp = () => {
    alert("Hamarosan elérhető!");
    return;
    // phaseBodyOut();
    // setTimeout(() => {
    //   history.push("/login/signup");
    // }, 150);
  };

  return (
    <React.Fragment>
      <div className={classes.useful}>
        {!inFrame && !gyakAngol ? (
          <div className={classes.lang}>
            <LanguageList language={language} setLanguage={setLanguage} />
          </div>
        ) : null}
        <div className={classes.logoWrapper}>
          <div className={classes.logo}>
            <img style={{ width: "80px" }} alt={"seal"} src="/logo192.png" />
          </div>
          <div className={classes.firm}>{getTitle()}</div>
          {inFrame ? (
            <div className={classes.note}>
              Figyelem: a Szókincsvarázslónak külön jelszava van!
            </div>
          ) : null}
        </div>
        <div className={classes.placeholder}></div>
        <div className={classes.fields}>
          <div className={classes.bigtitle}>
            {tx("A2D96234-3E2B-40A6-B012-B4E8993271AF", "User Login")}
            <div onClick={signUp} className={classes.signup}>
              {tx("sisiisiiis", "Sign up")}
            </div>
          </div>
          <div className={classes.loginError + (loginError ? " active" : "")}>
            {loginError}
          </div>

          <div>
            <TextField
              id="outlined-helperText"
              label={tx("53684DA4-43FD-4C94-9857-6273B9FB7542", "E-mail")}
              fullWidth
              type="email"
              value={loginEmail}
              variant="outlined"
              inputRef={userEmail}
              onKeyDown={checkForEnter}
              onChange={(event: any) => {
                if (verifyEmail(event.target.value)) {
                  setloginEmailState("success");
                } else {
                  setloginEmailState("error");
                }
                setloginEmail(event.target.value);
              }}
            />
            <FormControl
              variant={"outlined"}
              fullWidth
              className={classes.margin}
            >
              <InputLabel htmlFor="standard-adornment-password">
                {tx("D348AEF9-EE4D-43A5-95EB-8C5530A7CA60", "Password")}
              </InputLabel>
              <OutlinedInput
                labelWidth={70}
                id="standard-adornment-password"
                type={pwVisible ? "text" : "password"}
                fullWidth
                onKeyDown={checkForEnter}
                onChange={(event: any) => {
                  if (verifyLength(event.target.value, 3)) {
                    setloginPasswordState("success");
                  } else {
                    setloginPasswordState("error");
                  }
                  setloginPassword(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={setVisibility}
                    >
                      {pwVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <div className={classes.dialogactions}>
            <div className={classes.dialogstart}>
              <div className={classes.checkboxDiv}>
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                      checked={stayLoggedIn}
                      onChange={changeStayLoggedIn}
                      name="checkedA"
                      classes={{ checked: classes.checked }}
                      style={{ color: "var(--toolbar-background)" }}
                    />
                  }
                  label={tx(
                    "46AF3449-BC2A-445D-9C61-C70BCC236514543",
                    "Remember me"
                  )}
                />
              </div>
            </div>
            <div className={classes.dialogend}>
              <Button
                disabled={
                  loginPasswordState !== "success" ||
                  loginEmailState !== "success"
                }
                className={classes.buttonStart}
                onClick={signIn}
              >
                {loggingIn ? (
                  <img
                    src="/load1.gif"
                    alt={tx("46AF3449-BC2A-445D-9C61-C70BCC236514", "Wait...")}
                    height="30px"
                  />
                ) : (
                  tx("C1C8922B-B8B1-46F3-9D8A-19127307E1E6", "Login")
                )}
              </Button>
            </div>
          </div>

          <div className={classes.forgotPassword}>
            <span className={classes.linkLike} onClick={forgotPassword}>
              {tx(
                "93FE8CEA-64B8-4BCF-A3AF-0F78016F0A13",
                "I forgot my password"
              )}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginCard;
