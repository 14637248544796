import React from "react";

import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Send } from "@material-ui/icons";

import { shuffleArray } from "../../utils/utils";
import { tx } from "../../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    practiceDiv: { marginBottom: "20px", marginTop: "15px", width: "100%" },
    caption: {
      fontSize: "110%",
      fontWeight: "bold",
      color: "var(--toolbar-background)",
      marginBottom: "10px",
    },
    button: {},
  })
);

const ViewerPractice = (props: any) => {
  const classes = useStyles();

  const { sentences, index } = props;

  const practiceIt = () => {
    const newPractice: any[] = [];

    for (let g = index + 1; g < sentences.length; g++) {
      if (sentences[g].includes(":practice:")) {
        break;
      }

      if (sentences[g].includes("^")) {
        const [english, foreign] = sentences[g].split("^");
        newPractice.push({ english, foreign });
      }
    }
    props.getPractice(shuffleArray(newPractice));
  };

  return (
    <React.Fragment>
      <div className={classes.practiceDiv}>
        {props.src ? <div className={classes.caption}>{props.src}</div> : ""}
        <Button
          onClick={practiceIt}
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<Send />}
        >
          {tx("dala12", "Practice")}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ViewerPractice;
