// @ts-ignore
import thunk, { ThunkAction } from "redux-thunk";

// import logger from 'redux-logger';
import { Action, configureStore } from "@reduxjs/toolkit";

import rootReducer, { RootState } from "./reducers/_index";

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk]
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
