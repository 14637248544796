import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogActions, Button } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

import ReactHtmlParser from "html-react-parser";

const styles = (theme: Theme) => ({
  content: { padding: "20px" },
  paper: {
    margin: "10px",
  },
  intro: {
    fontSize: "18px",
    fontFamily: "var(--font-normal)",
    lineHeight: "130%",
  },
  actions: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});
const useStyles = makeStyles(styles as any);

const TransDialog = (props: any) => {
  const [cont, setCont] = useState<any>("");

  useEffect(() => {
    setCont(
      ReactHtmlParser(props.translated?.replace(/\n/g, "<br/><br/>") || "")
    );
  }, [props.translated]);

  const classes = useStyles();

  const closeDialog = () => {
    props.setOpen(false);
  };

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.paper }}
      onClose={closeDialog}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogContent dividers={true} classes={{ root: classes.content }}>
        <div className={classes.intro}>{cont}</div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={closeDialog} color="primary">
          Bezárás
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransDialog;
