import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const confirmOpenSlice = createSlice({
  initialState: false,
  name: "confirmOpen",
  reducers: {
    setConfirmOpen: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    }
  }
});

export const { setConfirmOpen } = confirmOpenSlice.actions;

export default confirmOpenSlice.reducer;
