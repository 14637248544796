import React, { useEffect } from "react";
import { setHelpOpen, setHelpSrc } from "Redux/reducers/reducer.help";
import { setProceedAction } from "Redux/reducers/reducer.proceed";
import store from "Redux/store";
import { tx } from "utils/globalize";
import { nextExercise } from "utils/progress/stepper";

// @material-ui/core components
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { phaseBodyIn, phaseIn } from "../utils/utils";
import HelpDashboard from "./topics/dashboard-h";
import HelpIPhone from "./topics/20-homescreen_iphone";
import HelpAndroid from "./topics/21-homescreen_android";
import HelpReader from "./topics/reader-h";
import HelpMostUsed from "./topics/mostused-h";
import HelpPron from "./topics/pronunciation-h";
import HelpDictionary from "./topics/dictionary-h";
import HelpCard from "./topics/cards-h";
import HelpVideo from "./topics/video-h";
import HelpGrammar from "./topics/grammar-h";
import HelpWrite from "./topics/writer-h";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "10px",
      paddingTop: "10px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    smalltitle: {
      paddingTop: "10px",
      paddingBottom: "5px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    help: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      color: "grey",
    },

    line: {
      height: "0px",
      width: "100%",
      borderBottom: "1px solid var(--line-grey)",
      marginBottom: "18px",
    },
    version: {
      fontSize: "12px",
      fontFamily: "var(--font-normal)",
    },
  })
);

const Help = (props: any) => {
  const classes = useStyles();

  useEffect(() => {
    store.dispatch(
      setProceedAction({
        action: nextExercise,
        visibleAction: false,
        actionIcon: "forward",
      })
    );

    document.getElementById("ipaper")?.scrollTo(0, 0);

    setTimeout(() => {
      phaseBodyIn();
      phaseIn();
    }, 0);
  }, []);

  const runHelp = (helpFile: any) => {
    store.dispatch(setHelpSrc(helpFile));
    store.dispatch(setHelpOpen(true));
  };

  return (
    <React.Fragment>
      <div className={classes.bigtitle}>
        {tx("settingsasdfasdfaaaarrr", "Installation")}
      </div>
      <div className={classes.line} />
      <div className={classes.help} onClick={() => runHelp(<HelpIPhone />)}>
        {tx("helpabnasdfavigoutreview", "Install on iPhone")}
      </div>
      <div className={classes.help} onClick={() => runHelp(<HelpAndroid />)}>
        {tx("helpabnasdfavigoutand", "Install on Android")}
      </div>
      <div className={classes.bigtitle}>
        {tx("olkasdlkakowera", "WizzWords")}
      </div>
      <div className={classes.line} />
      <div className={classes.help} onClick={() => runHelp(<HelpDashboard />)}>
        {tx("practheorywewer", "Dashboard")}
      </div>
      <div className={classes.help} onClick={() => runHelp(<HelpReader />)}>
        {tx("praccardswe", "Reader")}
      </div>
      <div className={classes.help} onClick={() => runHelp(<HelpVideo />)}>
        {tx("praccardswevid", "Videos")}
      </div>
      <div className={classes.help} onClick={() => runHelp(<HelpCard />)}>
        {tx("pracconvwe", "Word Study")}
      </div>
      <div className={classes.help} onClick={() => runHelp(<HelpMostUsed />)}>
        {tx("pracquasdiz", "Most Used Words")}
      </div>
      <div className={classes.help} onClick={() => runHelp(<HelpGrammar />)}>
        {tx("agata7", "Grammar")}
      </div>
      <div className={classes.help} onClick={() => runHelp(<HelpDictionary />)}>
        {tx("pracquasdizas", "Dictionary")}
      </div>

      <div className={classes.help} onClick={() => runHelp(<HelpPron />)}>
        {tx("pracpron", "Pronunciation Drills")}
      </div>
      <div className={classes.help} onClick={() => runHelp(<HelpWrite />)}>
        {tx("pracwrite", "Writing Drills")}
      </div>
    </React.Fragment>
  );
};

export default Help;
