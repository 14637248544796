import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import store from "../../Redux/store";
import { setContractionOne } from "Redux/reducers/reducer.contractions";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {},
    hling: { color: "blue", fontWeight: "bold" },
    act: { background: "lightgrey" },
    tone: { color: "lightgrey", transition: "all 0.2s" },
    orig: { transition: "all 0.2s" },
  })
);

const WordsWord = React.memo((props: any) => {
  // const classes = useStyles();
  const { stemmer, sentence, splitter, sIndex, lookupWord } = props;
  const [currCode, setCurrCode] = useState("");
  const [currWord, setCurrWord] = useState("");
  const [currGr, setCurrGr] = useState("");
  const [anim, setAnim] = useState(false);
  const [contaction, setContaction] = useState(false);

  const unContracted = useSelector<RootState, any>(
    (state) => state.contractions["a" + sIndex + "-" + props.index]
  );

  useEffect(() => {
    const parts = props.word.split("{");

    // const showWord = parts[1]?.replace("{", "") || "";
    const partsNew = parts[0]?.split("[") || "";

    if (unContracted || !parts[1] || parts[1] === "}") {
      setCurrWord(partsNew[0]);
      setContaction(false);
    } else {
      if (parts[1] === "-}") {
        setCurrWord("");
      } else {
        setCurrWord(parts[1]?.replace("}", ""));
        setContaction(true);
      }
    }

    const id = partsNew[1]?.replace("]", "").split("*");

    setCurrCode(id?.[0] || "");
    setCurrGr(id?.[1] || "");
  }, [props.word, unContracted]);

  const myWord = useSelector<RootState, any>((state) => state.words[currCode]);

  const highlight = useSelector<RootState, any>(
    (state) => state.settings.highlight
  );

  let style = "";

  if (myWord && highlight) {
    if (myWord.done === 50000) {
      style = "zdone";
    } else {
      style = "zactive";
    }
  }

  const findWord = useCallback(
    (e: any, word: string, b: number) => {
      if (currCode) {
        setAnim(true);
        setTimeout(() => setAnim(false), 800);
      }

      let temp = sentence
        .replace(/\s{2,}/gi, " ")
        .trim()
        .split(" ");

      temp = temp.map((item: string, index: number) => {
        item = item.replace(/\[.*?\]/g, "").replace(/\{.*?\}/g, "");
        if (b === index) {
          return "***" + item;
        } else {
          return item;
        }
      });

      const splitted = splitter.sentences(temp.join(" "));

      let found = splitted.find((item: string) => item.includes("***"));

      let origSentence = found.replace("***", "");

      let finalPos = -1;

      found = found.split(" ").map((item: any, index: number) => {
        if (item.includes("***")) {
          finalPos = index;
          return (
            <React.Fragment key={index}>
              <span className={"hl1 "}>{item.replace("***", "")}</span>{" "}
            </React.Fragment>
          );
        } else {
          return <span key={index}>{item + " "}</span>;
        }
      });

      const gActive = document.querySelectorAll(
        ".hlactive, .hlactivezdone, .hlactivezactive"
      );

      for (let g = 0; g < gActive.length; g++) {
        if (gActive[g].classList.contains("hlactive")) {
          gActive[g].classList.remove("hlactive");
        }
        if (gActive[g].classList.contains("hlactivezdone")) {
          gActive[g].classList.remove("hlactivezdone");
          gActive[g].classList.add("zdone");
        }
        if (gActive[g].classList.contains("hlactivezactive")) {
          gActive[g].classList.remove("hlactivezactive");
          gActive[g].classList.add("zactive");
        }
      }

      if (
        !currCode &&
        document.getElementById("a" + sIndex + "-" + props.index)
      ) {
        document
          .getElementById("a" + sIndex + "-" + props.index)
          ?.classList.add("hlactive");
      }

      // const newActive = (
      //   store.getState().hl[sIndex]?.[props.index] || ""
      // ).replace("hlactive", "");

      // store.dispatch(setHlOne([sIndex, props.index, "hlactive" + newActive]));

      let newWord = stemmer(word.toLowerCase().replace(/[.?,:!;)('“”„…]/g, ""));

      if (
        newWord.slice(-1) === "i" &&
        word.toLowerCase().substr(newWord.length - 1, 1) === "y"
      ) {
        newWord = newWord.substr(0, newWord.length - 1) + "y";
      }

      lookupWord({
        word: newWord,
        sentence: found,
        origSentence,
        pos: [sIndex, props.index, finalPos],
        e: e,
        currCode,
        currGr,
        orig: props.word.split("[")[0],
      });
    },
    [
      stemmer,
      lookupWord,
      sIndex,
      sentence,
      splitter,
      props.index,
      currCode,
      currGr,
      props.word,
    ]
  );

  const doLookUp = (e: any) => {
    findWord(e, currWord, props.index);
  };

  const doUnContract = () => {
    store.dispatch(
      setContractionOne({ id: "a" + sIndex + "-" + props.index, value: true })
    );
    store.dispatch(
      setContractionOne({
        id: "a" + sIndex + "-" + (props.index + 1),
        value: true,
      })
    );
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <span
          id={"a" + sIndex + "-" + props.index}
          className={style + " " + (anim ? "anim" : "")}
          onClick={contaction ? doUnContract : doLookUp}
        >
          {currWord.includes("*") ? (
            <b>{currWord.replace("*", "")}</b>
          ) : (
            currWord
          )}
        </span>
        <span> </span>
      </React.Fragment>
    </React.Fragment>
  );
});

export default WordsWord;
