import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const wordbarSlice = createSlice({
  initialState: {} as any,
  name: "wordbar",
  reducers: {
    setWordbar: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setWordbarOne: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setWordbar, setWordbarOne } = wordbarSlice.actions;

export default wordbarSlice.reducer;
