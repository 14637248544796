import React, { useEffect, useState } from "react";
import { phaseBodyIn, phaseIn } from "utils/utils";
import { tx } from "utils/globalize";
import { useSelector } from "react-redux";
import { makeStyles, createStyles, Chip, ButtonBase } from "@material-ui/core";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import AddIcon from "@material-ui/icons/Add";
import Repeat from "@material-ui/icons/Repeat";
import { downloadFile } from "utils/downloader";
import { firestore } from "firebaseUtil/firebaseUtil";
import { Howl } from "howler";

let recordingChunks = [];
let recorder;
let playing = false;
let started = false;
let startingChunk = -1;
let recordingUrl;
let tempTime;
let startTime;
let error;
let bindUrl;

/**
 * Save the recording chunks when they become available.
 */
const saveRecordingChunk = (event) => {
  recordingChunks.push(event.data);
  if (!started && playing) {
    startingChunk++;
  }
};

function detectSilence(
  stream,
  onSoundEnd = (_) => {},
  onSoundStart = (_) => {},
  silence_delay = 500,
  min_decibels = -70
) {
  const ctx = new AudioContext();
  const analyser = ctx.createAnalyser();
  const streamNode = ctx.createMediaStreamSource(stream);
  streamNode.connect(analyser);
  analyser.minDecibels = min_decibels;

  const data = new Uint8Array(analyser.frequencyBinCount); // will hold our data
  let silence_start = performance.now();
  let triggered = false; // trigger only once per silence event

  function loop(time) {
    tempTime = time;
    if (tempTime - startTime > 5000 && playing && started === false) {
      stopRecording();
      error = 1;
    }
    if (tempTime - startTime > 8000 && playing && started === true) {
      stopRecording();
      error = 1;
    }
    requestAnimationFrame(loop); // we'll loop every 60th of a second to check
    analyser.getByteFrequencyData(data); // get current data
    if (data.some((v) => v)) {
      // if there is data above the given db limit
      if (triggered) {
        triggered = false;
        onSoundStart();
      }

      silence_start = time; // set it to now
    }
    if (!triggered && time - silence_start > silence_delay) {
      onSoundEnd();
      triggered = true;
    }
  }
  loop();
}

function onSilence() {
  if (playing && started) {
    recorder.stop();
    started = false;
    playing = false;
  }
}

function onSpeak() {
  if (playing) {
    started = true;
    startTime = tempTime;
  }
}

/**
 * Create an url of the recording and show it in the video;
 */
const saveRecording = (callback = () => {}) => {
  const recordingBlob = new Blob(
    [
      recordingChunks[0],
      ...recordingChunks.slice(Math.max(startingChunk - 4, 1)),
    ],
    {
      type: "audio/mpeg",
    }
  );
  recordingUrl = URL.createObjectURL(recordingBlob);
  // console.log(recordingUrl);

  if (error) {
    callback(4);
    return;
  }
  callback(3);

  playThis(callback);
};

const playThis = (callback) => {
  const setupHowl = (src) =>
    new Howl({
      src: [src],
      format: ["mp3"],
      autoplay: true,
      onend: () => {
        new Howl({
          src: [recordingUrl],
          format: ["mp3"],
          autoplay: true,
          onend: () => callback(0),
        });
      },
    });

  downloadFile(
    "gs://netenglishm.appspot.com/linesnew/" + bindUrl + ".mp3",
    setupHowl,
    ""
  );
};

const record = (setRecordingCB, setThisStreamCB) => {
  navigator.mediaDevices
    .getUserMedia({
      audio: true,
    })
    .then((stream) => {
      setThisStreamCB(stream);
      try {
        recorder = new MediaRecorder(stream);
      } catch (e) {
        return;
      }
      recorder.ondataavailable = saveRecordingChunk;
      recorder.onstop = () => saveRecording(setRecordingCB);
      detectSilence(stream, onSilence, onSpeak);
    })
    .catch((error) => {
      console.log("error recording", error);
    });
};

const stopRecording = () => {
  playing = false;
  started = false;
  recorder.stop();
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    horizontal: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
    },
    horizontal1: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      marginTop: "40px",
    },
    warning: {
      marginTop: "30px",
      marginLeft: "15px",
      marginRight: "15px",
      fontSize: "18px",
      lineHeight: "140%",
    },
    flexi: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    left: { flex: "1 1 auto" },
    right: { flex: "0 0 auto" },
    bigtitle: {
      paddingBottom: "10px",
      marginTop: "7px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      lineHeight: "120%",
      flex: "1 1 auto",
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    line: {
      height: "20px",
      borderBottom: "1px solid var(--line-grey)",
      marginLeft: "-35px",
      marginRight: "-35px",
    },
    english: {
      fontSize: "18px",
      lineHeight: "135%",
      fontFamily: "var(--font-normal)",
      background: "#e5e5e5",
      border: "1px solid grey",
      borderRadius: "3px",
      padding: "15px",
      marginTop: "25px",
      marginBottom: "15px",
    },
    foreign: {
      fontSize: "18px",
      lineHeight: "135%",
      fontFamily: "var(--font-normal)",
      background: "#e5e5e5",
      border: "1px solid grey",
      borderRadius: "3px",
      padding: "15px",
      marginTop: "25px",
      marginBottom: "15px",
      opacity: 0.8,
    },
    status: {
      width: "100%",
      textAlign: "center",
      marginBottom: "15px",
    },
    chip: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "16px",
    },
    disabled: {
      opacity: 0.5,
    },
    button: {
      borderRadius: "50%",
      color: "var(--toolbar-color)",
      background: "var(--toolbar-background)",
      marginLeft: "8px",
      padding: "9px",
    },
    disabledButton: {
      opacity: 0.6,
    },
  })
);

const Recorder = () => {
  const [recording, setRecording] = useState(0);
  const [thisStream, setThisStream] = useState();
  const [sentences, setSentences] = useState([
    { english: "", foreign: "", eid: "" },
  ]);
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [first, setFirst] = useState(true);

  // const lessonData = useSelector((state) => state.lessonData);
  const classes = useStyles();
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    record(setRecording, setThisStream);
  }, []);

  const loading = useSelector((state) => state.loading);
  const init = useSelector((state) => state.appTransParts.init);

  useEffect(() => {
    if (!first || !currentUser) {
      return;
    }
    setFirst(false);
    firestore
      .doc("/other/lines" + (currentUser.language || ""))
      .get()
      .then((res) => {
        setSentences(res.data().lines);
        let tempIndex = parseInt(Math.random() * (res.data().lines.length - 1));

        while (res.data().lines[tempIndex].english.includes("&")) {
          tempIndex = parseInt(Math.random() * (res.data().lines.length - 1));
        }

        setSentenceIndex(tempIndex);
      });
  }, [currentUser, first]);

  useEffect(() => {
    setTimeout(() => {
      if (!loading && !init) {
        phaseIn();
        phaseBodyIn();
      }
    }, 3000);
  }, [loading, init]);

  useEffect(() => {
    if (recording === 4) {
      recordingUrl = undefined;
      setTimeout(() => {
        setRecording(0);
      }, 3000);
    }
  }, [recording]);

  // useEffect(() => {
  //   // setSentences(cullText(lessonData));
  //   setSentences(g);
  // }, []);

  useEffect(() => {
    if (thisStream) {
      return () => {
        thisStream.getTracks().forEach(function (track) {
          track.stop();
        });
      };
    }
  }, [thisStream]);

  const startRecording = () => {
    startingChunk = -1;
    recordingChunks = [];
    recorder.start(100);
    playing = true;
    started = false;
    startTime = tempTime;
    error = undefined;
    setRecording(2);
  };

  const playAndRecord = () => {
    const setupHowl = (src) => {
      new Howl({
        src: [src],
        format: ["mp3"],
        autoplay: true,
        onend: () => {
          startRecording();
        },
      });
    };

    setRecording(1);

    downloadFile(
      "gs://netenglishm.appspot.com/linesnew/" +
        sentences[sentenceIndex].eid +
        ".mp3",
      setupHowl,
      ""
    );
  };

  const getIcon = () => {
    if (recording === 0) {
      return tx("ooioioioio", "Any");
    }

    if (recording === 1) {
      return tx("ooioioio333io", "Playing");
    }

    if (recording === 2) {
      return tx("ooioioioio1", "Recording");
    }

    if (recording === 3) {
      return tx("ooioioioio13434", "Playback");
    }

    if (recording === 4) {
      return tx("ooioioioio1343334", "Error");
    }
  };

  const playThisNow = () => {
    setRecording(3);
    playThis(() => {
      setRecording(0);
    }, sentences[sentenceIndex].eid);
  };

  const newSentence = () => {
    let tempIndex = parseInt(Math.random() * (sentences.length - 1));

    while (sentences[tempIndex].english.includes("&")) {
      tempIndex = parseInt(Math.random() * (sentences.length - 1));
    }

    setSentenceIndex(parseInt(Math.random() * (sentences.length - 1)));
    recordingUrl = undefined;
  };

  const currentSentence = sentences[sentenceIndex];
  bindUrl = currentSentence.eid;

  return (
    <React.Fragment>
      <div className={classes.flexi}>
        <div className={classes.bigtitle}>
          {tx("llalalalalal", "Pronunciation drill")}
        </div>
      </div>
      <div className={classes.bigtitle}></div>
      <div className={classes.text}>
        {tx("wlwllkcccc", "You can practice your pronunciation here.")}
      </div>

      <div className={classes.line} />

      <div className={classes.english}>{currentSentence.english}</div>
      <div className={classes.foreign}>{currentSentence.foreign}</div>
      <div className={classes.horizontal}>
        <div className={classes.left}>
          {recording ? (
            <Chip className={classes.chip} label={getIcon()} />
          ) : null}
        </div>
        <div className={classes.right}>
          <ButtonBase
            className={
              classes.button + (!!recording ? " " + classes.disabled : "")
            }
            variant="outlined"
            color="secondary"
            onClick={playAndRecord}
            disabled={!!recording}
          >
            <RecordVoiceOver />
          </ButtonBase>
          <ButtonBase
            variant="outlined"
            className={
              classes.button +
              (!!recording || !recordingUrl ? " " + classes.disabled : "")
            }
            classes={{ disabled: classes.disabled }}
            color="secondary"
            onClick={playThisNow}
            disabled={!!recording || !recordingUrl}
          >
            <Repeat />
          </ButtonBase>
          <ButtonBase
            variant="outlined"
            className={
              classes.button + (!!recording ? " " + classes.disabled : "")
            }
            color="secondary"
            onClick={newSentence}
            disabled={!!recording}
          >
            <AddIcon />
          </ButtonBase>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Recorder;
