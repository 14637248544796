import store from "Redux/store";
import { phaseOut } from "../utils";
import { updatePosition } from "firebaseUtil/firebaseUtil.firestore";
import { nextValid, prevValid } from "../findNextExercise";

export const nextExercise = () => {
  const lessonStatus = store.getState().lessonStatus;

  const newExercise = nextValid(lessonStatus.step);

  if (newExercise === -1) {
    moveToNextChapter();
    return;
  }

  phaseOut();
  setTimeout(() => {
    updatePosition({ currentPosition: newExercise });
    const mainFrame = document.getElementById("mainFrame");

    if (mainFrame) {
      mainFrame.scrollTop = 0;
    }
  }, 150);
};

export const prevExercise = () => {
  const lessonStatus = store.getState().lessonStatus;

  const newExercise = prevValid(lessonStatus.step);

  phaseOut();
  setTimeout(() => {
    updatePosition({ currentPosition: newExercise });
    const mainFrame = document.getElementById("mainFrame");

    if (mainFrame) {
      mainFrame.scrollTop = 0;
    }
  }, 150);
};

export const moveToNextChapter = async () => {
  const lessonStatus = store.getState().lessonStatus;
  const program = store.getState().program;
  const settings = store.getState().settings;

  let currentIndex = 0;

  if (!settings.pro) {
    const tempIndex = program.index.findIndex(
      (item: any) => lessonStatus.seq === item
    );
    currentIndex = tempIndex + 1;
  } else {
    currentIndex = program.index.findIndex(
      (item: any) => program[item].done < 3
    );
  }

  const nextItem = program.index[currentIndex];

  updatePosition({
    currentPosition: nextValid(-1),
    currentLesson: nextItem,
  });
};

export const getNextChapter = async () => {
  const lessonStatus = store.getState().lessonStatus;
  const program = store.getState().program;
  const settings = store.getState().settings;

  let currentIndex = 0;

  if (!settings.pro) {
    const tempIndex = program.index.findIndex(
      (item: any) => lessonStatus.seq === item
    );
    currentIndex = tempIndex + 1;
  } else {
    currentIndex = program.index.findIndex(
      (item: any) => program[item].done < 3
    );
  }

  const nextItem = program.index[currentIndex];

  return nextItem;
};
