import { phaseIn, phaseOut } from "../words.utils";

const tabs = [
  "xreader",
  "xloader",
  "xownwords",
  "xwordlist",
  "xoriglist",
  "history",
  "paste",
  "wordresult",
];

export const changeTabs = (newTab: string) => {
  tabs.forEach((item) => {
    if (item !== newTab) {
      phaseOut(item);
    }
  });

  if (newTab !== "xloader") {
    setTimeout(() => phaseIn(newTab), 150);
  }
};
