import React from "react";
import { useSelector } from "react-redux";
import { setStatus } from "Redux/reducers/reducer.status";
import store from "Redux/store";

import { IconButton } from "@material-ui/core";
import { AddTwoTone, FormatListBulletedTwoTone } from "@material-ui/icons";
import MenuBookTwoTone from "@material-ui/icons/MenuBookTwoTone";

import { RootState } from "../../Redux/reducers/_index";
import { setGrammar } from "Redux/reducers/reducer.grammar";

const ToolbarTabs = (props: any) => {
  const { classes, setQuery, setWordResult } = props;
  const status = useSelector<RootState, string>((state) => state.status);

  return (
    <React.Fragment>
      {status !== "wordlist" && status !== "word" ? (
        <React.Fragment>
          <IconButton
            className={classes.sIcon + (status === "reader" ? " active" : "")}
            onClick={() => {
              store.dispatch(setStatus("reader"));
              setQuery("");
              store.dispatch(setGrammar(false));
              setWordResult({});
            }}
          >
            <MenuBookTwoTone />
          </IconButton>
          <IconButton
            className={classes.tIcon + (status === "origlist" ? " active" : "")}
            onClick={() => {
              store.dispatch(setStatus("origlist"));
              setQuery("");
              store.dispatch(setGrammar(false));
              setWordResult({});
            }}
          >
            <AddTwoTone />
          </IconButton>
          <IconButton
            className={
              classes.dicIcon + (status === "ownwords" ? " active" : "")
            }
            onClick={() => {
              store.dispatch(setStatus("ownwords"));
              setQuery("");
              store.dispatch(setGrammar(false));
              setWordResult({});
            }}
          >
            <FormatListBulletedTwoTone />
          </IconButton>
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default ToolbarTabs;
