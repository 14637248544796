import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const hlSlice = createSlice({
  initialState: [] as any,
  name: "currentPos",
  reducers: {
    setHl: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setHlOne: (state: any, action: PayloadAction<any>) => {
      if (state[action.payload[0]] !== undefined) {
        state[action.payload[0]][action.payload[1]] = action.payload[2];
      } else {
        state[action.payload[0]] = {};
        state[action.payload[0]][action.payload[1]] = action.payload[2];
      }
    },
    setHlLast: (state: any, action: PayloadAction<any>) => {
      state.last = action.payload;
    },
  },
});

export const { setHl, setHlOne, setHlLast } = hlSlice.actions;

export default hlSlice.reducer;
