import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setWordbarOne } from "Redux/reducers/reducer.wordbar";
import store from "Redux/store";

import {
  createStyles,
  IconButton,
  makeStyles,
  Popover,
  Slider,
  Theme,
  Typography,
} from "@material-ui/core";

import { formatTime } from "../words.utils";
import Mp3Controls from "./textViewer.mp3controls";
import { updateSetting } from "../../firebaseUtil/firebaseUtil.firestore";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mp3Container: {
      position: "relative",
      width: "100%",
    },
    speedDiv: { minWidth: "240px", padding: "15px 12px 0px 12px" },
    icon1text: {
      height: "24px",
      borderRadius: "2px",
      border: "1px solid var(--mail-text)",
      padding: "5px",
      fontSize: "15px",
      fontStretch: "condensed",
      color: "var(--mail-text)",
    },
    caption: {
      textAlign: "center",
      fontSize: "13px",
    },
    controls: { display: "flex", width: "100%", alignItems: "center" },
    controlLeft: { flex: "0 0 25px", paddingLeft: "20px", paddingTop: "0px" },
    controlClock: {
      width: "77px",
      fontSize: "13px",
      textAlign: "center",
      fontFamily: "monospace",
      color: "var(--mail-text)",
    },
    controlMinutes: { float: "left", width: "37x", textAlign: "right" },
    controlSeconds: { float: "right" },
    middle: { display: "inline-block" },
    controlMiddle: { flex: "1 1 auto", paddingLeft: "20px", paddingTop: "0px" },
    controlRight: { flex: "1 1 auto", textAlign: "right" },
    markLabel: { fontSize: "10px", top: "20px" },
    sliderRoot: { padding: "10px 0px" },
    sticky: {
      position: "sticky",
      top: "0px",
      zIndex: 15000,
      marginLeft: "-20px",
      marginRight: "-20px",
      marginBottom: "15px",
      background: "var(--player-background)",
      color: "var(--player-color)",
      borderBottom: "1px solid lightgrey",
      borderTop: "1px solid lightgrey",
    },
  })
);

const marks = [
  {
    value: 0.6,
    label: "0.6",
  },
  {
    value: 0.8,
    label: "0.8",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 1.2,
    label: "1.2",
  },
  {
    value: 1.4,
    label: "1.4",
  },
  {
    value: 1.6,
    label: "1.6",
  },
  {
    value: 1.8,
    label: "1.8",
  },
];

const ViewerMp3 = (props: any) => {
  const classes = useStyles();
  const audioRef = useRef<HTMLAudioElement>(null);
  const speedRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const duration = useRef(0);
  const position = useRef(0);
  const durationHTML = useRef<HTMLDivElement>(null);
  const positionHTML = useRef<HTMLDivElement>(null);

  let src = props.src.split("|") as string;
  let mp3 = src[0];
  let caption = src[1];

  const playSpeed = useSelector<RootState, number>(
    (state) => state.settings.playSpeed
  );

  const loop = useSelector<RootState, number>((state) => state.settings.loop);

  useEffect(() => {
    return () => {
      store.dispatch(setWordbarOne({ id: "player", value: null }));
    };
  }, []);

  useEffect(() => {
    const play = () =>
      store.dispatch(setWordbarOne({ id: "playing", value: 1 }));

    const pause = () =>
      store.dispatch(setWordbarOne({ id: "playing", value: 0 }));

    const timeupdate = () => {
      if (
        position.current !== Math.round(props.videoPlayer.current.currentTime)
      ) {
        position.current = Math.round(props.videoPlayer.current.currentTime);
        if (positionHTML.current) {
          positionHTML.current.innerHTML = formatTime(position.current) + "";
        }
      }
    };

    const durationchange = () => {
      duration.current = props.videoPlayer.current.duration;
      position.current = Math.round(props.videoPlayer.current.currentTime);
      if (positionHTML.current) {
        positionHTML.current.innerHTML = formatTime(position.current) + "";
      }
      if (durationHTML.current) {
        durationHTML.current.innerHTML = formatTime(duration.current) + "";
      }
    };

    props.videoPlayer.current = audioRef.current;
    store.dispatch(setWordbarOne({ id: "player", value: audioRef.current }));
    props.videoPlayer.current.addEventListener("play", play);
    props.videoPlayer.current.addEventListener("pause", pause);
    props.videoPlayer.current.addEventListener("timeupdate", timeupdate);
    props.videoPlayer.current.addEventListener(
      "durationchange",
      durationchange
    );

    return () => {
      props.videoPlayer.current.removeEventListener("play", play);
      props.videoPlayer.current.removeEventListener("pause", pause);
      props.videoPlayer.current.removeEventListener("timeupdate", timeupdate);
      props.videoPlayer.current.removeEventListener(
        "durationchange",
        durationchange
      );
    };
  }, [props.videoPlayer, props.src]);

  useEffect(() => {
    if (props.videoPlayer.current?.playbackRate) {
      props.videoPlayer.current.playbackRate = playSpeed || 1;
    }
  }, [playSpeed, props.videoPlayer, props.src]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className={classes.sticky}>
        <div className={classes.mp3Container} key={mp3}>
          <audio ref={audioRef} src={mp3} loop={!!loop} />
          {caption ? <div className={classes.caption}>{caption}</div> : ""}
        </div>
        <div className={classes.controls}>
          <div className={classes.controlLeft}>
            <IconButton
              ref={speedRef}
              className={classes.icon1text}
              onClick={() => setAnchorEl(speedRef.current)}
            >
              {(playSpeed || 1) + "x"}
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Typography className={classes.speedDiv}>
                {marks.length > 1 ? (
                  <Slider
                    value={playSpeed}
                    classes={{
                      markLabel: classes.markLabel,
                      root: classes.sliderRoot,
                    }}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    onChange={(e: any, v: any) => {
                      updateSetting({ playSpeed: v });
                    }}
                    step={0.2}
                    marks={marks}
                    min={0.4}
                    max={2}
                  />
                ) : (
                  ""
                )}
              </Typography>
            </Popover>
          </div>
          <div className={classes.controlMiddle}>
            <div className={classes.controlClock}>
              <div className={classes.controlMinutes} ref={positionHTML} />{" "}
              <div className={classes.middle}>/</div>
              <div className={classes.controlSeconds} ref={durationHTML} />
            </div>
          </div>
          <div className={classes.controlRight}>
            <Mp3Controls />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewerMp3;
