import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const backOpenSlice = createSlice({
  initialState,
  name: "backOpen",
  reducers: {
    setBackOpen: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setBackOpen } = backOpenSlice.actions;

export default backOpenSlice.reducer;
