import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: { fontSize: "12px", lineHeight: "110%" },
  })
);

const RecaptchaNote = (props: any) => {
  const classes = useStyles();

  const text = () => {
    switch (props.language) {
      case "hu":
        return (
          <div className={classes.text}>
            Ezt az oldalt reCAPTCHA mechanizmus védi és a Google{" "}
            <a href="https://policies.google.com/privacy">
              {" "}
              adatvédelmi szabályzata{" "}
            </a>{" "}
            és
            <a href="https://policies.google.com/terms">
              {" "}
              szolgáltatási feltételei{" "}
            </a>{" "}
            vonatkoznak rá.
          </div>
        );
      default:
        return (
          <div className={classes.text}>
            This site is protected by reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
            <a href="https://policies.google.com/terms"> Terms of Service</a>{" "}
            apply.
          </div>
        );
    }
  };

  return <React.Fragment>{text()}</React.Fragment>;
};

export default RecaptchaNote;
