import store from "Redux/store";
//@ts-ignore
import createActivityDetector from "activity-detector";

import firebase, { firestore } from "../firebaseUtil/firebaseUtil";

// import { getNewWords } from '../utils/cullText';
// import { lameWordList } from './wordlist';

let active = false;

export const startTimer = () => {
  // console.log("active");
  active = true;
};

export const stopTimer = () => {
  // console.log("deactive");
  active = false;
};

export const setUpTimer = () => {
  startTimer();
  const activityDetector = createActivityDetector({ timeToIdle: 120000 });
  // window.addEventListener("focus", startTimer);
  // window.addEventListener("blur", stopTimer);
  activityDetector.on("idle", () => {
    stopTimer();
  });

  activityDetector.on("active", () => {
    startTimer();
  });
  setInterval(timing, 10000);
};

const timing = () => {
  const currentUser = store.getState().currentUser;

  if (active && document.location.href.includes("/app/words") && currentUser) {
    const timing = JSON.parse(
      localStorage.getItem("ne_timer" + currentUser.id) || "{}"
    );
    // const lesson = store.getState().lessonStatus.seq;
    const today = new Date();

    const day = Math.floor(today.valueOf() / 1000 / 60 / 60 / 24) + "";

    timing[`days.${day}.time`] = (timing[`days.${day}.time`] || 0) + 10;
    timing[`all.time`] = (timing[`all.time`] || 0) + 10;
    // timing[`lessons.${lesson}`] = (timing[`lessons.${lesson}`] || 0) + 20;

    timing.current = (timing.current || 0) + 1;

    localStorage.setItem("ne_timer" + currentUser.id, JSON.stringify(timing));

    if (timing.current === 45) {
      saveTiming();
    }
  }
};

export const saveTiming = () => {
  const userId = store.getState().currentUser?.id;
  if (!userId) {
    return;
  }

  const timing = JSON.parse(localStorage.getItem("ne_timer" + userId) || "{}");

  if (!timing[`all.time`]) {
    return;
  }
  // console.log("saved");

  localStorage.setItem("ne_timer" + userId, "");

  const saveObject: any = {};

  Object.keys(timing).forEach((key) => {
    saveObject[key] = firebase.firestore.FieldValue.increment(timing[key]);
  });

  firestore
    .doc(`/users/${userId}/data/stats`)
    .update(saveObject)
    .catch((e) => {
      if (e.code === "not-found") {
        firestore
          .doc(`/users/${userId}/data/stats`)
          .set({})
          .then(() => {
            firestore.doc(`/users/${userId}/data/stats`).update(saveObject);
          });
        return;
      }
    });

  return;
};

// export const updateWordProgress = async (
//   lesson: string,
//   lessonData: Document,
//   userId: string
// ) => {
//   const stats = await firestore.doc(`/users/${userId}/data/stats`).get();

//   if (!stats.exists) {
//     await firestore.doc(`/users/${userId}/data/stats`).set({});
//   }

//   const oldWords = stats.data()?.words || "";

//   const newWords = getNewWords(lessonData, (oldWords || "") + lameWordList);
//   const newWordsCount = newWords.length;

//   const today = new Date();

//   const day = Math.floor(today.valueOf() / 1000 / 60 / 60 / 24) + "";

//   const increment = 1;

//   const newWordList = (oldWords + "|" + newWords.join("|")).replace(
//     /[|]{2,}/g,
//     "|"
//   );

//   const dayTimeWords = `days.${day}.words`;
//   const dayTimeLessons = `days.${day}.lessons`;

//   firestore
//     .doc(`/users/${userId}/data/stats`)
//     .update({
//       "all.words": firebase.firestore.FieldValue.increment(newWordsCount),
//       "all.lessons": firebase.firestore.FieldValue.increment(increment),
//       [dayTimeWords]: firebase.firestore.FieldValue.increment(newWordsCount),
//       [dayTimeLessons]: firebase.firestore.FieldValue.increment(increment),
//       words: newWordList,
//     })
//     .catch((e) => {
//       if (e.code === "not-found") {
//         firestore.doc(`/users/${userId}/data/stats`).set(
//           {
//             days: {
//               [day]: {
//                 words: firebase.firestore.FieldValue.increment(newWordsCount),
//                 lessons: firebase.firestore.FieldValue.increment(increment),
//               },
//             },
//             all: {
//               words: firebase.firestore.FieldValue.increment(newWordsCount),
//               lessons: firebase.firestore.FieldValue.increment(increment),
//             },
//             words: newWordList,
//           },
//           { merge: true }
//         );
//       }
//     });

//   return;
// };
