import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BookStatusType {
  open: boolean;
  anchor: any;
  active: number;
  lesson: string;
}

const initialState: BookStatusType = {
  open: false,
  anchor: null,
  active: 0,
  lesson: "",
};

const bookStatusSlice = createSlice({
  initialState,
  name: "bookStatus",
  reducers: {
    setBookStatus: (
      state: BookStatusType,
      action: PayloadAction<BookStatusType>
    ) => {
      return action.payload;
    },
  },
});

export const { setBookStatus } = bookStatusSlice.actions;

export default bookStatusSlice.reducer;
