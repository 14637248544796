import React from "react";

import { Button } from "@material-ui/core";
import DialogRaw from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
// @material-ui/core components
import { makeStyles, Theme } from "@material-ui/core/styles";
import { tx } from "../../utils/globalize";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = (theme: Theme) => ({
  modal: {
    marginTop: "0px",
    marginLeft: "10px",
    marginRight: "10px",
    overflow: "visible",
  },
  containing: {
    overflowY: "hidden",
  },
  paperScrollBody: {
    maxWidth: "1200px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "1200px !important",
      margin: "32px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "1200px !important",
      margin: "32px 0px",
    },
  },
  wrapper: { padding: "10px", minWidth: "150px" },
  text: { fontSize: "18px", marginBottom: "20px", marginTop: "15px" },
  buttonDiv: { marginBottom: "10px", width: "100%", textAlign: "Center" },
});

const useStyles = makeStyles(style as any);
const Dialog = DialogRaw as any;

const NoMoreDialog = React.memo((props: any) => {
  const classes = useStyles();

  return (
    <Dialog
      scroll="body"
      classes={{
        container: classes.containing,
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalLogin,
        paperScrollBody: classes.paperScrollBody,
      }}
      open={props.moreOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => props.setMoreOpen(false)}
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <div className={classes.wrapper}>
        <div className={classes.text}>
          {tx("dala1nomore", "No more words for today...")}
        </div>
        <div className={classes.buttonDiv}>
          <Button
            onClick={() => {
              props.textTypeRef.current = "new";

              setTimeout(() => props.getNext(-1), 0);
            }}
          >
            {tx("dala1good", "Give me new words.")}
          </Button>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            onClick={() => {
              props.textTypeRef.current = "pending";
              props.doneRef.current = [];
              setTimeout(() => props.getNext(-1), 0);
            }}
          >
            {tx("dala1good1", "I restudy outstanding words.")}
          </Button>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            onClick={() => {
              props.setMoreOpen(false);
            }}
          >
            {tx("dala1goodclsoe", "Close")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
});

export default NoMoreDialog;
