import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 0;

const routedSlice = createSlice({
  initialState,
  name: "routed",
  reducers: {
    incrementRouted: (state: number, action: PayloadAction<null>) => {
      return state + 1;
    },
  },
});

export const { incrementRouted } = routedSlice.actions;

export default routedSlice.reducer;
