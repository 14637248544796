import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "Redux/reducers/_index";
import { setStatus } from "Redux/reducers/reducer.status";
import { setWordbarOne } from "Redux/reducers/reducer.wordbar";
import store from "Redux/store";

import {
  ButtonBase,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CategoryIcon from "@material-ui/icons/Category";

import { firestore } from "../../firebaseUtil/firebaseUtil";
import { updateReader, updateReaderBulk } from "../words.utils";
import { phaseOut, phaseIn } from "../../utils/utils";
import { tx } from "../../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    omni: { fontSize: "21px" },
    res: {},
    textButtonDiv: {
      width: "100%",
      textAlign: "left",
    },
    circled: {
      width: "17px",
      lineHeight: "17px",
      borderRadius: "50%",
      textAlign: "center",
      fontSize: "10px",
      border: "1px solid #666",
      display: "inline-block",
      transform: "translateY(-2px)",
      marginRight: "6px",
    },

    textButton: {
      textAlign: "left",
      width: "100%",
      height: "40px",
      marginTop: "0px",
      padding: "7px",
      marginLeft: "5px",
      fontSize: "17px",
      justifyContent: "flex-start",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    wrapper: {
      marginTop: "20px",
      marginBottom: "10px",
    },
    title: {
      fontWeight: "bold",
      marginBottom: "0px",
      fontSize: "21px",
      marginTop: "8px",
    },
    titleNoArrow: {
      fontWeight: "bold",
      marginBottom: "15px",
      fontSize: "21px",
      marginTop: "8px",
    },
    subtitle: {
      marginBottom: "10px",
      fontSize: "18px",
      marginTop: "20px",
      fontWeight: 700,
    },
    doneword: {
      background: "#ffffee",
      marginBottom: "2px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      padding: "2px 8px 2px 8px",
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      "&.done": { background: "#eeffee" },
    },
    catword: {
      background: "#eeffee",
      marginBottom: "2px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      padding: "2px 8px 2px 8px",
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      "&.active": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    wordpart: { flex: "1 1 auto", maxWidth: "calc(100% - 45px)" },
    wordend: { flex: "0 0 30px" },
  })
);

const TextSearch = React.memo((props: any) => {
  const classes = useStyles();
  const [textList, setTextList] = useState<any>([]);
  const [show, setShow] = useState("files");
  const [localList, setLocalList] = useState<any>({});
  const [listLoaded, setListLoaded] = useState(false);

  const currentUserEmail = useSelector<RootState, any>(
    (state) => state.currentUser?.email
  );

  const currentUserLang = useSelector<RootState, any>(
    (state) => state.currentUser?.language
  );

  const myLists = useSelector<RootState, any>(
    (state) => state.customize?.lists?.texts
  );

  useEffect(() => {});

  const location = useLocation();
  const history = useHistory();

  const reader = useSelector<RootState, any>((state) => state.reader);
  const status = useSelector<RootState, any>((state) => state.status);

  useEffect(() => {
    if (listLoaded && status === "loader") {
      phaseIn("xloader");
    }
  }, [listLoaded, status]);

  useEffect(() => {
    if (status === "loader") {
      setLocalList(reader.currentList);
    }
  }, [status, reader.currentList]);

  const getGrammar = useCallback(() => {
    //  console.log(currentUserLang);
    switch (currentUserLang) {
      case "hu":
        return "Nyelvtan";
      case "es":
        return "Gramática";
      case "it":
        return "Grammatica";
      case "pl":
        return "Gramatyka";
      default:
        return "Grammar";
    }
  }, [currentUserLang]);

  const loadList = useCallback(
    async (item: any) => {
      let gone: any = [];

      phaseOut("xloader");
      setListLoaded(false);

      setTimeout(async () => {
        if (item.id === "own") {
          gone = reader.own || [];
          updateReader("localList", {
            texts: gone,
            title: "Saját...",
            own: true,
            id: "own",
          });
        } else {
          gone = await firestore.doc(`/textlists/${item.id}`).get();
          if (gone.data()) {
            const texts = gone.data().texts?.map((item: any) => ({
              ...item,
              done: !!reader.history.find((hist: any) => hist.id === item.id),
            }));

            setLocalList({
              ...gone.data(),
              texts,
              title: item.title,
              id: item.id,
              own: false,
            });
          }
          setTimeout(() => setListLoaded(true), 150);
        }
      }, 150);

      setTimeout(() => {
        setShow("files");
      }, 300);
    },
    [reader.own, reader.history]
  );

  useEffect(() => {
    if (location.pathname.includes("/gr")) {
      loadList({
        id: "gr" + currentUserLang,
        title: getGrammar(),
      });
      history.push("/app/words");
    }
  }, [location, loadList, history, listLoaded, currentUserLang, getGrammar]);

  const setNewText = useCallback(
    async (item: any) => {
      let newText: any;

      store.dispatch(setWordbarOne({ id: "player", value: null }));

      newText = await firestore.doc(`/texts/${item.id}`).get();

      let add = {};

      if (localList.id.indexOf("gr")) {
        if (localList.id === "v1") {
          add = {
            lastVideo: { listId: localList.id, textId: item.id },
          };
        } else {
          add = {
            lastText: { listId: localList.id, textId: item.id },
          };
        }
      }

      let updateValue: any = {
        currentText: {
          ...(newText.data() || { title: "", text: "", id: "" }),
          text: (newText.data().text || "").replace("|", "\n"),
          list: localList.title,
          listId: localList.id,
          id: newText.data()?.id,
        },
        currentList: { ...localList },
        ...add,
      };

      // if (!reader.history.find((item: any) => item.id === newText.data()?.id)) {
      //   const texts = reader.currentList.texts?.map((item: any) => {
      //     if (item.id !== newText.data()?.id) {
      //       return item;
      //     } else {
      //       return { ...item, done: true };
      //     }
      //   });

      //   updateValue.currentList = { texts };

      //   updateValue.history = firebase.firestore.FieldValue.arrayUnion({
      //     id: newText.data()?.id,
      //     title: newText.data()?.title,
      //     date: Math.floor(Date.now() / 86400000),
      //     listId: reader.currentList.id,
      //     cat: reader.currentList.title,
      //   });
      // }

      updateReaderBulk(updateValue);
      const xreader = document.getElementById("xreader");
      if (xreader) {
        xreader.scrollTop = 0;
      }
      setTimeout(() => store.dispatch(setStatus("reader")), 300);
    },
    [localList]
  );

  useEffect(() => {
    if (!currentUserEmail) {
      return;
    }
    firestore
      .doc("/textlists/list" + currentUserLang)
      .get()
      .then((res) => {
        setTextList([...(res.data()?.texts || [])]);
        if (!document.location.href.includes("/gr")) {
          setTimeout(() => setListLoaded(true), 150);
        }
      });
  }, [currentUserEmail, currentUserLang]);

  let icount = 0;

  return (
    <React.Fragment>
      {show === "files" ? (
        <div className={classes.wrapper}>
          {localList.id?.indexOf("gr") ? (
            <div className={classes.title}>
              <IconButton onClick={() => setShow("list")}>
                <ArrowUpwardIcon />
              </IconButton>
              {localList.title}
            </div>
          ) : (
            <div className={classes.titleNoArrow}>{localList.title}</div>
          )}

          {localList?.texts?.map((item: any, index: number) => {
            if (!item.title.includes("*")) {
              icount++;
              return (
                <div
                  key={item.id + index}
                  className={classes.doneword + (item.done ? " done" : "")}
                >
                  <ButtonBase
                    className={classes.textButton}
                    onClick={() => setNewText(item)}
                  >
                    <Typography variant="inherit" noWrap>
                      <div className={classes.circled}>{icount}</div>{" "}
                      {item.title}
                    </Typography>
                  </ButtonBase>
                </div>
              );
            } else {
              return (
                <div className={classes.subtitle} key={item.id + index}>
                  <Typography variant="inherit" noWrap>
                    {item.title.replace("*", "")}
                  </Typography>
                </div>
              );
            }
          })}
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.wrapper}>
            <div className={classes.title}>
              <IconButton>
                <CategoryIcon />
              </IconButton>
              {tx("dala10", "Categories")}
            </div>
            {textList
              .filter((item: any) => item.id.indexOf("gr"))
              .map((item: any, index: number) => (
                <div className={classes.catword} key={index}>
                  <ButtonBase
                    className={classes.textButton}
                    onClick={() => {
                      loadList(item);
                    }}
                  >
                    <Typography variant="inherit" noWrap>
                      {item.title}
                    </Typography>
                  </ButtonBase>
                </div>
              ))}
            {myLists?.length ? (
              <React.Fragment>
                <hr />
                {(myLists || []).map((item: any, index: number) => (
                  <div className={classes.catword} key={index}>
                    <ButtonBase
                      className={classes.textButton}
                      onClick={() => {
                        loadList(item);
                      }}
                    >
                      <Typography variant="inherit" noWrap>
                        {item.title}
                      </Typography>
                    </ButtonBase>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
});

export default TextSearch;
