import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LessonListType {
  number: string;
  seq: string;
  summary: string;
  title: string;
  length: number;
  words: number;
  color: string;
  summaryTid?: string;
  titleTid?: string;
  done?: boolean;
  completions?: number[];
}

const initialState: LessonListType[] = [];

const lessonListSlice = createSlice({
  initialState,
  name: "lessonList",
  reducers: {
    setLessonList: (
      state: LessonListType[],
      action: PayloadAction<LessonListType[]>
    ) => {
      return action.payload;
    },
    setLessonListDone: (
      state: LessonListType[],
      action: PayloadAction<any>
    ) => {
      const index = state.findIndex((item) => item.seq === action.payload.seq);
      state[index].done = true;
      state[index].completions = state[index].completions || [];
      state[index].completions?.push(action.payload.date);
    },
  },
});

export const { setLessonList, setLessonListDone } = lessonListSlice.actions;

export default lessonListSlice.reducer;
