import React from "react";

import { ButtonBase, createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textButtonDiv: {
      width: "100%",
      textAlign: "left",
      "&:first-of-type": { marginTop: "20px" },
    },
    textButton: {
      textAlign: "left",
      width: "100%",
      height: "40px",
      marginTop: "5px",
      padding: "7px",
      fontSize: "18px",
      justifyContent: "flex-start",
    },
    headword: {},
    spacer: { height: "95px" },
    cardheadword: { color: "blue", fontWeight: "bold" },
  })
);

const WordDicEntries = React.memo((props: any) => {
  const classes = useStyles();

  const { showWord, results } = props;

  return (
    <React.Fragment>
      <div id="worddic">
        {results.map((item: any, index: number) => {
          return (
            <div key={index} className={classes.textButtonDiv}>
              <ButtonBase
                className={classes.textButton}
                onClick={() => showWord(item.id)}
              >
                <div
                  className={
                    item.card ? classes.cardheadword : classes.headword
                  }
                >
                  {item.e}
                </div>
              </ButtonBase>
            </div>
          );
        })}
        {props.activeSentence?.sOrig ? <div className={classes.spacer} /> : ""}
      </div>
    </React.Fragment>
  );
});

export default WordDicEntries;
