import React from "react";
import {
  makeStyles,
  Dialog,
  Chip,
  Fade,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import styleRaw from "utils/Dialogs/modalStyle";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import store from "Redux/store";
import { setHelpOpen } from "Redux/reducers/reducer.help";

import CloseIcon from "@material-ui/icons/Close";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { tx } from "../utils/globalize";
import { useHistory } from "react-router";

let styles = styleRaw as any;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Fade timeout={1000} ref={ref} {...props} />;
});

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & { children?: React.ReactElement<any, any> },
//   ref: React.Ref<unknown>
// ) {
//   return <Fade timeout={1500} ref={ref} {...props} />;
// });

const useStyles = makeStyles({
  ...styles,
  radius: {
    borderRadius: "4px",
    background: "#e5e5e5",
    padding: "5px",
  },
  mine: {
    position: "fixed",
    margin: "auto",
    display: "block",
    background: "white",
    left: "10px",
    right: "10px",
    maxWidth: "600px",
    maxHeight: "calc(100% - 29px)",
  },
  scrollPaper: {
    display: "block",
  },
  iframe: {
    width: "100%",
    position: "absolute",
    height: "100%",
    transform: "translate(0, -100%)",
  },
  iframeWrapper: {
    width: "95",
    paddingTop: "185%", // 35/70 = 0.5, the ratio can be changed here
    margin: "auto",
    position: "relative",
  },
  header: {
    height: "28px",
    width: "100%",
  },
  paper: {
    margin: "6px",
  },
  back: {
    background: "var(--toolbar-background)",
    color: "var(--toolbar-color)",
  },
  backChip: {
    background: "var(--toolbar-background)",
    color: "var(--toolbar-color)",
    border: "0px solid white",
  },
});

export default function HelpDialog(props: any) {
  const history = useHistory();
  const classes = useStyles();

  const help = useSelector<RootState, any>((state) => state.help);

  const closeHelp = () => {
    store.dispatch(setHelpOpen(false));
  };

  const moreHelp = () => {
    store.dispatch(setHelpOpen(false));
    history.push("/app/help");
  };

  return (
    <Dialog
      style={{ zIndex: 1900 }}
      PaperProps={{ square: true }}
      open={help.open}
      classes={{ paper: classes.paper }}
      TransitionComponent={Transition}
      disableBackdropClick={false}
      onClose={closeHelp}
      onBackdropClick={closeHelp}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogActions className={classes.back}>
        <Chip
          className={classes.back}
          icon={<HelpOutline />}
          size="small"
          variant="outlined"
          clickable
          label={tx("olzxckckck", "More Help")}
          onClick={moreHelp}
          classes={{ outlined: classes.backChip, icon: classes.back }}
        />
        <Chip
          className={classes.back}
          style={{ float: "right" }}
          icon={<CloseIcon />}
          size="small"
          clickable
          variant="outlined"
          label={tx("olzxckcsdskck", "Close")}
          onClick={closeHelp}
          classes={{ outlined: classes.backChip, icon: classes.back }}
        />
      </DialogActions>
      <DialogContent dividers={true} className={"helpid"}>
        {help.src}
      </DialogContent>
    </Dialog>
  );
}
