import { FreeData } from "firebaseUtil/firebaseUtil";

export const allLanguages: FreeData = [
  { code: "pl", name: "Polish", ownName: "Polish" },
  { code: "es", name: "Spanish", ownName: "Spanish" },
  { code: "cs", name: "Czech", ownName: "Czecho" },
  { code: "en", name: "English", ownName: "English" },
  { code: "hu", name: "Hungarian", ownName: "Hungarian" },
  { code: "it", name: "Italian", ownName: "Italiano" },
  { code: "ro", name: "Romanian", ownName: "Romanescu" },
  { code: "sk", name: "Slovakian", ownName: "Slovak" },
];
