import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setWordbarOne } from "Redux/reducers/reducer.wordbar";
import store from "Redux/store";

import {
  createStyles,
  IconButton,
  makeStyles,
  Popover,
  Slider,
  Theme,
  Typography,
} from "@material-ui/core";

import Mp3Controls from "./textViewer.mp3controls";
import { updateSetting } from "../../firebaseUtil/firebaseUtil.firestore";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoContainer: {
      position: "relative",
      width: "100%",
      paddingBottom: "56.25%",
      height: 0,
      marginBottom: "0px",
      zIndex: 10000,
      transform: "scale(1)",
    },
    videoFrame: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
    },
    caption: {
      textAlign: "center",
      fontSize: "13px",
    },
    speedDiv: { minWidth: "240px", padding: "15px 18px 0px 18px" },
    icon1text: {
      height: "24px",
      borderRadius: "2px",
      border: "1px solid var(--mail-text)",
      padding: "5px",
      fontSize: "15px",
      fontStretch: "condensed",
      color: "var(--mail-text)",
    },
    controls: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      background: "var(--player-background)",
    },
    controlLeft: { width: "30px", paddingLeft: "20px", paddingTop: "0px" },
    controlRight: { flex: "1 1 auto", textAlign: "right" },
    markLabel: { fontSize: "10px", top: "20px" },
    sliderRoot: { padding: "10px 0px" },
    sticky: {
      position: "sticky",
      top: "0px",
      zIndex: 15000,
      marginLeft: "-20px",
      marginRight: "-20px",
      marginBottom: "15px",
      background: "white",
      color: "var(--player-color)",
      "@media (min-width: 600px) and (max-height: 750px)": {
        paddingLeft: "60px",
        paddingRight: "60px",
      },
      "@media (min-width: 600px) and (max-height: 600px)": {
        paddingLeft: "80px",
        paddingRight: "80px",
      },
      "@media (min-width: 600px) and (max-height: 500px)": {
        paddingLeft: "100px",
        paddingRight: "100px",
      },
    },
  })
);

const ViewerYoutube = (props: any) => {
  const classes = useStyles();
  const [marks, setMarks] = useState<any>([1]);
  const [disabled, setDisabled] = useState(true);
  const speedRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const playSpeed = useSelector<RootState, number>(
    (state) => state.settings.playSpeed
  );

  const reader = useSelector<RootState, number>((state) => state.reader);

  let src = props.src.split("|") as string;
  let [video, caption] = src;

  if (!video.includes("https://")) {
    video = `https://www.youtube.com/embed/${src[0]}?autoplay=0&mute=0&cc_load_policy=1&enablejsapi=1&playsinline=1`;
  }

  useEffect(() => {
    return () => {
      store.dispatch(setWordbarOne({ id: "player", value: null }));
    };
  }, []);

  useEffect(() => {
    store.dispatch(setWordbarOne({ id: "playing", value: 0 }));
  }, [reader]);

  useEffect(() => {
    if (props.videoPlayer.current?.setPlaybackRate) {
      props.videoPlayer.current.setPlaybackRate(playSpeed);
    }
  }, [playSpeed, props.videoPlayer, props.src]);

  useEffect(() => {
    const onStateChange = (event: any) => {
      store.dispatch(setWordbarOne({ id: "playing", value: event.data }));
      if (event.data === 1) {
        setDisabled(false);
        store.dispatch(
          setWordbarOne({ id: "player", value: props.videoPlayer.current })
        );
      }
    };

    const loadVideo = () => {
      // the Player object is created uniquely based on the id in props
      // @ts-ignore
      new window.YT.Player(`player${props.index}`, {
        events: {
          onReady: onPlayerReady,
          onStateChange: onStateChange,
        },
      });
    };

    const onPlayerReady = (event: any) => {
      event.target.pauseVideo();
      props.videoPlayer.current = event.target;

      setMarks(event.target.getAvailablePlaybackRates());
    };

    // @ts-ignore
    if (!window.YT) {
      // If not, load the script asynchronously
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      // @ts-ignore
      window.onYouTubeIframeAPIReady = loadVideo;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
    } else {
      // If script is already there, load the video directly
      loadVideo();
    }
  }, [props.src, props.index, props.videoPlayer]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className={classes.sticky}>
        <div className={classes.videoContainer}>
          <iframe
            id={"player" + props.index}
            className={classes.videoFrame}
            src={video}
            title="player"
            allowFullScreen
          />
          {caption ? <div className={classes.caption}>{caption}</div> : ""}
        </div>
        <div className={classes.controls}>
          <div className={classes.controlLeft}>
            <IconButton
              ref={speedRef}
              className={classes.icon1text}
              onClick={() => setAnchorEl(speedRef.current)}
            >
              {(playSpeed || 1) + "x"}
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Typography className={classes.speedDiv}>
                {marks.length > 1 ? (
                  <Slider
                    value={playSpeed}
                    classes={{
                      markLabel: classes.markLabel,
                      root: classes.sliderRoot,
                    }}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    onChange={(e: any, v: any) => {
                      updateSetting({ playSpeed: v });
                    }}
                    step={0.25}
                    marks={marks.map((mark: any) => ({
                      value: mark,
                      label: mark + "",
                    }))}
                    min={marks[0]}
                    max={marks[marks.length - 1]}
                  />
                ) : (
                  ""
                )}
              </Typography>
            </Popover>
          </div>
          <div className={classes.controlRight}>
            <Mp3Controls disabled={disabled} video={true} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewerYoutube;
