import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { open: false, text: "" };

const backdropOpenSlice = createSlice({
  initialState,
  name: "backdropOpen",
  reducers: {
    setBackdropOpen: (state: any, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setBackdrop: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setBackdropOpen, setBackdrop } = backdropOpenSlice.actions;

export default backdropOpenSlice.reducer;
