import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "Redux/reducers/_index";
import Condition from "utils/Conditional";

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForwardTwoTone";

import { firestore } from "../firebaseUtil/firebaseUtil";
import { UserType } from "../Redux/reducers/reducer.currentUser";
import { getStatData } from "../reports/reports.utils";
import { tx } from "../utils/globalize";
import { phaseOut } from "../utils/utils";
import DashBoardCount from "./dashboard.count";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    results: {
      margin: "0px auto",
    },
    text: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      fontSize: "24px",
      marginBottom: "20px",
      marginTop: "40px",
    },
    frame: {
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.4)",
      borderRadius: "3px",
      padding: "10px",
      width: "100%",
      display: "flex",
      marginBottom: "10px",
      minWidth: "280px",
      flex: "1 1 270px",
      "&&:first-child": { marginRight: "8px" },
      "@media (max-width: 620px)": {
        maxWidth: "580px",
        "&&:first-child": { marginRight: "0px" },
      },
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    title: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      fontStyle: "18px",
      color: "lightgrey",
      paddingBottom: "8px",
    },
    baseButton: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontStyle: "18px",
      color: "var(--toolbar-background)",
      marginTop: "10px",
      border: "1px solid var(--toolbar-background)",
      borderRadius: "3px",
      textTransform: "none",
      padding: "6px 10px",
    },
    buttonDiv: {
      width: "100%",
      textAlign: "right",
      marginBottom: "50px",
    },
    linkWrapper: {
      textAlign: "right",
    },
    linkDiv: {
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "8px",
      textAlign: "right",
      color: "#898989",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      cursor: "pointer",
    },
    one: {
      width: "100%",
    },
    topFlex: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  })
);

const DashboardResults = (props: any) => {
  const { stats, setStats } = props;
  const classes = useStyles();
  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );
  const history = useHistory();

  useEffect(() => {
    const loadStats = async () => {
      const iData = await firestore
        .doc(`/users/${currentUser?.id}/data/stats`)
        .get();
      return iData.data();
    };

    if (currentUser) {
      loadStats().then((stat) => setStats(stat));
      setTimeout(() => loadStats().then((stat) => setStats(stat)), 0);
    }
  }, [currentUser, setStats]);

  const getTimeMinutes = (count: number) => {
    if (count === 0) {
      return tx("minuteslllll0", "minute [t0]");
    }
    if (count === 1) {
      return tx("minuteslllll1", "minute [t1]");
    }

    return tx("minuteslllll", "minutes [t2]");
  };

  const getTimeHours = (count: number) => {
    if (count === 0) {
      return tx("hourslllwlw0", "hour [t0]");
    }
    if (count === 1) {
      return tx("hourslllwlw1", "hour [t1]");
    }

    return tx("hourslllwlw", "hours [t2]");
  };

  const statWeek = getStatData(stats, 7);
  const statMonth = getStatData(stats, 30);
  const statAll = {
    done: stats?.all?.done || 0,
    reviews: stats?.all?.reviews || 0,
    time: stats?.all?.time || 0,
    units: getTimeMinutes(stats?.all?.time),
  };

  if (statAll.time > 35999) {
    statAll.time = Math.round(statAll.time / 60);
    statAll.units = getTimeHours(Math.round(statAll.time / 60));
  }

  const showWeek = statAll.time !== statWeek.time;
  const showMonth =
    statAll.time !== statMonth.time && statMonth.time !== statWeek.time;

  const gotoProgress = () => {
    phaseOut();
    setTimeout(() => {
      history.push("/app/report");
    }, 150);
  };

  const getCards = (count: number) => {
    if (count === 0) {
      return tx("oxlwweweorwerd0", "card [t0]");
    }
    if (count === 1) {
      return tx("oxlwweweowerrd1", "card [t1]");
    }

    return tx("oxlwweweorwerd", "cards [t2]");
  };

  const getDones = (count: number) => {
    if (count === 0) {
      return tx("oxlwordwzxzxw0", "done [t0]");
    }
    if (count === 1) {
      return tx("oxlwordwzxzxw1", "done [t1]");
    }

    return tx("oxlwordwazw", "dones [t2]");
  };

  return (
    <React.Fragment>
      <div className={classes.results}>
        <div className={classes.text}>{tx("kllzoooo", "Progress")}</div>
        <div className={classes.topFlex}>
          <div className={classes.frame}>
            <div className={classes.one}>
              <div className={classes.title}>
                {tx("lastsweasdveasmonth", "All time")}
              </div>
              <div className={classes.flex}>
                <DashBoardCount
                  type={statAll.units}
                  number={Math.ceil(statAll.time / 60)}
                />
                <DashBoardCount
                  type={getCards(statAll.reviews)}
                  number={statAll.reviews}
                />
                <DashBoardCount
                  type={getDones(statAll.done)}
                  number={statAll.done}
                />
              </div>
            </div>
          </div>
          <Condition when={showMonth}>
            <div className={classes.frame}>
              <div className={classes.one}>
                <div className={classes.title}>
                  {tx("lastsweveasmonth3", "This month")}
                </div>
                <div className={classes.flex}>
                  <DashBoardCount
                    type={statMonth.units}
                    number={Math.ceil(statMonth.time / 60)}
                  />
                  <DashBoardCount
                    type={getCards(statMonth.reviews)}
                    number={statMonth.reviews}
                  />
                  <DashBoardCount
                    type={getDones(statMonth.done)}
                    number={statMonth.done}
                  />
                </div>
              </div>
            </div>
          </Condition>
          <Condition when={showWeek}>
            <div className={classes.frame}>
              <div className={classes.one}>
                <div className={classes.title}>
                  {tx("lastsweveas4", "This week")}
                </div>
                <div className={classes.flex}>
                  <DashBoardCount
                    type={statWeek.units}
                    number={Math.ceil(statWeek.time / 60)}
                  />
                  <DashBoardCount
                    type={getCards(statWeek.reviews)}
                    number={statWeek.reviews}
                  />
                  <DashBoardCount
                    type={getDones(statWeek.done)}
                    number={statWeek.done}
                  />
                </div>
              </div>
            </div>
          </Condition>
        </div>
        <div className={classes.linkWrapper}>
          <div className={classes.linkDiv}>
            <div onClick={gotoProgress}>{tx("kokooosdsdssw", "Show more")}</div>
            <div>
              <ArrowForward />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardResults;
