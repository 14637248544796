import React, { useState } from "react";
import { useSelector } from "react-redux";
import NewWordDialog from "words/utils/dialogNewWord";

import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Snackbar,
} from "@material-ui/core";
import { GTranslate, LocalLibrary } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";

import { RootState } from "../../Redux/reducers/_index";
import Alert from "@material-ui/lab/Alert";
import { tx } from "../../utils/globalize";
import { getDictionary } from "../words.utils";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extraDic: {
      bottom: "54px",
      left: 15,
      position: "absolute",
      zIndex: 8000,
      padding: "5px",
      borderRadius: "25px",
      background: "rgba(0,0,0,0.8)",
    },
    iconbutton: { padding: "7px", color: "rgba(255,255,255,0.8)" },
  })
);

const DictionaryButtons = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const { query, activeSentence } = props;

  const status = useSelector<RootState, string>((state) => state.status);
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  const handleClose = () => setSnackOpen(false);

  return (
    <React.Fragment>
      {status === "wordlist" || status === "word" ? (
        <div className={classes.extraDic}>
          <IconButton
            className={classes.iconbutton}
            onClick={() => {
              window.open(
                `https://translate.google.com/#view=home&hl=${
                  currentUser?.language
                }&op=translate&sl=en&tl=${
                  currentUser?.language
                }&text=${encodeURI(activeSentence?.sOrig || query)}`,
                "gtrans1"
              );
            }}
          >
            <GTranslate />
          </IconButton>
          <IconButton
            className={classes.iconbutton}
            onClick={() => {
              window.open(getDictionary(currentUser?.language || "", query));
            }}
          >
            <LocalLibrary />
          </IconButton>
          <IconButton
            className={classes.iconbutton}
            onClick={() => setOpen(true)}
          >
            <AddIcon />
          </IconButton>
        </div>
      ) : (
        ""
      )}
      <NewWordDialog
        okAction={props.addToListCustom}
        open={open}
        setOpen={setOpen}
        cancelAction={() => setOpen(false)}
        engDefault={query}
        hunDefault=""
        setSnackOpen={setSnackOpen}
      />
      <Snackbar
        open={snackOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success">
          {tx("newwordxxx", "New word was added to your vocabulary list.")}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default DictionaryButtons;
