import React from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpDictionary() {
  const classes = useStyles();

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>{tx("azari", "Dictionary")}</div>
        <div className={classes.helpBold}>
          {tx("azari1", "What's this whole thing?")}
        </div>
        <div className={classes.helpText}>
          {tx("azari2", "This is a simple bilingual dictionary.")}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/dicfull.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azari3",
            `At the bottom you can type in the English word you're looking for and you will see a list of potential words and phrases you can choose from in the dictionary.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azari4",
            `Click on the word you need from the list to see the meanings in your language.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azari5",
            `Try to select those which are blue, as those have example sentences
          and further information about the word.`
          )}
        </div>

        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/dicdef.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx("azari6", `You will now see the definitions (translations).`)}{" "}
          {tx(
            "azari7",
            `Find the proper one
          and click on the little + button next to it if you want the definition
          to appear in your vocabulary book as well.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azari8",
            `Prefer using the definitions with the blue background as those are
          words with example sentences and further information about the word.`
          )}
        </div>
        <div className={classes.helpBold}>
          {tx("azari9", `I the lower right corner you will see three buttons.`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/dicbuttons.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azari10",
            `a) It translates the current sentence with Google Translate.`
          )}{" "}
          {tx("azari11", `A new window opens with the translation.`)}{" "}
          {tx("azari12", `When you are done close this window.`)}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azari13",
            `b) You can look up the current word in a bigger dictionary.`
          )}{" "}
          {tx(
            "azari14",
            `A new window opens with the bigger dictionary. When you are done close this window.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azari15",
            `c) If you want to add a totally new word pair to your vocabulary book (not taken from the list) click here.`
          )}{" "}
          {tx(
            "azari16",
            `You will see a new dialog where you can type in the English and the translated version which will be added to your vocabulary book.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/dici.png`}
            className={classes.helpImg}
          />
        </div>

        <div className={classes.helpText}>
          {tx(
            "azariwewe17",
            `When you want to exit the dictionary close it with the close button in the top right corner or click on the little arrow in the bottom right corner and you get back to the point from where you entered the dictionary.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/dicend.png`}
            className={classes.helpImg}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
