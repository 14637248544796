import React, { useState, useRef } from "react";
import LanguageMenu from "./header.languagemenu";
import { makeStyles, Theme, createStyles, ButtonBase } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { paddingLeft: "35px" },
    flex: {
      display: "flex",
      alignItems: "center",
      paddingTop: "2px",
    },
    flag: { marginRight: "8px" },
    name: { fontSize: "18px", fontFamily: "var(--font-medium)" },
    img: {},
  })
);

const LanguageList = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const menu = useRef<HTMLDivElement>(null);

  const openMenu = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <ButtonBase onClick={openMenu}>
          <div className={classes.flex} ref={menu}>
            <div className={classes.flag}>
              <img
                alt={"flag"}
                src={`/flags/${props.language}.png`}
                height="20px"
                className={classes.img}
              />
            </div>
          </div>
          <LanguageMenu
            open={open}
            setOpen={setOpen}
            anchor={menu.current}
            setLanguage={props.setLanguage}
          />
        </ButtonBase>
      </div>
    </React.Fragment>
  );
};

export default LanguageList;
