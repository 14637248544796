import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PositionType {
  currentPosition: number;
  currentLesson: string;
}

const initialState: PositionType = {
  currentPosition: 0,
  currentLesson: "",
};

const positionSlice = createSlice({
  initialState,
  name: "position",
  reducers: {
    setPositionCurrentPosition: (
      state: PositionType,
      action: PayloadAction<number>
    ) => {
      state.currentPosition = action.payload;
    },
    setPositionCurrentLesson: (
      state: PositionType,
      action: PayloadAction<string>
    ) => {
      state.currentLesson = action.payload;
    },
  },
});

export const {
  setPositionCurrentPosition,
  setPositionCurrentLesson,
} = positionSlice.actions;

export default positionSlice.reducer;
