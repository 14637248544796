import React, { useEffect, useState } from "react";

import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { tx } from "../../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dlg: { zIndex: 6000, transform: "translateZ(0)" },
  })
);

const NewWordDialog = (props: any) => {
  const classes = useStyles();
  const [valueEng, setValueEng] = useState("");
  const [valueHun, setValueHun] = useState("");

  const {
    okAction,
    open,
    cancelAction,
    engDefault,
    hunDefault,
    setOpen,
    setSnackOpen,
  } = props;

  useEffect(() => {
    setValueEng(engDefault);
    setValueHun(hunDefault);
  }, [engDefault, hunDefault]);

  const changeEng = (e: any) => {
    setValueEng(e.currentTarget.value);
  };

  const changeHun = (e: any) => {
    setValueHun(e.currentTarget.value);
  };

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();

      okAction({ e: valueEng, d: valueHun });
      setValueEng("");
      setValueHun("");
      setOpen(false);
      setSnackOpen(true);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={cancelAction}
        aria-labelledby="form-dialog-title"
        className={classes.dlg}
      >
        <DialogTitle id="form-dialog-title">
          {tx("dala1zsw", "New Word")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {tx("dala1zsc", "Add word pair to vocuabulary.")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            inputProps={{ autoCapitalize: "none", autoCorrect: "off" }}
            label={tx("dala1wwewer", "English")}
            type={"text"}
            value={valueEng}
            onChange={changeEng}
            fullWidth
          />
          <TextField
            margin="dense"
            id="name"
            label={tx("lwoxlsw", "[yourlang]")}
            inputProps={{ autoCapitalize: "none", autoCorrect: "off" }}
            type={"text"}
            value={valueHun}
            onChange={changeHun}
            onKeyDown={keyDown}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelAction} color="primary">
            {tx("dala1cac", "Cancel")}
          </Button>
          <Button
            onClick={() => {
              okAction({ e: valueEng, d: valueHun });
              setValueEng("");
              setValueHun("");
              setOpen(false);
              setSnackOpen(true);
            }}
            color="primary"
          >
            {tx("dala1ok", "Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default NewWordDialog;
