import store from "Redux/store";

import { firestore, FreeData } from "./firebaseUtil";

export const updatePosition = async (position: FreeData) => {
  const currentUser = store.getState().currentUser;
  await firestore
    .doc(`/users/${currentUser?.id}/data/position`)
    .update(position);
};

export const updateProgram = async (lesson: string, value: FreeData) => {
  const currentUser = store.getState().currentUser;
  await firestore
    .doc(`/users/${currentUser?.id}/data/program`)
    .update({ [lesson]: value });
};

export const updateProgramWhole = async (value: FreeData) => {
  const currentUser = store.getState().currentUser;
  await firestore
    .doc(`/users/${currentUser?.id}/data/program`)
    .update({ ...value });
};

export const updateWords = async (wordId: string, value: any) => {
  const currentUser = store.getState().currentUser;
  await firestore
    .doc(`/users/${currentUser?.id}/data/words`)
    .set({ words: { [wordId]: value } }, { merge: true });
};

export const updateSetting = async (setting: FreeData) => {
  const currentUser = store.getState().currentUser;

  await firestore
    .doc(`/users/${currentUser?.id}/data/settings`)
    .update(setting);
};

export const updateHelpTopics = async (topics: FreeData) => {
  const currentUser = store.getState().currentUser;

  await firestore
    .doc(`/users/${currentUser?.id}/data/helptopics`)
    .update(topics);
};
