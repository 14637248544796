import { setAppTransParts } from "Redux/reducers/reducer.appTransParts";
import { setAuthLoaded } from "Redux/reducers/reducer.authLoaded";
import { setCurrentUser } from "Redux/reducers/reducer.currentUser";
import { setCustomize } from "Redux/reducers/reducer.customize";
import { setSettings, SettingsType } from "Redux/reducers/reducer.settings";
import { setWords } from "Redux/reducers/reducer.words";
import store from "Redux/store";
import { loadAppTranslation } from "utils/saver.loadTranslation";

import { firestore } from "../firebaseUtil/firebaseUtil";
import {
  HelpTopicsType,
  setHelpTopics,
} from "../Redux/reducers/reducer.helptopic";

import { setCustomStyles } from "../utils/setCustomStyles";
import firebase from "firebase/app";

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

let wordsListener: any = null;
let settingsListener: any = null;
let customListener: any = null;
let userListener: any = null;
let helpListener: any = null;

export const setupListeners = (
  userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  userAuth: firebase.User
) => {
  //********************************
  //** User Snapshot
  //********************************

  userListener = userRef.onSnapshot(
    async (snapShot) => {
      const origLanguage = store.getState().currentUser?.language;
      const origExpiry = store.getState().currentUser?.expiry;

      store.dispatch(
        setCurrentUser({
          id: userRef.id,
          ...snapShot.data(),
        })
      );

      if (snapShot.data()?.language !== origLanguage) {
        store.dispatch(
          setAppTransParts(await loadAppTranslation(snapShot.data()?.language))
        );
      }

      if (
        snapShot.data()?.expiry !== origExpiry ||
        snapShot.data()?.language !== origLanguage
      ) {
        userAuth.getIdToken(true);
      }

      // if (snapShot.exists) {
      //   createProgram({ ...snapShot.data(), id: userRef.id } as UserType);
      // }

      if (snapShot.data()?.type !== "admin") {
        localStorage.setItem("language", snapShot.data()?.language || "en");
      }
      localStorage.setItem("currentUser", userRef.id);
      localStorage.setItem("userType", snapShot.data()?.type);
      localStorage.setItem("userLang", snapShot.data()?.language);

      store.dispatch(setAuthLoaded(true));
    },
    (error) => {
      console.log("userlistener", error);
    }
  );

  // //********************************
  // //** Program Snapshot
  // //********************************
  // const programRef = firestore.doc(`/users/${userRef.id}/data/program`);

  // programRef.onSnapshot(async (snapShot) => {
  //   const program = snapShot.data();

  //   store.dispatch(setProgram(program as ProgramType));

  //   // if (
  //   //   store.getState().settings.pro &&
  //   //   store.getState().position.currentLesson
  //   // ) {
  //   //   setTimeout(async () => {
  //   //     const thisChapter = await getNextChapter();
  //   //     if (thisChapter !== store.getState().position.currentLesson) {
  //   //       moveToNextChapter();
  //   //     }
  //   //   }, 0);
  //   // }
  // });

  //********************************
  //** Words Snapshot
  //********************************
  const wordsRef = firestore.doc(`/users/${userRef.id}/data/words`);

  wordsListener = wordsRef.onSnapshot(
    async (snapShot) => {
      const words = snapShot.data();

      if (words?.wordRaw) {
        store.dispatch(setWords(words.wordRaw || {}));
      } else {
        store.dispatch(setWords(words || {}));
      }
    },
    (error) => {
      console.log("wordslistener", error);
    }
  );

  // //********************************
  // //** TextPos Snapshot
  // //********************************
  // const textPosRef = firestore.doc(`/users/${userRef.id}/data/textpos`);

  // textPosRef.onSnapshot(async (snapShot) => {
  //   const textPos = snapShot.data();

  //   store.dispatch(setTextPos(textPos || {}));
  // });

  //********************************
  //** Settings Snapshot
  //********************************
  const settingsRef = firestore.doc(`/users/${userRef.id}/data/settings`);

  const settingsTemp = localStorage.getItem(`ne_settings${userRef.id}`);

  if (settingsTemp) {
    store.dispatch(setSettings(JSON.parse(settingsTemp)));
  }

  settingsListener = settingsRef.onSnapshot(
    async (snapShot) => {
      const settings = snapShot.data();

      const defaultSettings = {
        testReview: false,
        autoRead: true,
        pronunciation: true,
        writing: true,
        readSpeed: 1,
        playSpeed: 1,
        pro: true,
        startingLevel: "A",
        showFirstPage: true,
        wordLevel: "A",
        playing: true,
        highlight: true,
        fontSize: 18,
        head: true,
      };

      if (!snapShot.exists || !snapShot.data()?.fontSize) {
        if (!snapShot.exists) {
          settingsRef.set({
            ...defaultSettings,
          });
          return;
        }
        settingsRef.set({
          ...defaultSettings,
          theme: snapShot.data()?.theme || {},
        });
        return;
      }
      localStorage.setItem(
        `ne_settings${userRef.id}`,
        JSON.stringify(snapShot.data())
      );

      store.dispatch(
        setSettings({ ...defaultSettings, ...(settings as SettingsType) })
      );
    },
    (error) => {
      console.log("wordslistener", error);
    }
  );

  //********************************
  //** HelpTopics Snapshot
  //********************************
  const helpTopicsRef = firestore.doc(`/users/${userRef.id}/data/helptopics`);

  const helpTopicsTemp = localStorage.getItem(`ne_helptopic${userRef.id}`);

  if (helpTopicsTemp) {
    store.dispatch(setHelpTopics(JSON.parse(helpTopicsTemp)));
  }

  helpListener = helpTopicsRef.onSnapshot(
    async (snapShot) => {
      const helpTopics = snapShot.data();

      const defaultSettings = {
        practices: 0,
        intro: 0,
        theory: 0,
        cards: 0,
        conversations: 0,
        pronunciation: 0,
        writing: 0,
        quiz: 0,
        dashboard: 0,
      };

      if (!snapShot.exists) {
        helpTopicsRef.set({
          ...defaultSettings,
        });
        return;
      }
      localStorage.setItem(
        `ne_helptopic${userRef.id}`,
        JSON.stringify(snapShot.data())
      );

      store.dispatch(
        setHelpTopics({ ...defaultSettings, ...(helpTopics as HelpTopicsType) })
      );
    },
    (error) => {
      console.log("wordslistener", error);
    }
  );
};

export const setupCustomization = (ccode: string) => {
  //********************************
  //** Customize Snapshot
  //********************************

  const customizeRef = inIframe()
    ? firestore.doc(`/companies/${ccode || "default"}/data/customizeDev`)
    : firestore.doc(`/companies/${ccode || "default"}/data/customize`);

  customListener = customizeRef.onSnapshot(
    async (snapShot) => {
      let custData = snapShot.data();

      if (!snapShot.exists) {
        custData = {
          colors: {
            primaryText: "rgba(255, 255, 255, 0.9)",
            primaryBackground: "#e76659",
            secondaryBackground: "#ffdebf",
            secondaryText: "rgba(0, 0, 0, 0.7)",
            darkText: "#000000",
            lightText: "#808080",
            font: "Raleway",
            fontSize: "18px",
            deepBackground: "#172b4d",
            paperColor: "white",
            mailText: "black",
          },
        };
      }
      if (custData) {
        custData.colors = {
          ...custData.colors,
          ...(store.getState().settings.theme || {}),
        };
      }

      const sc = custData?.colors.secondaryBackground;
      const yiq =
        (parseInt(sc.substr(1, 2), 16) * 299 +
          parseInt(sc.substr(3, 2), 16) * 587 +
          parseInt(sc.substr(5, 2), 16) * 114) /
        1000;

      (custData || {}).colors.mailText =
        yiq >= 150 ? "rgba(0,0,0,0.7)" : "rgba(255,255,255,0.7)";
      store.dispatch(setCustomize({ ...custData }));
      localStorage.setItem("customized", JSON.stringify(custData));
      setCustomStyles(custData);
    },
    (error) => {
      console.log("wordslistener", error);
    }
  );
};

export const removeListeners = () => {
  if (customListener) {
    customListener = customListener();
  }

  if (settingsListener) {
    settingsListener = settingsListener();
  }

  if (wordsListener) {
    wordsListener = wordsListener();
  }

  if (userListener) {
    userListener = userListener();
  }

  if (helpListener) {
    helpListener = helpListener();
  }
};
