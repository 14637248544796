import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { IconButton, makeStyles, Theme } from "@material-ui/core";

import { createStyles } from "@material-ui/core";
import ChangePasswordCard from "admin/PasswordChange/changePW.card";
import ForgottenCard from "../admin/Forgotten/forgotten.card";
import LoginCard from "../admin/Login/login.card";
// import SignUp from '../signup/signup';
import Footer from "../footer/footer";
import { tx } from "../utils/globalize";
import SignUp from "signup/signup";
import { ArrowBack } from "@material-ui/icons";
import { backToGyakApp } from "./PrimaryAppBar";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    login: {
      overflowY: "auto",
      overflowX: "hidden",
      position: "relative",
      left: 0,
      right: 0,
      top: 0,
      background: "white",
      padding: "30px",
      paddingTop: "40px",
      zIndex: 1,
      opacity: 1,
      transition: "opacity 0.15s, z-index 0s 0.15s",
      maxWidth: "820px",
      "@media (min-width: 821px)": {
        margin: "25px auto",
        paddingLeft: "35px",
        paddingTop: "40px",
        paddingRight: "35px",
        minHeight: "calc(var(--vh) - 50px)",
      },
      margin: "0 auto",
      backgroundColor: "white",
      minHeight: "var(--vh)",
      paddingBottom: "235px",
    },
    copyright: {
      bottom: 10,
      justifySelf: "end",
      flex: "3 1 auto",
      alignItems: "flex-end",
      fontFamily: "var(--font-normal)",
      color: "#888888",
      fontSize: "15px",
      margin: "0 auto",
      paddingTop: "15px",
      paddingBottom: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
      lineHeight: "130%",
      background: "white",
      textAlign: "center",
      left: 0,
      right: 0,
      position: "absolute",
    },
    otherbutton: {
      position: "absolute",
      top: "10px",
      left: "10px",
    },
  })
);

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const inFrame = inIframe();

const LoginUi = () => {
  const classes = useStyles();
  let { path, url } = useRouteMatch();

  return (
    <React.Fragment>
      <div className={classes.login}>
        {inFrame ? (
          <IconButton className={classes.otherbutton} onClick={backToGyakApp}>
            <ArrowBack />
          </IconButton>
        ) : null}
        <Switch>
          <Route path={`${path}/login`}>
            <LoginCard />
          </Route>
          <Route path={`${path}/forgotten`}>
            <ForgottenCard />
          </Route>
          <Route path={`${path}/signup`}>
            <SignUp />
          </Route>
          <Route path={`${path}/newpw`}>
            <ChangePasswordCard />
          </Route>
          <Route path={`${path}`}>
            <Redirect to={`${url}/login`} />
          </Route>
        </Switch>
        <div className={classes.copyright}>
          <Footer tx={tx} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginUi;
