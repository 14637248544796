import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { setCurrentWord } from 'Redux/reducers/reducer.currentWord';
import { setStats } from 'Redux/reducers/reducer.stats';
import store from 'Redux/store';

import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import { AddCircleOutline, GetAppTwoTone, PublishTwoTone, Refresh } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import LoopIcon from '@material-ui/icons/Loop';

import { firestore } from '../../firebaseUtil/firebaseUtil';
import { RootState } from '../../Redux/reducers/_index';
import { tx } from '../../utils/globalize';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    doneword: {
      background: "var(--done-background)",
      marginBottom: "8px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      padding: "5px",
      paddingLeft: "15px",
      alignItems: "center",
      width: "calc(100% - 20px)",
      display: "flex",
      flexDirection: "row",
      "&.active": { background: "var(--active-background)" },
      "&.done": { background: "var(--done-background)" },
      "&.waiting": { background: "var(--pending-background)" },
      "@media (min-width: 478px)": {
        width: "calc(100% - 45px)",
      },
    },
    wordpart: {
      flex: "1 1 auto",
      maxWidth: "calc(100% - 85px)",
      "&.waiting": { maxWidth: "calc(100% - 121px)" },
    },
    engword: {
      flex: "1 1 auto",
      color: "var(--list-main-color)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    engwordblue: {
      flex: "1 1 auto",
      color: "blue",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
    hunword: {
      flex: "1 1 auto",
      color: "var(--list-secondary-color)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    icon2: {},
    updown: {
      transition: "all 0.3s",
      position: "fixed",
      bottom: 10,
      left: 30,
      background: "rgba(0,0,0,0.5)",
      zIndex: 80000,
      width: "76px",
      borderRadius: "8px",
      opacity: 0,
    },
    updownbutton: { padding: "7px" },
    wordend: {
      flex: "1 1 85px",
      textAlign: "right",
      "&.waiting": { flex: "1 1 125px" },
    },
    moregreen: {
      padding: "5px",
      marginLeft: "3px",
      color: "green",
    },
    morered: {
      padding: "5px",
      marginLeft: "3px",
      color: "red",
    },
    more: {
      padding: "5px",
      marginLeft: "3px",
    },
    date: {
      fontWeight: "bold",
      position: "absolute",
      bottom: "5px",
      width: "calc(100% - 20px)",
      display: "flex",
      alignItems: "center",
      "@media (min-width: 478px)": {
        width: "calc(100% - 45px)",
      },
    },
    datePart: { flex: "0 0 auto", paddingRight: "35px" },
    countPartDone: { flex: "0 0 50px", color: "green" },
    countPartRepeat: { flex: "0 0 50px", color: "brown" },
    nothing: {
      position: "absolute",
      top: "40px",
      fontSize: "21px",
      lineHeight: "150%",
      right: "35px",
      left: "35px",
      textAlign: "center",
    },
    titleblue: {
      color: "blue",
    },
    icon: { verticalAlign: "bottom" },
  })
);

const Row = React.memo(({ data, index, style }: any) => {
  // const showMenu = useCallback((e: any) => {
  //   data.menuRef.current = e.target;
  //   data.setMenuOpen(true);
  //   data.itemRef.current = index;
  // },[]);

  const classes = data.classes;

  const item = data.myList[index];

  const itemDone = Math.floor((item?.done || 0) / 86400);
  const thisMoment = Math.floor(Date.now() / 1000 / 86400);

  let type = "";

  if (thisMoment >= itemDone) {
    type = "active";
  }

  if (thisMoment < itemDone) {
    type = "waiting";
  }

  if (itemDone === 50000) {
    type = "done";
  }

  if (item.date) {
    return (
      <div style={style} className={"pad30"}>
        <div className={classes.date}>
          <div className={classes.datePart}>
            {new Date(item.date * 86400000).toLocaleDateString()}
          </div>
          <div className={classes.countPartDone}>
            <CheckIcon className={classes.icon} />
            {item.complete}
          </div>
          <div className={classes.countPartRepeat}>
            <LoopIcon className={classes.icon} />
            {item.repeat}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={style} className={"pad30"}>
      <div className={classes.doneword + " " + type}>
        <div
          className={classes.wordpart + " " + type}
          onClick={() => {
            data.showWord(item);

            data.doneRef.current = [];
            data.textIdRef.current = "";
            data.textTypeRef.current = "active";
            // setTimeout(() => data.getNext(item), 0);
          }}
        >
          <div className={item.o ? classes.engwordblue : classes.engword}>
            {item.e}
          </div>
          {data.showHun ? <div className={classes.hunword}>{item.d}</div> : ""}
        </div>
        <div className={classes.wordend + " " + type}>
          <div className={classes.wordend}>
            {type === "" ? (
              <IconButton
                onClick={data.addToListOfficial(item, "add")}
                className={classes.more}
              >
                <AddCircleOutline />
              </IconButton>
            ) : (
              ""
            )}
            <IconButton
              onClick={() => data.deleteItem(item)}
              className={classes.morered}
            >
              <Close />
            </IconButton>

            {type === "active" || type === "waiting" ? (
              <IconButton
                onClick={
                  item.id.includes("-")
                    ? () => data.addToListCustom(item, "done")
                    : () => data.addToListOfficial(item, "done")
                }
                className={classes.moregreen}
              >
                <CheckIcon />
              </IconButton>
            ) : (
              ""
            )}
            {type === "done" || type === "waiting" ? (
              <IconButton
                onClick={
                  item.id.includes("-")
                    ? () => data.addToListCustom(item)
                    : () => data.addToListOfficial(item, "add")
                }
                className={classes.more}
              >
                <Refresh />
              </IconButton>
            ) : (
              ""
            )}

            {type === "" ? (
              <React.Fragment>
                <IconButton
                  onClick={
                    item.id.includes("-")
                      ? () => data.addToListCustom(item, "done")
                      : () => data.addToListOfficial(item, "done")
                  }
                  className={classes.moregreen}
                >
                  <CheckIcon />
                </IconButton>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

// const aeq = (prev: any, next: any) => {
//   console.log(prev);
//   console.log("getnext", prev.getNext === next.getNext);
//   console.log("dicList", prev.dicList === next.dicList);
//   console.log("setdicList", prev.setDicList === next.setDicList);
//   console.log("markWorkDone", prev.markWorkDone === next.markWorkDone);
//   return true;
// };

let gref: any = null;

const WordOwnWords = React.memo((props: any) => {
  const classes = useStyles();

  const menuRef = useRef<any>();
  const itemRef = useRef<any>();
  const [menuOpen, setMenuOpen] = useState(false);
  const [myList, setMyList] = useState<any>([]);

  const {
    showUndone,
    showDone,
    setDicList,
    textTypeRef,
    addToListCustom,
    addToListOfficial,
    deleteItem,
    doneRef,
    textIdRef,
    listRef,
  } = props;

  const words = useSelector<RootState, any>((state) => state.words);
  const language = useSelector<RootState, any>(
    (state) => state.currentUser?.language
  );

  const onScroll = () => {
    const updown = document.getElementById("xupdown");
    if (gref) {
      clearTimeout(gref);
    }
    if (updown) {
      updown.classList.add("xvisible");
      gref = setTimeout(() => updown.classList.remove("xvisible"), 4000);
    }
  };

  useEffect(() => {
    const usedWords = Object.keys(words);

    const newObj: any = {};

    const thisMoment = Math.floor(Date.now() / 1000);

    for (let g = 0; g < usedWords.length; g++) {
      const item = words[usedWords[g]];
      if (!item?.done) {
        continue;
      }

      const day = item.s + "";

      if (newObj[day]) {
        newObj[day].push(item);
      } else {
        newObj[day] = [item];
      }
    }

    const days = Object.keys(newObj).sort();

    const finalList: any[] = [];
    let finalFullList: any[] = [];

    let done = 0;
    let toBeDone = 0;
    let done3000 = 0;
    let toBeDone3000 = 0;

    let forToday = 0;

    for (let g = 0; g < days.length; g++) {
      let newListDone = [];
      let newList = [];

      const dayList = newObj[days[g]];

      for (let n = 0; n < dayList.length; n++) {
        const item = dayList[n];

        if (item?.done === 50000 * 86400) {
          newListDone.push(item);
          if (item.id.slice(2) < "A5") {
            done3000++;
          }
        } else {
          newList.push(item);
          if (item.id.slice(2) < "A5") {
            toBeDone3000++;
          }

          if (item?.done <= thisMoment) {
            forToday++;
          }
        }
      }

      newListDone.sort((a: any, b: any) => {
        if (a.e > b.e) {
          return 1;
        } else {
          return -1;
        }
      });

      newList.sort((a: any, b: any) => (a.done > b.done ? 1 : -1));

      finalList.push({
        date: parseInt(days[g]),
        complete: newListDone.length,
        repeat: newList.length,
      });

      done = done + newListDone.length;
      toBeDone = toBeDone + newList.length;

      if (showDone) {
        newListDone.forEach((item) => {
          finalList.push(item);
        });
      }
      if (showUndone) {
        newList.forEach((item) => {
          finalList.push(item);
        });
      }

      finalFullList = [...finalFullList, ...newList];
    }

    store.dispatch(
      setStats({
        done: done,
        repeat: toBeDone,
        all: done + toBeDone,
        today: forToday,
        done3000,
        toBeDone3000,
      })
    );

    localStorage.setItem(
      "ne_stats",
      JSON.stringify({
        done: done,
        repeat: toBeDone,
        all: done + toBeDone,
        today: forToday,
      })
    );

    setDicList(finalFullList);
    setMyList(finalList);
  }, [words, setDicList, showDone, showUndone]);

  const showWord = (w: any) => {
    if (w.id.includes("-")) {
      store.dispatch(setCurrentWord({ ...w, s: [] }));
      setTimeout(() => {
        props.setDefOpen(true);
        // setTimeout(() => contRef.current.update(), 0);
      }, 0);
      return;
    }

    firestore
      .doc(`/vocab${language.toUpperCase()}/` + w.id)
      .get()
      .then((res) => {
        store.dispatch(setCurrentWord(res.data()));

        setTimeout(() => {
          props.setDefOpen(true);
          // setTimeout(() => contRef.current.update(), 0);
        }, 0);
      });
  };

  return (
    <React.Fragment>
      {myList.length === 0 ? (
        <div className={classes.nothing}>
          <div>
            {tx(
              "thisisit",
              "This is your vocabulary book. You will see here the words you select for study."
            )}{" "}
            {tx(
              "thisisit1",
              "Read some texts and select the words you don't understand."
            )}
          </div>
          <div>{tx("thisisit2", "See the help to find out how.")}</div>
        </div>
      ) : (
        ""
      )}
      <AutoSizer>
        {({ height, width }: any) => (
          <List
            ref={listRef}
            className="List"
            height={height}
            itemCount={myList.length || 0}
            itemSize={props.showHun ? 55 : 50}
            width={width}
            onScroll={onScroll}
            overscanCount={10}
            itemData={{
              myList,
              classes,
              showHun: props.showHun,
              getNext: props.getNext,
              menuRef,
              menuOpen,
              setMenuOpen,
              itemRef,
              textTypeRef,
              doneRef,
              textIdRef,
              showWord,
              addToListOfficial,
              addToListCustom,
              deleteItem,
            }}
          >
            {Row as any}
          </List>
        )}
      </AutoSizer>
      <div className={classes.updown} id={"xupdown"}>
        <IconButton
          className={classes.updownbutton}
          onClick={() => {
            listRef.current.scrollToItem(0);
          }}
        >
          <PublishTwoTone />
        </IconButton>
        <IconButton
          className={classes.updownbutton}
          onClick={() => {
            listRef.current.scrollToItem(8000);
          }}
        >
          <GetAppTwoTone />
        </IconButton>
      </div>
    </React.Fragment>
  );
});

export default WordOwnWords;
