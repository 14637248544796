import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QuizType {
  lessons: string;
  title: string;
  text: string;
  quizAnswers: any;
  quizes: any;
}

const initialState: QuizType = {
  lessons: "",
  title: "Quiz",
  text: "Testing you now.",
  quizAnswers: null,
  quizes: null,
};

const quizSlice = createSlice({
  initialState,
  name: "quiz",
  reducers: {
    setQuizLessons: (state: QuizType, action: PayloadAction<string>) => {
      state.lessons = action.payload;
    },
    setQuizTitle: (state: QuizType, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setQuizText: (state: QuizType, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
    setQuiz: (state: QuizType, action: PayloadAction<QuizType>) => {
      return action.payload;
    },
    setQuizAnswers: (state: QuizType, action: PayloadAction<any>) => {
      state.quizAnswers = action.payload;
    },
    setQuizes: (state: QuizType, action: PayloadAction<any>) => {
      state.quizes = action.payload;
    },
  },
});

export const {
  setQuizLessons,
  setQuizTitle,
  setQuizText,
  setQuiz,
  setQuizes,
  setQuizAnswers,
} = quizSlice.actions;

export default quizSlice.reducer;
