import React from "react";

import { useStyles } from "./00-styles";
import { th } from "utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpIPhone() {
  const classes = useStyles();
  const loadDone = useSelector<RootState, any>(
    (state) => state.appTransParts.init
  );

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  // const authLoaded = useSelector<RootState, any>((state) => state.authLoaded);

  return (
    <React.Fragment>
      <div className={!loadDone ? classes.wrapper : classes.wrapperInvisible}>
        <div className={classes.helpHeader}>
          {th(
            "BsdBC6C8EE-C96F-46A5-9A91-6C4922B98571sAand",
            `Welcome to NetEnglish!`
          )}
        </div>
        <div className={classes.helpBold}>
          {th(
            "BBC6C8EE-C96F-46A5-9A91-6C49sd22B98571sAand",
            `First things first:`
          )}
        </div>
        <div className={classes.helpHeader}>
          {th(
            "BBC6C8EE-C96F-46A5-9A91-6C4922B98571sAand",
            `Put NetEnglish on your home screen!`
          )}
        </div>
        <div className={classes.helpText}>
          {th(
            "88BAE731-6863-4EC1-9B68-1F382DC651C4",
            `NetEnglish is a so called web application which means you can run it from the internet in a simple browser.`
          )}
        </div>
        <div className={classes.helpText}>
          {th(
            "C0C71A5E-FA3F-4C26-B569-4FAC732B1A3F",
            `However on your phone you can create an icon for it on your main screen and run it like any other application.`
          )}
        </div>
        <div className={classes.helpBold}>
          {th("todothisnow", "To do this now...")}
        </div>
        <div className={classes.helpText}>
          {th(
            "9D474CEA-DBB1-4946-8982-4C869DBCB67Ba",
            `Click on the sharing button on the bottom bar in the browser where you are reading this.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="iphone1"
            src={`/help/${lang}/iphone1.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "92FDEC93-1403-4E17-9036-A2395AF9DC25",
            `Find and click on the Add to Main screen button.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="iphone2"
            src={`/help/${lang}/iphone2.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "6EAF7203-BADD-446E-A428-9B06A6B25061",
            `Click on Add in the top right corner of the appearing dialog.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="iphone3"
            src={`/help/${lang}/iphone3.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "9A60FC2D-8E60-49C5-8C89-CFF2C740BBF6",
            `Your browser will close and you will see your new icon on the main screen.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="iphone4"
            src={`/help/${lang}/and5.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "1E9C694B9-BB49-4604-AB0B-9F9E3612E9E3",
            `Close this browser and start your application clicking on that icon.`
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
