import React from "react";
import { useSelector } from "react-redux";
import { setStatus } from "Redux/reducers/reducer.status";
import store from "Redux/store";

import { IconButton } from "@material-ui/core";
import { ClearTwoTone, SearchTwoTone } from "@material-ui/icons";

import { RootState } from "../../Redux/reducers/_index";

const QueryInput = (props: any) => {
  const { query, setQuery, classes, setActiveSentence, setWordResult } = props;

  const status = useSelector<RootState, string>((state) => state.status);

  const changeQuery = (e: any) => {
    setQuery(e.currentTarget.value);
    store.dispatch(setStatus("wordlist"));
    setWordResult({});
  };

  return (
    <React.Fragment>
      {status === "wordlist" || status === "word" ? (
        <input
          id="xinput"
          autoCorrect="off"
          autoCapitalize="none"
          className={classes.input}
          value={query}
          onChange={changeQuery}
          autoFocus
        />
      ) : (
        ""
      )}
      <IconButton
        className={
          classes.searchIcon +
          (status === "word" || status === "wordlist" ? " active" : "")
        }
        onClick={() => {
          setQuery("");
          setWordResult({});
          store.dispatch(setStatus("wordlist"));
          setActiveSentence({ s: "", sOrig: "", w: [0, 0], fileId: "" });
          setTimeout(() => document.getElementById("xinput")?.focus(), 0);
        }}
      >
        {query ? <ClearTwoTone /> : <SearchTwoTone />}
      </IconButton>
    </React.Fragment>
  );
};

export default QueryInput;
