//student
//tx("asdfasdf1update","===New update available")

import { firestore } from "firebaseUtil/firebaseUtil";
import { setUpdating } from "Redux/reducers/reducer.updating";
import store from "Redux/store";

import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import { AppUpdate } from "@robingenz/capacitor-app-update";

import { CapacitorUpdater } from "capacitor-updater";
import { setConfirmOpen } from "Redux/reducers/reducer.confirmOpen";

let platform = "";
let appInfo: any;
let mobileVersion = "";

App.getInfo()
  .then((i: any) => {
    appInfo = i;
    mobileVersion = i.build;
  })
  .catch((e: any) => {});

Device.getInfo().then((i: any) => {
  platform = i.platform;
});

export const getAppVersion = async () => {
  let data: any;

  try {
    const response = await firestore.doc("/version/version").get();
    data = { ...response.data() };
    // bookVersion = localStorage.getItem("bookVersion");
    // fileVersion = localStorage.getItem("fileVersion");
  } catch (e) {
    console.log("error fetching version");
    return "";
  }

  // let updateInfo: any;
  //

  // try {
  //   updateInfo = await AppUpdate.getAppUpdateInfo();

  //   data[platform] = parseInt(updateInfo.availableVersion);
  // } catch (e) {
  //   console.log(e);
  //   console.log("not official release");
  // }

  return data;
};

const checkIfUpdateIsNeeded = async () => {
  const newVersion = await getAppVersion();

  console.log("appinfo: " + appInfo?.build);

  if (platform === "web") {
    const appVersion = localStorage.getItem("appVersion");

    if (newVersion.appVersion <= parseInt(appVersion || "0")) {
      return 0;
    }
    return 1;
  }

  if (
    platform !== "web" &&
    appInfo.build > 0 &&
    newVersion[platform] > appInfo.build
  ) {
    // alert(appInfo.build);
    return 2;
  }
  // check here if there's push update for mobile

  const myMobileVersion = localStorage.getItem(
    "myMobileVersion" + mobileVersion
  );

  if (
    newVersion[mobileVersion] &&
    newVersion[mobileVersion] !== myMobileVersion
  ) {
    return 3;
  }

  return 0;
};

export const checkForUpdate = async (firstRun: any = false) => {
  // let bookVersion: any;
  if (store.getState().updating) {
    return;
  }

  let needsUpdate: any;

  try {
    needsUpdate = await checkIfUpdateIsNeeded();
  } catch (e) {
    console.log(JSON.stringify(e));
    return;
  }

  console.log(needsUpdate);
  //

  // if (needsUpdate === 2) {
  //   const updateInfo = await AppUpdate.getAppUpdateInfo();

  //   if (updateInfo.flexibleUpdateAllowed) {
  //     store.dispatch(setUpdating(true));
  //     AppUpdate.addListener("onFlexibleUpdateStateChange", async (state) => {
  //       if (state.installStatus === 4) {
  //         store.dispatch(setUpdating(false));
  //         await AppUpdate.completeFlexibleUpdate();
  //       }

  //       if (state.installStatus === 5 || state.installStatus === 6) {
  //         store.dispatch(setUpdating(false));
  //         alert(tx("errorup", "Error Updating. Try again later"));
  //         return;
  //       }
  //     });

  //     const status = await AppUpdate.startFlexibleUpdate();

  //     alert(status.code);

  //     return;
  //   }
  // }

  if (!needsUpdate) {
    return;
  }

  if (firstRun === true && needsUpdate !== 2) {
    updateApp();
    return;
  }

  //@ts-ignore
  // if (window.myApiMain) {
  //   updateApp();
  //   return;
  // }

  store.dispatch(setConfirmOpen(true));
};

export const updateApp = async () => {
  // let bookVersion: any;
  const updateNeeded = await checkIfUpdateIsNeeded();

  if (!updateNeeded) {
    return;
  }

  if (updateNeeded === 2) {
    await AppUpdate.openAppStore();

    return;
  }

  if (updateNeeded === 1) {
    const newVersion = await getAppVersion();
    localStorage.setItem("appVersion", newVersion.appVersion);

    //@ts-ignore
    window.location.reload(true);
    return;
  }
  //

  if (updateNeeded === 3) {
    const newVersion = await getAppVersion();

    store.dispatch(setUpdating(true));

    try {
      const version = await CapacitorUpdater.download({
        url: newVersion[mobileVersion],
      });
      store.dispatch(setUpdating(false));
      localStorage.setItem(
        "myMobileVersion" + mobileVersion,
        newVersion[mobileVersion]
      );

      firestore.terminate();
      await CapacitorUpdater.set(version);

      // CapacitorUpdater.reload();
    } catch (e) {
      store.dispatch(setUpdating(false));
    }

    // show the splashscreen to let the update happen
  }
};
