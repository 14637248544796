import React from "react";

import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { tx } from "../../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {},
  })
);

const AlertDialog = (props: any) => {
  // const classes = useStyles();

  const { text, okAction, title, open } = props;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={okAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => okAction()} color="primary">
            {tx("dala1saa", "OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialog;
