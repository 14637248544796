import React from "react";
// import { makeStyles, createStyles } from "@material-ui/styles";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     text: {},
//   })
// );

const LegalFrame = (props: any) => {
  // const classes = useStyles();

  return (
    <React.Fragment>
      {props.link ? (
        <div
          className="scroll-wrapper"
          style={{
            height: "calc(var(--vh) - 64px)",
            width: "calc(var(--vw) - 20px)",
            maxWidth: "950px",
            maxHeight: "calc(100% - 64px)",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "calc(100% - 35px)",
              width: "100%",
            }}
            title="legal"
            src={"https://" + props.link}
          />
        </div>
      ) : (
        props.content
      )}
    </React.Fragment>
  );
};

export default LegalFrame;
