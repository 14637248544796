import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgDiv: { marginBottom: "15px", width: "100%", textAlign: "center" },
    caption: {
      textAlign: "center",
      fontSize: "13px",
    },
  })
);

const ViewerImage = (props: any) => {
  const classes = useStyles();

  let src = props.src.split("|") as string;
  let [img, caption, width] = src;

  return (
    <React.Fragment>
      <div className={classes.imgDiv}>
        <img
          style={{ width: (width || "100") + "%" }}
          src={img}
          alt="some visual"
        />
        {caption ? <div className={classes.caption}>{caption}</div> : ""}
      </div>
    </React.Fragment>
  );
};

export default ViewerImage;
