import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";

import {
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  AddCircleOutline,
  GTranslate,
  LocalLibrary,
  SchoolTwoTone,
  VolumeUpTwoTone,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import { speakNow } from "../utils/utils.speech";
import { blist, sentenceFormat, getDictionary } from "../words.utils";
import { tx } from "../../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {},
    controls: {},
    explanation: { marginBottom: "8px", lineHeight: "150%" },
    explanationBold: {
      marginBottom: "8px",
      fontWeight: "bold",
      lineHeight: "150%",
    },
    versions: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      flexDirection: "column",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      paddingBottom: "8px",
    },
    defWrap: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    headWord: { fontWeight: "bold" },
    definition: {
      display: "flex",
      width: "100%",
      padding: "12px",
      paddingLeft: "20px",
      paddingRight: "20px",
      fontSize: "18px",
      lineHeight: "130%",
    },
    active: {},
    formName: {
      fontSize: "15px",
      lineHeight: "130%",
      color: "lightgrey",
      paddingRight: "10px",
    },
    subFormText: {
      fontSize: "15px",
      paddingRight: "0px",
      lineHeight: "130%",
      color: "grey",
      display: "flex",
    },
    title: {
      display: "flex",
      width: "100%",
      padding: "9px",
      paddingLeft: "15px",
      fontSize: "21px",
      lineHeight: "130%",
      alignItems: "center",
      background: "antiquewhite",
    },
    volume: { paddingLeft: "12px", opacity: 0.5, transform: "scale(0.7)" },
    volume1: {
      paddingLeft: "12px",
      paddingRight: "12px",
      opacity: 0.5,
      transform: "scale(0.7)",
    },
    word: {
      flex: "1 1 auto",
      display: "flex",
      alignItems: "end",
      paddingTop: "3px",
    },
    pos: {
      display: "inline-block",
      color: "grey",
      fontSize: "17px",
    },
    close: {
      textAlign: "right",
      color: "grey",
      fontSize: "17px",
      flex: "1 1 auto",
    },
    closeIcon: {
      padding: "6px",
    },
    dcontent: { paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px" },
    ipa: {
      fontSize: "15px",
      paddingRight: "0px",
      lineHeight: "130%",
      color: "grey",
      display: "flex",
      paddingBottom: "6px",
      paddingTop: "6px",
      marginTop: "3px",
      paddingLeft: "20px",
    },
    header: {
      margin: "0px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontSize: "15px",
      fontWeight: "bold",
      paddingLeft: "15px",
      background: "aliceblue",
      marginBottom: "5px",
    },
    root: {
      "&.Mui-expanded": {
        margin: "0px",
        minHeight: "0px",
      },
    },
    englishLone: {
      fontSize: "16px",
      padding: "5px 25px 5px 25px",
      lineHeight: "130%",
      color: "black",
      flex: "1 1 auto",
    },
    engWrapper: { flex: "1 1 auto" },
    english: {
      fontSize: "16px",
      padding: "10px 0px 5px 25px",
      lineHeight: "130%",
      color: "black",
    },
    foreign: {
      fontSize: "16px",
      padding: "5px 25px 10px 25px",
      lineHeight: "130%",
      color: "grey",
    },
    sentWrapper: {
      display: "flex",

      borderBottom: "1px solid rgba(0,0,0,0.1)",
      "&:last-child": { borderBottom: "0px solid white" },
    },
    actives: {
      fontSize: "18px",
      padding: "10px 20px 15px 20px",
      lineHeight: "130%",
      color: "black",
    },
    white: {},
    bottom: { padding: "0px 5px 0px 5px" },
    dlg: { width: "calc(100% - 32px)" },
    paperScrollPaper: { maxHeight: "calc(100% - 32px)" },
    noMargin: { margin: "0px" },
    study: { flex: "1 1 auto" },
  })
);

const FullPopup = React.memo((props: any) => {
  const classes = useStyles();

  const currentWord = useSelector<RootState, any>((state) => state.currentWord);
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  const existWord = useSelector<RootState, any>(
    (state) => state.words[currentWord.id]
  );

  const { word } = currentWord;
  if (!word) {
    return null;
  }
  const [wordPart] = word.split("*");
  let [eng, pos] = wordPart.split("+");
  // const partOfSentence = blist[part];
  const forms = eng.split("/");

  const visualPos = blist[pos] || "";

  const handleClose = () => {
    props.setDefOpen(false);
  };

  const sentences3000 = currentWord.cards?.slice(1) || [];

  // const sentencesOther = currentWord.s?.filter(
  //   (item: any) => item.split("{")[1].split("}")[0].length !== 2
  // );

  // const formSentence = (sentence: string, index: number) => {
  //   let temp = sentence.split("{");
  //   temp = temp[0].split("[");
  //   let id = temp[1]?.replace("]", "") || "aaaa";
  //   let sent = temp[0];

  //   return (
  //     <React.Fragment key={index}>
  //       <div className={classes.sentWrapper}>
  //         <div className={classes.englishLone}>{sent}</div>
  //         <div className={classes.volume1}>{getSpeaker(sent)}</div>
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  // const formSentenceTrans = (sentence: string) => {
  //   let temp = sentence.split("{");
  //   temp = temp[0].split("[");
  //   let id = temp[1]?.replace("]", "") || "aaaa";
  //   let sent = temp[0];

  //   return (
  //     <React.Fragment key={id}>
  //       <div className={classes.sentWrapper}>
  //         <div className={classes.engWrapper}>
  //           <div className={classes.english}>{sent}</div>
  //           <div className={classes.foreign}>
  //             {currentWord?.trans?.[id] || sent}
  //           </div>
  //         </div>
  //         <div className={classes.volume1}>{getSpeaker(sent)}</div>
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  const formSentenceCard = (sentence: any) => {
    return (
      <React.Fragment key={sentence.eid}>
        <div className={classes.sentWrapper}>
          <div className={classes.engWrapper}>
            <div className={classes.english}>{sentence.english}</div>
            <div className={classes.foreign}>{sentence.foreign}</div>
          </div>
          <div className={classes.volume1}>{getSpeaker(sentence.english)}</div>
        </div>
      </React.Fragment>
    );
  };

  const getExplanation = (explanations: string[]) => {
    return explanations.map((line, index) =>
      line.includes("*") ? (
        <div key={index} className={classes.explanationBold}>
          {line.replace("*", "")}
        </div>
      ) : (
        <div key={index} className={classes.explanation}>
          {line.replace("*", "")}
        </div>
      )
    );
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{ square: true }}
        open={props.defOpen}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        classes={{
          paperFullWidth: classes.dlg,
          paperScrollPaper: classes.paperScrollPaper,
          paper: classes.noMargin,
        }}
      >
        <div className={classes.title}>
          <div className={classes.word}>
            {currentWord.explanations ? (
              <div className={classes.headWord}>{currentWord.d}</div>
            ) : (
              <React.Fragment>
                <div className={classes.headWord}>
                  {forms[0].replace(/%%%/g, "/")}
                </div>
                <div className={classes.volume}>{getSpeaker(forms[0])}</div>
                <div className={classes.pos}>{visualPos}</div>
              </React.Fragment>
            )}
          </div>
          <div className={classes.close}>
            <IconButton className={classes.closeIcon} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <DialogContent dividers={true} className={classes.dcontent}>
          <div className={classes.wrapper}>
            {currentWord.pron ? (
              <React.Fragment>
                <div className={classes.header}>
                  {tx("azarp1", `Pronunciation`)}
                </div>
                <div className={classes.ipa}>{currentWord.pron}</div>
              </React.Fragment>
            ) : (
              ""
            )}
            {forms.length > 1 ? (
              <div className={classes.versions}>
                <div className={classes.header}>
                  {tx("azarp2", `Other forms`)}
                </div>
                {forms[1] ? (
                  <div className={classes.defWrap}>
                    <div className={classes.formName}>
                      {getFormName(pos, 1)}:{" "}
                    </div>
                    <div className={classes.subFormText}>{forms[1]}</div>
                    <div className={classes.volume}>{getSpeaker(forms[1])}</div>
                  </div>
                ) : null}
                {forms[2] ? (
                  <div className={classes.defWrap}>
                    <div className={classes.formName}>
                      {getFormName(pos, 2)}:{" "}
                    </div>
                    <div className={classes.subFormText}>{forms[2]}</div>
                    <div className={classes.volume}>{getSpeaker(forms[2])}</div>
                  </div>
                ) : null}
              </div>
            ) : (
              ""
            )}
            <div className={classes.header}>
              {currentWord.explanations
                ? tx("azarpwerwer", `Description`)
                : tx("azarp3", `Definition`)}
            </div>
            <div className={classes.definition}>
              {currentWord.explanations
                ? getExplanation(currentWord.explanations)
                : currentWord.d}
            </div>
            {props.activeSentence?.s || existWord?.a?.s ? (
              <React.Fragment>
                <div className={classes.header}>{tx("azarp4", `Context`)}</div>
                <div className={classes.actives}>
                  {sentenceFormat(
                    props.activeSentence.s ? props.activeSentence : existWord.a
                  )}
                </div>
              </React.Fragment>
            ) : (
              ""
            )}

            {sentences3000?.length ? (
              <React.Fragment>
                <div className={classes.header}>
                  {tx("azarp5", `Examples with translation`)}
                </div>
                {sentences3000.map((item: any) => {
                  return formSentenceCard(item);
                })}
              </React.Fragment>
            ) : (
              ""
            )}
            {/* {sentencesOther?.length ? (
              <React.Fragment>
                <div className={classes.header}>
                  {tx("azarp6", `Other examples`)}
                </div>
                {sentencesOther.map((item: any, index: number) => {
                  return formSentence(item, index);
                })}
              </React.Fragment>
            ) : (
              ""
            )} */}
          </div>
        </DialogContent>
        <DialogActions className={classes.bottom}>
          <div className={classes.study}>
            <IconButton
              disabled={Boolean(currentWord.explanations)}
              className={classes.white}
              onClick={() => {
                props.addWord();
                props.getNext(currentWord);
              }}
            >
              <SchoolTwoTone />
            </IconButton>
          </div>

          <div>
            <IconButton
              className={classes.white}
              onClick={() => {
                window.open(
                  `https://translate.google.com/#view=home&hl=${
                    currentUser?.language
                  }&op=translate&sl=en&tl=${
                    currentUser?.language
                  }}&text=${encodeURI(
                    props.activeSentence?.sOrig || existWord?.a?.s || forms[0]
                  )}`,
                  "gtrans1"
                );
              }}
            >
              <GTranslate />
            </IconButton>
            <IconButton
              className={classes.white}
              onClick={() => {
                window.open(
                  getDictionary(currentUser?.language || "", forms[0])
                );
              }}
            >
              <LocalLibrary />
            </IconButton>
            <IconButton
              disabled={Boolean(existWord || currentWord.explanations)}
              className={classes.white}
              onClick={props.addWord}
            >
              <AddCircleOutline />
            </IconButton>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default FullPopup;

const getSpeaker = (str: string) => {
  return (
    <React.Fragment>
      <span onClick={() => speakNow(str)}>
        <VolumeUpTwoTone />
      </span>
    </React.Fragment>
  );
};

const getFormName = (pos: string, num: number) => {
  // @ts-ignore
  const tempForm = posObj[pos]?.[num] || "";
  return tempForm;
};

const posObj = {
  "v.": {
    1: tx("azarp11", `2. form`),
    2: tx("azarp12", `3. form`),
  },
  "n.": {
    1: tx("azarp13", `plural`),
  },
  "adj.": {
    1: tx("azarp14", `comparative`),
    2: tx("azarp15", `superlative`),
  },
};
