import React, { useEffect, useState } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginBottom: "15px",
      boxShadow: "1px 1px 1px 1px lightgrey",
      padding: "6px",
      lineHeight: "130%",
    },
    orig: { marginBottom: "5px" },
    translation: { color: "grey" },
  })
);

const ViewerTranslation = React.memo((props: any) => {
  const classes = useStyles();
  const [translation, setTranslation] = useState("");
  const [english, setEnglish] = useState("");

  useEffect(() => {
    const split = props.sentence.split("^");

    setTranslation(split[1]);
    setEnglish(split[0]);
  }, [props.sentence]);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.orig}>{english}</div>
        <div className={classes.translation}>{translation}</div>
      </div>
    </React.Fragment>
  );
});

export default ViewerTranslation;

// {
//   words.map((word, index) => (
//     <WordsWord
//       word={word}
//       sIndex={props.index}
//       index={index}
//       key={index}
//       sentence={english}
//       splitter={props.splitter}
//       lookupWord={props.lookupWord}
//       stemmer={props.stemmer}
//       longPressAction={props.longPressAction}
//     />
//   ));
// }
