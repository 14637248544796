import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const grammarSlice = createSlice({
  initialState: false,
  name: "grammar",
  reducers: {
    setGrammar: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setGrammar } = grammarSlice.actions;

export default grammarSlice.reducer;
