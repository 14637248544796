import React from "react";
import { useSelector } from "react-redux";

import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { RootState } from "../../Redux/reducers/_index";
import { updateWords, clean } from "../words.utils";
import { AddCircleOutline } from "@material-ui/icons";
import { htmlIt } from "../../utils/readxml";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    def: {
      marginBottom: "8px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "20px",
      padding: "8px",
      paddingLeft: "15px",
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      "@media (min-width: 478px)": {
        width: "100%",
      },
    },
    spacer: {
      height: "155px",
    },
    defStudy: {
      marginBottom: "8px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "20px",
      padding: "8px",
      paddingLeft: "15px",
      alignItems: "baseline",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      background: "lightblue",
      "@media (min-width: 478px)": {
        width: "100%",
      },
    },
    entry: {
      marginBottom: "20px",
      marginTop: "25px",
      fontSize: "24px",
      color: "blue",
      fontWeight: "bold",
    },
    defWord: { flex: "1 1 auto", lineHeight: "120%" },
    action: { flex: "0 0 30px" },
    black: { color: "grey" },
    green: { color: "green" },
    brown: { color: "brown" },
  })
);

const WordDef = React.memo((props: any) => {
  const classes = useStyles();

  const allWords = useSelector<RootState, any>((state) => state.words3000);
  const myWords = useSelector<RootState, any>((state) => state.words);

  const studyWords =
    allWords.words?.filter(
      (item: any) =>
        !item.word?.indexOf(props.result.e + "+") ||
        !item.word?.indexOf(props.result.e + "/") ||
        item.word === props.result.e
    ) || [];

  // console.log(studyWords);

  const addToList = (item: any) => {
    const newDate = Math.floor(Date.now() / 1000);
    const startDate = Math.floor(newDate / 86400);

    const newSentence = {
      s: props.activeSentence.sOrig || "",
      fileId: props.activeSentence.fileId || "",
      w: props.activeSentence.w || [-1, -1],
    };

    const newValue = {
      done: newDate,
      word: item.e,
      id: clean(item.e.trim() + "-" + item.d.trim()),
      d: item.d,
      s: startDate,
      e: item.e,
      a: newSentence,
      ef: 2.5,
      r: 0,
      i: 0,
    };

    updateWords(clean(item.e.trim() + "-" + item.d.trim()), newValue);

    // if (newSentence?.fileId === store.getState().reader.currentText.id) {
    //   store.dispatch(
    //     setHlOne([newSentence?.w[0], newSentence?.w[1], "hlactivezactive"])
    //   );
    // }
  };

  // const addToListOfficial = (item: any) => {
  //   const newDate = Math.floor(Date.now() / 1000);
  //   const startDate = Math.floor(newDate / 86400);

  //   const wordEng = item.word.split("+")[0].split("/")[0];

  //   const newSentence = {
  //     s: props.activeSentence.sOrig || "",
  //     fileId: props.activeSentence.fileId || "",
  //     w: props.activeSentence.w || [-1, -1],
  //   };

  //   const newValue = {
  //     o: true,
  //     done: newDate,
  //     word: wordEng,
  //     id: item.id,
  //     d: item.d,
  //     s: startDate,
  //     e: wordEng,
  //     a: newSentence,
  //   };

  //   store.dispatch(setHlOne(props.activeSentence.w));

  //   updateWords(item.id, newValue);

  //   store.dispatch(
  //     setWordsOne({
  //       id: item.id,
  //       value: newValue,
  //     })
  //   );
  //   store.dispatch(
  //     setWordsOne({
  //       id: "words",
  //       value: sortWords(allWords.words),
  //     })
  //   );

  //   if (newSentence?.fileId === store.getState().reader.currentText.id) {
  //     store.dispatch(
  //       setHlOne([newSentence?.w[0], newSentence?.w[1], "hlactivezactive"])
  //     );
  //   }
  // };

  const getIcon = (item: any) => {
    switch (
      (
        myWords[clean(item.e.trim() + "-" + item.d.trim())] ||
        myWords[item.e.trim() + "-" + item.d.trim()]
      )?.done
    ) {
      case undefined:
        return <AddCircleOutline className={classes.black} />;
      case 50000 * 86400:
        return <CheckCircleIcon className={classes.green} />;
      default:
        return <HighlightOffIcon className={classes.brown} />;
    }
  };

  const getIconAdd = (item: any) => {
    switch (
      (
        myWords[clean(item.e.trim() + "-" + item.d.trim())] ||
        myWords[item.e.trim() + "-" + item.d.trim()]
      )?.done
    ) {
      case undefined:
        return false;
      default:
        return true;
    }
  };

  const getIconOfficial = (item: any) => {
    switch (myWords[item.id]?.done) {
      case undefined:
        return <AddCircleOutline />;
      default:
        return <HighlightOffIcon className={classes.brown} />;
    }
  };

  const getIconOfficialAdd = (item: any) => {
    switch (myWords[item.id]?.done) {
      case undefined:
        return false;
      default:
        return true;
    }
  };

  return (
    <React.Fragment>
      <div className={classes.entry}>{props.result.e}</div>
      {studyWords.map((item: any) => (
        <div className={classes.defStudy} key={item.id}>
          <div className={classes.defWord}>{item.d}</div>
          <div className={classes.action}>
            <IconButton
              disabled={getIconOfficialAdd(item)}
              onClick={() => props.addToListOfficial(item)}
            >
              {getIconOfficial(item)}
            </IconButton>
          </div>
        </div>
      ))}

      {props.result?.words?.map((item: any, index: number) => (
        <div className={classes.def} key={index}>
          <div className={classes.defWord}>
            {item.e !== props.result.e ? (
              <span style={{ color: "grey" }}>
                {htmlIt(item.e)}
                <br />
              </span>
            ) : (
              ""
            )}
            {htmlIt(item.d)}
          </div>
          <div className={classes.action}>
            <IconButton
              onClick={() => addToList(item)}
              disabled={getIconAdd(item)}
            >
              {getIcon(item)}
            </IconButton>
          </div>
        </div>
      ))}
      {props.activeSentence?.sOrig ? <div className={classes.spacer} /> : ""}
    </React.Fragment>
  );
});

export default WordDef;
