import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SnackType = {
  open: boolean;
  text: string;
  type: string;
};

const initialState: SnackType = {
  open: false,
  text: "",
  type: "success",
};

const snackSlice = createSlice({
  initialState,
  name: "snack",
  reducers: {
    setSnack: (state: SnackType, action: PayloadAction<SnackType>) => {
      return action.payload;
    },
    toggleSnack: (state: SnackType, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { setSnack, toggleSnack } = snackSlice.actions;

export default snackSlice.reducer;
