import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TransView {
  english: string;
  foreign: string;
  id: string;
  tag: string;
}

const initialState: TransView[] = [];

const translationSlice = createSlice({
  initialState,
  name: "translation",
  reducers: {
    setTranslation: (state: any, action: PayloadAction<TransView[]>) => {
      return action.payload;
    }
  }
});

export const { setTranslation } = translationSlice.actions;

export default translationSlice.reducer;
