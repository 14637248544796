/*

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components

import styleRaw from "utils/Dialogs/modalStyle";
import { useSelector } from "react-redux";
import store from "Redux/store";
import { setConfirmOpen } from "Redux/reducers/reducer.confirmOpen";
import { RootState } from "Redux/reducers/_index";
import { tx } from "utils/globalize";
import { TransitionProps } from "@material-ui/core/transitions";
import { Button } from "@material-ui/core";
import { updateApp } from "app/App.upgrade";

let style = styleRaw as any;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

export default function Confirmation(props: any) {
  const classes = useStyles();
  const confirmOpen = useSelector<RootState, boolean>(
    (state) => state.confirmOpen
  );

  const closeConfirm = () => {
    store.dispatch(setConfirmOpen(false));
  };

  return (
    <Dialog
      style={{ zIndex: 1900 }}
      classes={{
        root: classes.modalRoot + " " + classes.specialRoot,
        paper: classes.modal,
      }}
      PaperProps={{ square: true }}
      open={confirmOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeConfirm}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={() => store.dispatch(setConfirmOpen(false))}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>
          {tx("oxlsllwlwlwl", "Update available")}
        </h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <p>
          {tx(
            "wlwerklasd9",
            "An update is available to the program. It might be important. Should I update now?"
          )}
        </p>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={closeConfirm} color="secondary">
          {tx("qwerlasdkfasdf", "Later")}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            store.dispatch(setConfirmOpen(false));
            setTimeout(() => updateApp());
          }}
        >
          {tx("qwewerwrlasdkfasdf", "Update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
