var WebFont = require("webfontloader");

export const setCustomStyles = (custom: any) => {
  let root = document.documentElement;

  root.style.setProperty(
    "--toolbar-background",
    custom.colors.primaryBackground
  );
  root.style.setProperty("--toolbar-color", custom.colors.primaryText);
  root.style.setProperty(
    "--player-background",
    custom.colors.secondaryBackground
  );
  root.style.setProperty("--player-color", custom.colors.secondaryText);
  root.style.setProperty("--dark-color", custom.colors.darkText);
  root.style.setProperty("--light-color", custom.colors.lightText);
  root.style.setProperty("--font-normal", custom.colors.font);
  root.style.setProperty("--font-bold", custom.colors.font);
  root.style.setProperty("--font-size", custom.colors.fontSize);
  root.style.setProperty("--list-main-color", custom.colors.darkText);
  root.style.setProperty("--list-secondary-color", custom.colors.lightText);
  root.style.setProperty("--deep-background", custom.colors.deepBackground);
  root.style.setProperty("--paper-color", custom.colors.paperColor || "white");
  root.style.setProperty("--mail-text", custom.colors.mailText || "black");

  WebFont.load({
    google: {
      families: [`${custom.colors.font}:ital,wght@0,400;0,500;0,700`],
    },
  });
};
