import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const checkoutItemSlice = createSlice({
  initialState,
  name: "checkoutItem",
  reducers: {
    setCheckoutItem: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setCheckoutItemCoupon: (state: any, action: PayloadAction<any>) => {
      state.coupon = action.payload;
    },
  },
});

export const {
  setCheckoutItem,
  setCheckoutItemCoupon,
} = checkoutItemSlice.actions;

export default checkoutItemSlice.reducer;
