import React from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpPron() {
  const classes = useStyles();

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>
          {tx("azarm10", `Pronunciation Practice`)}
        </div>
        <div className={classes.helpBold}>
          {tx("azarm11", `What's this whole thing?`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm12",
            `This function is not available on iPhones and iPads.`
          )}{" "}
          {tx(
            "azarm13",
            `You also need a microphone connected to your computer if you do it on a computer.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm14",
            `When you first click on this function, the app will ask you whether you allow the use of your microphone.`
          )}{" "}
          {tx(
            "azarm15",
            `If you want to use this function you have to allow this.`
          )}
        </div>
        <div className={classes.helpBold}>
          {tx("azawerm15zxsd", `The parts`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/pron1.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azwearn62wwewe",
            `a) The English sentence you need to pronounce.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx("aazarn63we", `b) The translation in [yourlang].`)}
        </div>
        <div className={classes.helpList}>
          {tx(
            "aazarn64we",
            `c) Click here to have the app say the sentence in English and then record your version of the same thing.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx("aazarn65we", `d) Jump to the next sentence.`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm16",
            `If you click on the first button the app reads aloud the sentence shown in English.`
          )}{" "}
          {tx(
            "azarm17sd",
            `Meanwhile you will see a little label saying Playing.`
          )}{" "}
          {tx(
            "azarm18",
            `When the sentence is read, the label changes to Recording.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm19",
            `At this point read the sentence aloud following the English pronunciation you just heard.`
          )}{" "}
          {tx("azarm20", `When you are done stay silent.`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm21",
            `The app will now play back first the original English sentence and right after your version of it.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm22",
            `Like this you can check for yourself how much you approach the original English pronuncation.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm23",
            `If you want to listen to the English sentence and your version again, click on the Play (second) button.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/pron2.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm24",
            `If you want to repeat the recording, click on the first button again and repeat the above steps.`
          )}{" "}
          {tx(
            "azarm25",
            `Do it until you feel your rendition approaches the original English pronunciation.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx("azarm25", `If you want a new sentence click on the + button.`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/pron3.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm26",
            `If you want to do this practice successfully try to do it at a relatively silent place, because the microphone might be disturbed by outside noises.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm27",
            `If you wait too much before starting to read your sentence you will get an error message.`
          )}{" "}
          {tx(
            "azarm28",
            `Just press the first button again and repeat the whole process.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarm28cs",
            `The app detects when you start to speak and when you finish speaking.`
          )}{" "}
          {tx(
            "azarm29",
            `If you hold a pause in reading the sentence back the recording will
          stop there.`
          )}{" "}
          {tx(
            "azarm30",
            `So try to repeat the sentence without pausing too long.`
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
