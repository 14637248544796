import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    line: { display: "flex", width: "100%", paddingBottom: "18px" },
    name: { flex: "0 0 100px", fontWeight: 700, textAlign: "left" },
    link: {
      flex: "1 1 auto",
      textAlign: "right",
      color: "#000055",
      textDecoration: "underline",
    },
  })
);

const ContactLink = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.res ? (
        <div className={classes.line}>
          <div className={classes.name}>{props.name}</div>
          <div
            className={classes.link}
            onClick={() => {
              window.location.href = props.href;
              return;
            }}
          >
            {props.res}
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default ContactLink;
