import React from 'react';

import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extraDic: {
      color: "white",
      bottom: "0px",
      left: "50%",
      position: "absolute",
      zIndex: 8000,
      transform: "translateX(-50%)",
      width: "fit-content",
      fontSize: "12px",
      display: "none",
    },
    white: { color: "white", fontSize: "18px" },
  })
);

const LevelSelectButtons = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.status === "loader" ? (
        <div className={classes.extraDic}>
          <IconButton className={classes.white} onClick={() => {}}>
            A1
          </IconButton>
          <IconButton className={classes.white} onClick={() => {}}>
            A2
          </IconButton>
          <IconButton className={classes.white} onClick={() => {}}>
            B1
          </IconButton>
          <IconButton className={classes.white} onClick={() => {}}>
            B2
          </IconButton>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default LevelSelectButtons;
