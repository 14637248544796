import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles, Input } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {},
  })
);

const Email = (props: any) => {
  const classes = useStyles();

  const { email, setButtonForward } = props;

  useEffect(() => {
    if (verifyEmail(email)) {
      setButtonForward(true);
    } else {
      setButtonForward(false);
    }
  }, [setButtonForward, email]);

  const verifyEmail = (value: any) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const changeEmail = (e: any) => {
    const email = e.target.value;

    props.setEmail(email);
  };

  const checkForEnter = (event: any) => {
    if (event.key === "Enter") {
      if (verifyEmail(email)) {
        props.nextStep();
      }
    }
  };

  return (
    <React.Fragment>
      <div className={classes.input}>
        <Input
          autoFocus
          fullWidth
          placeholder="email@email.com"
          inputProps={{ "aria-label": "email", type: "email" }}
          onChange={changeEmail}
          value={props.email}
          onKeyDown={checkForEnter}
        />
      </div>
    </React.Fragment>
  );
};

export default Email;
