import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { setCurrentWord } from "Redux/reducers/reducer.currentWord";
import store from "Redux/store";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { firestore } from "../../firebaseUtil/firebaseUtil";
import { RootState } from "../../Redux/reducers/_index";
import { KeyWordRow } from "./keyWords.row";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    doneword: {
      background: "#ffffff",
      marginBottom: "8px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      padding: "5px",
      paddingLeft: "15px",
      alignItems: "center",
      width: "calc(100% - 20px)",
      display: "flex",
      flexDirection: "row",
      "&.active": { background: "var(--active-background)" },
      "&.done": { background: "var(--done-background)" },
      "&.waiting": { background: "var(--pending-background)" },
      "@media (min-width: 478px)": {
        width: "calc(100% - 45px)",
      },
    },
    wordend: { flex: "0 0 80px", textAlign: "right" },
    moregreen: {
      padding: "5px",
      marginLeft: "8px",
      color: "green",
    },
    more: {
      padding: "5px",
      marginLeft: "3px",
    },
    wordpart: { flex: "1 1 auto", maxWidth: "calc(100% - 79px)" },
    engword: {
      flex: "1 1 auto",
      color: "var(--list-main-color)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    engwordblue: {
      flex: "1 1 auto",
      color: "blue",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
    hunword: {
      flex: "1 1 auto",
      color: "var(--list-secondary-color)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

const KeyWords = React.memo((props: any) => {
  const [myWords, setMyWords] = useState<any>({ words: [] });

  const words3000 = useSelector<RootState, any>((state) => state.words3000);
  const words = useSelector<RootState, any>((state) => state.words);
  const wordLevel = useSelector<RootState, any>(
    (state) => state.settings.wordLevel
  );
  const language = useSelector<RootState, any>(
    (state) => state.currentUser?.language
  );

  const {
    getNext,
    addToListOfficial,
    listRef,
    doneRef,
    textIdRef,
    textTypeRef,
    showDone,
    showUndone,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (!words3000?.words) {
      return;
    }

    setMyWords({
      words: words3000.words.filter((item: any) => {
        const done = words[item?.id]?.done;

        if (!showDone && done === 4320000000) {
          return false;
        }
        if (!showUndone && done && done < 4320000000) {
          return false;
        }
        return !item.id.indexOf(wordLevel);
      }),
    });
  }, [wordLevel, words3000, showDone, showUndone, words]);

  const showWord = (w: any) => {
    firestore
      .doc(`/vocab${language.toUpperCase()}/` + w.id)
      .get()
      .then((res) => {
        store.dispatch(setCurrentWord(res.data()));

        setTimeout(() => {
          props.setDefOpen(true);
          // setTimeout(() => contRef.current.update(), 0);
        }, 0);
      });
  };

  return (
    <React.Fragment>
      <AutoSizer>
        {({ height, width }: any) => (
          <List
            ref={listRef}
            className="List"
            height={height}
            itemCount={myWords?.words.length || 0}
            itemSize={56}
            width={width}
            overscanCount={10}
            itemData={{
              words,
              words3000,
              getNext: getNext,
              addToListOfficial,
              showWord,
              classes,
              doneRef,
              textIdRef,
              textTypeRef,
              myWords,
            }}
          >
            {KeyWordRow as any}
          </List>
        )}
      </AutoSizer>
    </React.Fragment>
  );
});

export default KeyWords;
