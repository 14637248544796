import TermsDialog from "footer/footer.terms";
import Legal from "legal/legal";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { RootState } from "../Redux/reducers/_index";
import { isIOS } from "../settings/settings";
import { useLocation } from "react-router";
import IosPayment from "app/App.iospayment";
import { Device } from "@capacitor/device";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      width: "100%",
      paddingTop: "20px",
      borderTop: "1px solid lightgrey",
      fontFamily: "var(--font-normal)",
      fontSize: "16px",
      lineHeight: "145%",
      color: "grey",
      bottom: "15px",
      paddingBottom: "20px",
    },
    bullets: { width: "100%", textAlign: "center" },
    copyright: {
      marginTop: "5px",
      textAlign: "center",
      fontFamily: "var(--font-normal)",
    },
    bullet: {
      cursor: "pointer",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      display: "inline-block",
    },
    barion: { textAlign: "center", marginTop: "8px" },
    constrains: { maxWidth: "550px", display: "inline-block" },
  })
);

// let platform = "web";

// Device.getInfo().then((info) => (platform = info.platform));

const Footer = (props: any) => {
  const classes = useStyles();
  const [termsOpen, setTermsOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [termsContent, setTermsContent] = useState<any>(
    <React.Fragment></React.Fragment>
  );

  const location = useLocation();

  const { tx } = props;

  // eslint-disable-next-line
  useSelector<RootState, any>((state) => state.appTransParts);

  const platform = useSelector<RootState, any>((state) => state.platform);
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const tempLanguage = useSelector<RootState, any>(
    (state) => state.tempLanguage
  );

  const szv =
    document.location.hostname.includes("szv.gyakorlatias-angol.hu") ||
    currentUser?.ccode === "06ab17c7-b847-4c01-aa58-430cb94e0ac2";

  const closeDialog = () => {
    setTermsOpen(false);
  };

  const openPrivacy = useCallback(() => {
    if (isIOS() && szv) {
      window.open(
        "https://gyakorlatias-angol.hu/adatkezelesi-tajekoztato-2/",
        "_blank"
      );
    } else {
      setTermsContent(<Legal language={tempLanguage} doc="privacy" />);
      setTermsOpen(true);
    }
  }, [szv, tempLanguage]);

  const openTerms = useCallback(() => {
    if (isIOS() && szv) {
      window.open(
        "https://gyakorlatias-angol.hu/altalanos-szerzodesi-feltetelek/",
        "_blank"
      );
    } else {
      setTermsContent(<Legal language={tempLanguage} doc="terms" />);
      setTermsOpen(true);
    }
  }, [szv, tempLanguage]);

  useEffect(() => {
    if (location.search.includes("privacy")) {
      openPrivacy();
    }

    if (location.search.includes("terms")) {
      openTerms();
    }
  }, [location.search, openPrivacy, openTerms]);

  const openCookies = () => {
    setTermsContent(<Legal language={tempLanguage} doc="cookies" />);
    setTermsOpen(true);
  };

  const openImpress = () => {
    setTermsContent(<Legal language={tempLanguage} doc="impress" />);
    setTermsOpen(true);
  };

  // const openSubscriptionTerms = () => {
  //   setTermsContent(<Legal language={props.language} doc="subscription" />);
  //   setTermsOpen(true);
  // };

  const openMoreInfo = () => {
    window.open(
      szv
        ? "https://gyakorlatias-angol.hu/"
        : "https://wizzwords.net/?lang=" + tempLanguage,
      "_blank"
    );
  };

  const openPay = () => {
    setPaymentOpen(true);
  };

  return (
    <React.Fragment>
      {platform === "ios" ? (
        <IosPayment open={paymentOpen} setOpen={setPaymentOpen} />
      ) : null}
      <div className={classes.footer}>
        <div className={classes.bullets}>
          <div className={classes.constrains}>
            {platform === "ios" ? (
              <React.Fragment>
                <div className={classes.bullet} onClick={openPay}>
                  {tempLanguage !== "it"
                    ? tx("japocs2csd", "Buy licence")
                    : "Acquistare licenza"}
                </div>{" "}
                •{" "}
              </React.Fragment>
            ) : null}
            <div className={classes.bullet} onClick={openPrivacy}>
              {tx("japo2", "Privacy Policy")}
            </div>{" "}
            •{" "}
            <div className={classes.bullet} onClick={openTerms}>
              {tx("japo4", "Terms and Conditions")}
            </div>{" "}
            •{" "}
            {szv || platform !== "web" ? (
              ""
            ) : (
              <React.Fragment>
                <div className={classes.bullet} onClick={openCookies}>
                  {tx("japo4acookie", "Cookies")}
                </div>{" "}
                •{" "}
              </React.Fragment>
            )}
            {szv ? (
              ""
            ) : (
              <React.Fragment>
                <div className={classes.bullet} onClick={openImpress}>
                  {tx("japo4acookies", "About us")}
                </div>{" "}
                •{" "}
              </React.Fragment>
            )}
            <div className={classes.bullet} onClick={openMoreInfo}>
              {tx("japo5", "More info")}
            </div>
          </div>
        </div>
        <div className={classes.copyright}>
          © 2022{" "}
          {szv ? "Németh Ildikó." : "NetEnglish Solutions OÜ Hungarian PE."}
          <br />
          {tx("japo6", "All Rights Reserved.")}
        </div>
        <TermsDialog
          open={termsOpen}
          closeDialog={closeDialog}
          help={termsContent}
          tx={tx}
        />
      </div>
    </React.Fragment>
  );
};

export default Footer;
