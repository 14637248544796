import React from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpWrite() {
  const classes = useStyles();

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>
          {tx("wazarm10", `Writing Practice`)}
        </div>
        <div className={classes.helpBold}>
          {tx("wazarm11", `What's this whole thing?`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "wazarm12",
            `You can practice writing here a bit and the app will help check your spelling.`
          )}{" "}
        </div>
        <div className={classes.helpText}>
          {tx(
            "wazarm132",
            `The idea is simple. The app reads you a sentence and you have to write it down correctly.`
          )}{" "}
        </div>
        <div className={classes.helpText}>
          {tx(
            "wwazarm132kk",
            `Then you click on the little check button and the computer will show you any errors you made.`
          )}{" "}
        </div>
        <div className={classes.helpBold}>
          {tx("wazarm15", `The Main Parts`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/writer.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarn62we",
            `a) This is where you type in the sentence as you hear it.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx("azarn63we", `b) The translation in [yourlang].`)}
        </div>
        <div className={classes.helpList}>
          {tx("azarn64we", `c) Number of sentences practiced today.`)}
        </div>
        <div className={classes.helpList}>
          {tx("azarn65we", `d) Plays the sentence for you.`)}
        </div>
        <div className={classes.helpList}>
          {tx("azarn65weaa", `e) Move to the next sentence.`)}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarn65weaa111",
            `f) Gets your writing checked and shows errors.`
          )}
        </div>
        <div className={classes.helpBold}>
          {tx("azarn65weaasd", `An example`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/writer2.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx("azarn65weaassxxxd", `And after pressing the check button:`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/writer3.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "weazarm1zsds6",
            `As you can see the app marks missing and added letters.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "wseazarm1zsds6",
            `You can now click on the right button which now shows an X to close the corrections of the sentence.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "wsdsseazarm1zsds6",
            `If you are fine with your sentence you can click on the + button and take the next.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx("wxssdsseazarm1zsds6", `It is that simple.`)}
        </div>{" "}
      </div>
    </React.Fragment>
  );
}
