import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DialogFullType {
  open: boolean;
  content: any;
  action: any;
  cancel: any;
  header?: string;
  current?: number;
}

const initialState: DialogFullType = {
  open: false,
  content: null,
  action: () => {},
  cancel: () => {},
};

const dialogFullSlice = createSlice({
  initialState,
  name: "dialogFull",
  reducers: {
    setDialogFull: (
      state: DialogFullType,
      action: PayloadAction<DialogFullType>
    ) => {
      return action.payload;
    },
    setDialogFullOpen: (
      state: DialogFullType,
      action: PayloadAction<boolean>
    ) => {
      state.open = action.payload;
    },
  },
});

export const { setDialogFull, setDialogFullOpen } = dialogFullSlice.actions;

export default dialogFullSlice.reducer;
