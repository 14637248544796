import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { StatsType } from "Redux/reducers/reducer.stats";

import { IconButton } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { CheckTwoTone, LoopTwoTone, SchoolTwoTone } from "@material-ui/icons";

const ToolbarOwnwords = (props: any) => {
  const {
    showDone,
    showUndone,
    setShowDone,
    setShowUndone,
    classes,
    getNext,
    doneRef,
    textIdRef,
    textTypeRef,
  } = props;

  const stats = useSelector<RootState, StatsType>((state) => state.stats);
  const status = useSelector<RootState, string>((state) => state.status);

  return (
    <React.Fragment>
      {status === "ownwords" ? (
        <React.Fragment>
          <IconButton
            className={classes.icon1 + (showUndone ? " active" : "")}
            onClick={() => {
              setShowUndone(!showUndone);
            }}
          >
            <Badge badgeContent={stats.repeat} color="primary" max={5000}>
              <LoopTwoTone />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.icon2 + (showDone ? " active" : "")}
            onClick={() => {
              setShowDone(!showDone);
            }}
          >
            <Badge badgeContent={stats.done} color="primary" max={5000}>
              <CheckTwoTone />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.icon3}
            onClick={() => {
              doneRef.current = [];
              textIdRef.current = "";
              textTypeRef.current = "active";
              getNext(-1);
            }}
          >
            <SchoolTwoTone />
          </IconButton>
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default ToolbarOwnwords;
