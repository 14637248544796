import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// const initialState: any = new Document();

const initialState: any = null;

const lessonDataSlice = createSlice({
  initialState,
  name: "lessonData",
  reducers: {
    setLessonData: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setLessonData } = lessonDataSlice.actions;

export default lessonDataSlice.reducer;
