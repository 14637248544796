import React from "react";

// @material-ui/core components
import { createStyles, makeStyles, Switch, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    horizontal: {
      display: "flex",
      flexDirection: "row-reverse",
      width: "100%",
      alignItems: "baseline",
      paddingBottom: "20px",
    },
    track: { backgroundColor: "var(--player-background) !important" },
    checked: { color: "var(--toolbar-background) !important" },
    divleft: { flex: "0 0 auto" },
    divright: { flex: "1 1 auto" },
    bigtitle: {
      paddingBottom: "10px",
      marginTop: "7px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    mainText: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      lineHeight: "135%",
      color: "black",
    },
    optionText: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      color: "#5e5e5e",
    },

    line: {
      height: "0px",
      width: "100%",
      marginLeft: "-20px",
      marginRight: "-20px",
      borderBottom: "1px solid var(--line-grey)",
      marginBottom: "18px",
    },
  })
);

const SettingsSwitch = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.horizontal}>
        <div className={classes.divleft}>
          <Switch
            classes={{ track: classes.track, checked: classes.checked }}
            checked={props.status}
            onChange={props.change}
          />
        </div>
        <div className={classes.divright}>
          <div className={classes.mainText}>{props.mainText}</div>
          <div className={classes.optionText}>
            {props.status ? props.onOption : props.offOption}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettingsSwitch;
