//student
//tx("asdfasdf1upp","===Updating")
import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";

const AppUpdating = (props: any) => {
  const updating = useSelector<RootState, any>((state) => state.updating);

  return (
    <React.Fragment>
      <Backdrop open={updating} id="morek" style={{ zIndex: 1 }}>
        <CircularProgress color="inherit" />
        <div>...</div>
      </Backdrop>
    </React.Fragment>
  );
};

export default AppUpdating;
