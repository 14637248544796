import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const bookOpenSlice = createSlice({
  initialState: { open: false, view: 1 },
  name: "bookOpen",
  reducers: {
    setBookOpen: (state: any, action: PayloadAction<any>) => {
      state.open = action.payload;
    },
    setBookView: (state: any, action: PayloadAction<any>) => {
      state.view = action.payload;
    },
    setBook: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setBookOpen, setBookView, setBook } = bookOpenSlice.actions;

export default bookOpenSlice.reducer;
