import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { tx } from "../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {},
    text: { paddingBottom: "15px" },
  })
);

const SignupIntro = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.input}>
        <div className={classes.text}>
          {tx(
            "ozioo98",
            "You will get a permanent licence to use NetEnglish if you sign up here as you arrived here with a valid coupon."
          )}
        </div>
        <div className={classes.text}>
          {tx("ozioo981", "Click on the button to start the signup process.")}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignupIntro;
