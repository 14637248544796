import Account from "account/account";
import Recorder from "practiceTypes/audioRecorder/recorder";
import Writer from "practiceTypes/writer/writer";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { RootState } from "Redux/reducers/_index";
import { SnackType, toggleSnack } from "Redux/reducers/reducer.snack";
import store from "Redux/store";

import { createStyles, makeStyles, Snackbar, Theme } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Dashboard from "../dashboard/dashboard";
import FirstSteps from "../firstSteps/firstSteps";
import Help from "../help/help";

import Settings from "../settings/settings";
import Words from "../words/words";
// import MenuFAB from "./MenuFAB";
import NextButton from "./NextButton";
import PrimaryAppBar from "./PrimaryAppBar";
import Contact from "../contact/contact";
import FABThis from "./ui.app.fab";
import { isIOS } from "../settings/settings";
import FirstAgreement from "app/App.agreement";
import RecorderApp from "practiceTypes/audioRecorder/recorderApp";
import LegalStandAlone from "legal/legal.standalone";

const Report = React.lazy(() => import("reports/reports"));

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appPaper: {
      position: "relative",
      padding: "20px",
      background: "var(--paper-color)",
      margin: "0px auto 0px auto",
      paddingBottom: "30px",
      maxWidth: "820px",
      width: "100%",
      height: "100%",
      userSelect: "none",
      overflowY: "auto",
      overflowX: "hidden",
      "@media (min-width: 821px)": {
        borderRadius: "3px",
        marginTop: "20px",
        marginBottom: "20px",
        minHeight: "calc(100% - 50px)",
        padding: "35px",
      },
      transform: "translate3d(0, 0, 0)",
      "&.words": { padding: "0px", height: "100%" },
    },
    lowerPart: {
      position: "relative",
      width: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
      height: "calc(100% - 50px)",
      "@media (min-width: 821px)": {
        height: "calc(100% - 90px)",
      },
    },
    alert: {
      border: "1px solid grey",
      marginTop: "55px",
      zIndex: 3000,
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
    },
    fab: {
      background: "var(--toolbar-background)",
      transition: "all 0.3s",
      zIndex: 500,
      position: "fixed",
      bottom: 50 + theme.spacing(2),
      right: theme.spacing(2),

      "@media (min-width: 821px)": {
        bottom: 50 + theme.spacing(2),
        right: "calc(50vw - 406px + " + theme.spacing(2) + "px)",
      },
    },
    avatar: { width: "46px", height: "46px" },
  })
);

const AppUi = () => {
  const classes = useStyles();

  const snack = useSelector<RootState, SnackType>((state) => state.snack);
  const platform = useSelector<RootState, string>((state) => state.platform);

  let { path } = useRouteMatch();

  const getApp = () => {
    if (platform === "ios" || platform === "android") {
      return <RecorderApp />;
    }

    if (platform === "web" && !isIOS()) {
      return <Recorder />;
    }
    return null;
  };

  return (
    <React.Fragment>
      <PrimaryAppBar />
      <NextButton />
      {/* <MenuFAB /> */}
      <FABThis />
      <div className={classes.lowerPart} id="ipaper">
        <div
          className={
            classes.appPaper +
            (document.location.pathname.includes("/words") ? " words" : "")
          }
        >
          <div
            id="mainFrame"
            className={
              "mfhidden" +
              (!document.location.pathname.includes("/words") ? " piwords" : "")
            }
          >
            <Switch>
              <Route path={`${path}/report`}>
                <Suspense fallback={<div />}>
                  <Report />
                </Suspense>
              </Route>

              <Route path={`${path}/dashboard`}>
                <Dashboard />
              </Route>
              <Route path={`${path}/contact`}>
                <Contact />
              </Route>
              <Route path={`${path}/firststeps`}>
                <FirstSteps />
              </Route>
              <Route path={`${path}/agreement`}>
                <FirstAgreement />
              </Route>
              <Route path={`${path}/account`}>
                <Account />
              </Route>
              <Route path={`${path}/words`}>
                <Words />
              </Route>
              <Route path={`${path}/pron`}>{getApp()}</Route>

              <Route path={`${path}/writer`}>
                <Writer />
              </Route>

              <Route path={`${path}/settings`}>
                <Settings />
              </Route>
              <Route path={`${path}/settings`}>
                <Settings />
              </Route>
              <Route path={`${path}/help`}>
                <Help />
              </Route>

              <Route path="/">
                <Redirect to="/words" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
      <Snackbar
        open={snack.open}
        autoHideDuration={4000}
        onClose={() => store.dispatch(toggleSnack(false))}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert
          severity="success"
          variant="filled"
          classes={{ root: classes.alert }}
        >
          {snack.text}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AppUi;
