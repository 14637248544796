import store from "Redux/store";
import { decodedStr } from "./utils";
import { prepareFormat } from "./readxml";

export const tx = (
  uuid: string,
  orig: string = "",
  avar: string = "",
  bvar: string = ""
) => {
  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  orig = orig.replace(/xxx/g, avar);
  orig = orig.replace(/yyy/g, bvar);
  return decodedStr(orig);
};

export const th = (
  uuid: string,
  orig: string = "",
  avar: string = "",
  bvar: string = ""
) => {
  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  orig = orig.replace(/xxx/g, avar);
  orig = orig.replace(/yyy/g, bvar);
  return prepareFormat(decodedStr(orig));
};
