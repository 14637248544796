import React from "react";
import { useSelector } from "react-redux";

import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { Forward5 } from "@material-ui/icons";
import Pause from "@material-ui/icons/Pause";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Replay5Icon from "@material-ui/icons/Replay5";

import { RootState } from "../../Redux/reducers/_index";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: "var(--mail-text) !important",
    },
  })
);

const Mp3Controls = (props: any) => {
  // const classes = useStyles();

  const wordbar = useSelector<RootState, any>((state) => state.wordbar);
  const classes = useStyles();

  const pausePlay = () => {
    if (wordbar.player?.pauseVideo) {
      wordbar.player.pauseVideo();
    }
    if (wordbar.player?.pause) {
      wordbar.player.pause();
    }
  };

  const startPlay = () => {
    if (wordbar.player?.pauseVideo) {
      wordbar.player.playVideo();
    }
    if (wordbar.player?.pause) {
      wordbar.player.play();
    }
  };

  const back5 = () => {
    if (wordbar.player?.pauseVideo) {
      wordbar.player.seekTo(wordbar.player.getCurrentTime() - 5);
      wordbar.player.playVideo();
    } else {
      wordbar.player.currentTime = wordbar.player.currentTime - 5;

      wordbar.player.play();
    }
  };

  const forward5 = () => {
    if (wordbar.player?.pauseVideo) {
      wordbar.player.seekTo(wordbar.player.getCurrentTime() + 5);
      wordbar.player.playVideo();
    } else {
      wordbar.player.currentTime = wordbar.player.currentTime + 5;

      wordbar.player.play();
    }
  };

  return (
    <React.Fragment>
      <IconButton
        className={classes.icon}
        disabled={props.disabled}
        onClick={back5}
      >
        <Replay5Icon />
      </IconButton>
      {!props.video ? (
        wordbar.playing === 1 ? (
          <IconButton onClick={pausePlay}>
            <Pause />
          </IconButton>
        ) : (
          <IconButton
            className={classes.icon}
            disabled={props.disabled}
            onClick={startPlay}
          >
            <PlayCircleOutlineIcon />
          </IconButton>
        )
      ) : (
        ""
      )}
      <IconButton
        className={classes.icon}
        disabled={props.disabled}
        onClick={forward5}
      >
        <Forward5 />
      </IconButton>
    </React.Fragment>
  );
};

export default Mp3Controls;
