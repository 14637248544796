import { auth, signOut } from "firebaseUtil/firebaseUtil";
// core components
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "Redux/reducers/_index";
import { tx } from "utils/globalize";
import CustomizedSnackbar from "utils/SnackBar";
import { phaseBodyOut } from "utils/utils";

// @material-ui/icons
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import { makeStyles, Theme } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { createStyles } from "@material-ui/styles";
import { phaseIn, phaseBodyIn, getTitle } from "../../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "20px",
      color: "black",
      fontSize: "20px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      maxHeight: "47px",
    },
    linkLike: { textDecoration: "underline", cursor: "pointer" },
    cardExtra: { background: "white" },
    logoWrapper: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "center",
      flex: "0.5 1 auto",
      transition: "all 1s",
      minHeight: "var(--logoHeight)",
    },
    placeholder: {
      flex: "0.5 1 5px",
    },
    logo: {
      marginBottom: "5px",
    },
    firm: {
      paddingBottom: "10px",
      marginTop: "0px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    textCenter: {
      textAlign: "center",
    },
    forgotPassword: {
      fontSize: "90%",
      paddingTop: "16px",
      color: "var(--toolbar-background)",
      "& a": { color: "var(--toolbar-background)" },
    },
    useful: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxHeight: "var(--vh)",
      transition: "all 1s",
    },
    copyright: {
      justifySelf: "end",
      flex: "3 1 auto",
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "var(--font-normal)",
      color: "#888888",
      fontSize: "15px",
      textAlign: "center",
      margin: "0 auto",
      paddingTop: "15px",
      lineHeight: "130%",
    },
    info: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      marginBottom: "15px",
      lineHeight: "135%",
    },
    dialogactions: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
      transition: "all 1s",
    },
    dialogstart: {
      flex: "0 0 60%",
    },
    dialogend: {
      flex: "0 0 40%",
    },
    fields: { flex: "0 0 auto", transition: "all 1s" },

    buttonStart: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      padding: "8px 0px 8px 0px",
      width: "100%",
      marginTop: "0px",
      minHeight: "40px",
      borderRadius: "3px",
      "@media (hover: hover)": {
        "&:hover": {
          background: "var(--toolbar-background-dark)",
          boxShadow: "none",
        },
      },
      "&:hover": {
        background: "var(--toolbar-background-dark)",
        boxShadow: "none",
      },
      "&:disabled": {
        color: "var(--theme-disabled)",
        border: "1px solid var(--theme-disabled)",
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:active": {
        background: "var(--toolbar-background-dark)",
        boxShadow: "none",
      },
      "&:focus": {
        background: "var(--toolbar-background-dark)",
        boxShadow: "none",
      },
      "&:focus:active": {
        background: "var(--toolbar-background-dark)",
        boxShadow: "none",
      },
    },
    buttonCancel: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      color: "var(--secondary-color)",
      padding: "0px 0px 14px 0px",
      marginTop: "0px",
      marginRight: "30px",
      minHeight: "40px",

      "@media (hover: hover)": {
        "&:hover": {
          color: "var(--toolbar-background-dark)",
          boxShadow: "none",
        },
      },
      "&:disabled": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "white",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "white",
        boxShadow: "none",
      },
      "&:focus:active": {
        backgroundColor: "white",
        boxShadow: "none",
      },
    },
    forgotError: {
      height: "0px",
      fontSize: "16px",
      lineHeight: "38px",
      margin: "0px auto 7px",
      fontFamily: "var(--font-normal)",
      textAlign: "center",
      color: "red",
      transition: "all 0.3s",
      "&.active": {
        transition: "all 0.3s",
        height: "45px",
      },
    },
    formControl: {
      border: "1px solid grey",
    },
    margin: {
      marginTop: theme.spacing(2),
    },
    checkbox: {
      color: "grey",
    },
    label: {
      fontSize: "14px",
      fontFamily: "var(--font-normal)",
    },
    checkboxDiv: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  })
);

const ChangePasswordCard = () => {
  const [snack, setSnack] = useState({
    open: false,
    text: "",
    type: "success",
  });
  const password = useRef<HTMLInputElement>();
  const classes = useStyles();
  const [loginPassword, setloginPassword] = React.useState("");
  const [code, setCode] = useState("");
  const [loginPasswordState, setloginPasswordState] = React.useState("");
  const [pwVisible, setPwVisible] = useState(false);

  const [email, setEmail] = useState("");
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    signOut();
  }, []);

  const loadTrans = useSelector<RootState, any>(
    (state) => state.appTransParts.init
  );

  useEffect(() => {
    if (!loadTrans) {
      phaseIn();
      phaseBodyIn();
    }
  }, [loadTrans]);

  useEffect(() => {
    setTimeout(() => {
      password.current?.focus();
    }, 100);

    const codeIn =
      location.search?.match(/oobCode=[^&]*/)?.[0].replace("oobCode=", "") ||
      "";

    if (codeIn === "" && !currentUser) {
      phaseBodyOut();
      setTimeout(() => {
        history.push("/login/login");
      }, 300);
      return;
    }

    if (currentUser && !codeIn) {
      setEmail(currentUser.email);
      return;
    }

    setCode(codeIn);

    auth
      .verifyPasswordResetCode(codeIn)
      .then(function (email) {
        if (currentUser && email !== currentUser.email) {
          auth.signOut();
        }
        setEmail(email); //     // Display a "new password" form with the user's email address
      })
      .catch(function () {
        phaseBodyOut();
        setTimeout(() => {
          history.push("/login/login");
        }, 300);
      });
  }, [currentUser, history, location.search]);

  const changePassword = () => {
    setloginPasswordState("pending");

    if (currentUser) {
      auth.currentUser
        ?.updatePassword(loginPassword)
        .then(function () {
          setSnack({
            open: true,
            text: tx("08908asd0a8sdf", "Password successfully changed"),
            type: "success",
          });

          setTimeout(() => {
            if (currentUser) {
              phaseBodyOut();
              setTimeout(() => {
                history.push("/app/");
              }, 300);
            } else {
              phaseBodyOut();
              setTimeout(() => {
                history.push("/login/login/?email=" + email);
              }, 300);
            }
          }, 2500);
        })
        .catch(function (error: any) {
          console.log(error);
          let errorText = tx("unkoko0345jls", "Unknown error.");
          if (error.code === "auth/requires-recent-login") {
            errorText = tx(
              "osid0980af",
              "To change your password you have to log in again. If you forgot your password log out and then click on the Forgot my password link on the login screen."
            );
          }
          setSnack({
            open: true,
            text:
              tx("alsdkfjlasdfkjlasdf", "Password change failed.") + errorText,
            type: "error",
          });
        });
      return;
    }

    if (code) {
      auth
        .confirmPasswordReset(code, loginPassword)
        .then(function () {
          setSnack({
            open: true,
            text: tx(
              "098asdlkwel",
              "Password successfully changed. Log in now!"
            ),
            type: "success",
          });
          setTimeout(() => {
            phaseBodyOut();
            setTimeout(() => {
              history.push("/login/login/?email=" + email);
            }, 300);
          }, 3000);
        })
        .catch(function () {
          setSnack({
            open: true,
            text: tx("zcxxxc0980", "Password change failed."),
            type: "error",
          });
        });
    }
  };

  const checkForEnter = (event: any) => {
    if (event.key === "Enter") {
      if (loginPasswordState === "success") {
        changePassword();
      }
    }
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value: string, length: number) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const setVisibility = () => {
    setPwVisible(!pwVisible);
    password.current?.focus();
  };

  const toLogin = () => {
    phaseBodyOut();
    setTimeout(() => {
      history.push("/login/login/?email=" + email);
    }, 300);
  };

  return (
    <React.Fragment>
      <div className={classes.useful}>
        <div className={classes.logoWrapper}>
          <div className={classes.logo}>
            <img style={{ width: "80px" }} alt={"seal"} src="/logo192.png" />
          </div>
          <div className={classes.firm}>{getTitle()}</div>
        </div>
        <div className={classes.placeholder}></div>
        <div className={classes.fields}>
          <div className={classes.bigtitle}>
            {tx("935A7C74-EEE7-40EB-BDCA-58443BD2E41D47nw", "Set New Password")}
          </div>
          <div>
            <div className={classes.info}>
              {tx(
                "0C4207E7-6622-4FE8-9E50-09FB0A5443CBEB",
                "Set the new password for this e-mail address. It has to be at least six characters long and must contain at least one letter and one number. Clicking on the small eye will reveal the password as you type."
              )}
            </div>

            <div>
              <FormControl
                variant={"outlined"}
                fullWidth
                className={classes.margin}
              >
                <InputLabel htmlFor="standard-adornment-password">
                  {tx("D348AEF9-EE4D-43A5-95EB-8C5530A7CA60", "Password")}
                </InputLabel>
                <OutlinedInput
                  labelWidth={70}
                  id="standard-adornment-password"
                  type={pwVisible ? "text" : "password"}
                  fullWidth
                  onKeyDown={checkForEnter}
                  onChange={(event: any) => {
                    if (verifyLength(event.target.value, 3)) {
                      setloginPasswordState("success");
                    } else {
                      setloginPasswordState("error");
                    }
                    setloginPassword(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={setVisibility}
                      >
                        {pwVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className={classes.dialogactions}>
              <div className={classes.dialogstart}>
                {currentUser ? (
                  <div className={classes.forgotPassword}>
                    <span className={classes.linkLike} onClick={toLogin}>
                      {tx("933FE8CEA-64B8-4BCF-A3AF-0F78016F0A13", "Login")}
                    </span>
                  </div>
                ) : null}
              </div>

              <div className={classes.dialogend}>
                <Button
                  disabled={loginPasswordState !== "success"}
                  className={classes.buttonStart}
                  onClick={changePassword}
                >
                  {tx("C1C8922B-B8B1-46F3-9D8A-19127307E1E6ACwew", "Accept")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <CustomizedSnackbar
          open={snack.open}
          setSnack={setSnack}
          text={snack.text}
          type={snack.type}
        />
      </div>
    </React.Fragment>
  );
};

export default ChangePasswordCard;
