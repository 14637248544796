import React from "react";
import { useSelector } from "react-redux";

import {
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { RootState } from "../../Redux/reducers/_index";
import { blist } from "../words.utils";
import { tx } from "../../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { padding: "8px", paddingBottom: "0px", background: "aliceblue" },
    icons: {
      width: "100%",
      display: "flex",
      borderTop: "1px solid lightgrey",
      background: "antiquewhite",
    },
    expWrapper: { padding: "13px 13px 5px 13px", fontSize: "18px" },
    expTitle: { paddingBottom: "10px", fontWeight: "bold" },
    expDescription: { maxWidth: "280px" },
    explanation: { marginBottom: "8px", lineHeight: "150%" },
    explanationBold: {
      marginBottom: "8px",
      fontWeight: "bold",
      lineHeight: "150%",
    },
    divfirst: {
      flex: "1 1 auto",
      textAlign: "left",
    },
    divlast: {
      flex: "1 1 auto",
      textAlign: "right",
    },
    mainForm: {
      fontSize: "18px",
      lineHeight: "130%",
      fontWeight: "bold",
      paddingRight: "35px",
    },
    pos: {
      fontSize: "18px",
      lineHeight: "130%",
      fontWeight: "bold",
      color: "grey",
    },
    subForm: {
      fontSize: "18px",
      lineHeight: "130%",
      color: "grey",
      paddingRight: "35px",
    },
    subFormText: {
      fontSize: "18px",
      lineHeight: "130%",
      color: "lightgrey",
    },
    trans: {
      fontSize: "18px",
      lineHeight: "130%",
      fontWeight: "bold",
      paddingRight: "25px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    paper: {
      opacity: 0,
      "&.vis": { opacity: 1, transition: "all 0.2s" },
      padding: "0px",
      paddingBottom: "0px",
    },
    outer: {
      opacity: 0,
      transition: "all 0.2s",
      "&.vis": { opacity: 1, transition: "all 0.2s" },
      overflow: "visible",
      position: "relative",
      marginRight: "10px",
      maxWidth: "90%",
      minWidth: "250px",
    },
    closer: {
      position: "absolute",
      top: "-10px",
      right: "-10px",
      borderRadius: "50%",
      background: "white",
      lineHeight: 0,
      cursor: "pointer",
    },
    itable: { padding: "13px 13px 5px 13px", width: "100%" },
    ibutton: { padding: "8px" },
    closeIcon: { fill: "darkred", transform: "scale(1.1)" },
  })
);

const SmallPopup = (props: any) => {
  const classes = useStyles();
  const currentWord = useSelector<RootState, any>((state) => state.currentWord);

  const existWord = useSelector<RootState, any>(
    (state) => state.words[currentWord.id]
  );

  const { word } = currentWord;
  if (!word) {
    return null;
  }
  const [wordPart] = word.split("*");
  let [eng, pos] = wordPart.split("+");
  // const partOfSentence = blist[part];
  const forms = eng.split("/");

  const visualPos = blist[pos] || "";

  const { setAnchorEl, openBig, addWord, visible, setVisible } = props;

  const closeMe = () => {
    setVisible(false);
    setTimeout(() => setAnchorEl(null), 250);
  };

  const getExplanation = (explanations: string[]) => {
    return explanations.map((line, index) =>
      line.includes("*") ? (
        <div key={index} className={classes.explanationBold}>
          {line.replace("*", "")}
        </div>
      ) : (
        <div key={index} className={classes.explanation}>
          {line.replace("*", "")}
        </div>
      )
    );
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <div className={classes.outer + " " + (visible ? "vis" : "")}>
          <div className={classes.closer} onClick={closeMe}>
            <CancelTwoToneIcon className={classes.closeIcon} />
          </div>
          <Paper
            className={classes.paper + " " + (visible ? "vis" : "")}
            elevation={6}
          >
            {currentWord.explanations ? (
              <React.Fragment>
                <div className={classes.expWrapper}>
                  <div className={classes.expTitle}>{currentWord.d}</div>
                  <div className={classes.expDescription}>
                    {getExplanation(currentWord.explanations)}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <table className={classes.itable}>
                <tbody>
                  <tr>
                    <td className={classes.mainForm}>
                      {forms[0].replace(/%%%/g, "/")}
                    </td>
                    <td className={classes.pos}>{visualPos}</td>
                  </tr>
                  {forms[1] ? (
                    <tr>
                      <td className={classes.subForm}>{forms[1]}</td>
                      <td className={classes.subFormText}>
                        {getFormName(pos, 1)}
                      </td>
                    </tr>
                  ) : null}
                  {forms[2] ? (
                    <tr>
                      <td className={classes.subForm}>{forms[2]}</td>
                      <td className={classes.subFormText}>
                        {getFormName(pos, 2)}
                      </td>
                    </tr>
                  ) : null}
                  {forms[3] ? (
                    <tr>
                      <td className={classes.subForm}>{forms[3]}</td>
                      <td className={classes.subFormText}>
                        {getFormName(pos, 3)}
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td colSpan={2} className={classes.trans}>
                      {currentWord.d}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <div className={classes.icons}>
              <div className={classes.divfirst}>
                <IconButton className={classes.ibutton} onClick={openBig}>
                  <MoreHorizIcon />
                </IconButton>
              </div>
              <div className={classes.divlast}>
                <IconButton
                  disabled={Boolean(existWord || currentWord.explanations)}
                  className={classes.ibutton}
                  onClick={addWord}
                >
                  <AddCircleOutline />
                </IconButton>
              </div>
            </div>
          </Paper>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default SmallPopup;

const getFormName = (pos: string, num: number) => {
  // @ts-ignore
  const tempForm = posObj[pos]?.[num] || "";
  return tempForm;
};

const posObj = {
  "v.": {
    1: tx("azarp11", `2. form`),
    2: tx("azarp12", `3. form`),
  },
  "n.": {
    1: tx("azarp13", `plural`),
  },
  "adj.": {
    1: tx("azarp14", `comparative`),
    2: tx("azarp15", `superlative`),
  },
};
