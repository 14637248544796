import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HelpTopicsType {
  practices: number;
  intro: number;
  theory: number;
  cards: number;
  conversations: number;
  pronunciation: number;
  writing: number;
  quiz: number;
  dashboard: number;
}

export const initialState: HelpTopicsType = {
  practices: 0,
  intro: 0,
  theory: 0,
  cards: 0,
  conversations: 0,
  pronunciation: 0,
  writing: 0,
  quiz: 0,
  dashboard: 0,
};

const helpTopicsSlice = createSlice({
  initialState,
  name: "helpTopics",
  reducers: {
    setHelpTopics: (
      state: HelpTopicsType,
      action: PayloadAction<HelpTopicsType>
    ) => {
      return action.payload;
    },
    setHelpPractices: (
      state: HelpTopicsType,
      action: PayloadAction<number>
    ) => {
      state.practices = action.payload;
    },
    setHelpIntro: (state: HelpTopicsType, action: PayloadAction<number>) => {
      state.intro = action.payload;
    },
    setHelpTheory: (state: HelpTopicsType, action: PayloadAction<number>) => {
      state.theory = action.payload;
    },
    setHelpCards: (state: HelpTopicsType, action: PayloadAction<number>) => {
      state.cards = action.payload;
    },
    setHelpQuiz: (state: HelpTopicsType, action: PayloadAction<number>) => {
      state.quiz = action.payload;
    },
    setHelpDashboard: (
      state: HelpTopicsType,
      action: PayloadAction<number>
    ) => {
      state.dashboard = action.payload;
    },
    setHelpConversations: (
      state: HelpTopicsType,
      action: PayloadAction<number>
    ) => {
      state.conversations = action.payload;
    },
    setHelpPronunciation: (
      state: HelpTopicsType,
      action: PayloadAction<number>
    ) => {
      state.pronunciation = action.payload;
    },
    setHelpWriting: (state: HelpTopicsType, action: PayloadAction<number>) => {
      state.writing = action.payload;
    },
  },
});

export const {
  setHelpPractices,
  setHelpIntro,
  setHelpTheory,
  setHelpCards,
  setHelpConversations,
  setHelpPronunciation,
  setHelpWriting,
  setHelpTopics,
  setHelpQuiz,
  setHelpDashboard,
} = helpTopicsSlice.actions;

export default helpTopicsSlice.reducer;
