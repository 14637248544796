import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Dialog,
  Chip,
  Fade,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import styleRaw from "utils/Dialogs/modalStyle";
import firebase from "firebaseUtil/firebaseUtil";
import CloseIcon from "@material-ui/icons/Close";
import { tx } from "../utils/globalize";
import { useHistory } from "react-router";
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";
import { Device } from "@capacitor/device";
import { setBackOpen } from "Redux/reducers/reducer.backOpen";
import store from "Redux/store";

let styles = styleRaw as any;
let backgroundRef: any = () => {};
let successRef: any = () => {};

const verifyEmail = (value: any) => {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Fade timeout={1000} ref={ref} {...props} />;
});

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & { children?: React.ReactElement<any, any> },
//   ref: React.Ref<unknown>
// ) {
//   return <Fade timeout={1500} ref={ref} {...props} />;
// });

const useStyles = makeStyles({
  ...styles,
  radius: {
    borderRadius: "4px",
    background: "#e5e5e5",
    padding: "5px",
  },
  backdropbon: {
    zIndex: 50000,
  },
  mine: {
    position: "fixed",
    margin: "auto",
    display: "block",
    background: "white",
    left: "10px",
    right: "10px",
    maxWidth: "600px",
    maxHeight: "calc(100% - 29px)",
  },
  scrollPaper: {
    display: "block",
  },
  iframe: {
    width: "100%",
    position: "absolute",
    height: "100%",
    transform: "translate(0, -100%)",
  },
  iframeWrapper: {
    width: "95",
    paddingTop: "185%", // 35/70 = 0.5, the ratio can be changed here
    margin: "auto",
    position: "relative",
  },
  text: {
    paddingBottom: "10px",
    fontSize: "18px",
    fontFamily: "var(--font-normal)",
  },
  price: {
    marginTop: "10px",
    paddingBottom: "20px",
    fontSize: "18px",
    fontFamily: "var(--font-normal)",
  },
  thisprice: {
    paddingBottom: "15px",
    fontSize: "18px",
    fontFamily: "var(--font-bold)",
  },
  texthead: {
    color: "var(--toolbar-background)",
    paddingBottom: "20px",
    fontSize: "21px",
    fontFamily: "var(--font-bold)",
  },
  header: {
    height: "28px",
    width: "100%",
  },
  paper: {
    margin: "6px",
  },
  back: {
    background: "var(--toolbar-background)",
    color: "var(--toolbar-color)",
  },
  backChip: {
    background: "var(--toolbar-background)",
    color: "var(--toolbar-color)",
    border: "0px solid white",
  },
});

export const setupAppPay = () => {
  // InAppPurchase2.verbosity = InAppPurchase2.DEBUG;
  InAppPurchase2.applicationUsername = "bubu";
  InAppPurchase2.sandbox = false;

  InAppPurchase2.validator =
    "https://validator.fovea.cc/v1/validate?appName=com.wizzwords.app&apiKey=b4f4e380-29a4-4052-823f-495ca8fab16d";

  // InAppPurchase2.when("ininotesmonthly").registered(
  //   (product: IAPProduct) => {}
  // );

  InAppPurchase2.register({
    id: "wizzwords",
    type: InAppPurchase2.CONSUMABLE,
  });

  InAppPurchase2.error((e: any) => {
    backgroundRef(false);
    //alert(JSON.stringify(e));
    // store.dispatch(setGlobalLoading(false));
  });

  // InAppPurchase2.when("wizzwordsit").updated((product: any) => {
  //   if (store.getState().currentUser?.id) {
  //     store.dispatch(
  //       setUserStatus({
  //         ...updateProduct(product),
  //       })
  //     );
  //   }
  // });

  InAppPurchase2.when("wizzwords")
    .approved((p: any) => {
      p.verify();
    })
    .verified((p: any) => {
      p.finish();
      // store.dispatch(setGlobalLoading(false));
      if (p.owned === false) {
        successRef();
        alert(tx("checkoo34", "Controlla la tua email per la tua password."));
        // store.dispatch(
        //   setConfirm({
        //     confirmButton: tx("0izDrM7vin7QivMwesdfF", "OK"),
        //     header: tx("tdpv7yPTImcgNye_", "Payment Successful..."),
        //     content: (
        //       <React.Fragment>
        //         <div>
        //           You are now a Pro Member. There are no more limits on the
        //           amount of data you can upload, and you can use the
        //           Transcription and OCR service as well.
        //         </div>
        //       </React.Fragment>
        //     ),
        //     open: true,
        //   })
        // );
      }
    })
    .cancelled(() => {
      backgroundRef();
    });

  InAppPurchase2.refresh();
};

Device.getInfo().then((info) => {
  if (info.platform === "ios") {
    setupAppPay();
  }
});

const IosPayment = React.memo((props: any) => {
  const history = useHistory();
  const classes = useStyles();

  const [product, setProduct] = useState<any>({});
  const [email, setEmail] = useState<any>("");
  const [language, setLanguage] = useState<any>("it");
  const [valid, setValid] = useState(false);

  const success = () => {
    store.dispatch(setBackOpen(false));
    props.setOpen(false);
  };

  backgroundRef = () => store.dispatch(setBackOpen(false));
  successRef = success;

  useEffect(() => {
    setProduct(InAppPurchase2.get("wizzwords"));
  }, []);

  const buyProduct = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, "qweorasdflkalsd")
      .then((item) => {
        return;
      })
      .catch((e: any) => {
        if (e.code !== "auth/wrong-password") {
          InAppPurchase2.applicationUsername = email + "|" + language;
          store.dispatch(setBackOpen(true));
          InAppPurchase2.order("wizzwords").then();
          return;
        }

        alert(
          tx("txeweisit", "Esiste già un utente con questo indirizzo email.")
        );
        // InAppPurchase2.applicationUsername = email;
      });
  };

  const setAddress = (e: any) => {
    setEmail(e.target.value.toLowerCase());
    if (verifyEmail(e.target.value)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        style={{ zIndex: 1900 }}
        PaperProps={{ square: true }}
        open={props.open}
        classes={{ paper: classes.paper }}
        TransitionComponent={Transition}
        disableBackdropClick={false}
        onClose={() => props.setOpen(false)}
        onBackdropClick={() => props.setOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogActions className={classes.back}>
          <Chip
            className={classes.back}
            style={{ float: "right" }}
            icon={<CloseIcon />}
            size="small"
            clickable
            variant="outlined"
            label={tx("olzxckcsdskck", "Close")}
            onClick={() => props.setOpen(false)}
            classes={{ outlined: classes.backChip, icon: classes.back }}
          />
        </DialogActions>
        <DialogContent dividers={true} className={"helpid"}>
          <div className={classes.texthead}>
            {tx("buyagu1", "Acquista una licenza...")}
          </div>
          <div className={classes.text}>
            {tx(
              "buyagu2",
              "Si prega di fornire l'indirizzo e-mail per il quale si desidera ottenere una licenza."
            )}{" "}
            {tx(
              "buyagu3",
              "Ti invieremo un'e-mail con la tua password dopo il pagamento."
            )}
          </div>
          <div className={classes.price}>
            {tx(
              "kowkeowkessxx",
              "Il prezzo per uso illimitato (non abbonamento):"
            )}
          </div>

          <div className={classes.thisprice}>{product.price}</div>

          <div style={{ marginBottom: "15px", marginTop: "15px" }}>
            <TextField
              id="outlined-number"
              label={tx("kowekowe", "E-mail")}
              type="email"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={email}
              onChange={setAddress}
              variant="outlined"
            />
          </div>

          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <Button variant="contained" disabled={!valid} onClick={buyProduct}>
              {tx("buynowewe", "acquista ora")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
});

export default IosPayment;

//Please provide the email address you would like to get a licence for.
//We will send you an email with your password after payment.
//Buy Now
//Buy a licence...
//Check your email for your password.
//A user with this email address already exists.
//The price for unlimited use (not subscription):
