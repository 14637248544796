import React from "react";

// @material-ui/core components
import { createStyles, makeStyles, Slider, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    horizontal: {
      display: "flex",
      flexDirection: "row-reverse",
      width: "100%",
      alignItems: "baseline",
      paddingBottom: "20px",
    },
    divleft: { flex: "0 0 auto" },
    divright: { flex: "1 1 auto" },
    bigtitle: {
      paddingBottom: "10px",
      marginTop: "7px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    mainText: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      lineHeight: "135%",
      color: "black",
      marginBottom: "16px",
    },
    optionText: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      color: "lightgrey",
    },

    line: {
      height: "0px",
      width: "100%",
      marginLeft: "-20px",
      marginRight: "-20px",
      borderBottom: "1px solid var(--line-grey)",
      marginBottom: "18px",
    },
    x: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      color: "var(--toolbar-background)",
      fontSize: "18px",
      textAlign: "center",
    },
  })
);

const SettingsSlider = (props: any) => {
  const classes = useStyles();

  const changeSetting = (event: any, value: any) => {
    props.change(value);
  };

  return (
    <React.Fragment>
      <div className={classes.horizontal}>
        <div className={classes.divleft}>
          <div className={classes.x}>{props.value}x</div>
        </div>
        <div className={classes.divright}>
          <div className={classes.mainText}>{props.mainText}</div>
          <div className={classes.optionText}>
            <Slider
              value={props.value}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              onChangeCommitted={changeSetting}
              step={0.1}
              marks
              min={0.4}
              max={2}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettingsSlider;
