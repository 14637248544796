import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string = "";

const currentLessonSlice = createSlice({
  initialState,
  name: "currentLesson",
  reducers: {
    setCurrentLesson: (state: string, action: PayloadAction<string>) => {
      return action.payload;
    }
  }
});

export const { setCurrentLesson } = currentLessonSlice.actions;

export default currentLessonSlice.reducer;
