import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { setStatus } from "Redux/reducers/reducer.status";
import store from "Redux/store";

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  AccountCircle,
  DynamicFeedTwoTone,
  FormatListBulletedTwoTone,
  PersonalVideoTwoTone,
  RecordVoiceOverTwoTone,
  SpellcheckTwoTone,
  TimelineTwoTone,
} from "@material-ui/icons";
import Cached from "@material-ui/icons/CachedTwoTone";
import HelpOutline from "@material-ui/icons/HelpOutlineTwoTone";
import LockIcon from "@material-ui/icons/LockTwoTone";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportantTwoTone";
import SettingsIcon from "@material-ui/icons/SettingsTwoTone";

import { tx } from "../utils/globalize";
import { phaseBodyOut, phaseOut } from "../utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import { getProfileUrl } from "../utils/downloader";
import { Avatar } from "@material-ui/core";
import { isIOS } from "../settings/settings";
import MenuBookTwoTone from "@material-ui/icons/MenuBookTwoTone";
import { AddTwoTone, SearchTwoTone } from "@material-ui/icons";
import { firestore } from "../firebaseUtil/firebaseUtil";
import { updateReaderBulk } from "../words/words.utils";
import { setGrammar } from "Redux/reducers/reducer.grammar";

interface DrawerType {
  toggleDrawer: React.EventHandler<any>;
  drawerOn: boolean;
}

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  badge: {
    transform: "scale(0.7) translate(50%, -50%)",
  },
  badgeNone: {
    display: "none",
  },
  listItem: {
    fontFamily: "var(--font-normal)",
  },
  icon: { color: "var(--toolbar-background)" },
  avatar: { width: "30px", height: "30px" },
});

const PrimaryDrawer: React.FC<DrawerType> = (props: DrawerType) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [url, setUrl] = useState("");

  const signMeOut = () => {
    phaseOut();
    phaseBodyOut();
    setTimeout(() => {
      history.push("/login/login");
    }, 150);
  };
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const authLoaded = useSelector<RootState, any>((state) => state.authLoaded);
  const reader = useSelector<RootState, any>((state) => state.reader);
  const platform = useSelector<RootState, any>((state) => state.platform);
  const customize = useSelector<RootState, any>((state) => state.customize);

  useEffect(() => {
    const loadName = async () => {
      setUrl(await getProfileUrl(customize?.teacher?.pic || ""));
    };

    if (authLoaded && customize?.teacher?.pic) {
      loadName();
    }
  }, [currentUser, authLoaded, customize]);

  const showSettings = () => {
    phaseOut();
    setTimeout(() => history.push("/app/settings"), 150);
  };

  const showReport = () => {
    phaseOut();
    setTimeout(() => history.push("/app/report"), 150);
  };

  const refreshApp = () => {
    //@ts-ignore
    window.location.reload(true);
  };

  const showHelp = () => {
    phaseOut();
    setTimeout(() => history.push("/app/help"), 150);
  };

  const showProHelp = () => {
    if (customerTeacher?.linkType === "internal") {
      phaseOut();
    }

    setTimeout(() => {
      if (customerTeacher?.linkType === "internal") {
        history.push("/app/contact");
      } else {
        window.open("https://" + customerTeacher?.myUrl, "_speck");
      }
    }, 150);
  };

  // const showReader = () => {
  //   if (!location.pathname.includes("/app/words")) {
  //     phaseOut();
  //     store.dispatch(setStatus("reader"));
  //     setTimeout(() => history.push("/app/words"), 150);
  //   } else {
  //     store.dispatch(setStatus("reader"));
  //   }
  // };

  const loadListNext = useCallback(
    async (itemId: any) => {
      let gone: any = [];

      let listId = "";
      let textId = "";

      if (itemId === "v1") {
        listId = "v1";
        textId = reader.lastVideo?.textId || "v1001";
      } else {
        listId = reader.lastText?.listId || "l1";
        textId = reader.lastText?.textId || "l1001";
      }

      if (!listId.indexOf("gr")) {
        listId = "l1";
        textId = "l1001";
      }

      gone = await firestore.doc(`/textlists/${listId}`).get();
      if (gone.data()) {
        const texts = gone.data().texts?.map((item: any) => ({
          ...item,
          done: !!reader.history.find((hist: any) => hist.id === item.id),
        }));

        let firstItem: any;

        if (!textId) {
          firstItem = texts.find((item: any) => !item.done);

          if (!firstItem) {
            firstItem = texts[0];
          }
        } else {
          firstItem = texts.find((item: any) => item.id === textId);
        }

        const firstText = (
          await firestore.doc(`/texts/${firstItem.id}`).get()
        ).data();

        updateReaderBulk({
          currentList: {
            ...gone.data(),
            texts,
            title: gone.data().title,
            id: listId,
            own: false,
          },
          currentText: {
            ...firstText,
            text: firstText?.text.replace("|", "\n"),
            list: gone.data().title,
            listId: listId,
            id: firstText?.id,
          },
        });
      }
    },
    [reader.history, reader.lastText, reader.lastVideo]
  );

  const readText = async () => {
    if (
      store.getState().reader.currentList.id === "v1" ||
      !store.getState().reader.currentList.id.indexOf("gr")
    ) {
      await loadListNext("l3");
    }

    if (!location.pathname.includes("/app/words")) {
      phaseOut();
      setTimeout(() => history.push("/app/words"), 150);
    }

    store.dispatch(setStatus("reader"));
  };

  const watchVideos = async () => {
    if (store.getState().reader.currentList.id !== "v1") {
      await loadListNext("v1");
    }

    if (!location.pathname.includes("/app/words")) {
      phaseOut();
      setTimeout(() => history.push("/app/words"), 150);
    }

    store.dispatch(setStatus("reader"));
  };

  const showVocab = () => {
    if (!location.pathname.includes("/app/words")) {
      phaseOut();
      store.dispatch(setStatus("ownwords"));
      setTimeout(() => history.push("/app/words"), 150);
    } else {
      store.dispatch(setStatus("ownwords"));
    }
  };

  const goDictionary = () => {
    if (!location.pathname.includes("/app/words")) {
      phaseOut();
      store.dispatch(setStatus("wordlist"));
      setTimeout(() => history.push("/app/words"), 150);
    } else {
      store.dispatch(setStatus("wordlist"));
    }
  };

  const showMostUsed = () => {
    if (!location.pathname.includes("/app/words")) {
      phaseOut();
      store.dispatch(setStatus("origlist"));
      setTimeout(() => history.push("/app/words"), 150);
    } else {
      store.dispatch(setStatus("origlist"));
    }
  };

  // const showAccount = () => {
  //   phaseOut();
  //   setTimeout(() => history.push("/app/account"), 150);
  // };

  const practiceGrammar = () => {
    phaseOut();
    store.dispatch(setStatus("loader"));
    store.dispatch(setGrammar(true));
    setTimeout(() => history.push("/app/words/gr"), 150);
  };

  const showPron = () => {
    phaseOut();
    setTimeout(() => history.push("/app/pron"), 150);
  };

  const showWriter = () => {
    phaseOut();
    setTimeout(() => history.push("/app/writer"), 150);
  };

  const firstSteps = () => {
    phaseOut();
    setTimeout(() => history.push("/app/firststeps"), 150);
  };

  const customerTeacher = useSelector<RootState, any>(
    (state) => state.customize?.teacher
  );

  return (
    <Drawer open={props.drawerOn} onClose={props.toggleDrawer} anchor={"right"}>
      <div
        className={classes.list}
        role="presentation"
        onClick={props.toggleDrawer}
        onKeyDown={props.toggleDrawer}
      >
        <React.Fragment>
          <List>
            <ListItem button onClick={showVocab}>
              <ListItemIcon className={classes.icon}>
                <FormatListBulletedTwoTone />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("aszlxckllwlwtesting", "My Words")}
              />
            </ListItem>
            <ListItem button onClick={readText}>
              <ListItemIcon className={classes.icon}>
                <MenuBookTwoTone />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("zlxckllwlwprdogsram", "Reader")}
              />
            </ListItem>
            <ListItem button onClick={watchVideos}>
              <ListItemIcon className={classes.icon}>
                <PersonalVideoTwoTone />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("agata3", "Videos")}
              />
            </ListItem>
            <ListItem button onClick={showMostUsed}>
              <ListItemIcon className={classes.icon}>
                <AddTwoTone />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("aszlxckllwlw", "Most Used Words")}
              />
            </ListItem>
            {(isIOS() && platform === "web") ||
            currentUser?.type !== "subadmin" ? (
              ""
            ) : (
              <ListItem button onClick={showPron}>
                <ListItemIcon className={classes.icon}>
                  <RecordVoiceOverTwoTone />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItem }}
                  primary={tx("aszlxckllwlwew", "Pronunciation Drill")}
                />
              </ListItem>
            )}
            <ListItem button onClick={showWriter}>
              <ListItemIcon className={classes.icon}>
                <SpellcheckTwoTone />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("aszlxckllwzselwew", "Writing Drill")}
              />
            </ListItem>
            <ListItem button onClick={practiceGrammar}>
              <ListItemIcon className={classes.icon}>
                <DynamicFeedTwoTone />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("agata7", "Grammar")}
              />
            </ListItem>
            <ListItem button onClick={goDictionary}>
              <ListItemIcon className={classes.icon}>
                <SearchTwoTone />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("titlea1", "Dictionary")}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={showReport}>
              <ListItemIcon className={classes.icon}>
                <TimelineTwoTone />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("kllzoooo", "Progress")}
              />
            </ListItem>
            <ListItem button onClick={showSettings}>
              <ListItemIcon className={classes.icon}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("zlxcklsdlwlw", "Settings")}
              />
            </ListItem>

            {/* <ListItem button onClick={showAccount}>
              <ListItemIcon className={classes.icon}>
                <FaceTwoToned />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("zlxckllzswewewxcwewlw", "Account")}
              />
            </ListItem> */}
            <ListItem button onClick={refreshApp}>
              <ListItemIcon className={classes.icon}>
                <Cached />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("zlxckllzsewxcwewlw", "Reload")}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={showHelp}>
              <ListItemIcon className={classes.icon}>
                <HelpOutline />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("zlxwckllzxcwewlwerw", "Help")}
              />
            </ListItem>
            {customerTeacher?.inMenu ? (
              <ListItem button onClick={showProHelp}>
                <ListItemIcon className={classes.icon}>
                  {url ? (
                    <Avatar
                      alt={customerTeacher?.name}
                      src={url || "/load1.gif"}
                      className={classes.avatar}
                    />
                  ) : (
                    <AccountCircle />
                  )}
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItem }}
                  primary={tx("zlxwckllzxcwewlwerwwer", "Pro Help")}
                />
              </ListItem>
            ) : (
              ""
            )}
            <ListItem button onClick={firstSteps}>
              <ListItemIcon className={classes.icon}>
                <NotificationImportantIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("zlxckllzxcwewlwerwaswwwwe", "First Steps")}
              />
            </ListItem>

            <Divider />
            <ListItem button onClick={signMeOut}>
              <ListItemIcon className={classes.icon}>
                <LockIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={tx("zlxckllzxcwewlw", "Log out")}
              />
            </ListItem>
          </List>
        </React.Fragment>
      </div>
    </Drawer>
  );
};

export default PrimaryDrawer;
