import React, { useEffect } from "react";
import { setStatus } from "Redux/reducers/reducer.status";
import { setWordbarOne } from "Redux/reducers/reducer.wordbar";
import store from "Redux/store";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import Toolbar3000 from "./toolbar.3000";
import ToolbarDictionary from "./toolbar.dictionary";
import ToolbarLoader from "./toolbar.loader";
import ToolbarOwnwords from "./toolbar.ownWords";
import QueryInput from "./toolbar.query";
import ToolbarReader from "./toolbar.reader";
import ToolbarTabs from "./toolbar.tabs";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    omni: {
      zIndex: 1000,
      position: "relative",
      padding: "5px",
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      width: "100%",
      borderTop: "1px solid lightgrey",
      height: "47px",
    },
    query: {
      width: "100%",
      height: "30px",
      position: "relative",
      zIndex: 50000,
    },
    icon: {
      color: "var(--toolbar-color)",
    },
    searchIcon: {
      position: "absolute",
      right: "10px",
      top: "6px",
      height: "24px",
      padding: "0px",
      borderRadius: "0px",
      color: "var(--toolbar-color)",
      "&.active": {
        boxShadow: "0px 0px 2px 1px var(--black-icon-shadow)",
        color: "var(--black-icon-color)",
      },
    },
    icon1: {
      position: "absolute",
      left: "10px",
      top: "6px",
      height: "24px",
      color: "var(--toolbar-color)",
      "&.active": { boxShadow: "0px 0px 2px 1px  var(--icon-shadow)" },
      padding: "0px",
      borderRadius: "0px",
    },
    icon2: {
      position: "absolute",
      left: "50px",
      color: "var(--toolbar-color)",
      top: "6px",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--icon-shadow)" },
      padding: "0px",
      borderRadius: "0px",
    },
    icon3: {
      position: "absolute",
      left: "90px",
      color: "var(--toolbar-color)",
      top: "6px",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--icon-shadow)" },
      borderRadius: "0px",
      padding: "0px",
    },
    icon1text: {
      position: "absolute",
      left: "10px",
      color: "var(--toolbar-color)",
      top: "6px",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--icon-shadow)" },
      borderRadius: "2px",
      border: "1px solid var(--toolbar-color)",
      padding: "3px",
      fontSize: "15px",
    },
    icon4: {
      position: "absolute",
      left: "130px",
      color: "var(--toolbar-color)",
      top: "6px",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--icon-shadow)" },
      padding: "0px",
      borderRadius: "0px",
    },
    dicIcon: {
      position: "absolute",
      right: "50px",
      color: "var(--toolbar-color)",
      top: "6px",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--icon-shadow)" },
      padding: "0px",
      borderRadius: "0px",
    },
    dicIconBlack: {
      position: "absolute",
      right: "50px",
      color: "var(--black-icon-color)",
      top: "6px",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--black-icon-shadow)" },
      padding: "0px",
      borderRadius: "0px",
    },
    tIcon: {
      position: "absolute",
      right: "90px",
      color: "var(--toolbar-color)",
      top: "6px",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--icon-shadow)" },
      padding: "0px",
      borderRadius: "0px",
    },
    sIcon: {
      position: "absolute",
      right: "130px",
      color: "var(--toolbar-color)",
      top: "6px",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--icon-shadow)" },
      padding: "0px",
      borderRadius: "0px",
    },
    fIcon: {
      position: "absolute",
      right: "170px",
      top: "6px",
      color: "var(--toolbar-color)",
      height: "24px",
      "&.active": { boxShadow: "0px 0px 2px 1px var(--icon-shadow)" },
      padding: "0px",
      borderRadius: "0px",
    },
    input: {
      width: "100%",
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      padding: "3px",
      height: "35px",
    },
    list: {
      paddingBottom: "0px",
      marginBottom: "0px",
      marginTop: "0px",
      paddingTop: "0px",
      cursor: "pointer",
    },
    smallIcon: {
      padding: "7px",
      color: "var(--toolbar-color)",
    },
    arrowWrapper: { display: "flex", width: "100%" },
    listItem: { paddingRight: "40px", fontSize: "16px" },
    posLeft: {
      flex: "1 1 auto",
    },
    posRight: {
      flex: "1 1 auto",
      textAlign: "right",
    },
    mIcon: { color: "var(--toolbar-background)" },
  })
);

const WordsToolBar = (props: any) => {
  const classes = useStyles();
  const {
    doneRef,
    textIdRef,
    textTypeRef,
    editorRef,
    nextBack,
    newTitleRef,
    query,
    setQuery,
    setWordResult,
    setActiveSentence,
    getNext,
    showDone,
    showUndone,
    setShowDone,
    setShowUndone,
    videoPlayer,
  } = props;

  useEffect(() => {
    store.dispatch(
      setWordbarOne({
        id: "changeTab",
        value: (stat: string) => {
          store.dispatch(setStatus(stat));
        },
      })
    );
  }, []);

  return (
    <React.Fragment>
      <div className={classes.omni}>
        <div className={classes.query}>
          <QueryInput
            classes={classes}
            setWordResult={setWordResult}
            setActiveSentence={setActiveSentence}
            query={query}
            setQuery={setQuery}
            videoPlayer={videoPlayer}
          />
          <Toolbar3000
            classes={classes}
            showDone={showDone}
            setShowDone={setShowDone}
            showUndone={showUndone}
            setShowUndone={setShowUndone}
          />
          <ToolbarLoader
            classes={classes}
            editorRef={editorRef}
            newTitleRef={newTitleRef}
          />
          <ToolbarReader classes={classes} getNext={getNext} />
          <ToolbarOwnwords
            classes={classes}
            showDone={showDone}
            setShowDone={setShowDone}
            showUndone={showUndone}
            setShowUndone={setShowUndone}
            getNext={getNext}
            doneRef={doneRef}
            textIdRef={textIdRef}
            textTypeRef={textTypeRef}
          />
          <ToolbarTabs
            classes={classes}
            setQuery={setQuery}
            setWordResult={setWordResult}
          />
          <ToolbarDictionary
            classes={classes}
            setQuery={setQuery}
            setWordResult={setWordResult}
            nextBack={nextBack}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default WordsToolBar;
