import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProgramType {
  index: string[];
  [field: string]:
    | {
        number: string;
        seq: string;
        summary: string;
        title: string;
        length: number;
        words: number;
        color: string;
        summaryTid?: string;
        titleTid?: string;
        done?: boolean;
        completions?: number[];
      }
    | string[];
}

const initialState: any = { index: [] };

const programSlice = createSlice({
  initialState,
  name: "program",
  reducers: {
    setProgram: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setProgramLesson: (state: any, action: PayloadAction<any>) => {
      state[action.payload.lesson] = action.payload.value;
    },
    setProgramMultiple: (state: any, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  setProgram,
  setProgramLesson,
  setProgramMultiple,
} = programSlice.actions;

export default programSlice.reducer;
