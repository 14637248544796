import React from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpCard() {
  const classes = useStyles();

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>
          {tx("azarga1", `Practice Cards`)}
        </div>
        <div className={classes.helpBold}>
          {tx("azarga2", `What are these?`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga3",
            `Practice cards give you a simple but effective way to practice words, phrases or any grammar rules for that matter.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga4",
            `When you study words in WizzWords in most cases you don't only have the word to study but you have some example sentences with translations available as well.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga5",
            `So when you start studying you get a card similar to this:`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/card1.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx("azarga6", `Important parts are:`)}
        </div>
        <div className={classes.helpList}>
          {tx("azarga10", `a) This tells you whether it's a noun, a verb etc.`)}
        </div>

        <div className={classes.helpList}>
          {tx("azarga7", `b) You can close the cards if you click here.`)}
        </div>

        <div className={classes.helpList}>
          {tx(
            "azarga9",
            `c) These are extra forms of the word (for example its plural).`
          )}
        </div>

        <div className={classes.helpList}>
          {tx(
            "azarga8",
            `d) Click here to hear the current English text visible on the card.`
          )}
        </div>

        <div className={classes.helpList}>
          {tx("azarga11", `e) This is the English text on the card.`)}
        </div>
        <div className={classes.helpText}>
          {tx("azarga12", `The idea is simple.`)}{" "}
          {tx(
            "azarga31",
            `You look at the English word or sentence and you translate it to your language.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga13",
            `If you are not sure about the solution or want to check whether you're right click on the card itself and it turns around.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/card2.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga14",
            `On the other side you'll see the translated version of the card.`
          )}{" "}
          {tx(
            "azarga37",
            `If you click on the card again it turns back to the English text.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga15",
            `When you're done with a card you have two buttons at the bottom:`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/card3.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarga16",
            `a) Click here to bring up the next card in the sequence.`
          )}{" "}
          {tx(
            "azarga38",
            `(In the lower right corner you'll see the number of cards still left for the given word.)`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarga17",
            `b) Click here if you think you have practiced the word in enough sentences and want to finish practicing it from English to your language.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga18",
            `If you click b) or you get to the last card the same cards will show up again however now with their translated side first.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx("azarga19", `Translate the text back to English.`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga20",
            `You can check your solution if you turn around the card by clicking on it.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga21",
            `Again, you can go to the next card with the right side button or you can finish the cards with the left side button.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga22",
            `When you finish the last card from your language to English you will get a question:`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/card4.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga23",
            `It's important that you answer based on your feelings with the word.`
          )}{" "}
          {tx(
            "azarga42",
            `The computer will use a special algorithm based on your answer to set the time when you'll next review this word.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga24",
            `If you are sure that you know this word completely, click on the I know this word button and the word will not come up again.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga25",
            `The app brings up all words which are scheduled to be repeated or studied on that date. `
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga26",
            `When you complete the last word it will ask you what to do next:`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/card5.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarga28",
            `a) You can study words from the most used 3500 English words which the computer will automatically select for you and add to your list.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarga27",
            `b) You can repeat the words which are still active but scheduled for a later day.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx("azarga29", `c) You can finish word study for the day.`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga30",
            `Cards generally automatically play English sentences when they first show up on a card.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarga31a",
            `You can turn this feature on and off by holding the speaker button for about a second.`
          )}{" "}
          {tx(
            "azarga49",
            `The text Auto will appear and disappear to show this.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/card6.png`}
            className={classes.helpImg}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
