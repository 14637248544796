import "./App.css";

import BasicUi from "mainComponents/ui";
import React, { Suspense, useEffect } from "react";
import { setAuthLoaded } from "Redux/reducers/reducer.authLoaded";
import { setCurrentUser } from "Redux/reducers/reducer.currentUser";
import { setProgram } from "Redux/reducers/reducer.program";
import store from "Redux/store";
import Confirmation from "utils/Confirmation";
import { phaseBodyOut } from "utils/utils";

import { Backdrop, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { Device } from "@capacitor/device";
import { auth, getUserProfileDocument } from "../firebaseUtil/firebaseUtil";
import Timer from "../timer/timer.timer";
import { phaseOut } from "../utils/utils";
import HistoryCheck from "./App.historyCheck";
import { setupListeners, removeListeners } from "./App.utils";
import { setCustomStyles } from "../utils/setCustomStyles";
import { checkGoing } from "./App.loadWarnings";
import { useLoadAppTrans } from "../utils/useLoadAppTrans";

import HelpDialog from "../help/help.dialog";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import { setPlatform } from "Redux/reducers/reducer.platform";
import UpgradeChecker from "./App.upgradechecker";
import AppUpdating from "./App.updating";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#172b4d" },
    secondary: { main: "#ff7c00" },
  },
});

function App() {
  useLoadAppTrans();
  const loadDone = useSelector<RootState, any>(
    (state) => state.appTransParts.init
  );

  const backOpen = useSelector<RootState, any>((state) => state.backOpen);

  useEffect(() => {
    const dimmer = document.getElementById("dimmerlite");
    if (dimmer) {
      dimmer.style.transition = "none";
    }
    phaseOut();
    phaseBodyOut();
    setTimeout(() => {
      if (dimmer) {
        dimmer.style.transition = "";
      }
    }, 0);
  }, []);

  useEffect(() => {
    let custom = localStorage.getItem("customized");
    let customObj: any = {};

    if (!custom) {
      return;
    } else {
      customObj = JSON.parse(custom);
    }

    setCustomStyles(customObj);
  }, []);

  useEffect(() => {
    Device.getInfo().then((info: any) => {
      store.dispatch(setPlatform(info.platform));
    });
  }, []);

  // useEffect(() => {
  //   const vCheck = setInterval(confirmUpgrade, 60000);
  //   versionCheck("");
  //   return () => clearInterval(vCheck);
  // }, []);

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth && !store.getState().authLoaded) {
        const userRef = await getUserProfileDocument(userAuth, {});
        if (userRef) {
          removeListeners();
          setupListeners(userRef, userAuth);
        }
      } else {
        if (store.getState().currentUser) {
          store.dispatch(setCurrentUser(null));
          store.dispatch(setProgram({ index: [] }));
          store.dispatch(setAuthLoaded(true));
        } else {
          store.dispatch(setAuthLoaded(true));
        }
        // removeListeners();
        // removeReaderListener();
      }
    });
  }, []);

  useEffect(() => {
    if (!loadDone) {
      Device.getInfo().then((info: any) => {
        if (info.platform === "web") {
          checkGoing();
        }
      });
    }
  }, [loadDone]);

  return (
    <ThemeProvider theme={theme}>
      <Timer />

      <BasicUi />
      <UpgradeChecker />
      <Confirmation />
      <HistoryCheck />
      <Suspense fallback={<div />}>
        <HelpDialog />
      </Suspense>
      <AppUpdating />
      <Backdrop id="woeowe" open={backOpen}></Backdrop>
    </ThemeProvider>
  );
}

export default App;
