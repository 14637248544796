import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { loadAppTranslation } from "./saver.loadTranslation";
import store from "Redux/store";
import { setAppTransParts } from "Redux/reducers/reducer.appTransParts";
import {
  setProceedMenu,
  setProceedAction,
} from "Redux/reducers/reducer.proceed";
import { setTempLanguage } from "Redux/reducers/reducer.tempLanguage";
import { useSelector } from "react-redux";
import { setupCustomization } from "../app/App.utils";

export const myLang = () => {
  const loc = document.location.hostname;
  if (loc.includes("app.netenglish.it")) {
    return "it";
  }
  if (loc.includes("app.netenglish.mx")) {
    return "es";
  }
  if (loc.includes("app.netenglish.pl")) {
    return "pl";
  }

  if (loc.includes("szv.gyakorlatias-angol.hu")) {
    return "hu";
  }

  return "it";
};

export const useLoadAppTrans = () => {
  const [ready, setReady] = useState(false);
  const location = useLocation();
  const currentUserLang = useSelector<any, any>(
    (state) => state.currentUser?.language
  );

  useEffect(() => {
    let ccode =
      location.search?.match(/ccode=[^&]*/)?.[0].replace("ccode=", "") || "";

    if (ccode) {
      setupCustomization(ccode);
    }
  }, [location.search]);

  useEffect(() => {
    // if (ready) {
    //   setTimeout(()=>{
    //   phaseBodyIn();
    //   }
    //   return;
    // }

    const startApp = async () => {
      let language =
        store.getState().currentUser?.language ||
        location.search?.match(/lang=[^&]*/)?.[0].replace("lang=", "") ||
        localStorage.getItem("language") ||
        myLang();

      if (document.location.href.includes("szv.gyakorlatias-angol.hu")) {
        language = "hu";
      }
      // console.log("baba", language);

      localStorage.setItem("language", language);

      store.dispatch(setTempLanguage(language));

      const appTranslation = await loadAppTranslation(language);

      store.dispatch(setAppTransParts(appTranslation));
      setTimeout(() => {
        setReady(true);
      }, 0);
    };
    store.dispatch(setProceedMenu({ visibleMenu: false }));
    store.dispatch(setProceedAction({ visibleAction: false }));

    startApp();
  }, [location.search, ready, currentUserLang]);

  return ready;

  // return 0;
};
