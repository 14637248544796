import React, { useState } from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpMostUsed() {
  const classes = useStyles();
  const [open1, setOpen1] = useState(false);

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>
          {tx("azark10", `List of Most Used Words`)}
        </div>
        <div className={classes.helpBold}>
          {tx("azark11", `What's this whole thing?`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azark12",
            `Here you can see the list of the 3500+ most used English words.`
          )}{" "}
          {tx(
            "azark13",
            `This list is of course subjective, but in any case these are important English words.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azark13a",
            `They are in four different categories according to their frequency of usage: A1, A2, B1 and B2.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mostmenu.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {tx(
            "azark14",
            `Clicking on the button in the lower left corner you can select which category you want to see.`
          )}{" "}
          {tx(
            "azark15",
            `You can see all of them together or only A1, A2, B1 or B2, but even if you choose all of them the words will be shown in four categories.`
          )}{" "}
          {tx("azark16", `A1 first, then A2, B1, B2.`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azark17",
            `If you click on one of the words, a new dialog appears and you will see all available data on the selected word.`
          )}
        </div>
        <div
          onClick={() => setOpen1(true)}
          className={open1 ? classes.helpLinkClosed : classes.helpLinkOpen}
        >
          {tx("azark18", `See details...`)}
        </div>
        <div className={open1 ? classes.open : classes.closed}>
          <div className={classes.helpText}>
            {tx("azark19", `The content of this dialog is self-explanatory.`)}{" "}
            {tx(
              "azark20",
              `If you click on one of the small speakers, the application will read aloud the selected sentence.`
            )}
          </div>
          <div className={classes.imgDiv}>
            <img
              alt="card35"
              src={`/help/${lang}/worddata.png`}
              className={classes.helpImg}
            />
          </div>
          <div className={classes.helpText}>
            {tx(
              "azark20",
              `At the bottom of the dialog there are some additional options:`
            )}
          </div>
          <div className={classes.helpList}>
            {tx("azark21", `a) Learning words with practice cards.`)}{" "}
            {tx("azark22", `(See instructions under My Words.)`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark22a",
              `b) It translates the current sentence with Google Translate.`
            )}{" "}
            {tx("azark23", `A new window opens with the translation.`)}{" "}
            {tx("azark24", `When you are done close this window.`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark25",
              `c) You can look up the current word in a bigger dictionary.`
            )}{" "}
            {tx("azark26", `A new window opens with the bigger dictionary.`)}{" "}
            {tx("azark27", `When you are done close this window.`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark28",
              `d) You add the word to your vocabulary list to study and repeat later.`
            )}
          </div>
        </div>
        <div className={classes.helpText}>
          {tx("azark29", `The background color of the words is important.`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mostcolors.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azark30",
            `a) White background means that the word has not been touched by you in any way.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azark31",
            `b) Pink background means that the word is selected for repetition today.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azark32",
            `c) Yellow background means that even though you're still learning the word the word is not selected for repetition today but will come up later.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azark33",
            `d) Green background means that the word is considered known, and you decided that you know it.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azark34",
            `Depending on the status of the word there can be three types of buttons next to the words:`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mostbuttons.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx("azark35", `a) This marks the word as completed (known).`)}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azark36",
            `b) The word is added to the list of words to repeat TODAY.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azark37",
            `c) The word which is marked as KNOWN is added again to the words to be studied today.`
          )}
        </div>
        <div className={classes.helpBold}>
          {tx("azark38", `How can you use this list?`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azark39",
            `You can go through the words and you can mark those you already know as KNOWN.`
          )}{" "}
          {tx(
            "azark40",
            `Then you can add those which you don't know to your vocabulary list for study.`
          )}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azark41",
            `This list is also useful if you want to review the list of already learned and known words to check if there are some that you want to review.`
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
