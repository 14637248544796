import { FreeData } from 'firebaseUtil/firebaseUtil';
import React, { useCallback, useEffect, useState } from 'react';

import DialogRaw from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
// @material-ui/core components
import { makeStyles, Theme } from '@material-ui/core/styles';

import { speakNow } from '../utils/utils.speech';
import StudyCard from './wordCard.card';

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = (theme: Theme) => ({
  modal: {
    marginTop: "0px",
    overflow: "visible",
  },
  containing: {
    overflowY: "hidden",
  },
  paperScrollBody: {
    maxWidth: "1200px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "1200px !important",
      margin: "32px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100% - 20px) !important",
      margin: "32px 0px",
    },
  },
});

const useStyles = makeStyles(style as any);
const Dialog = DialogRaw as any;

interface CardModalType {
  content: FreeData;
  cardOpen: boolean;
  doneRef: any;
  setCardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getNext: any;
  loadingWord: boolean;
  backToList: any;
  finishSlides: any;
  setFinishSlides: any;
  setActiveSentence: any;
  textIdRef: any;
}

const WordCardModal: React.FC<CardModalType> = React.memo(
  ({
    content,
    cardOpen,
    setCardOpen,
    doneRef,
    getNext,
    loadingWord,
    finishSlides,
    setFinishSlides,
    setActiveSentence,
    textIdRef,
  }) => {
    const [englishFirst, setEnglishFirst] = useState(true);
    const [inContent, setInContent] = useState<any[]>([]);
    const [engPosition, setEngPosition] = useState(0);
    const [isFlipped, setIsFlipped] = useState(false);

    const classes = useStyles();

    const resetStanding = useCallback(() => {
      if (content) {
        if (content?.a?.s) {
          setActiveSentence(content.a);
        } else {
          setActiveSentence({
            s: "",
            sOrig: "",
            w: [0, 0],
            fileId: "",
          });
        }

        setInContent(content.cards);

        setEngPosition(0);
      }
    }, [content, setActiveSentence]);

    const redo = useCallback(() => {
      setIsFlipped(false);
      setFinishSlides(false);
      setEnglishFirst(true);
      setTimeout(() => {
        setEngPosition(0);
      }, 0);
    }, [setFinishSlides]);

    // useEffect(() => {
    //   resetStanding();
    // }, [content, resetStanding]);

    useEffect(() => {
      if (content?.a?.s) {
        setActiveSentence(content.a);
      } else {
        setActiveSentence({
          s: "",
          sOrig: "",
          w: [0, 0],
          fileId: "",
        });
      }
      setInContent(content.cards);
      redo();
    }, [cardOpen, redo, content, setActiveSentence]);

    // const getNextSlide = (wasGood: boolean) => {
    //   return inContent.findIndex((item) => !item.done);
    // };

    const doneSlide = useCallback(() => {
      let nextSlide = engPosition + 1;

      if (nextSlide === inContent.length) {
        if (englishFirst) {
          setInContent(inContent.map((item) => ({ ...item, done: false })));
          setEnglishFirst(false);
          setIsFlipped(true);
          setEngPosition(0);
          return;
        } else {
          if (content.id === "practice") {
            setCardOpen(false);
            return;
          }
          setFinishSlides(true);
        }
      }

      setIsFlipped(!englishFirst);
      setEngPosition(nextSlide);
    }, [
      engPosition,
      englishFirst,
      inContent,
      setFinishSlides,
      content,
      setCardOpen,
    ]);

    const fastForward = useCallback(() => {
      if (englishFirst) {
        setInContent(inContent.map((item) => ({ ...item, done: false })));
        setEnglishFirst(false);
        setIsFlipped(true);
        setEngPosition(0);
        return;
      } else {
        if (content.id === "practice") {
          setCardOpen(false);
          return;
        }
        setFinishSlides(true);
      }
    }, [content.id, englishFirst, inContent, setCardOpen, setFinishSlides]);

    const showNextSlide = useCallback(() => {
      const nextSlide =
        engPosition < inContent.length - 1 ? engPosition + 1 : 0;

      setIsFlipped(!englishFirst);
      setEngPosition(nextSlide);
    }, [engPosition, englishFirst, inContent.length]);

    const tellEnglish = (item: string) => {
      speakNow(item);
    };

    return (
      <Dialog
        scroll="body"
        classes={{
          container: classes.containing,
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalLogin,
          paperScrollBody: classes.paperScrollBody,
        }}
        open={cardOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setCardOpen(false);
          textIdRef.current = "";
          doneRef.current = [];
          setActiveSentence({
            s: "",
            sOrig: "",
            w: [0, 0],
            fileId: "",
          });
        }}
        aria-labelledby="login-modal-slide-title"
        aria-describedby="login-modal-slide-description"
      >
        <StudyCard
          content={inContent}
          doneSlide={doneSlide}
          engPosition={engPosition}
          englishFirst={englishFirst}
          fastForward={fastForward}
          finishSlides={finishSlides}
          getNext={getNext}
          isFlipped={isFlipped}
          loadingWord={loadingWord}
          redo={redo}
          resetStanding={resetStanding}
          setActiveSentence={setActiveSentence}
          setCardOpen={setCardOpen}
          setEngPosition={setEngPosition}
          setEnglishFirst={setEnglishFirst}
          setIsFlipped={setIsFlipped}
          showNextSlide={showNextSlide}
          tellEnglish={tellEnglish}
          textIdRef={textIdRef}
          title={content.title}
          wcontent={content}
          doneRef={doneRef}
        />
      </Dialog>
    );
  }
);

export default WordCardModal;
