import TermsDialog from "footer/footer.terms";
import Legal from "legal/legal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Checkbox, createStyles, makeStyles, Theme } from "@material-ui/core";

import { RootState } from "../Redux/reducers/_index";
import { UserType } from "../Redux/reducers/reducer.currentUser";
import { tx } from "../utils/globalize";
import { phaseBodyIn, phaseIn, phaseOut } from "../utils/utils";
import { ButtonBase } from "@material-ui/core";
import { firestore } from "../firebaseUtil/firebaseUtil";
import { useHistory } from "react-router-dom";
import store from "../Redux/store";
import { setBackdropOpen } from "Redux/reducers/reducer.backdropOpen";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      width: "100%",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "130%",
      marginTop: "15px",
    },
    flexleft: { flex: "0 0 50px" },
    flexright: { flex: "1 1 auto" },
    input: { marginTop: "0px" },
    spacer: { height: "10px" },
    linkType: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      color: "var(--toolbar-background)",
      cursor: "pointer",
    },
    okButton: {
      textAlign: "right",
    },
    buttonDiv: { marginTop: "15px", marginRight: "20px", textAlign: "right" },
    button: {
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      padding: "8px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginLeft: "10px",
    },
    buttonDisabled: {
      opacity: 0.5,
    },
    introTop: {
      fontWeight: 700,
      color: "var(--toolbar-background)",
      fontSize: "21px",
      marginBottom: "15px",
    },
    intro: {
      fontWeight: 400,
      fontSize: "18px",
      marginBottom: "15px",
    },
  })
);

const FirstAgreement = (props: any) => {
  const classes = useStyles();

  const history = useHistory();

  const [termsContent, setTermsContent] = useState<any>("");
  const [termsOpen, setTermsOpen] = useState(false);
  const [forward, setForward] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  const appLoaded = useSelector<RootState, any>(
    (state) => state.appTransParts.init
  );

  useEffect(() => {
    if (!appLoaded) {
      phaseBodyIn();
      phaseIn();
    }
  }, [appLoaded]);

  useEffect(() => {
    if (acceptTerms) {
      setForward(true);
    } else {
      setForward(false);
    }
  }, [acceptTerms]);

  const termsChange = (e: any) => {
    setAcceptTerms(e.target.checked);
  };

  const showTerms = () => {
    setTermsContent(<Legal language={currentUser?.language} doc="terms" />);
    setTermsOpen(true);
  };

  const closeDialog = () => {
    setTermsOpen(false);
  };

  const showPrivacy = () => {
    setTermsContent(<Legal language={currentUser?.language} doc="privacy" />);
    setTermsOpen(true);
  };

  const approveIt = () => {
    store.dispatch(setBackdropOpen(true));
    phaseOut();
    firestore
      .doc(`/users/${currentUser?.id}`)
      .update({ signed: true })
      .then(() => {
        store.dispatch(setBackdropOpen(false));
      })
      .catch((e) => {
        store.dispatch(setBackdropOpen(false));
        history.push("/login/login");
      });
  };

  return (
    <React.Fragment>
      <div className={classes.input}>
        <div className={classes.introTop}>
          {tx("justooo", "Just one more step...")}
        </div>
        <div className={classes.intro}>
          {tx(
            "acllasdd",
            "Please read and approve the terms and conditions and the privacy policies."
          )}
        </div>
        <div className={classes.flex}>
          <div className={classes.flexleft}>
            <Checkbox
              checked={acceptTerms}
              onChange={termsChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          <div className={classes.flexright}>
            {tx(
              "termsaccwer",
              "I accept the Terms and Conditions, and I agree to the storage and handling of my data by NetEnglish Solutions OÜ in accordance with its Privacy Policy. These can be read here:"
            )}{" "}
            <span onClick={showTerms} className={classes.linkType}>
              {" "}
              {tx("term1", "Terms and Conditions")}
            </span>
            {" - "}
            <span onClick={showPrivacy} className={classes.linkType}>
              {tx("terms2", "Privacy Policy")}
            </span>
          </div>
        </div>
        <div className={classes.spacer} />
        <div className={classes.buttonDiv}>
          <ButtonBase
            disabled={!forward}
            className={classes.button}
            onClick={approveIt}
            classes={{ disabled: classes.buttonDisabled }}
          >
            {tx("ocoasdntinunextsdjwelwer", "OK")}
          </ButtonBase>
        </div>

        <div id="html_element"></div>
        <TermsDialog
          open={termsOpen}
          closeDialog={closeDialog}
          help={termsContent}
          tx={tx}
        />
      </div>
    </React.Fragment>
  );
};

export default FirstAgreement;
