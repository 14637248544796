import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setStatus } from "Redux/reducers/reducer.status";
import store from "Redux/store";
import AlertDialog from "words/utils/dialogAlert";

import { IconButton } from "@material-ui/core";
import { HistoryTwoTone } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";

import { firestore } from "../../firebaseUtil/firebaseUtil";
import { updateReader } from "../words.utils";
import { setGrammar } from "Redux/reducers/reducer.grammar";

const ToolbarLoader = (props: any) => {
  const { classes, editorRef, newTitleRef } = props;
  const [alertData, setAlertData] = useState<any>({ open: false });

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const status = useSelector<RootState, string>((state) => state.status);

  const clearNew = () => {
    editorRef.current.doc.setState("");
  };

  const alertNow = useCallback(
    (alertText: string, title = "Figyelmeztetés...") => {
      setAlertData({
        text: alertText,
        okAction: () => setAlertData({ text: alertText, title, open: false }),
        title,
        open: true,
      });
    },
    []
  );

  const saveNew = useCallback(async () => {
    const text = editorRef.current.doc.getValue().trim();
    const title = newTitleRef.current;

    if (!title) {
      alertNow("Kérlek, adj egy címet a dokumentumnak!");
      return;
    }

    if (!text.trim()) {
      alertNow("A dokumentumnak nincs szövege!");
      return;
    }

    if (text.length > 10000) {
      alertNow(
        "A dokumentum legfeljebb 10000 karakter lehet. Ez most: " + text.length
      );
      return;
    }

    const newId = Date.now() + "";

    await firestore
      .doc(`/users/${currentUser?.id}/texts/${newId}`)
      .set({ id: newId, text: text, title, own: true });

    updateReader("own", [
      ...(store.getState().reader.own || []),
      { id: newId, title },
    ]);

    alertNow("Elmentettem az új dokumentumot.", "Siker");
    store.dispatch(setStatus("loader"));
  }, [currentUser, editorRef, newTitleRef, alertNow]);

  return (
    <React.Fragment>
      {status === "loader" ? (
        <React.Fragment>
          <IconButton
            className={classes.icon1}
            onClick={() => {
              store.dispatch(setStatus("history"));
              store.dispatch(setGrammar(false));
            }}
          >
            <HistoryTwoTone />
          </IconButton>
        </React.Fragment>
      ) : (
        ""
      )}
      {status === "paste" ? (
        <React.Fragment>
          <IconButton
            className={classes.icon1}
            onClick={() => store.dispatch(setStatus("loader"))}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <IconButton className={classes.icon2} onClick={saveNew}>
            <SaveIcon />
          </IconButton>
          <IconButton className={classes.icon3} onClick={clearNew}>
            <ClearIcon />
          </IconButton>
        </React.Fragment>
      ) : (
        ""
      )}
      <AlertDialog {...alertData} />
    </React.Fragment>
  );
};

export default ToolbarLoader;
