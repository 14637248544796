import TermsDialog from "footer/footer.terms";
import Legal from "legal/legal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Checkbox,
  createStyles,
  makeStyles,
  Radio,
  Theme,
} from "@material-ui/core";

import { RootState } from "../Redux/reducers/_index";
import { UserType } from "../Redux/reducers/reducer.currentUser";
import { tx } from "../utils/globalize";
import RecaptchaNote from "./signup.recaptcha";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      width: "100%",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "130%",
    },
    flexleft: { flex: "0 0 50px" },
    flexright: { flex: "1 1 auto" },
    input: { marginTop: "0px" },
    spacer: { height: "10px" },
    linkType: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      color: "var(--toolbar-background)",
      cursor: "pointer",
    },
  })
);

const Agreement = (props: any) => {
  const classes = useStyles();

  const { setButtonForward, acceptTerms, okayNL } = props;
  const [termsContent, setTermsContent] = useState<any>("");
  const [termsOpen, setTermsOpen] = useState(false);

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  useEffect(() => {
    if (okayNL && acceptTerms) {
      setButtonForward(true);
    } else {
      setButtonForward(false);
    }
  }, [okayNL, acceptTerms, setButtonForward]);

  const changeOkay = (which: number) => {
    props.setOkayNL(which);
  };

  const termsChange = (e: any) => {
    props.setAcceptTerms(e.target.checked);
  };

  const showTerms = () => {
    setTermsContent(<Legal language={currentUser?.language} doc="terms" />);
    setTermsOpen(true);
  };

  const closeDialog = () => {
    setTermsOpen(false);
  };

  const showPrivacy = () => {
    setTermsContent(<Legal language={currentUser?.language} doc="privacy" />);
    setTermsOpen(true);
  };

  return (
    <React.Fragment>
      <div className={classes.input}>
        <div className={classes.flex}>
          <div className={classes.flexleft}>
            <Radio
              checked={props.okayNL === 1}
              onChange={() => changeOkay(1)}
              value="a"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          </div>
          <div className={classes.flexright}>
            {tx(
              "yesiwant",
              "Yes, I want the coupon newsletters with upwards of 50% discounts on the paid subscription."
            )}
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.flexleft}>
            <Radio
              checked={props.okayNL === 2}
              onChange={() => changeOkay(2)}
              value="b"
              name="radio-button-demo"
              inputProps={{ "aria-label": "B" }}
            />
          </div>
          <div className={classes.flexright}>
            {tx(
              "noiwant",
              "No, I don't want the coupon newsletters. I want to pay full price. Fair is fair."
            )}
          </div>
        </div>
        <div className={classes.spacer} />
        <div className={classes.flex}>
          <div className={classes.flexleft}>
            <Checkbox
              checked={props.acceptTerms}
              onChange={termsChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          <div className={classes.flexright}>
            {tx(
              "termsaccwer",
              "I accept the Terms and Conditions, and I agree to the storage and handling of my data by NetEnglish Solutions OÜ in accordance with its Privacy Policy. These can be read here:"
            )}{" "}
            <span onClick={showTerms} className={classes.linkType}>
              {" "}
              {tx("term1", "Terms and Conditions")}
            </span>
            {" - "}
            <span onClick={showPrivacy} className={classes.linkType}>
              {tx("terms2", "Privacy Policy")}
            </span>
          </div>
        </div>
        <div className={classes.spacer} />
        <div className={classes.flex}>
          <div className={classes.flexleft}></div>
          <div className={classes.flexright}>
            <RecaptchaNote language={props.language} />
          </div>
        </div>
        <div id="html_element"></div>
        <TermsDialog
          open={termsOpen}
          closeDialog={closeDialog}
          help={termsContent}
          tx={tx}
        />
      </div>
    </React.Fragment>
  );
};

export default Agreement;
