import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: "0px",
      lineHeight: "150%",
      transition: "all 0.15s",
      opacity: 1,
    },
    buttonDiv: {
      textAlign: "center",
      marginTop: "30px",
      marginBottom: "30px",
    },
    wrapperInvisible: {
      padding: "0px",
      lineHeight: "150%",
      transition: "all 0.15s",
      opacity: 0,
    },
    open: {
      maxHeight: "3000px",
      transition: "all 0.8s",
      overflow: "hidden",
      padding: "10px",
      background: "#eee",
      border: "1px solid lightgrey",
      marginBottom: "10px",
      borderRadius: "5px",
    },
    closed: {
      maxHeight: "0px",
      transition: "all 0.8s",
      overflow: "hidden",
      marginBottom: "10px",
    },
    helpText: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      paddingBottom: "10px",
      lineHeight: "150%",
    },
    helpLinkOpen: {
      color: "blue",
      cursor: "pointer",
      height: "auto",
      transition: "all 0.4s",
      overflow: "hidden",
      marginBottom: "10px",
    },
    helpLinkClosed: {
      color: "blue",
      cursor: "pointer",
      height: "0px",
      transition: "all 0.4s",
      overflow: "hidden",
      marginBottom: "10px",
    },
    helpList: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      paddingBottom: "10px",
      lineHeight: "150%",
      textIndent: "-20px",
      paddingLeft: "20px",
    },
    helpBold: {
      fontFamily: "var(--font-bold)",
      fontSize: "18px",
      paddingBottom: "10px",
      lineHeight: "150%",
      fontWeight: "bold",
    },
    helpHeader: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      fontSize: "21px",
      paddingTop: "15px",
      paddingBottom: "20px",
      lineHeight: "100%",
      color: "var(--toolbar-background)",
    },
    helpHeaderCenter: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      fontSize: "21px",
      paddingTop: "15px",
      paddingBottom: "20px",
      lineHeight: "150%",
      color: "var(--toolbar-background)",
      textAlign: "center",
    },
    helpImg: {
      width: "100%",
      maxWidth: "400px",
    },
    helpImgSmall: {
      width: "100%",
      maxWidth: "180px",
    },
    imgDiv: { marginTop: "8px", marginBottom: "20px", textAlign: "center" },
  })
);
