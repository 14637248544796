import { auth, signOut } from "firebaseUtil/firebaseUtil";
// core components
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { tx } from "utils/globalize";
import CustomizedSnackbar from "utils/SnackBar";

import { phaseBodyOut } from "utils/utils";

// @material-ui/core components
import { Button, makeStyles, TextField, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";
import { phaseIn, phaseBodyIn, getTitle } from "../../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "20px",
      color: "black",
      fontSize: "20px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      maxHeight: "47px",
    },
    linkLike: { textDecoration: "underline", cursor: "pointer" },
    cardExtra: { background: "white" },
    logoWrapper: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "center",
      flex: "0.5 1 auto",
      transition: "all 1s",
      minHeight: "var(--logoHeight)",
    },
    placeholder: {
      flex: "0.5 1 5px",
    },
    logo: {
      marginBottom: "5px",
    },
    firm: {
      paddingBottom: "10px",
      marginTop: "0px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    textCenter: {
      textAlign: "center",
    },
    forgotPassword: {
      fontSize: "90%",
      paddingTop: "16px",
      color: "var(--toolbar-background)",
      "& a": { color: "var(--toolbar-background)" },
    },
    useful: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxHeight: "var(--vh)",
      transition: "all 1s",
    },
    copyright: {
      justifySelf: "end",
      flex: "3 1 auto",
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "var(--font-normal)",
      color: "#888888",
      fontSize: "15px",
      textAlign: "center",
      margin: "0 auto",
      paddingTop: "15px",
      lineHeight: "130%",
    },
    info: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      marginBottom: "15px",
      lineHeight: "135%",
    },
    dialogactions: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
      transition: "all 1s",
    },
    dialogstart: {
      flex: "0 0 60%",
    },
    dialogend: {
      flex: "0 0 40%",
    },
    fields: { flex: "0 0 auto", transition: "all 1s" },

    buttonStart: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      padding: "8px 0px 8px 0px",
      width: "100%",
      marginTop: "0px",
      minHeight: "40px",
      borderRadius: "3px",
      "@media (hover: hover)": {
        "&:hover": {
          background: "var(--toolbar-background-dark)",
          boxShadow: "none",
        },
      },
      "&:hover": {
        background: "var(--toolbar-background-dark)",
        boxShadow: "none",
      },
      "&:disabled": {
        color: "var(--theme-disabled)",
        border: "1px solid var(--theme-disabled)",
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:active": {
        background: "var(--toolbar-background-dark)",
        boxShadow: "none",
      },
      "&:focus": {
        background: "var(--toolbar-background-dark)",
        boxShadow: "none",
      },
      "&:focus:active": {
        background: "var(--toolbar-background-dark)",
        boxShadow: "none",
      },
    },
    buttonCancel: {
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      color: "var(--secondary-color)",
      padding: "0px 0px 14px 0px",
      marginTop: "0px",
      marginRight: "30px",
      minHeight: "40px",

      "@media (hover: hover)": {
        "&:hover": {
          color: "var(--toolbar-background-dark)",
          boxShadow: "none",
        },
      },
      "&:disabled": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "white",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "white",
        boxShadow: "none",
      },
      "&:focus:active": {
        backgroundColor: "white",
        boxShadow: "none",
      },
    },
    forgotError: {
      height: "0px",
      fontSize: "16px",
      lineHeight: "38px",
      margin: "0px auto 7px",
      fontFamily: "var(--font-normal)",
      textAlign: "left",
      color: "red",
      transition: "all 0.3s",
      "&.active": {
        transition: "all 0.3s",
        height: "45px",
      },
    },
    formControl: {
      border: "1px solid grey",
    },
    margin: {
      marginTop: theme.spacing(2),
    },
    checkbox: {
      color: "grey",
    },
    label: {
      fontSize: "14px",
      fontFamily: "var(--font-normal)",
    },
    checkboxDiv: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  })
);

const ForgottenCard = () => {
  const [snackOpen, setSnackOpen] = useState(false);
  const [mailError, setMailError] = useState("");
  const [loginEmail, setloginEmail] = React.useState("");
  const [loginEmailState, setloginEmailState] = React.useState("");
  const userEmail = useRef<HTMLInputElement>();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const loadTrans = useSelector<RootState, any>(
    (state) => state.appTransParts.init
  );

  useEffect(() => {
    signOut();
  }, []);

  useEffect(() => {
    if (!loadTrans) {
      phaseIn();
      phaseBodyIn();
    }
  }, [loadTrans]);

  useEffect(() => {
    setTimeout(() => userEmail.current?.focus(), 760);

    let thisEmail =
      location.search?.match(/email=.*/)?.[0].replace("email=", "") || "";

    thisEmail = thisEmail.replace(/%40/g, "@").replace(/%2F/g, ".");

    setloginEmail(thisEmail);
    setTimeout(() => {
      if (verifyEmail(thisEmail)) {
        setloginEmailState("success");
      } else {
        setloginEmailState("error");
      }
    }, 0);
  }, [location.search]);

  const sendMail = () => {
    setloginEmailState("pending");
    setMailError("");
    auth.languageCode = localStorage.getItem("language") || "en";
    auth
      .sendPasswordResetEmail(loginEmail)
      .then(function () {
        setSnackOpen(true);
        setTimeout(() => {
          phaseBodyOut();
          setTimeout(() => {
            history.push("/login/login?email=" + loginEmail);
          }, 300);
        }, 3000);
      })
      .catch(function (error) {
        switch (error.code) {
          case "auth/user-not-found":
            setMailError(
              tx(
                "B68AA85E-E70E-4E53-8E21-4959F8BC5CFF",
                "No user with this e-mail address"
              )
            );
            return;
          case "auth/invalid-email":
            setMailError(
              tx(
                "A62AD710-6E90-41C8-8044-3DBAAF70932C",
                "Invalid e-mail format."
              )
            );
            return;
          default:
            setMailError(
              tx("0958F9B8-A98C-46FE-9494-1EF64D80C579", "Unknown error.")
            );
            console.log(error.code);
        }
        userEmail.current?.focus();
      });
  };

  const verifyEmail = (value: any) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const checkForEnter = (event: any) => {
    if (event.key === "Enter") {
      if (loginEmailState === "success") {
        sendMail();
      }
    }
  };

  const toLogin = () => {
    phaseBodyOut();
    setTimeout(() => {
      history.push("/login/login?email=" + loginEmail);
    }, 300);
  };

  return (
    <React.Fragment>
      <div className={classes.useful}>
        <div className={classes.logoWrapper}>
          <div className={classes.logo}>
            <img style={{ width: "80px" }} alt={"seal"} src="/logo192.png" />
          </div>
          <div className={classes.firm}>{getTitle()}</div>
        </div>
        <div className={classes.placeholder}></div>
        <div className={classes.fields}>
          <div className={classes.bigtitle}>
            {tx(
              "935A7C74-EEE7-40EB-BDCA-58443BD2E41D47",
              "Request new password"
            )}
          </div>
          <div>
            <div className={classes.info}>
              {tx(
                "0C4207E7-6622-4FE8-9E50-09FB0A54sd43CBEB",
                "Enter your e-mail address, and we will send you an e-mail with a button to reset your password."
              )}
            </div>
            <div>
              <TextField
                id="email"
                label={tx("53684DA4-43FD-4C94-985347-6273B9FB7542", "E-mail")}
                fullWidth
                type="email"
                value={loginEmail}
                variant="outlined"
                inputRef={userEmail}
                onKeyDown={checkForEnter}
                onChange={(event: any) => {
                  if (verifyEmail(event.target.value)) {
                    setloginEmailState("success");
                  } else {
                    setloginEmailState("error");
                  }
                  setloginEmail(event.target.value);
                }}
              />
              {mailError ? (
                <div className={classes.forgotError}>{mailError}</div>
              ) : null}
            </div>
            <div className={classes.dialogactions}>
              <div className={classes.dialogstart}>
                <div className={classes.forgotPassword}>
                  <span className={classes.linkLike} onClick={toLogin}>
                    {tx("933FE8CEA-64B8-4BCF-A3AF-0F78016F0A13", "Login")}
                  </span>
                </div>
              </div>

              <div className={classes.dialogend}>
                <Button
                  disabled={loginEmailState !== "success"}
                  className={classes.buttonStart}
                  onClick={sendMail}
                >
                  {tx("C1C8922B-B8B1-46F3-9D8A-19127307E1E6AC", "Send")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <CustomizedSnackbar
          open={snackOpen}
          setOpen={setSnackOpen}
          text={tx(
            "asdf0898wekkkas",
            "E-mail successfully sent. Check your mailbox and follow the instructions."
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default ForgottenCard;
