import React, { useEffect } from "react";
// import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setBuyNow } from "Redux/reducers/reducer.buynow";
import { setLoading } from "Redux/reducers/reducer.loading";
import { incrementRouted } from "Redux/reducers/reducer.routed";
import { setTutorial } from "Redux/reducers/reducer.tutorial";
import store from "Redux/store";

import { signOut } from "../firebaseUtil/firebaseUtil";
import { RootState } from "../Redux/reducers/_index";
import { UserType } from "../Redux/reducers/reducer.currentUser";
import { beginsWith } from "../utils/utils";
import { loadWords, setupWords } from "../words/words.utils";
import { setupCustomization } from "./App.utils";

ReactGA.initialize("UA-163818110-1", { debug: false });
ReactGA.plugin.require("ec"); // not 'ecommerce'
ReactGA.plugin.require("ecommerce"); // not 'ecommerce'

// const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
//   autoConfig: true, // set pixel's autoConfig
//   debug: true, // enable logs
// };
// ReactPixel.init("376330536685036", undefined, options);

const checkTutorial = () => {
  if (store.getState().settings.showFirstPage) {
    store.dispatch(setTutorial(true));
  }
};

const checkBuyNow = () => {
  const currentUser = store.getState().currentUser;
  if (!currentUser) {
    return;
  }
  if (
    currentUser.subscriptionType === 0 &&
    Date.now() - currentUser.joined < 72 * 3600000
  ) {
    store.dispatch(setBuyNow(true));
  }
};

function setDocHeight() {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`);
  document.documentElement.style.setProperty("--vw", `${window.innerWidth}px`);
  if (window.innerHeight > 600) {
    document.documentElement.style.setProperty("--logoHeight", `170px`);
    document.documentElement.style.setProperty("--logoPadding", `25px`);
  } else {
    document.documentElement.style.setProperty("--logoHeight", `135px`);
    document.documentElement.style.setProperty("--logoPadding", `0px`);
  }
}

const AuthCheck = () => {
  const location = useLocation();
  const history = useHistory();
  // const posHistory = useRef<any>();

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  const authLoaded = useSelector<RootState, boolean>(
    (state) => state.authLoaded
  );

  // const position = useSelector<RootState, PositionType>(
  //   (state) => state.position
  // );

  useEffect(() => {
    if (currentUser?.ccode) {
      setupCustomization(currentUser.ccode);
    } else {
      if (currentUser) {
        setupCustomization("default");
      }
    }
  }, [currentUser]);

  useEffect(() => {
    store.dispatch(incrementRouted(null));
    // ReactPixel.pageView();
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);

  useEffect(() => {
    setInterval(checkTutorial, 3600000);
    setInterval(checkBuyNow, 1800000);
    window.addEventListener("resize", setDocHeight);
    window.addEventListener("orientationchange", setDocHeight);

    setDocHeight();
  }, []);

  const userEmail = currentUser?.email;

  useEffect(() => {
    if (userEmail) {
      checkTutorial();
      checkBuyNow();
    }
  }, [userEmail]);

  useEffect(() => {
    if (!authLoaded) {
      return;
    }

    if (location.search.includes("&token=")) {
      return;
    }

    if (beginsWith(location.pathname, ["/privacy", "/terms"])) {
      return;
    }

    if (beginsWith(location.pathname, "/login/signup")) {
      if (currentUser) {
        signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/app/agreement")) {
      if (currentUser?.signed) {
        history.push("/app/firststeps");
        return;
      }
    }

    if (beginsWith(location.pathname, "/app")) {
      if (!currentUser?.signed && currentUser?.type === "user") {
        if (location.pathname !== "/app/agreement") {
          history.push("/app/agreement");
          return;
        }
        return;
      }

      if (
        currentUser &&
        (currentUser.type === "user" ||
          currentUser.type === "contributor" ||
          currentUser.type === "admin" ||
          currentUser.type === "subadmin")
      ) {
        //
      } else {
        history.push("/login/login");
      }
      return;
    }

    if (!currentUser && !beginsWith(location.pathname, "/login")) {
      console.log("not current user");
      history.push("/login/login");
      return;
    }

    if (beginsWith(location.pathname, "/login")) {
      // signOut();
      //
      return;
    }
  }, [location, history, currentUser, authLoaded]);

  useEffect(() => {
    const setup = async () => {
      // await loadXML(
      //   position.currentLesson || "001f",
      //   store.getState().position.currentPosition || 0,
      //   "lessons",
      //   ""
      // );

      await setupWords(currentUser);
      await loadWords(currentUser);

      setTimeout(() => {
        store.dispatch(setLoading(false));
        localStorage.removeItem("ne_point");
      }, 0);
    };

    // if (
    //   position.currentLesson &&
    //   position.currentLesson !== posHistory.current?.currentLesson &&
    //   currentUser &&
    //   !(currentUser?.type === "voice")
    // ) {
    if (currentUser) {
      setup();
    }
    // }
    // posHistory.current = {
    //   currentLesson: position.currentLesson,
    // };
  }, [currentUser]);

  return <div />;
};

export default AuthCheck;
