import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const readerSlice = createSlice({
  initialState: {
    currentText: {
      text: `This is the first paragraphs of coming the whole text. There will be other paragraphs.\nThis is just a new line.\nAnd this is a third line.`,
      title: "",
      id: "",
      listId: "",
    },
    title: "",
    id: "",
    currentList: { texts: [], title: "" },
    history: [],
    own: [],
  } as any,
  name: "loading",
  reducers: {
    setReader: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setReaderOne: (state: any, action: PayloadAction<any>) => {
      state[action.payload].id = action.payload.value;
    },
  },
});

export const { setReader, setReaderOne } = readerSlice.actions;

export default readerSlice.reducer;
