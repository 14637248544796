import React from "react";
import { tx } from "../utils/globalize";
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      flex: "1 1 0px",
      marginTop: "8px",
      marginRight: "10px",
      "@media (max-width: 400px)": {
        flex: "1 1 auto",
        width: "100%",
      },
    },
  })
);

const Languages = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <FormControl className={classes.textField}>
        <InputLabel htmlFor="language">
          {tx("yourlanglal", "Your Language")}
        </InputLabel>
        <Select
          native
          id="language"
          value={props.language}
          onChange={props.handleChange}
          inputProps={{
            name: "Your language",
            id: "language",
          }}
        >
          <option value="en" label="English">
            English
          </option>
          <option value="hu" label="Magyar">
            Magyar
          </option>
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default Languages;
