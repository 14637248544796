import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setStatus } from "Redux/reducers/reducer.status";
import store from "Redux/store";

import {
  Badge,
  ButtonBase,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {
  AddTwoTone,
  DynamicFeedTwoTone,
  FormatListBulletedTwoTone,
  PersonalVideoTwoTone,
  RecordVoiceOverTwoTone,
  SpellcheckTwoTone,
  SettingsTwoTone,
} from "@material-ui/icons";
import MenuBookTwoTone from "@material-ui/icons/MenuBookTwoTone";

import { firestore } from "../firebaseUtil/firebaseUtil";
import { RootState } from "../Redux/reducers/_index";
import { tx } from "../utils/globalize";
import { phaseOut } from "../utils/utils";
import { updateReaderBulk } from "../words/words.utils";
import { isIOS } from "../settings/settings";
import { SearchTwoTone } from "@material-ui/icons";
import { setGrammar } from "Redux/reducers/reducer.grammar";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: "-20px -20px 0px -20px",
      padding: "15px 20px 20px 20px",
      // borderTopLeftRadius: "3px",
      // borderTopRightRadius: "3px",
      "@media (min-width: 821px)": {
        margin: "-35px -35px 0px -35px",
      },
    },
    levelWrapper: {
      background: "white",
      padding: "14px 20px",
      borderRadius: "5px",
      marginTop: "10px",
      marginBottom: "10px",
    },
    introText: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      fontSize: "24px",
      marginBottom: "20px",
    },
    next: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      marginBottom: "10px",
    },
    buttonDiv: { marginTop: "5px", textAlign: "right" },

    button: {
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
    },
    switch: {
      color: "white",
      marginBottom: "10px",
    },
    linkWrapper: {
      textAlign: "right",
    },
    linkDiv: {
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "8px",
      textAlign: "right",
      color: "white",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      cursor: "pointer",
    },
    white: {
      color: "white",
    },
    buttonChoice: {
      padding: "8px 5px 8px 5px",
      flex: "1 1 0",
      maxWidth: "168px",
      minWidth: "120px",
    },
    buttonChoiceEmpty: {
      flex: "1 1 0",
      maxWidth: "168px",
      minWidth: "120px",
      height: "0px",
      padding: "0px 5px 0px 5px",
    },
    but: {
      color: "var(--toolbar-background)",
      padding: "4px",
      border: "1px solid var(--toolbar-background)",
      borderRadius: "8px",
      width: "100%",
      boxShadow: "var(--toolbar-background) 1px 1px 3px 0px",
    },
    buttonFlex: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContents: "space-between",
    },
    buttonFlexEmpty: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    bflex: {
      display: "flex",
      flexDirection: "column",
      height: "100px",
      paddingTop: "10px",
      width: "100%",
    },
    icon: { flex: "1 1 auto", transformOrigin: "top" },
    iicon: { fontSize: "19px" },
    buttonText: {
      flex: "0 0 20px",
      fontSize: "15px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    badge: {},
    badgeSpan: {
      right: "5px",
      top: "10px",
      background: "var(--player-background)",
      color: "var(--toolbar-background)",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      border: "1px solid var(--toolbar-background)",
    },
    oneicon: {
      transform: "scale(2.5)",
    },
  })
);

const st = JSON.parse(localStorage.getItem("ne_stats") || "{}");

const DashBoardIntro = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  // const currentUser = useSelector<RootState, UserType>(
  //   (state) => state.currentUser
  // );

  const reader = useSelector<RootState, any>((state) => state.reader);
  const statsOne = useSelector<RootState, any>((state) => state.stats);
  const platform = useSelector<RootState, any>((state) => state.platform);

  const statBasic = st?.today || 0;

  const practiceWords = () => {
    phaseOut();
    store.dispatch(setStatus("ownwords"));
    setTimeout(() => history.push("/app/words"), 150);
  };

  const goDictionary = () => {
    phaseOut();
    store.dispatch(setStatus("wordlist"));
    setTimeout(() => history.push("/app/words"), 150);
  };

  const readText = async () => {
    if (
      reader.currentList.id === "v1" ||
      !reader.currentList.id.indexOf("gr")
    ) {
      await loadListNext("l3");
    }

    phaseOut();
    store.dispatch(setStatus("reader"));
    setTimeout(() => history.push("/app/words"), 150);
  };

  const study3000 = () => {
    phaseOut();
    store.dispatch(setStatus("origlist"));
    setTimeout(() => history.push("/app/words"), 150);
  };

  const practiceGrammar = () => {
    phaseOut();
    store.dispatch(setStatus("loader"));
    store.dispatch(setGrammar(true));
    setTimeout(() => history.push("/app/words/gr"), 150);
  };

  const goSettings = () => {
    phaseOut();
    setTimeout(() => history.push("/app/settings"), 150);
  };

  const practicePron = () => {
    phaseOut();
    setTimeout(() => history.push("/app/pron"), 150);
  };

  const practiceWriting = () => {
    phaseOut();
    setTimeout(() => history.push("/app/writer"), 150);
  };

  const watchVideos = async () => {
    if (reader.currentList.id !== "v1") {
      await loadListNext("v1");
    }

    phaseOut();
    store.dispatch(setStatus("reader"));
    setTimeout(() => history.push("/app/words"), 150);
  };

  const loadListNext = useCallback(
    async (itemId: any) => {
      let gone: any = [];

      let listId = "";
      let textId = "";

      if (itemId === "v1") {
        listId = "v1";
        textId = reader.lastVideo?.textId || "v1001";
      } else {
        listId = reader.lastText?.listId || "l1";
        textId = reader.lastText?.textId || "l1001";
      }

      if (!listId.indexOf("gr")) {
        listId = "l1";
        textId = "l1001";
      }

      gone = await firestore.doc(`/textlists/${listId}`).get();
      if (gone.data()) {
        const texts = gone.data().texts?.map((item: any) => ({
          ...item,
          done: !!reader.history.find((hist: any) => hist.id === item.id),
        }));

        let firstItem: any;

        if (!textId) {
          firstItem = texts.find((item: any) => !item.done);

          if (!firstItem) {
            firstItem = texts[0];
          }
        } else {
          firstItem = texts.find((item: any) => item.id === textId);
        }

        const firstText = (
          await firestore.doc(`/texts/${firstItem.id}`).get()
        ).data();

        updateReaderBulk({
          currentList: {
            ...gone.data(),
            texts,
            title: gone.data().title,
            id: listId,
            own: false,
          },
          currentText: {
            ...firstText,
            text: firstText?.text.replace("|", "\n"),
            list: gone.data().title,
            listId: listId,
            id: firstText?.id,
          },
        });
      }
    },
    [reader.history, reader.lastText, reader.lastVideo]
  );

  // <div className={classes.next}>
  //   {tx("yournexlkllwwekkw", "Select an option:")}
  // </div>;

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.buttonFlex}>
          <div className={classes.buttonChoice}>
            <ButtonBase onClick={practiceWords} className={classes.but}>
              <div className={classes.bflex}>
                <div className={classes.icon}>
                  <Badge
                    showZero
                    badgeContent={
                      statsOne.today === undefined ? statBasic : statsOne.today
                    }
                    className={classes.badge}
                    color={"primary"}
                    classes={{ badge: classes.badgeSpan }}
                  >
                    <FormatListBulletedTwoTone style={{ fontSize: 62 }} />
                  </Badge>
                </div>

                <div className={classes.buttonText}>
                  {tx("agata1", "My Words")}
                </div>
              </div>
            </ButtonBase>
          </div>
          <div className={classes.buttonChoice}>
            <ButtonBase onClick={readText} className={classes.but}>
              <div className={classes.bflex}>
                <div className={classes.icon}>
                  <MenuBookTwoTone style={{ fontSize: 62 }} />
                </div>
                <div className={classes.buttonText}>
                  {tx("agata2", "Reader")}
                </div>
              </div>
            </ButtonBase>
          </div>
          <div className={classes.buttonChoice}>
            <ButtonBase onClick={watchVideos} className={classes.but}>
              <div className={classes.bflex}>
                <div className={classes.icon}>
                  <PersonalVideoTwoTone style={{ fontSize: 62 }} />
                </div>
                <div className={classes.buttonText}>
                  {tx("agata3", "Videos")}
                </div>
              </div>
            </ButtonBase>
          </div>
          <div className={classes.buttonChoice}>
            <ButtonBase onClick={study3000} className={classes.but}>
              <div className={classes.bflex}>
                <div className={classes.icon}>
                  <AddTwoTone style={{ fontSize: 62 }} />
                </div>
                <div className={classes.buttonText}>
                  {tx("agata4", "Most Used")}
                </div>
              </div>
            </ButtonBase>
          </div>
          {isIOS() && platform === "web" ? (
            ""
          ) : (
            <div className={classes.buttonChoice}>
              <ButtonBase onClick={practicePron} className={classes.but}>
                <div className={classes.bflex}>
                  <div className={classes.icon}>
                    <RecordVoiceOverTwoTone style={{ fontSize: 62 }} />
                  </div>
                  <div className={classes.buttonText}>
                    {tx("agata5", "Pronunciation")}
                  </div>
                </div>
              </ButtonBase>
            </div>
          )}
          <div className={classes.buttonChoice}>
            <ButtonBase onClick={practiceWriting} className={classes.but}>
              <div className={classes.bflex}>
                <div className={classes.icon}>
                  <SpellcheckTwoTone style={{ fontSize: 62 }} />
                </div>
                <div className={classes.buttonText}>
                  {tx("agata6", "Writing")}
                </div>
              </div>
            </ButtonBase>
          </div>
          <div className={classes.buttonChoice}>
            <ButtonBase onClick={practiceGrammar} className={classes.but}>
              <div className={classes.bflex}>
                <div className={classes.icon}>
                  <DynamicFeedTwoTone style={{ fontSize: 62 }} />
                </div>
                <div className={classes.buttonText}>
                  {tx("agata7", "Grammar")}
                </div>
              </div>
            </ButtonBase>
          </div>
          <div className={classes.buttonChoice}>
            <ButtonBase onClick={goDictionary} className={classes.but}>
              <div className={classes.bflex}>
                <div className={classes.icon}>
                  <SearchTwoTone style={{ fontSize: 62 }} />
                </div>
                <div className={classes.buttonText}>
                  {tx("titlea1", "Dictionary")}
                </div>
              </div>
            </ButtonBase>
          </div>
          <div className={classes.buttonChoice}>
            <ButtonBase onClick={goSettings} className={classes.but}>
              <div className={classes.bflex}>
                <div className={classes.icon}>
                  <SettingsTwoTone style={{ fontSize: 62 }} />
                </div>
                <div className={classes.buttonText}>
                  {tx("zlxcklsdlwlw", "Settings")}
                </div>
              </div>
            </ButtonBase>
          </div>
          <div className={classes.buttonChoiceEmpty} />
          <div className={classes.buttonChoiceEmpty} />
          <div className={classes.buttonChoiceEmpty} />
          <div className={classes.buttonChoiceEmpty} />
          <div className={classes.buttonChoiceEmpty} />
          <div className={classes.buttonChoiceEmpty} />
          <div className={classes.buttonChoiceEmpty} />
          <div className={classes.buttonChoiceEmpty} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashBoardIntro;
