import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    struct: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "var(--toolbar-background)",
      flex: "1 1 33%",
    },
    number: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      fontSize: "50px",
    },
    type: {
      fontFamily: "var(--font-normal)",
      fontSize: "15px",
    },
  })
);

const DashBoardCount = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.struct}>
        <div className={classes.number}>{props.number}</div>
        <div className={classes.type}>{props.type}</div>
      </div>
    </React.Fragment>
  );
};

export default DashBoardCount;
