import React from "react";

import { useStyles } from "./00-styles";
import { th } from "utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpAndroid() {
  const classes = useStyles();

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>
          {th(
            "BBC6C8EE-C96F-46A5-9A91-6C4922B98571sAand",
            `Put NetEnglish on your home screen!`
          )}
        </div>
        <div className={classes.helpText}>
          {th(
            "88BAE731-6863-4EC1-9B68-1F382DC651C4",
            `NetEnglish is a so called web application which means you can run it from the internet in a simple browser.`
          )}
        </div>
        <div className={classes.helpText}>
          {th(
            "C0C71A5E-FA3F-4C26-B569-4FAC732B1A3F",
            `However on your phone you can create an icon for it on your main screen and run it like any other application.`
          )}
        </div>
        <div className={classes.helpText}>
          {th(
            "16AD07F4-A788-4012-89DE-BE2B4B521C0D",
            `To do this first open NetEnglish in your Chrome browser if not yet already done. (For best results it is recommended to use Chrome on Android phones.)`
          )}
        </div>
        <div className={classes.helpText}>
          {th(
            "243105D9-8ACA-4481-98D6-6A7E98553A84",
            `Load app.netenglish.com in your browser if not yet already done.`
          )}
        </div>
        <div className={classes.helpText}>
          {th(
            "62DE70C9-D10D-422A-BBB7-FF0CAF3C66FC",
            `Click on the three dots in the upper right corner in Chrome.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="and1"
            src={`/help/${lang}/and1.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "58A4549F-91F1-4FD2-8785-B0C964DF12D4",
            `Select Add to home screen from the menu appearing.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="and2"
            src={`/help/${lang}/and2.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "F26F14C3-67E9-4824-B658-560BAA496886",
            `Click on Add on the little window popping up.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="and3"
            src={`/help/${lang}/and3.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "CE00FA5C-D36A-4FC4-BC26-B2D9B4A856DD",
            `If another window pops up click on Add again.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="and4"
            src={`/help/${lang}/and4.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "DA9F0540-65B5-446F-B198-111C105B9218",
            `Close Chrome and you now have NetEnglish on your Home screen. You may have to look around to find it.`
          )}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="and5"
            src={`/help/${lang}/and5.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpText}>
          {th(
            "E9C694B9-BB49-4604-AB0B-9F9E3612E9E3",
            `You can now start your application clicking on this icon.`
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
