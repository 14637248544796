import { tx } from "../utils/globalize";

const getTimeMinutes = (count: number) => {
  if (count === 0) {
    return tx("minuteslllll0", "minute [t0]");
  }
  if (count === 1) {
    return tx("minuteslllll1", "minute [t1]");
  }

  return tx("minuteslllll", "minutes [t2]");
};

const getTimeHours = (count: number) => {
  if (count === 0) {
    return tx("hourslllwlw0", "hour [t0]");
  }
  if (count === 1) {
    return tx("hourslllwlw1", "hour [t1]");
  }

  return tx("hourslllwlw", "hours [t2]");
};

export const getStatData = (stats: any, days: number) => {
  if (!stats?.all) {
    return { done: 0, time: 0, reviews: 0 };
  }

  const dayData = stats.days || {};

  const today = new Date();

  const day = Math.floor(today.valueOf() / 1000 / 60 / 60 / 24);

  let done = 0;
  let reviews = 0;
  let time = 0;

  for (let g = 0; g < days; g++) {
    const gDay = dayData[day - g + ""] || {};
    done = done + (gDay.done || 0);
    time = time + (gDay.time || 0);
    reviews = reviews + (gDay.reviews || 0);
  }

  let units = getTimeMinutes(time);

  if (time > 35999) {
    time = Math.round(time / 60);
    units = getTimeHours(time);
  }

  return { done, time, reviews, units };
};

export const getChartData = (stats: any) => {
  const dayData = stats.days || {};

  const today = new Date();

  const day = Math.floor(today.valueOf() / 1000 / 60 / 60 / 24);

  const formattedDay = new Date(day * 86400000)
    .toLocaleDateString()
    .replace(/[./-]?\d\d\d\d[./-]?/g, "")
    .trim();

  let done = stats.all.done || 0;
  let reviews = stats.all.reviews || 0;
  let time = stats.all.time || 0;

  const weekStats: any = {
    doneCum: [{ name: formattedDay + "", uv: done }],
    reviewsCum: [{ name: formattedDay + "", uv: reviews }],
    timeCum: [{ name: formattedDay + "", uv: time }],
    done: [],
    reviews: [],
    time: [],
    indivDone: 0,
    indivReviews: 0,
    indivTime: 0,
  };

  const monthStats: any = {
    doneCum: [{ name: formattedDay + "", uv: done }],
    reviewsCum: [{ name: formattedDay + "", uv: reviews }],
    timeCum: [{ name: formattedDay + "", uv: time }],
    done: [],
    reviews: [],
    time: [],
    indivDone: 0,
    indivReviews: 0,
    indivTime: 0,
  };

  const dayStats: any = {
    doneCum: [{ name: formattedDay + "", uv: done }],
    reviewsCum: [{ name: formattedDay + "", uv: reviews }],
    timeCum: [{ name: formattedDay + "", uv: time }],
    done: [],
    reviews: [],
    time: [],
    indivDone: 0,
    indivReviews: 0,
    indivTime: 0,
  };

  for (let g = 0; g < 365; g++) {
    const gDay = dayData[day - g + ""] || {};

    weekStats.indivDone += gDay.done || 0;
    weekStats.indivReviews += gDay.reviews || 0;
    weekStats.indivTime += gDay.time || 0;

    monthStats.indivDone += gDay.done || 0;
    monthStats.indivReviews += gDay.reviews || 0;
    monthStats.indivTime += gDay.time || 0;

    const thisDay = new Date((day - g) * 86400000)
      .toLocaleDateString()
      .replace(/[./-]?\d\d\d\d[./-]?/g, "")
      .trim();

    const thisDayCumulative = new Date((day - g - 1) * 86400000)
      .toLocaleDateString()
      .replace(/[./-]?\d\d\d\d[./-]?/g, "")
      .trim();

    // day

    dayStats.doneCum.push(
      gDay.done !== undefined
        ? {
            name: thisDayCumulative,
            uv: dayStats.doneCum[g].uv - gDay.done,
          }
        : {
            name: thisDayCumulative,
            uv: dayStats.doneCum[g].uv,
          }
    );

    dayStats.reviewsCum.push(
      gDay.reviews !== undefined
        ? {
            name: thisDayCumulative,
            uv: dayStats.reviewsCum[g].uv - gDay.reviews,
          }
        : {
            name: thisDayCumulative,
            uv: dayStats.reviewsCum[g].uv,
          }
    );

    dayStats.timeCum.push(
      gDay.time !== undefined
        ? {
            name: thisDayCumulative,
            uv: dayStats.timeCum[g].uv - gDay.time,
          }
        : {
            name: thisDayCumulative,
            uv: dayStats.timeCum[g].uv,
          }
    );

    dayStats.done.push({ name: thisDay, uv: gDay.done });
    dayStats.reviews.push({ name: thisDay, uv: gDay.reviews });
    dayStats.time.push({ name: thisDay, uv: gDay.time });

    // week

    if ((g + 1) % 7 === 0) {
      const tempdCum =
        weekStats.doneCum[Math.floor(g / 7)].uv - weekStats.indivDone;

      weekStats.doneCum.push({
        name: thisDayCumulative,
        uv: tempdCum,
      });

      const temprCum =
        weekStats.reviewsCum[Math.floor(g / 7)].uv - weekStats.indivReviews;

      weekStats.reviewsCum.push({
        name: thisDayCumulative,
        uv: temprCum,
      });

      const temptCum =
        weekStats.timeCum[Math.floor(g / 7)].uv - weekStats.indivTime;

      weekStats.timeCum.push({
        name: thisDayCumulative,
        uv: temptCum,
      });

      weekStats.done.push({ name: thisDay, uv: weekStats.indivDone });

      weekStats.reviews.push({ name: thisDay, uv: weekStats.indivReviews });

      weekStats.time.push({ name: thisDay, uv: weekStats.indivTime });

      weekStats.indivDone = 0;
      weekStats.indivTime = 0;
      weekStats.indivReviews = 0;
    }

    if ((g + 1) % 30 === 0) {
      const tempdCum =
        monthStats.doneCum[Math.floor(g / 30)].uv - monthStats.indivDone;

      monthStats.doneCum.push({
        name: thisDayCumulative,
        uv: tempdCum,
      });

      const temprCum =
        monthStats.reviewsCum[Math.floor(g / 30)].uv - monthStats.indivReviews;

      monthStats.reviewsCum.push({
        name: thisDayCumulative,
        uv: temprCum,
      });

      const temptCum =
        monthStats.timeCum[Math.floor(g / 30)].uv - monthStats.indivTime;

      monthStats.timeCum.push({
        name: thisDayCumulative,
        uv: temptCum,
      });

      monthStats.done.push({ name: thisDay, uv: monthStats.indivDone });

      monthStats.reviews.push({ name: thisDay, uv: monthStats.indivReviews });

      monthStats.time.push({ name: thisDay, uv: monthStats.indivTime });

      monthStats.indivDone = 0;
      monthStats.indivTime = 0;
      monthStats.indivReviews = 0;
    }
  }

  weekStats.timeCum = weekStats.timeCum.map((item: any) => ({
    ...item,
    uv: Math.ceil(item.uv / 60),
  }));
  weekStats.time = weekStats.time.map((item: any) => ({
    ...item,
    uv: Math.ceil(item.uv / 60),
  }));

  monthStats.timeCum = monthStats.timeCum.map((item: any) => ({
    ...item,
    uv: Math.ceil(item.uv / 60),
  }));
  monthStats.time = monthStats.time.map((item: any) => ({
    ...item,
    uv: Math.ceil(item.uv / 60),
  }));

  dayStats.timeCum = dayStats.timeCum.map((item: any) => ({
    ...item,
    uv: Math.ceil(item.uv / 60),
  }));
  dayStats.time = dayStats.time.map((item: any) => ({
    ...item,
    uv: Math.ceil(item.uv / 60),
  }));

  return { dayStats, weekStats, monthStats };
};
