import AuthCheck from "app/App.authCheck";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { toggleSnack } from "Redux/reducers/reducer.snack";
import Waiter from "utils/Waiter";

import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { RootState } from "../Redux/reducers/_index";
import { SnackType } from "../Redux/reducers/reducer.snack";
import store from "../Redux/store";
import BaseDialog from "../utils/Dialogs/BaseDialog";
import FullDialog from "../utils/Dialogs/FullDialog";
import CustomizedSnackbar from "../utils/SnackBar";
import AppUi from "./ui.app";
import LoginUi from "./ui.login";
import LegalStandAlone from "legal/legal.standalone";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circle: { color: "var(--toolbar-background)" },
    backdrop: {
      width: "820px",
      display: "block",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      "@media (min-width: 821px)": {
        display: "block",
        top: "25px",
        bottom: "25px",
        left: "calc(50% - 406px)",
        right: "calc(50% - 406px)",
      },
      position: "fixed",
      backgroundColor: "white",
    },
    fixedcenter: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  })
);

const BasicUi = () => {
  const classes = useStyles();

  const backdropOpen = useSelector<RootState, any>(
    (state) => state.backdropOpen
  );

  const snack = useSelector<RootState, SnackType>((state) => state.snack);

  const setSnackOpen = (a: boolean) => store.dispatch(toggleSnack(a));

  return (
    <React.Fragment>
      <div className={classes.fixedcenter}>
        <CircularProgress
          color="primary"
          classes={{ colorPrimary: classes.circle }}
        />
      </div>
      <div id="dimmerlite" className={"mfhidden"}>
        <div id="iostop" />
        <Waiter text={backdropOpen.text} backdropOpen={backdropOpen.open} />
        <Switch>
          <Route path={`/privacy`}>
            <LegalStandAlone doc="privacy" />
          </Route>
          <Route path={`/terms`}>
            <LegalStandAlone doc="terms" />
          </Route>
          <Route path="/app">
            <AppUi />
          </Route>
          <Route path="/login">
            <LoginUi />
          </Route>
          <Route path="/">
            <Redirect to="/app/dashboard" />
          </Route>
        </Switch>
      </div>

      <BaseDialog />
      <FullDialog />
      <AuthCheck />

      <CustomizedSnackbar
        open={snack.open}
        setOpen={setSnackOpen}
        type="error"
        text={snack.text}
        anchor={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
    </React.Fragment>
  );
};

export default BasicUi;

// <div className={classes.backdrop} />
