import { functions } from "firebaseUtil/firebaseUtil";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "Redux/reducers/_index";
import { setAppTransParts } from "Redux/reducers/reducer.appTransParts";
import { setAuthLoaded } from "Redux/reducers/reducer.authLoaded";
import {
  setBackdrop,
  setBackdropOpen,
} from "Redux/reducers/reducer.backdropOpen";
import { setDialog, setDialogOpen } from "Redux/reducers/reducer.dialog";
import { setProceedAction } from "Redux/reducers/reducer.proceed";
import store from "Redux/store";

import { ButtonBase, createStyles, makeStyles, Theme } from "@material-ui/core";

import firebase, {
  signInWithEmail,
  signOut,
} from "../firebaseUtil/firebaseUtil";
import { tx } from "../utils/globalize";
import { nextExercise } from "../utils/progress/stepper";
import { loadAppTranslation } from "../utils/saver.loadTranslation";
import { phaseBodyIn, phaseBodyOut, phaseIn, phaseOut } from "../utils/utils";
import Agreement from "./signup.agreement";
import Email from "./signup.email";
import Language from "./signup.language";
import Password from "./signup.password";
import { setupCustomization } from "../app/App.utils";
import SignupIntro from "./signup.intro";
import { setTempLanguage } from "Redux/reducers/reducer.tempLanguage";
import { myLang } from "../utils/useLoadAppTrans";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyright: {
      bottom: 10,
      justifySelf: "end",
      flex: "3 1 auto",
      alignItems: "flex-end",
      fontFamily: "var(--font-normal)",
      color: "#888888",
      fontSize: "15px",
      margin: "0 auto",
      paddingTop: "15px",
      paddingBottom: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
      lineHeight: "130%",
      background: "white",
      textAlign: "center",
      left: 0,
      right: 0,
      position: "absolute",
    },
    coupon: {
      marginTop: "10px",
      marginBottom: "10px",
      textAlign: "center",
    },
    spacer: { height: "10px" },
    bigtitle: {
      paddingBottom: "30px",
      color: "var(--toolbar-background)",
      fontSize: "21px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    description: {
      paddingBottom: "20px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    buttonDiv: { marginTop: "20px", textAlign: "right" },

    button: {
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginLeft: "10px",
    },
    buttonBack: {
      background: "white",
      color: "grey",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "125%",
      padding: "12px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginLeft: "10px",
      border: "1px solid grey",
    },
    disabled: {
      opacity: 0.5,
    },
    linkLike: { textDecoration: "underline", cursor: "pointer" },
    login: {
      float: "left",
      fontSize: "90%",
      paddingTop: "16px",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      color: "var(--toolbar-background)",
      "& a": { color: "var(--toolbar-background)" },
    },
  })
);

const SignUp = (props: any) => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [password, setPassword] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [okayNL, setOkayNL] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [buttonBack, setButtonBack] = useState(false);
  const [buttonForward, setButtonForward] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [ready, setReady] = useState(false);
  const [, setCoupon] = useState("");
  const [validCoupon, setValidCoupon] = useState<any>(false);
  const location = useLocation();
  const history = useHistory();
  const [, setLanguageKnown] = useState(false);

  // const { language, setLanguage } = props;

  const language = useSelector<RootState, string>(
    (state) => state.tempLanguage
  );

  // eslint-disable-next-line
  const appTransParts = useSelector<RootState, any>(
    (state) => state.appTransParts
  );

  useEffect(() => {
    const loadCoupon = async (c: string) => {
      // store.dispatch(
      //   setBackdrop({
      //     open: true,
      //     text: tx("pvalidasdwwsdaypage", "Validating your coupon..."),
      //   })
      // );

      const couponValidate = functions.httpsCallable("couponValidate");

      let couponResult: any = {};

      try {
        couponResult = await couponValidate({
          coupon: c,
        });
      } catch (e) {
        console.log(e);
      }

      if (couponResult.data.coupon.ccode) {
        setupCustomization(couponResult.data.coupon.ccode);
        setCoupon(c);
        setValidCoupon(c);
        store.dispatch(setTempLanguage(couponResult.data.coupon.language));
        setLanguageKnown(true);
        setStep(0);
        localStorage.setItem("language", couponResult.data.coupon.language);
        const appTranslation = await loadAppTranslation(
          couponResult.data.coupon.language
        );
        store.dispatch(setAppTransParts(appTranslation));
        setReady(true);
      } else {
        alert("no valid coupon");
        let lang =
          location.search?.match(/lang=[^&]*/)?.[0].replace("lang=", "") || "";

        store.dispatch(
          setTempLanguage(
            lang || localStorage.getItem("language") || myLang() || "en"
          )
        );
      }
      setTimeout(() => {
        phaseBodyIn();
        phaseIn();
      }, 0);
    };

    let c =
      location.search?.match(/coupon=[^&]*/)?.[0].replace("coupon=", "") || "";
    if (c) {
      loadCoupon(c);
    } else {
      alert("no cupon");
    }
  }, [location.search, language]);

  useEffect(() => {
    const loadLang = async () => {
      if (language) {
        localStorage.setItem("language", language);
        const appTranslation = await loadAppTranslation(language);
        store.dispatch(setAppTransParts(appTranslation));
        setReady(true);
      }
    };

    if (store.getState().currentUser) {
      signOut();
    }

    store.dispatch(
      setProceedAction({
        action: nextExercise,
        visibleAction: false,
        actionIcon: "forward",
      })
    );

    loadLang();

    document.getElementById("ipaper")?.scrollTo(0, 0);
  }, [location.search, language]);

  useEffect(() => {
    if (
      ready &&
      !(
        location.search?.match(/coupon=[^&]*/)?.[0].replace("coupon=", "") || ""
      )
    ) {
      setTimeout(() => {
        phaseBodyIn();
        phaseIn();
      }, 0);
    }
  }, [ready, location.search]);

  useEffect(() => {
    switch (step) {
      case -1:
        setTitle(tx("welcometitle", "Welcome to NetEnglish!"));
        setDescription(tx("lkanllalal", "Select your own language."));

        setButtonBack(false);
        break;
      case 0:
        setTitle(tx("welcometitle", "Welcome to NetEnglish!"));
        setDescription(tx("lkanlwelalal", ""));
        setButtonBack(false);
        setButtonForward(true);
        break;
      case 1:
        setTitle(tx("welcometitle", "Welcome to NetEnglish!"));
        setDescription(
          tx(
            "giveemail",
            "Please provide your email address. This will be your user name."
          )
        );

        setButtonBack(true);
        break;
      case 2:
        setTitle(tx("welcometitle", "Welcome to NetEnglish!"));
        setDescription(
          tx(
            "setpass",
            "Please set a password. It should be at least 6 characters long."
          )
        );
        setButtonBack(true);
        break;
      case 3:
        setTitle(tx("welcometitle", "Welcome to NetEnglish!"));
        setDescription(
          tx(
            "choosenext",
            "Join our coupon mailing list and accept our terms of use."
          )
        );

        setButtonBack(true);
        break;
    }
  }, [step, appTransParts]);

  const content = () => {
    switch (step) {
      case -1:
        return (
          <Language
            language={language}
            setButtonForward={setButtonForward}
            nextStep={nextStep}
          />
        );
      case 0:
        return (
          <SignupIntro
            setButtonForward={setButtonForward}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <Email
            email={email}
            setEmail={setEmail}
            setButtonForward={setButtonForward}
            nextStep={nextStep}
          />
        );

      case 2:
        return (
          <Password
            password={password}
            setPassword={setPassword}
            setButtonForward={setButtonForward}
            nextStep={nextStep}
            email={email}
          />
        );
      case 3:
        return (
          <Agreement
            okayNL={okayNL}
            setOkayNL={setOkayNL}
            setButtonForward={setButtonForward}
            acceptTerms={acceptTerms}
            setAcceptTerms={setAcceptTerms}
            language={language}
          />
        );
    }
  };

  const nextStep = () => {
    if (step === 3) {
      if (!acceptTerms || !okayNL) {
        return;
      }

      recaptchaRef.current?.execute();
      return;
    }

    if (step === 1) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, "qweorasdflkalsd")
        .then((item) => {
          emailExists();
        })
        .catch((e: any) => {
          if (e.code === "auth/user-not-found") {
            setButtonForward(false);
            phaseOut();
            setTimeout(() => {
              setStep(step + 1);
              setTimeout(() => phaseIn());
            }, 150);
            return;
          }
          emailExists();
        });
      return;
    }

    if (step !== 0) {
      setButtonForward(false);
    } else {
      setButtonForward(true);
      setTimeout(() => buttonRef.current?.focus(), 0);
    }

    phaseOut();
    setTimeout(() => {
      setStep(step + 1);
      setTimeout(() => phaseIn());
    }, 150);
  };

  const stepBack = () => {
    if (step !== 3) {
      setButtonForward(false);
    } else {
      setButtonForward(true);
      setTimeout(() => buttonRef.current?.focus(), 0);
    }

    phaseOut();
    setTimeout(() => {
      setStep(step - 1);
      setTimeout(() => phaseIn());
    }, 150);
  };

  const submitNow = (token: string | null) => {
    store.dispatch(
      setBackdrop({
        open: true,
        text: tx("13pasdwwsdaypage", "Signing you up..."),
      })
    );
    const signUpUser = functions.httpsCallable("signUpUser");

    signUpUser({
      language,
      email,
      okayNL,
      acceptTerms,
      subscriptionType: 5,
      token,
      password,
      validCoupon: validCoupon || "free7days",
      expiry: Date.now(),
    }).then(function (result) {
      if (result.data.success) {
        signInWithEmail(email, password, true, {
          success: () => {
            setPassword("");
            phaseBodyOut();
            setTimeout(() => {
              store.dispatch(setBackdropOpen(false));
              store.dispatch(setAuthLoaded(false));
              history.push("/app/firststeps");
            }, 150);
          },
          failure: (error: string) => {
            setPassword("");
            store.dispatch(setBackdropOpen(false));
          },
        });
      } else {
        store.dispatch(setBackdropOpen(false));
        failedOnSignup();
      }
    });
  };

  const failedOnSignup = () => {
    const cancelDialog = () => {
      store.dispatch(setDialogOpen(false));
    };

    let iText = tx(
      "aslwolzlzlz",
      "Something went seriously wrong. There's either some network errors or you are trying to do something strange."
    );

    store.dispatch(
      setDialog({
        open: true,
        title: "",
        text: iText,
        button1Text: "",
        button2Text: tx("cancelwewedrweasdaop", "OK"),
        button1action: cancelDialog,
        button2action: cancelDialog,
      })
    );
  };

  const emailExists = () => {
    const cancelDialog = () => {
      store.dispatch(setDialogOpen(false));
    };

    let iText = tx(
      "aslwolzlzlz1",
      "A user with this e-mail address already exists."
    );

    store.dispatch(
      setDialog({
        open: true,
        title: "",
        text: iText,
        button1Text: "",
        button2Text: tx("cancelwewedrweasdaop", "OK"),
        button1action: cancelDialog,
        button2action: cancelDialog,
      })
    );
  };

  const login = () => {
    phaseBodyOut();
    setTimeout(() => {
      history.push("/login/login");
      return;
    }, 150);
  };

  return (
    <React.Fragment>
      <div className={classes.bigtitle}>{title}</div>
      <div className={classes.description}>{description}</div>
      {content()}
      <div className={classes.buttonDiv}>
        {step ? (
          ""
        ) : (
          <div className={classes.login}>
            <span className={classes.linkLike} onClick={login}>
              {tx("93FE8CEaswA-64B8-4BCF-A3AF-0F78016F0A13", "Login")}
            </span>
          </div>
        )}

        {buttonBack ? (
          <ButtonBase className={classes.buttonBack} onClick={stepBack}>
            {tx("ocoasdntinunesdxtjl", "Back")}
          </ButtonBase>
        ) : (
          ""
        )}

        <ButtonBase
          ref={buttonRef}
          className={
            classes.button + (!buttonForward ? " " + classes.disabled : "")
          }
          onClick={nextStep}
          disabled={!buttonForward}
        >
          {step === 5
            ? tx("ocoasdntinunextsdjl", "Sign Up")
            : tx("ocoasdntinunextjl", "Next")}
        </ButtonBase>

        <ReCAPTCHA
          sitekey="6LeIMqQZAAAAALLCCgSYAocK75gb7QUVsyNZLOsF"
          onChange={submitNow}
          hl="hu"
          ref={(ref) => (recaptchaRef.current = ref)}
          size="invisible"
        />
      </div>
    </React.Fragment>
  );
};

export default SignUp;
