import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HelpType {
  open: boolean;
  src: any;
  tempSrc: string;
}

const initialState: HelpType = {
  open: false,
  src: "",
  tempSrc: "cards.mp4",
};

const helpSlice = createSlice({
  initialState,
  name: "help",
  reducers: {
    setHelpSrc: (state: HelpType, action: PayloadAction<any>) => {
      state.src = action.payload;
    },
    setHelpOpen: (state: HelpType, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setHelpTemp: (state: HelpType, action: PayloadAction<string>) => {
      state.tempSrc = action.payload;
    },
  },
});

export const { setHelpSrc, setHelpOpen, setHelpTemp } = helpSlice.actions;

export default helpSlice.reducer;
