import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "Redux/reducers/_index";
import { setBookOpen, setBookView } from "Redux/reducers/reducer.bookOpen";
import { setHelpOpen, setHelpSrc } from "Redux/reducers/reducer.help";
import { setStatus } from "Redux/reducers/reducer.status";
import store from "Redux/store";

import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import HelpOutline from "@material-ui/icons/HelpOutlineTwoTone";
import MenuIcon from "@material-ui/icons/Menu";

import { updateHelpTopics } from "../firebaseUtil/firebaseUtil.firestore";
import HelpDashboard from "../help/topics/dashboard-h";
import HelpMyWords from "../help/topics/mywords-h";

// import HelpSettings from "../help/topics/24-settings";
// import HelpAccount from "../help/topics/35-account";
// import HelpProgress from "../help/topics/36-progress";

import { LessonStatusType } from "../Redux/reducers/reducer.lessonStatus";
// import HelpTip from "../utils/Dialogs/HelpTip";
import { tx } from "../utils/globalize";
import { beginsWith, phaseBodyOut, phaseOut } from "../utils/utils";
import NavMenu from "./NavMenu";
import PrimaryDrawer from "./PrimaryDrawer";
import HelpReader from "../help/topics/reader-h";
import HelpMostUsed from "../help/topics/mostused-h";
import HelpDictionary from "../help/topics/dictionary-h";
import HelpPron from "../help/topics/pronunciation-h";
import HelpVideo from "../help/topics/video-h";
import HelpGrammar from "../help/topics/grammar-h";
import { setGrammar } from "Redux/reducers/reducer.grammar";
import HelpWrite from "../help/topics/writer-h";
import { ArrowBack } from "@material-ui/icons";

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const inFrame = inIframe();

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexDiv: {
      display: "flex",
      flexDirection: "row",
    },
    menu: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    TopBar: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      height: "50px",
      background: "var(--toolbar-background)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      boxShadow: "none",
      overflow: "visible",
      position: "relative",
      color: "var(--toolbar-color)",
    },
    TopBarText: {
      padding: "15px",
      lineHeight: "110%",
      fontSize: "18px",
      paddingLeft: "20px",
      paddingRight: "0px",
      flex: "1 1 auto",
      display: "flex",
      alignItems: "center",
    },
    TopBarTextNoLeft: {
      padding: "15px",
      lineHeight: "110%",
      fontSize: "18px",
      paddingLeft: "0px",
      paddingRight: "0px",
      flex: "1 1 auto",
      display: "flex",
      alignItems: "center",
    },
    loop: {
      marginLeft: "10px",
      borderRadius: "50%",
      background: "var(--green-light)",
      color: "var(--green)",
      border: "1px solid var(--green)",
    },
    helpbutton: { color: "var(--toolbar-color)" },
    otherbutton: { color: "var(--toolbar-color)" },
    TopBarToolBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flex: "1 1 auto",
      textAlign: "right",
    },
    TopBarIconButton: {
      color: "var(--icon-color)",
    },
    TopBarDecorLine: {
      width: "0px",
      height: "3px",
      background: "var(--toolbar-background)",
      position: "absolute",
      bottom: -2.5,
      left: 0,
      transition: "all 0.5s",
    },
    sectionHeader: {
      height: "36px",
      background: "var(--header-color)",
      fontFamily: "var(--font-normal)",
      fontWeight: 400,
      fontSize: "18px",
      paddingLeft: "20px",
      paddingTop: "1px",
      lineHeight: "36px",
      borderBottom: "0.5px solid var(--line-color)",
    },
    loginHeader: {
      top: "-75px",
    },
    app: {
      maxWidth: "821px",
      "@media (min-width: 821px)": { margin: "25px auto" },
    },
    nodecor: {
      width: "0px",
    },
    noShow: {
      display: "none",
    },
    lessonFlex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
    },
    lessonNumber: {},
    lessonButton: {
      boxShadow: "none",
      padding: "0px 8px",
      borderRadius: "3px",
      fontSize: "14px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      minWidth: "0px",
      marginRight: "10px",
      marginTop: "-3px",
      textTransform: "none",
      minHeight: "27px",
    },

    up: {
      transform: "rotate(0deg)",
      transition: "all 0.3s",
    },
    down: {
      transform: "rotate(-180deg)",
      transition: "all 0.3s",
    },
    gt: {
      alignSelf: "center",
      width: "24px",
      textAlign: "center",
    },
    buttonGroup: {
      borderRadius: "3px",
      background: "#e2e2e2",
      padding: "4px",
      display: "inline-block",
      marginRight: "15px",
    },
    leftButton: {
      // boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
      padding: "2px",
      marginRight: "0px",
      width: "40px",
    },
    rightButton: {
      // boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
      padding: "2px",
      marginRight: "0px",
      width: "40px",
    },
    activeButton: {
      background: "white",
    },
    white: {
      background: "white",
    },
  })
);

export const backToGyakApp = () => {
  try {
    window.top?.postMessage("comeback", "*");
  } catch (e) {}

  try {
    window.top?.postMessage("comeback", "https://app.gyakorlatias-angol.hu");
  } catch (e) {}

  try {
    window.top?.postMessage("comeback", "https://app.gyakorlatias-angol.com");
  } catch (e) {}
};

const PrimaryAppBar = (props: any) => {
  const classes = useStyles({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [lessonMenuOpen, setLessonMenuOpen] = useState(false);
  const [subTitle] = useState("");
  const [helper, setHelper] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isAdmin =
    location.pathname.includes("editor") || location.pathname.includes("trans");

  const isLogin = location.pathname.includes("login");

  const isLesson = location.pathname.includes("/app/lesson");

  const lessonStatus = useSelector<RootState, LessonStatusType>(
    (state) => state.lessonStatus
  );

  const customizeCorporate = useSelector<RootState, any>(
    (state) => state.customize.corporate
  );

  const grammar = useSelector<RootState, boolean>((state) => state.grammar);

  const bookOpen = useSelector<RootState, any>((state) => state.bookOpen);

  const readerList = useSelector<RootState, any>(
    (state) => state.reader.currentList.id
  );

  const authLoaded = useSelector<RootState, boolean>(
    (state) => state.authLoaded
  );

  // eslint-disable-next-line
  const appTransParts = useSelector<RootState, any>(
    (state) => state.appTransParts
  );

  const status = useSelector<RootState, string>((state) => state.status);

  const bookView = useSelector<RootState, number>(
    (state) => state.bookOpen.view
  );

  useEffect(() => {
    if (
      !beginsWith(location.pathname, ["/app/lesson", "/app/help"]) &&
      authLoaded
    ) {
      setTimeout(() => setHelper(true), 150);
    } else {
      if (
        authLoaded &&
        lessonStatus.step === 0 &&
        beginsWith(location.pathname, ["/app/lesson"])
      ) {
        setHelper(true);
      } else {
        setHelper(false);
      }
    }
  }, [authLoaded, location.pathname, lessonStatus.step]);

  const toggleLessonMenu = () => {
    setLessonMenuOpen(!lessonMenuOpen);
  };

  const changeBookView = (view: number) => {
    store.dispatch(setBookView(view));
  };

  const titleBar = () => {
    if (bookOpen.open && bookOpen.view === 1) {
      return tx("95620890-8F20-46B9-A4E1-B1D75BD13D15", "Lessons");
    }

    if (bookOpen.open && bookOpen.view === 0) {
      return tx("95620890-8F20-46B9-A4E1-Bsd1D75BD13D15", "Levels");
    }

    if (location.pathname.includes("/testing")) {
      return tx("95620890-8F20-46B9-A4E1-BweweD7wewBD13D15a", "Testing");
    }

    if (location.pathname.includes("/words")) {
      switch (status) {
        case "word":
          return tx("titlea1", "Dictionary");
        case "wordlist":
          return tx("titlea2", "Dictionary");
        case "ownwords":
          return tx("titlea3", "My Vocabulary");
        case "origlist":
          return tx("titlea5", "Most Used Words");
        case "reader":
          if (readerList === "v1") {
            return tx("agata3", "Videos");
          }
          if (!readerList?.indexOf("gr")) {
            return tx("agata7", "Grammar");
          }
          return tx("titlea6", "Reader");
        case "loader":
          return tx("titlea7", "Select Subject");
        case "history":
          return tx("titlea8", "Completed Subjects");
        case "paste":
          return tx("titlea9", "New Text");
      }
    }

    if (location.pathname.includes("/quiz")) {
      return tx(
        "95620890-8F20-46B9-A4E1-BweweD7wewBD1wew3D15a",
        "Running Test"
      );
    }

    if (location.pathname.includes("/pron")) {
      return tx(
        "95620890-8F20-46B9-A4E1-BweweD7wsewBD1wew3D15a",
        "Pronunciation Drill"
      );
    }

    if (location.pathname.includes("/writer")) {
      return tx("95620890-8F20-46weBD1wew3D15a", "Writing Drill");
    }

    if (location.pathname.includes("/firststeps")) {
      return tx(
        "95620890-8F20-46B9-A4E1-BweweD7wewBD1wew3D1we5",
        "First Steps"
      );
    }

    if (location.pathname.includes("/app/success")) {
      return tx(
        "95620890-8F20-46B9-A4E1-BweweD7wewBD1wew3D1we5",
        "Successful Payment"
      );
    }

    if (location.pathname.includes("/app/nopayment")) {
      return tx(
        "95620890-8F20-46B9-A4E1-BweweD7wewBD1wew3D1we5",
        "Cancelled Payment"
      );
    }

    if (location.pathname.includes("/app/failed")) {
      return tx(
        "95620890-8F20-46B9-A4E1-BweweD7wewBD1wew3D1we5",
        "Payment Error"
      );
    }

    if (location.pathname.includes("/signup")) {
      return tx(
        "95620890-8F20-46B9-A4E1-BwsdfsesdfweD7wewBD1wew3D15",
        "Sign Up"
      );
    }

    if (location.pathname.includes("/app/pending")) {
      return tx(
        "95620890-8F20-46B9-A4E1-essdfweD7wewBD1wew3D15",
        "Pending Payment"
      );
    }

    if (location.pathname.includes("/checkout")) {
      return tx("95620890-8F20-46B9-A4E1-BwsdfseweD7wsdewBD1wew3D15", "Shop");
    }

    if (location.pathname.includes("/checkout1")) {
      return tx(
        "95620890-8F20-46B9-A4E1-BwsdfseweD7wsssdewBD1wew3D15",
        "WizzWords Checkout"
      );
    }

    if (location.pathname.includes("/dashboard")) {
      return customizeCorporate?.appName || "WizzWords";
    }

    if (location.pathname.includes("/contact")) {
      return tx(
        "935620890-8F20-46B9-A4E1-BwsdfseweD7wsssdewBD1wew3D15",
        "Contact"
      );
    }

    if (location.pathname.includes("/settings")) {
      return tx("95620890-8F20-46B9-A4E1-B1D75werwerwerBD13D15", "Settings");
    }

    if (location.pathname.includes("/agreement")) {
      return tx(
        "95620890-8F20-46B9-A4E1-B1D75wesd3D15",
        "Terms and Conditions"
      );
    }

    if (location.pathname.includes("/help")) {
      return tx("956208s90-8F20-46B9-A4E1-B1D75werwerwerBD13D15", "Help");
    }

    if (location.pathname.includes("/account")) {
      return tx("956208s90-8F20-46B9-A4E1-B1D75werwerwerBD13D15we", "Account");
    }

    if (location.pathname.includes("/report")) {
      return tx(
        "95620890-8F20-46B9-A4E1-B1D75werwerwesdrBD13D15",
        "Progress report"
      );
    }

    return (
      <React.Fragment>
        <div className={classes.lessonFlex}>
          <div className={classes.gt}>{">"}</div>
          <div>
            <Button
              variant="outlined"
              size="small"
              className={classes.lessonButton}
              endIcon={
                <ArrowDropDownIcon
                  className={lessonMenuOpen ? classes.down : classes.up}
                />
              }
              ref={buttonRef}
              onClick={toggleLessonMenu}
            >
              {subTitle}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  const closeSettings = () => {
    if (location.pathname.includes("/app/agreement")) {
      phaseBodyOut();
      phaseOut();
      setTimeout(() => {
        history.push("/login/login");
      }, 150);
      return;
    }

    if (location.pathname.includes("/app/signup")) {
      phaseBodyOut();
      phaseOut();
      setTimeout(() => {
        history.push("/login/login");
      }, 150);
      return;
    }

    if (beginsWith(location.pathname, "/app/words") && status === "word") {
      store.dispatch(setStatus("wordlist"));
      setTimeout(() => document.getElementById("xinput")?.focus(), 200);
      return;
    }

    if (
      beginsWith(location.pathname, "/app/words") &&
      status === "loader" &&
      grammar
    ) {
      phaseOut();
      setTimeout(() => {
        store.dispatch(setGrammar(false));
        history.push("/app/dashboard");
      }, 150);
      return;
    }

    if (
      beginsWith(location.pathname, "/app/words") &&
      status === "reader" &&
      grammar
    ) {
      store.dispatch(setStatus("loader"));
      return;
    }

    if (beginsWith(location.pathname, "/app/words") && status === "loader") {
      store.dispatch(setStatus("reader"));
      return;
    }

    if (beginsWith(location.pathname, "/app/words")) {
      phaseOut();
      setTimeout(() => {
        history.push("/app/dashboard");
      }, 150);
      return;
    }

    if (bookOpen.open) {
      store.dispatch(setBookOpen(false));
    } else {
      phaseOut();
    }

    if (
      store.getState().routed === 1 ||
      location.pathname.includes("/app/firststeps") ||
      location.pathname === "/app/checkout" ||
      location.pathname.includes("/app/pending") ||
      location.pathname.includes("/app/success") ||
      location.pathname.includes("/app/nopayment") ||
      location.pathname.includes("/app/failed")
    ) {
      setTimeout(() => history.push("/app/dashboard"), 150);
      return;
    }

    if (!bookOpen.open) {
      setTimeout(() => history.go(-1), 150);
    }
  };

  const instantHelp = () => {
    if (location.pathname.includes("/app/dashboard")) {
      store.dispatch(setHelpSrc(<HelpDashboard />));
      updateHelpTopics({ dashboard: 5 });
    }
    if (
      location.pathname.includes("/app/words") &&
      store.getState().status === "ownwords"
    ) {
      store.dispatch(setHelpSrc(<HelpMyWords />));
    }

    if (
      location.pathname.includes("/app/words") &&
      store.getState().status === "reader" &&
      store.getState().reader.currentList.id === "v1"
    ) {
      store.dispatch(setHelpSrc(<HelpVideo />));
    }

    if (location.pathname.includes("/app/words") && store.getState().grammar) {
      store.dispatch(setHelpSrc(<HelpGrammar />));
    }

    if (
      location.pathname.includes("/app/words") &&
      store.getState().status === "reader" &&
      store.getState().reader.currentList.id !== "v1" &&
      store.getState().reader.currentList.id.indexOf("gr")
    ) {
      store.dispatch(setHelpSrc(<HelpReader />));
    }

    if (
      location.pathname.includes("/app/words") &&
      store.getState().status === "origlist"
    ) {
      store.dispatch(setHelpSrc(<HelpMostUsed />));
    }

    if (location.pathname.includes("/app/writer")) {
      store.dispatch(setHelpSrc(<HelpWrite />));
    }

    if (
      location.pathname.includes("/app/words") &&
      (store.getState().status === "word" ||
        store.getState().status === "wordlist")
    ) {
      store.dispatch(setHelpSrc(<HelpDictionary />));
    }

    if (location.pathname.includes("/app/pron")) {
      store.dispatch(setHelpSrc(<HelpPron />));
    }

    // if (location.pathname.includes("/app/account")) {
    //   store.dispatch(setHelpSrc(<HelpAccount />));
    // }

    // if (location.pathname.includes("/app/report")) {
    //   store.dispatch(setHelpSrc(<HelpProgress />));
    // }

    // if (location.pathname.includes("/app/progress")) {
    //   store.dispatch(setHelpSrc(<HelpPronunciation />));
    // }
    store.dispatch(setHelpOpen(true));
  };

  const closer =
    beginsWith(location.pathname, "/app/") &&
    !beginsWith(location.pathname, "/app/dashboard");

  return (
    <React.Fragment>
      <div className={classes.TopBar}>
        {inFrame ? (
          <IconButton className={classes.otherbutton} onClick={backToGyakApp}>
            <ArrowBack />
          </IconButton>
        ) : null}
        <div
          className={inFrame ? classes.TopBarTextNoLeft : classes.TopBarText}
        >
          {titleBar()}
        </div>
        <div className={classes.TopBarToolBar}>
          {bookOpen.open ? (
            <React.Fragment>
              <div className={classes.buttonGroup}>
                <IconButton
                  className={classes.leftButton}
                  onClick={() => changeBookView(0)}
                  style={{ background: bookView - 1 ? "white" : "transparent" }}
                >
                  <AppsIcon />
                </IconButton>
                <IconButton
                  className={classes.rightButton}
                  style={{ background: bookView ? "white" : "transparent" }}
                  onClick={() => changeBookView(1)}
                >
                  <FormatListBulletedIcon />
                </IconButton>
              </div>
            </React.Fragment>
          ) : null}
          {helper ? (
            <IconButton className={classes.helpbutton} onClick={instantHelp}>
              {/* <HelpTip
                arrow={true}
                placement={"bottom-start"}
                classes={{ tooltip: "helpglow", arrow: "helparrow" }}
                topic={"cards"}
              > */}
              <HelpOutline />
              {/* </HelpTip> */}
            </IconButton>
          ) : null}

          {closer ? (
            <IconButton onClick={closeSettings} className={classes.otherbutton}>
              <CloseIcon />
            </IconButton>
          ) : (
            <React.Fragment>
              <IconButton
                onClick={toggleDrawer}
                className={classes.otherbutton}
              >
                <MenuIcon />
              </IconButton>
            </React.Fragment>
          )}
        </div>
        {isLesson ? (
          <div
            className={classes.TopBarDecorLine}
            style={{
              width:
                isAdmin || isLogin
                  ? "0%"
                  : ((lessonStatus.step + 1) / lessonStatus.allStep) * 100 +
                    "%",
            }}
          />
        ) : null}
      </div>
      <NavMenu
        open={lessonMenuOpen}
        setOpen={setLessonMenuOpen}
        lessonRef={buttonRef}
      />

      <PrimaryDrawer drawerOn={menuOpen} toggleDrawer={toggleDrawer} />
    </React.Fragment>
  );
};

export default PrimaryAppBar;
