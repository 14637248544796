import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 0;

const viewSlice = createSlice({
  initialState,
  name: "viewLoaded",
  reducers: {
    setView: (state: number, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setView } = viewSlice.actions;

export default viewSlice.reducer;
