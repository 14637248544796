import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const wordSlice = createSlice({
  initialState,
  name: "program",
  reducers: {
    setWords: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setWordsOne: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
    setWordsMultiple: (state: any, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setWords, setWordsOne, setWordsMultiple } = wordSlice.actions;

export default wordSlice.reducer;
