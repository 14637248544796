import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const playSpeedSlice = createSlice({
  initialState: 1,
  name: "playSpeed",
  reducers: {
    setPlaySpeed: (state: any, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setPlaySpeed } = playSpeedSlice.actions;

export default playSpeedSlice.reducer;
