import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {};

const words3000Slice = createSlice({
  initialState,
  name: "words3000",
  reducers: {
    setWords3000: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setWords3000 } = words3000Slice.actions;

export default words3000Slice.reducer;
