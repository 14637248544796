import React from "react";
import { useHistory } from "react-router-dom";
import { setDialog, setDialogOpen } from "Redux/reducers/reducer.dialog";
import { setShowFirstPage } from "Redux/reducers/reducer.settings";
import { setTutorial } from "Redux/reducers/reducer.tutorial";
import store from "Redux/store";

import { ButtonBase, createStyles, makeStyles, Theme } from "@material-ui/core";

import { updateSetting } from "../firebaseUtil/firebaseUtil.firestore";
import { tx } from "../utils/globalize";
import { phaseOut } from "../utils/utils";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: "-20px -20px 20px -20px",
      padding: "0px 10px 0px 20px",
      alignItems: "center",
      height: "50px",
      background: "var(--toolbar-background-bg)",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      display: "flex",
      flexDirection: "row",
      "@media (min-width: 821px)": {
        margin: "-35px -35px 35px -35px",
      },
    },
    button: {
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      padding: "8px 14px 8px 14px",
      fontSize: "16px",
      fontFamily: "var(--font-normal)",
      textTransform: "none",
      borderRadius: "3px",
      marginLeft: "5px",
    },
    flexleft: { flex: "1 1 auto" },
    flexright: { flex: "0 0 auto" },
  })
);

const Tutorial = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const showFirstSteps = () => {
    phaseOut();
    setTimeout(() => {
      history.push("/app/firststeps");
    }, 150);
  };

  const dismissIt = () => {
    const turnOff = () => {
      store.dispatch(setShowFirstPage(false));
      updateSetting({ showFirstPage: false });
      store.dispatch(setTutorial(false));
      store.dispatch(setDialogOpen(false));
    };

    const dismiss = () => {
      store.dispatch(setTutorial(false));
      store.dispatch(setDialogOpen(false));
    };

    store.dispatch(
      setDialog({
        open: true,
        title: "",
        text: tx(
          "wdadsasdw21",
          "The tutorials are vital for the full use of the program. We suggest you only turn off this warning if you have seen the videos! Otherwise just click Later [ui]."
        ),
        button1Text: tx("cancelwew234e", "Turn off"),
        button2Text: tx("cancelwewwedr34w3op", "Later"),
        button1action: turnOff,
        button2action: dismiss,
      })
    );
  };

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.flexleft}>
          {tx("mewerqwermbelklk", "Tutorial")}
        </div>
        <div className={classes.flexright}>
          <ButtonBase onClick={showFirstSteps} className={classes.button}>
            {tx("staasdfrtxxxx1", "SHOW")}
          </ButtonBase>
          <ButtonBase onClick={dismissIt} className={classes.button}>
            {tx("staasdfrtxxxx13ww", "LATER")}
          </ButtonBase>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tutorial;
