import React from "react";
import { updateSetting } from "../firebaseUtil/firebaseUtil.firestore";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import {
  setSettingsTheme,
  SettingsType,
} from "../Redux/reducers/reducer.settings";

// @material-ui/core components
import {
  createStyles,
  FormControl,
  makeStyles,
  Select,
  Theme,
  MenuItem,
} from "@material-ui/core";
import store from "Redux/store";
import { setCustomize } from "Redux/reducers/reducer.customize";
import { setCustomStyles } from "../utils/setCustomStyles";
import { setupCustomization } from "../app/App.utils";
import { tx } from "utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    horizontal: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "baseline",
      paddingBottom: "20px",
    },
    track: { backgroundColor: "var(--player-background) !important" },
    checked: { color: "var(--toolbar-background) !important" },
    divleft: { flex: "0 0 150px" },
    divright: { flex: "1 1 auto" },
    bigtitle: {
      paddingBottom: "10px",
      marginTop: "7px",
      color: "var(--toolbar-background)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    mainText: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      lineHeight: "135%",
      color: "black",
    },
    optionText: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      color: "#5e5e5e",
    },

    line: {
      height: "0px",
      width: "100%",
      marginLeft: "-20px",
      marginRight: "-20px",
      borderBottom: "1px solid var(--line-grey)",
      marginBottom: "18px",
    },
    drop: { width: "150px" },
  })
);

const themes: any = [
  {
    text: "default",
    value: "default",
  },
  {
    text: "Orange",
    value: "redorange",
    primaryBackground: "#E76659",
    secondaryBackground: "#FFDEBF",
    deepBackground: "#172B4D",
    primaryText: "#FFFFFF",
    font: "Raleway",
  },

  {
    text: "Lilac",
    value: "lilac",
    primaryBackground: "#6A779E",
    secondaryBackground: "#B6C3EB",
    deepBackground: "#EBD2CC",
    primaryText: "#FFFFFF",
    font: "Assistant",
  },
  {
    text: "Green Idea",
    value: "greenish",
    primaryBackground: "#287686",
    secondaryBackground: "#C79FA7",
    deepBackground: "#F6F0E7",
    primaryText: "#FFFFFF",
    font: "Bitter",
  },
  {
    text: "Deep Blue-Green",
    value: "bluegreen",
    primaryBackground: "#183A53",
    secondaryBackground: "#5C97B8",
    deepBackground: "#D1A58A",
    primaryText: "#FFFFFF",
    font: "Catamaran",
  },
  {
    text: "Elegant",
    value: "elegant",
    primaryBackground: "#003366",
    secondaryBackground: "#71B1C5",
    deepBackground: "#F0F2F3",
    primaryText: "#FFFFFF",
    font: "Exo",
  },
  {
    text: "Green",
    value: "green",
    primaryBackground: "#1C4508",
    secondaryBackground: "#29660C",
    deepBackground: "#F7E2C7",
    primaryText: "#EACA8B",
    font: "Heebo",
  },
  {
    text: "Grey Tan",
    value: "greytan",
    primaryBackground: "#908994",
    secondaryBackground: "#1C799E",
    deepBackground: "#F6F0E7",
    primaryText: "#F6F0E7",
    font: "Inter",
  },
  {
    text: "Blue Tan",
    value: "bluetan",
    primaryBackground: "#1C799E",
    secondaryBackground: "#908984",
    deepBackground: "#F6F0E7",
    primaryText: "#F6F0E7",
    font: "Maven Pro",
  },
  {
    text: "Red Brown",
    value: "redbrown",
    primaryBackground: "#B83330",
    secondaryBackground: "#908984",
    deepBackground: "#888888",
    primaryText: "#FFFFFF",
    font: "Catamaran",
  },
];

const SettingsThemes = (props: any) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const custData = {
      ...store.getState().customize,
      colors: {
        ...store.getState().customize.colors,
        ...themes.find((item: any) => item.value === event.target.value),
      },
    };

    updateSetting({
      theme: themes.find((item: any) => item.value === event.target.value),
    });

    if (event.target.value === "default") {
      store.dispatch(
        setSettingsTheme(
          themes.find((item: any) => item.value === event.target.value)
        )
      );
      setTimeout(() => {
        setupCustomization(store.getState().currentUser?.ccode);
      }, 0);
      return;
    }

    const sc = custData?.colors.secondaryBackground;
    const yiq =
      (parseInt(sc.substr(1, 2), 16) * 299 +
        parseInt(sc.substr(3, 2), 16) * 587 +
        parseInt(sc.substr(5, 2), 16) * 114) /
      1000;

    (custData || {}).colors.mailText =
      yiq >= 150 ? "rgba(0,0,0,0.7)" : "rgba(255,255,255,0.7)";
    store.dispatch(setCustomize({ ...custData }));
    localStorage.setItem("customized", JSON.stringify(custData));
    setCustomStyles(custData);
  };

  const settings = useSelector<RootState, SettingsType>(
    (state) => state.settings
  );

  return (
    <React.Fragment>
      <div className={classes.horizontal}>
        <div className={classes.divright}>
          <div className={classes.mainText}>{tx("apptheme", "App Theme")}</div>
          <div className={classes.optionText}>
            {tx("apptheme1", "Select theme to use in the Application")}
          </div>
        </div>
        <div className={classes.divleft}>
          <FormControl className={classes.drop}>
            <Select
              value={settings.theme?.value || "default"}
              onChange={handleChange}
              displayEmpty
              className={""}
              inputProps={{ "aria-label": "Without label" }}
            >
              {themes.map((item: any, index: number) => (
                <MenuItem key={index} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettingsThemes;
