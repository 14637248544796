import firebase from "firebase/app";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setWordbarOne } from "Redux/reducers/reducer.wordbar";
import store from "Redux/store";

import {
  Button,
  ButtonBase,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { firestore } from "../../firebaseUtil/firebaseUtil";
import { SettingsType } from "../../Redux/reducers/reducer.settings";
import { phaseIn, phaseOut, updateReaderBulk } from "../words.utils";
import ViewerImage from "./textViewer.image";
import ViewerMp3 from "./textViewer.mp3";
import ViewerPractice from "./textViewer.practice";
import Sentence from "./textViewer.sentence";
import ViewerTranslation from "./textViewer.translation";
import ViewerYoutube from "./textViewer.youtube";
import { tx } from "../../utils/globalize";
import { setContraction } from "Redux/reducers/reducer.contractions";
import TransDialog from "./textViewer.transdlg";

// var natural = require("natural");
// var tokenizer = new natural.WordTokenizer();
var splitter = require("sbd");
var stemmer = require("stemmer");

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sentence: { paddingBottom: "18px", lineHeight: 1.4 },
    completeDiv: {
      display: "flex",
      alignItems: "top",
    },
    completeLeft: {
      flex: "1 1 auto",
      color: "var(--primary-background)",
    },
    completeRight: {
      flex: "1 1 auto",
      textAlign: "right",
      fontSize: "16px",
      textTransform: "uppercase",
      color: "var(--primary-background)",
    },
    completeany: {},
    completeNextButton: { fontSize: "14px", textTransform: "uppercase" },
    completeText: { fontSize: "16px" },
    completeTextTop: {
      fontSize: "16px",
      paddingTop: "10px",
      textDecoration: "underline",
      color: "blue",
      cursor: "pointer",
    },
    completeDate: { color: "blue", fontSize: "16px" },
    big: {
      paddingBottom: "25px",
      paddingTop: "15px",
      fontSize: "130%",
      fontWeight: "bold",
      color: "#e76659",
    },
    small: { paddingBottom: "25px", fontSize: "115%", fontWeight: "bold" },
    wrapper: { padding: "30px 0px 30px 0px", fontSize: "18px" },
    head: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      paddingBottom: "10px",
    },
    mainTitle: {
      fontWeight: "bold",
      fontSize: "110%",
      lineHeight: "130%",
      paddingRight: "8px",
      flex: "1 1 60%",
    },
    horiz: { display: "flex", alignItems: "center" },
    next: { color: "grey" },
    subTitle: {
      fontSize: "100%",
      marginBottom: "5px",
      color: "grey",
      lineHeight: "130%",
      paddingTop: "3px",
      flex: "1 1 60%",
    },
    nextButton: {
      padding: "3px",
      marginLeft: "10px",
      border: "1px solid grey",
    },
    showPlaying: { display: "block", marginLeft: "-15px" },
    hidePlaying: { display: "none" },
  })
);

const WordsReader = React.memo((props: any) => {
  const classes = useStyles();
  const [sentences, setSentences] = useState<any[]>([]);
  const [transOpen, setTransOpen] = useState<any>(false);
  const longPressAction = useRef<any>(false);

  const currentUserLang = useSelector<RootState, any>(
    (state) => state.currentUser?.language
  );
  const reader = useSelector<RootState, any>((state) => state.reader);
  const wordbar = useSelector<RootState, any>((state) => state.wordbar);
  const settings = useSelector<RootState, SettingsType>(
    (state) => state.settings
  );
  //const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  useEffect(() => {
    if (wordbar.player?.pauseVideo) {
      wordbar.player.pauseVideo();
    }
    if (wordbar.player?.pause) {
      wordbar.player.pause();
    }

    store.dispatch(setWordbarOne({ id: "playing", value: 0 }));
  }, [reader, wordbar.player]);

  useSelector<RootState, any>((state) => state.appTransParts);

  useEffect(() => {
    store.dispatch(setContraction({}));
  }, [reader]);

  const loadListNext = useCallback(
    async (itemId: any) => {
      let gone: any = [];

      let listId = "";
      let textId = "";

      if (itemId === "v1") {
        listId = "v1";
        textId = reader.lastVideo?.textId || "";
      } else {
        listId = reader.lastText?.listId || "l1";
        textId = reader.lastText?.textId;
      }

      gone = await firestore.doc(`/textlists/${listId}`).get();
      if (gone.data()) {
        const texts = gone.data().texts?.map((item: any) => ({
          ...item,
          done: !!reader.history.find((hist: any) => hist.id === item.id),
        }));

        let firstItem: any;

        if (!textId) {
          firstItem = texts.find((item: any) => !item.done);

          if (!firstItem) {
            firstItem = texts[0];
          }
        } else {
          firstItem = texts.find((item: any) => item.id === textId);
        }

        const firstText = (
          await firestore.doc(`/texts/${firstItem.id}`).get()
        ).data();

        updateReaderBulk({
          currentList: {
            ...gone.data(),
            texts,
            title: gone.data().title,
            id: listId,
            own: false,
          },
          currentText: {
            ...firstText,
            text: firstText?.text.replace("|", "\n"),
            list: gone.data().title,
            listId: listId,
            id: firstText?.id,
          },
        });
      }
    },
    [reader.history, reader.lastText, reader.lastVideo]
  );

  useEffect(() => {
    const loadMe = async () => {
      if (!reader.currentText?.text) {
        await loadListNext("l1");
      }
      setTimeout(() => {
        const text = reader.currentText?.text?.replace(/\n{2,}/g, "\n") || "";

        setSentences(text.split("\n"));
      }, 0);
    };

    loadMe();
  }, [reader.currentText, loadListNext]);

  // useEffect(() => {
  //   if (!currentUser) {
  //     return;
  //   }
  //   if (inIframe()) {
  //     firestore
  //       .doc(`/texts/${currentUser?.ccode}`)
  //       .get()
  //       .then((res) => {
  //         store.dispatch(
  //           setReader({
  //             ...store.getState().reader,
  //             currentText: res.data() || {},
  //           })
  //         );
  //       });
  //   }
  // }, [currentUser]);

  // ? get next or prev text

  const setNextText = useCallback(
    async (id: string) => {
      phaseOut("xreader");

      // store.dispatch(setWordbarOne({ id: "player", value: null }));

      let newText: any;

      if (reader.currentList.own) {
        newText = await firestore
          .doc(`/users/${store.getState().currentUser?.id || "a"}/texts/${id}`)
          .get();
      } else {
        newText = await firestore.doc(`/texts/${id}`).get();
      }

      let add = {};

      if (reader.currentList.id === "v1") {
        add = {
          lastVideo: {
            listId: reader.currentList.id,
            textId: id,
          },
        };
      } else {
        if (reader.currentList.id.indexOf("gr")) {
          add = {
            lastText: {
              listId: reader.currentList.id,
              textId: id,
            },
          };
        }
      }

      let updateValue: any = {
        currentText: {
          ...(newText.data() || { title: "", text: "", id: "" }),
          text: (newText.data().text || "").replace("|", "\n"),
          list: reader.currentList.title,
          listId: reader.currentList.id,
          id: newText.data()?.id,
        },
        ...add,
      };

      updateReaderBulk(updateValue);
      const xreader = document.getElementById("xreader");
      if (xreader) {
        xreader.scrollTop = 0;
      }
      setTimeout(() => phaseIn("xreader"), 150);
    },
    [reader]
  );

  const completeText = () => {
    const updateValue = {
      history: firebase.firestore.FieldValue.arrayUnion({
        id: reader.currentText.id,
        title: reader.currentText.title,
        date: Math.floor(Date.now() / 86400000),
        listId: reader.currentList.id,
        cat: reader.currentList.title,
      }),
    };

    updateReaderBulk(updateValue);
  };

  const getNextText = useCallback(() => {
    const historyList = reader.history.map((item: any) => item.id);

    const notDones = reader.currentList.texts.filter(
      (item: any) =>
        !historyList.includes(item.id) &&
        reader.currentText.id !== item.id &&
        !item.title.includes("*")
    );

    let nextOne = notDones.find((item: any) => item.id > reader.currentText.id);

    if (!nextOne) {
      nextOne = notDones?.[0];
    }

    if (nextOne) {
      return (
        <ButtonBase
          className={classes.completeNextButton}
          onClick={() => setNextText(nextOne?.id)}
        >
          {tx("nextuioas", "next")}
        </ButtonBase>
      );
    }

    if (["l5", "l4", "l3", "l2", "l1", "v1"].includes(reader.currentList.id)) {
      return (
        <ButtonBase
          className={classes.completeNextButton}
          onClick={() => setNextText(nextOne?.id)}
        >
          {tx("nextlev", "Next level")}
        </ButtonBase>
      );
    }
    return "";
  }, [reader, setNextText, classes.completeNextButton]);

  let doneDate =
    reader.history.find((item: any) => item.id === reader.currentText.id)
      ?.date * 86400000;
  let finalDate = "";
  if (doneDate) {
    finalDate = new Date(doneDate).toLocaleDateString();
  }

  return (
    <React.Fragment>
      <div
        className={classes.wrapper}
        style={{ fontSize: (settings.fontSize || "18") + "px" }}
      >
        <div className={classes.head}>
          <div className={classes.horiz}>
            <div className={classes.mainTitle}>{reader.currentText.title}</div>
          </div>
          <div className={classes.horiz}>
            <div className={classes.subTitle}>{reader.currentText?.list}</div>
          </div>
        </div>
        {sentences.map((sentence, index) => {
          let cap = classes.sentence;
          if (sentence.includes("^")) {
            return (
              <ViewerTranslation
                key={index}
                splitter={splitter}
                lookupWord={props.lookupWord}
                sentence={sentence}
                index={index}
                stemmer={stemmer}
                longPressAction={longPressAction}
              />
            );
          }

          if (sentence.substr(0, 1) === ":") {
            if (sentence.substr(1, 8) === "youtube:") {
              return (
                <ViewerYoutube
                  key={index}
                  src={sentence.substr(9)}
                  index={index}
                  videoPlayer={props.videoPlayer}
                />
              );
            }
            if (sentence.substr(1, 4) === "img:") {
              return <ViewerImage key={index} src={sentence.substr(5)} />;
            }

            if (sentence.substr(1, 4) === "mp3:") {
              return (
                <ViewerMp3
                  key={index}
                  src={sentence.substr(5)}
                  videoPlayer={props.videoPlayer}
                />
              );
            }

            if (sentence.substr(0, 5) === ":big:") {
              cap = classes.big;
              sentence = sentence.substr(5);
            }

            if (sentence.substr(0, 7) === ":small:") {
              cap = classes.small;
              sentence = sentence.substr(7);
            }

            if (sentence.substr(0, 10) === ":practice:") {
              return (
                <ViewerPractice
                  key={index}
                  src={sentence.substr(10)}
                  sentences={sentences}
                  index={index}
                  getPractice={props.getPractice}
                />
              );
            }
          }
          return (
            <div key={index} className={cap}>
              <Sentence
                splitter={splitter}
                lookupWord={props.lookupWord}
                sentence={sentence}
                index={index}
                stemmer={stemmer}
                longPressAction={longPressAction}
              />
            </div>
          );
        })}
        <hr />
        <div className={classes.completeDiv}>
          <div className={classes.completeLeft}>
            {finalDate ? (
              <React.Fragment>
                <div className={classes.completeText}>
                  {tx("dala21", "First completed:")}
                </div>{" "}
                <div className={classes.completeDate}>{finalDate}</div>
              </React.Fragment>
            ) : (
              ""
            )}
            {currentUserLang === "hu" && reader.currentList.id !== "v1" ? (
              <div
                onClick={() => setTransOpen(true)}
                className={classes.completeTextTop}
              >
                Fordítás
              </div>
            ) : null}
          </div>

          <div className={classes.completeRight}>
            {finalDate ? (
              getNextText()
            ) : (
              <React.Fragment>
                <Button onClick={completeText}>{tx("dala23", "Done")}</Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <TransDialog
        open={transOpen}
        setOpen={setTransOpen}
        translated={reader?.currentText?.hu}
      />
    </React.Fragment>
  );
});

export default WordsReader;

// onMouseDown = { onKeyDown };
// onMouseUp = { onKeyUp };
// onTouchStart = { onKeyDown };
// onTouchEnd = { onKeyUp };
// onTouchMove = { onTouchMove };

//  const onKeyDown = useCallback(
//    (e: any) => {
//      e.persist();
//      longPressAction.current = false;
//      if (!settings.playing) {
//        return;
//      }

//      if (timer.current) {
//        clearTimeout(timer.current);
//        timer.current = null;
//      }

//      timer.current = setTimeout(() => {
//        longPressAction.current = true;
//        const speakDiv = (div: HTMLElement) => {
//          // @ts-ignore
//          responsiveVoice.speak(div.textContent, settings.voice, {
//            rate: settings.readSpeed,
//            onend: () => {
//              const div = divPlayed.current?.nextElementSibling;

//              if (div) {
//                speakDiv(div as HTMLDivElement);
//                divPlayed.current = div as HTMLDivElement;
//              }
//            },
//          });
//        };

//        // @ts-ignore
//        if (responsiveVoice.isPlaying()) {
//          // @ts-ignore
//          responsiveVoice.cancel();
//          return;
//        }

//        speakDiv(e.target.closest("div"));
//        divPlayed.current = e.target.closest("div");
//      }, 600);
//    },
//    [settings]
//  );

//  const onKeyUp = useCallback(
//    (e: any) => {
//      if (!settings.playing) {
//        return;
//      }

//      if (timer.current) {
//        clearTimeout(timer.current);
//      }
//    },
//    [settings.playing]
//  );

//  const onTouchMove = (e: any) => {
//    if (timer.current) {
//      clearTimeout(timer.current);
//      longPressAction.current = false;
//    }
//  };
