import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string[] = ["", ""];

const origXMLSlice = createSlice({
  initialState,
  name: "origXML",
  reducers: {
    setOrigXML: (state: string[], action: PayloadAction<string[]>) => {
      return action.payload;
    }
  }
});

export const { setOrigXML } = origXMLSlice.actions;

export default origXMLSlice.reducer;
