import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LessonStatusType {
  lessonNumber: string;
  seq: string;
  lessonTitle: string;
  allStep: number;
  step: number;
  review?: string;
  summary?: string;
  words?: string;
  browsing?: boolean;
}

const initialState: LessonStatusType = {
  lessonNumber: "A1",
  seq: "",
  lessonTitle: "",
  allStep: 0,
  step: 0,
  review: "",
  summary: "",
  words: "",
  browsing: false,
};

const lessonStatusSlice = createSlice({
  initialState,
  name: "lessonStatus",
  reducers: {
    setLessonStatus: (
      state: LessonStatusType,
      action: PayloadAction<LessonStatusType>
    ) => {
      return action.payload;
    },
    setLessonStatusStep: (
      state: LessonStatusType,
      action: PayloadAction<number>
    ) => {
      state.step = action.payload;
    },
    setLessonStatusAllStep: (
      state: LessonStatusType,
      action: PayloadAction<number>
    ) => {
      state.allStep = action.payload;
    },
    setLessonStatusBrowsing: (
      state: LessonStatusType,
      action: PayloadAction<boolean>
    ) => {
      state.browsing = action.payload;
    },
  },
});

export const {
  setLessonStatus,
  setLessonStatusStep,
  setLessonStatusAllStep,
  setLessonStatusBrowsing,
} = lessonStatusSlice.actions;

export default lessonStatusSlice.reducer;
