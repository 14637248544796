import React from 'react';
import { useSelector } from 'react-redux';
import { setStatus } from 'Redux/reducers/reducer.status';
import store from 'Redux/store';

import { IconButton } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { RootState } from '../../Redux/reducers/_index';

const ToolbarDictionary = (props: any) => {
  const { classes, nextBack, setQuery, setWordResult } = props;

  const status = useSelector<RootState, string>((state) => state.status);

  const goBackTo = () => {
    setQuery("");
    setWordResult({});
    store.dispatch(setStatus(nextBack || "ownwords"));
  };

  return (
    <React.Fragment>
      {status === "wordlist" || status === "word" ? (
        <IconButton className={classes.dicIconBlack} onClick={goBackTo}>
          <KeyboardBackspaceIcon />
        </IconButton>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default ToolbarDictionary;
