import firebase from "firebase/app";
import React from "react";
import { setReader } from "Redux/reducers/reducer.reader";
import { setWords3000 } from "Redux/reducers/reducer.words3000";
import store from "Redux/store";

import { firestore } from "../firebaseUtil/firebaseUtil";
import { tx } from "../utils/globalize";

export const blist: any = {
  "n.": tx("gsnn", "noun"),
  "v.": tx("gsnv", "verb"),
  "adj.": tx("gsna", "adjective"),
  "adjn.": tx("gsna", "adjective"),
  "det.": tx("gsnp", "pronoun"),
  "pron.": tx("gsnp1", "pronoun"),
  "prep.": tx("gsnpr", "preposition"),
  "adv.": tx("gsnad", "adverb"),
  "exclam.": tx("gsnex", "exclamation"),
  number: tx("gsnumber", "number"),
};

export const formatWord = (wordid: string, classes: any, ipaIn: any = "") => {
  if (!wordid) {
    return "";
  }
  const [wordPart, , ipa] = wordid.split("*");
  const [word] = wordPart.split("+");
  // const partOfSentence = blist[part];
  // let wording: any;
  let additional: any;

  const forms = word.split("/");
  if (forms.length > 1) {
    // wording = classes.wordingBig;
    additional = (
      <div className={classes.additional}>{forms.slice(1).join(" / ")}</div>
    );
  } else {
    // wording = classes.wording;
  }

  return (
    <div className={classes.legend}>
      {additional}
      <div className={classes.ipa}>{ipaIn || ipa}</div>
    </div>
  );
};

//

export const getWordNoPOS = (wordid: string) => {
  if (!wordid) {
    return "";
  }
  const [wordPart] = wordid.split("*");
  const [word] = wordPart.split("+");
  // const partOfSentence = blist[part];
  const forms = word.split("/");
  return (
    <React.Fragment>
      <div className={"xtitle"}>{forms[0]}</div>
    </React.Fragment>
  );
};
//

export const getWordWithTrans = (
  wordid: string,
  classes: any,
  d: string = ""
) => {
  if (!wordid) {
    return "";
  }
  const [wordPart] = wordid.split("*");
  const [word] = wordPart.split("+");
  // const partOfSentence = blist[part];
  const forms = word.split("/");
  return (
    <React.Fragment>
      <div className={classes.titleblue}>{forms[0]}</div>
      <div className={classes.titlepos}>{d}</div>
    </React.Fragment>
  );
};

export const getWordWithPOS = (
  wordid: string,
  classes: any,
  d: string = ""
) => {
  if (!wordid) {
    return "";
  }
  const [wordPart] = wordid.split("*");
  const [word, part] = wordPart.split("+");
  const partOfSentence = blist[part];
  const forms = word.split("/");
  return (
    <React.Fragment>
      <div className={classes.title}>{forms[0].replace(/%%%/g, "/")}</div>
      <div className={classes.titlepos}>{partOfSentence}</div>
    </React.Fragment>
  );
};
// <span className="xpos">{"(" + partOfSentence + ") " + num}</span>

export const sortWords = (wordList: any[]) => {
  const wordData = store.getState().words;

  const doneList = wordList.filter(
    (item: any) => wordData[item.id]?.done === 50000 * 86400
  );
  const timeList = wordList.filter(
    (item: any) =>
      wordData[item.id]?.done && wordData[item.id]?.done < 50000 * 86400
  );
  const alphaList = wordList.filter((item: any) => !wordData[item.id]?.done);

  timeList.sort(
    (a: any, b: any) =>
      (wordData[a.id]?.done || wordData[a.id] || 0) -
      (wordData[b.id]?.done || wordData[b.id] || 0)
  );

  doneList.sort(
    (a: any, b: any) => (wordData[b.id].id || 0) - (wordData[a.id].id || 0)
  );

  alphaList.sort((a: any, b: any) => {
    if (a.word.toLowerCase() > b.word.toLowerCase()) {
      return 1;
    } else {
      return -1;
    }
  });

  return [...doneList, ...timeList, ...alphaList];
};

const getAddedWords = () => {
  const wordList = store.getState().words;
  const words: any[] = Object.keys(wordList);
  let newWords: any[] = [];

  words.forEach((item: any) => {
    if (item.includes("-")) {
      newWords.push(wordList[item]);
    }
  });

  return newWords;
};

export const loadWords = async (user: any, level = "") => {
  const wordsUpdate = localStorage.getItem("ne_wordsupdate" + user?.id + level);

  const lang = store.getState().currentUser?.language || "hu";

  if (wordsUpdate) {
    const updated = await firestore
      .doc("/wordlists/" + lang + level + "_time")
      .get();

    if (updated.data()?.updated <= parseInt(wordsUpdate)) {
      let words = JSON.parse(
        localStorage.getItem("ne_words" + user?.id + level) || "{}"
      );

      words = [...sortWords(words)];

      store.dispatch(setWords3000({ words }));
      return;
    }
  }

  const words = await firestore.doc("/wordlists/" + lang + level).get();

  localStorage.setItem(
    "ne_wordsupdate" + user?.id + level,
    words.data()?.updated
  );
  localStorage.setItem(
    "ne_words" + user?.id + level,
    JSON.stringify(words.data()?.words)
  );

  const finalWords = [
    ...sortWords([...words.data()?.words, ...getAddedWords()]),
  ];

  store.dispatch(
    setWords3000({
      words: finalWords || [],
    })
  );
};

export const updateWords = async (
  wordId: string,
  value: any,
  doneCount = 0,
  reviewCount = 0
) => {
  const currentUser = store.getState().currentUser;

  if (currentUser?.updated) {
    await firestore
      .doc(`/users/${currentUser?.id}/data/words`)
      .set({ wordRaw: { [wordId]: value } }, { merge: true });
  } else {
    await firestore
      .doc(`/users/${currentUser?.id}/data/words`)
      .set({ [wordId]: value }, { merge: true });
  }

  const today = new Date();
  const day = Math.floor(today.valueOf() / 1000 / 60 / 60 / 24) + "";

  const dayTimeDone = `days.${day}.done`;
  const dayTimeReviews = `days.${day}.reviews`;

  if (doneCount || reviewCount) {
    firestore
      .doc(`/users/${currentUser?.id}/data/stats`)
      .update({
        "all.done": firebase.firestore.FieldValue.increment(doneCount),
        "all.reviews": firebase.firestore.FieldValue.increment(reviewCount),
        [dayTimeDone]: firebase.firestore.FieldValue.increment(doneCount),
        [dayTimeReviews]: firebase.firestore.FieldValue.increment(reviewCount),
        stats: store.getState().stats,
      })
      .catch((e) => {
        console.log(e);
        if (e.code === "not-found") {
          firestore.doc(`/users/${currentUser?.id}/data/stats`).set(
            {
              days: {
                [day]: {
                  done: firebase.firestore.FieldValue.increment(doneCount),
                  reviews: firebase.firestore.FieldValue.increment(reviewCount),
                },
              },
              all: {
                done: firebase.firestore.FieldValue.increment(doneCount),
                reviews: firebase.firestore.FieldValue.increment(reviewCount),
              },
            },
            { merge: true }
          );
        }
      });
  }
};

export const updateSetting = async (wordId: string, value: any) => {
  const currentUser = store.getState().currentUser;
  await firestore
    .doc(`/users/${currentUser?.id}/data/settings`)
    .set({ [wordId]: value }, { merge: true });
};

export const updateReader = async (wordId: string, value: any) => {
  const currentUser = store.getState().currentUser;
  await firestore
    .doc(`/users/${currentUser?.id}/data/texts`)
    .set({ [wordId]: value }, { merge: true });
};

export const sentenceFormat = (s: any) => {
  if (typeof s.s !== "string" || !s.w || s.w[0] === -1) {
    return s.s;
  }

  return s.s
    .replace(/\s{2,}/g, " ")
    .split(" ")
    .map((word: string, index: number) => {
      if (index === s.w[2]) {
        return (
          <React.Fragment key={index}>
            <span className="cword">{word}</span>{" "}
          </React.Fragment>
        );
      } else {
        return <span key={index}>{word + " "}</span>;
      }
    });
};

export const updateReaderBulk = async (obj: any) => {
  const currentUser = store.getState().currentUser;
  await firestore
    .doc(`/users/${currentUser?.id}/data/texts`)
    .set(obj, { merge: true });
};

export const updateWordsDelete = async (wordId: string) => {
  const currentUser = store.getState().currentUser;

  if (currentUser?.updated) {
    const words = JSON.parse(JSON.stringify(store.getState().words));
    delete words[wordId];

    await firestore.doc(`/users/${currentUser?.id}/data/words`).set(
      {
        wordRaw: { ...words },
      },
      { merge: false }
    );
  } else {
    await firestore.doc(`/users/${currentUser?.id}/data/words`).update({
      [wordId]: firebase.firestore.FieldValue.delete(),
    });
  }
};

// export const updateSetting = async (setting: any) => {
//   const currentUser = store.getState().currentUser;

//   await firestore
//     .doc(`/users/${currentUser?.id}/data/settings`)
//     .update(setting);
// };

export const phaseOut = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.add("mfhidden");
};

export const phaseIn = (div: string = "mainFrame") => {
  document.getElementById(div)?.classList.remove("mfhidden");

  document.getElementById("ipaper")?.scrollTo(0, 0);
};

let readerListener: any = null;

export const setupWords = async (userRef: any) => {
  // setup reader

  const readerRef = firestore.doc(`/users/${userRef.id}/data/texts`);

  readerListener = readerRef.onSnapshot(async (snapShot) => {
    let texts = snapShot.data();

    if (!texts?.currentText) {
      const text = await firestore.doc(`/texts/l1001`).get();
      const list = await firestore.doc(`/textlists/l1`).get();
      let initialState: any = {
        currentText: {
          ...(text.data() || {}),
          listId: list.data() || "",
        },
        title: text.data()?.title || "",
        id: text.data()?.id,
        currentList: list.data() || { texts: [], title: "", id: "" },
        history: [],
        own: [],
        lastText: { listId: "l1", textId: "l1001" },
      };

      texts = initialState;
      firestore.doc(`/users/${userRef.id}/data/texts`).set(texts as any);
    }

    store.dispatch(setReader({ ...texts, done: true }));
  });
};

export const removeReaderListener = () => {
  if (readerListener) {
    readerListener = readerListener();
  }
};

export const formatTime = (time: number) => {
  time = Math.round(time);
  const minutes = Math.floor(time / 60) + "";
  const seconds = (time % 60) + "";
  return minutes + ":" + seconds.padStart(2, "0");
};

export const clean = (c: string) => {
  return encodeURIComponent(c.replace(/\./g, "xzx").replace(/\*/g, "xzz"));
};

export const declean = (c: string) => {
  return decodeURIComponent(c);
};

export const getDictionary = (lang: string, word: string) => {
  switch (lang) {
    case "hu":
      return `http://szotar.sztaki.hu/search?fromlang=eng&tolang=hun&searchWord=${encodeURI(
        word
      )}&langcode=hu`;
    case "it":
      return `https://en.pons.com/translate/english-italian/${encodeURI(
        word
      )}/`;
    case "es":
      return `https://en.pons.com/translate/english-spanish/${encodeURI(word)}`;
    case "pl":
      return `https://en.pons.com/translate/english-polish/${encodeURI(word)}`;
    default:
      return `http://szotar.sztaki.hu/search?fromlang=eng&tolang=hun&searchWord=${encodeURI(
        word
      )}&langcode=hu`;
  }
};
