import React, { useState } from "react";

import { useStyles } from "./00-styles";
// import { th } from "utils/globalize";
import { tx } from "../../utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducers/_index";

export default function HelpMyWords() {
  const classes = useStyles();
  const [open1, setOpen1] = useState(false);

  const lang = useSelector<RootState, any>((state) => state.tempLanguage);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.helpHeader}>{tx("azarl10", `Word List`)}</div>
        <div className={classes.helpBold}>
          {tx("azarl11", `What's this whole thing?`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarl12",
            `This is where the words go when they are marked for study.`
          )}{" "}
          {tx("azarl13", `You can see them categorized by date of selection.`)}{" "}
          {tx("azarl14", `You can also see two numbers next to the date.`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mywordsnum.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl15",
            `a) This is the number of words already marked as known which were first selected for study on the given date.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl16",
            `b) This is the number of words still marked for study which were first selected for study on the given date.`
          )}
        </div>
        <div className={classes.helpBold}>{tx("azarl17", `The words`)}</div>
        <div className={classes.helpText}>
          {tx("azarl18", `What the background color means?`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/mywordscol.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl19",
            `a) Green background means that the word is considered known, and you decided that you know it.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl20",
            `b) Pink background means that the word is selected for repetition today.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl21",
            `c) Yellow background means that even though you're still learning the word the word is not selected for repetition today but will come up later.`
          )}
        </div>
        <div className={classes.helpBold}>{tx("azarl22", `Buttons`)}</div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/buttonshow.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx("azarl23", `a) This marks the word as completed (known).`)}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl24",
            `b) The word is added to the list of words to repeat TODAY.`
          )}
        </div>
        <div className={classes.helpBold}>
          {tx("azarl25", `Data about the words`)}
        </div>
        <div className={classes.helpText}>
          {tx(
            "azarl26",
            `If you click on any of the words, you will see all data about the word.`
          )}
        </div>
        <div
          onClick={() => setOpen1(true)}
          className={open1 ? classes.helpLinkClosed : classes.helpLinkOpen}
        >
          {tx("azark18", `See details...`)}
        </div>
        <div className={open1 ? classes.open : classes.closed}>
          <div className={classes.helpText}>
            {tx("azark19", `The content of this dialog is self-explanatory.`)}{" "}
            {tx(
              "azark20",
              `If you click on one of the small speakers, the application will read aloud the selected sentence.`
            )}
          </div>
          <div className={classes.imgDiv}>
            <img
              alt="card35"
              src={`/help/${lang}/worddata.png`}
              className={classes.helpImg}
            />
          </div>
          <div className={classes.helpText}>
            {tx(
              "azark20a",
              `At the bottom of the dialog there are some additional options:`
            )}
          </div>
          <div className={classes.helpList}>
            {tx("azark21", `a) Learning words with practice cards.`)}{" "}
            {tx("azark22", `(See instructions under My Words.)`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark22a",
              `b) It translates the current sentence with Google Translate.`
            )}{" "}
            {tx("azark23", `A new window opens with the translation.`)}{" "}
            {tx("azark24", `When you are done close this window.`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark25",
              `c) You can look up the current word in a bigger dictionary.`
            )}{" "}
            {tx("azark26", `A new window opens with the bigger dictionary.`)}{" "}
            {tx("azark27", `When you are done close this window.`)}
          </div>
          <div className={classes.helpList}>
            {tx(
              "azark28",
              `d) You add the word to your vocabulary list to study and repeat later.`
            )}
          </div>
        </div>
        <div className={classes.helpBold}>
          {tx("azarl55", `The buttons at the bottom:`)}
        </div>
        <div className={classes.imgDiv}>
          <img
            alt="card35"
            src={`/help/${lang}/ownwordsbuttons.png`}
            className={classes.helpImg}
          />
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl45",
            `a) If you click here you can hide or show the words still being
          learned.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl46",
            `b) If you click here you can hide or show the words already marked as known.`
          )}
        </div>
        <div className={classes.helpList}>
          {tx(
            "azarl47",
            `c) If you click here you can start the word study process showing the practice cards.`
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
