import React from "react";
import store from "Redux/store";

import { ButtonBase, createStyles, makeStyles, Theme } from "@material-ui/core";

import { updateWords } from "../words.utils";
import { tx } from "../../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ipa: {
      fontFamily: "var(--font-normal)",
      fontSize: "14px",
      display: "block",
      paddingLeft: "5px",
    },
    pos: {
      fontFamily: "var(--font-normal)",
      fontSize: "12px",
      paddingTop: "2px",
      paddingLeft: "5px",
    },
    additional: {
      fontFamily: "var(--font-normal)",
      fontSize: "16px",
      paddingLeft: "5px",
    },
    mainWord: { marginBottom: "1px" },
    square: {
      marginLeft: "auto",
      marginBottom: "20px",
      marginRight: "auto",
      position: "relative",
      width: "100%",
      paddingTop: "20px",
      borderRadius: "16px",
      transition: "all 0.15s",
      paddingBottom: "100%",
      maxHeight: "280px",
      "@media (min-width: 490px)": {
        width: "calc(var(--vh)/2)",
        height: "calc(var(--vh)/2)",
        paddingBottom: "0px",
        minWidth: "300px",
        minHeight: "300px",
      },
      userSelect: "none",
      "&.iwhite": {
        backgroundColor: "white",
        border: "1px solid var(--toolbar-background)",
        transition: "all 0.15s",
      },
    },
    wording: {
      position: "absolute",
      top: "-65px",
      color: "var(--toolbar-color)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      textAlign: "left",
    },
    wordingBig: {
      position: "absolute",
      top: "-80px",
      color: "var(--toolbar-color)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      textAlign: "left",
    },
    moving: {
      position: "absolute",
      bottom: "-50px",
      right: "10px",
    },
    result: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      fontSize: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      justifyContent: "center",
      width: "fit-content",
      minWidth: "270px",
    },
    remaining: {
      color: "#9B9B9B",
      textAlign: "center",
    },
    remaining0: {
      color: "#9B9B9B",
      paddingRight: "35px",
    },
    todo: {
      color: "var(--green)",
      flex: "0 0 100px",
    },
    buttonDiv: {
      width: "100%",
    },
    pendingDiv: {
      width: "100%",
      textAlign: "center",
      fontFamily: "var(--font-normal)",
      fontWeight: 500,
      fontSize: "18px",
      color: "var(--toolbar-background)",
      paddingBottom: "25px",
    },
    buttonDivPending: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      marginBottom: "15px",
    },
    button33: {
      flex: "1 1 auto",
    },
    buttons: {
      position: "absolute",
      width: "100%",
      left: 0,
      right: 0,
      top: 20,
    },
    button: {
      color: "var(--toolbar-background)",
      background: "white",
      borderRadius: "3px",
      border: "1px solid var(--toolbar-background)",
      padding: "5px",
      marginBottom: "20px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      textTransform: "uppercase",
      width: "100%",
    },
    buttonknow: {
      color: "var(--toolbar-background)",
      background: "white",
      borderRadius: "3px",
      border: "1px solid var(--toolbar-background)",
      padding: "13px",
      marginBottom: "20px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      textTransform: "uppercase",
      width: "100%",
    },
    buttonDefault: {
      background: "var(--toolbar-background)",
      color: "var(--toolbar-color)",
      borderRadius: "3px",
      border: "1px solid var(--toolbar-background)",
      padding: "15px 22px 15px 22px",
      marginBottom: "20px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      textTransform: "uppercase",
      width: "100%",
    },
    bigtitle: {
      textAlign: "center",
      paddingTop: "25px",
      marginTop: "7px",
      color: "var(--toolbar-color)",
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
  })
);

const CardFinalResult = (props: any) => {
  const classes = useStyles();

  const newWordDelay = (add: number) => {
    // phaseOut();

    const thisDate = Math.floor(Date.now() / 1000);

    const thisWord = store.getState().words[props.wcontent.id];

    let easiness = thisWord.ef;
    let repetitions = thisWord.r;
    let interval = thisWord.i;

    let nextAdd = 1;

    add = (add * 5) / 4;

    if (add < 2) {
      repetitions = 0;
      nextAdd = 1;
    } else if (repetitions === 0) {
      easiness = easiness + (0.1 - (5 - add) * (0.08 + (5 - add) * 0.02));
      if (easiness < 1.3) {
        easiness = 1.3;
      }
      repetitions = 1;
      nextAdd = 1;
    } else if (repetitions === 1) {
      easiness = easiness + (0.1 - (5 - add) * (0.08 + (5 - add) * 0.02));
      if (easiness < 1.3) {
        easiness = 1.3;
      }
      repetitions = 2;
      nextAdd = 6;
    } else if (repetitions > 1) {
      easiness = easiness + (0.1 - (5 - add) * (0.08 + (5 - add) * 0.02));
      nextAdd = Math.ceil(interval * easiness);
      repetitions++;
    }

    if (thisWord.done <= thisDate) {
      updateWords(
        props.wcontent.id,
        {
          done: thisDate + nextAdd * 86400,
          ef: easiness,
          r: repetitions,
          i: nextAdd,
        },
        0,
        1
      );
    }

    setTimeout(async () => {
      props.redo();
      await props.getNext(-1);
    }, 150);
  };

  const knowThis = () => {
    updateWords(
      props.wcontent.id,
      {
        done: 50000 * 86400,
      },
      1,
      1
    );
    setTimeout(async () => {
      props.redo();
      await props.getNext(-1);
    }, 150);
  };

  return (
    <React.Fragment>
      <div className={classes.square}>
        <div className={classes.buttons}>
          <div className={classes.pendingDiv}>
            {tx("dala1rem", "How well did you remember this?")}
          </div>
          <div className={classes.buttonDivPending}>
            <div className={classes.button33}>
              <ButtonBase
                onClick={() => newWordDelay(0)}
                className={classes.button}
              >
                <img src="/face/1.png" alt="1" />
              </ButtonBase>
            </div>
            <div className={classes.button33}>
              <ButtonBase
                onClick={() => newWordDelay(1)}
                className={classes.button}
              >
                <img src="/face/2.png" alt="1" />
              </ButtonBase>
            </div>
            <div className={classes.button33}>
              <ButtonBase
                onClick={() => newWordDelay(2)}
                className={classes.button}
              >
                <img src="/face/3.png" alt="1" />
              </ButtonBase>
            </div>
            <div className={classes.button33}>
              <ButtonBase
                onClick={() => newWordDelay(3)}
                className={classes.button}
              >
                <img src="/face/4.png" alt="1" />
              </ButtonBase>
            </div>
            <div className={classes.button33}>
              <ButtonBase
                onClick={() => newWordDelay(4)}
                className={classes.button}
              >
                <img src="/face/5.png" alt="1" />
              </ButtonBase>
            </div>
          </div>
          <div className={classes.buttonDiv}>
            <ButtonBase onClick={knowThis} className={classes.buttonknow}>
              {tx("hozosa", "I know this word fully!")}
            </ButtonBase>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardFinalResult;
