// @ts-ignore

import store from "Redux/store";

export const initSpeech = () => {
  // @ts-ignore
  responsiveVoice.setDefaultVoice("US English Male");
};

export const speakNow = (text: string) => {
  // @ts-ignore
  const settings = store.getState().settings;
  // @ts-ignore
  responsiveVoice.speak(text, "UK English Female", {
    rate: settings.readSpeed,
  });
  return;
};
// http://gyakorlas.gyakorlatias-angol.hu/
