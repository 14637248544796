import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProceedType {
  next:
    | 1
    | undefined
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  previous:
    | 1
    | undefined
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  repeat:
    | 1
    | undefined
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  action:
    | 1
    | undefined
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  actionIcon: string;
  visibleMenu: boolean;
  visibleAction: boolean;
  move?: boolean;
  backup?: any;
}

const initialState: ProceedType = {
  next: () => {},
  previous: () => {},
  repeat: () => {},
  action: () => {},
  actionIcon: "",
  visibleMenu: false,
  visibleAction: false,
  move: false,
};

const proceedSlice = createSlice({
  initialState,
  name: "proceed",
  reducers: {
    setProceed: (
      state: typeof initialState,
      action: PayloadAction<typeof initialState>
    ) => {
      return action.payload;
    },
    setProceedAction: (
      state: typeof initialState,
      action: PayloadAction<{
        action?: any;
        actionIcon?: string;
        visibleAction?: boolean;
      }>
    ) => {
      if (action.payload.action) {
        state.action = action.payload.action;
      }
      if (action.payload.actionIcon) {
        state.actionIcon = action.payload.actionIcon;
      }
      if (action.payload.visibleAction !== undefined) {
        state.visibleAction = action.payload.visibleAction;
      }
    },
    setProceedMenu: (
      state: typeof initialState,
      action: PayloadAction<{
        next?: any;
        previous?: any;
        repeat?: any;
        visibleMenu?: boolean;
        move?: boolean;
      }>
    ) => {
      if (action.payload.next) {
        state.next = action.payload.next;
      }

      if (action.payload.previous) {
        state.previous = action.payload.previous;
      }

      if (action.payload.repeat) {
        state.repeat = action.payload.repeat;
      }

      if (action.payload.visibleMenu !== undefined) {
        state.visibleMenu = action.payload.visibleMenu;
      }

      if (action.payload.move !== undefined) {
        state.move = action.payload.move;
      }
    },
    setProceedBackup: (
      state: typeof initialState,
      action: PayloadAction<any>
    ) => {
      state.backup = action.payload;
    },
  },
});

export const {
  setProceed,
  setProceedMenu,
  setProceedAction,
  setProceedBackup,
} = proceedSlice.actions;

export default proceedSlice.reducer;
