import "./css/card.css";

import { firestore } from "firebaseUtil/firebaseUtil";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { setCurrentWord } from "Redux/reducers/reducer.currentWord";
import { setStatus } from "Redux/reducers/reducer.status";
import { setWordbarOne } from "Redux/reducers/reducer.wordbar";
import { setWords3000 } from "Redux/reducers/reducer.words3000";
import store from "Redux/store";

// @material-ui/core components
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Popper,
  Theme,
} from "@material-ui/core";

import { RootState } from "../Redux/reducers/_index";
import { UserType } from "../Redux/reducers/reducer.currentUser";
import { phaseBodyIn } from "../utils/utils";
import FullPopup from "./defViewer/defViewer.fullPopup";
import SmallPopup from "./defViewer/defViewer.smallPopup";
import DictionaryButtons from "./dictionary/dictionary.buttons";
import WordDef from "./dictionary/dictionary.defs";
import WordDicEntries from "./dictionary/dictonary.entries";
import KeyWords from "./keyWords/keyWords";
import TextSearch from "./textLoader/textLoader";
import WordsHistory from "./textLoader/textLoader.history";
import LevelSelectButtons from "./textLoader/textLoader.levelSelect";
// import WordsPaste from "./textLoader/textLoader.paste";
import WordsReader from "./textViewer/textViewer";
import WordsToolBar from "./toolbar/toolbar";
import { changeTabs } from "./utils/utils.changeTab";
import WordCardModal from "./wordCard/wordCard.Modal";
import NoMoreDialog from "./wordCard/wordCard.nomoredlg";
import WordOwnWords from "./wordOwnWords/wordOwnWords";
import {
  clean,
  phaseIn,
  sortWords,
  updateWords,
  updateWordsDelete,
} from "./words.utils";
import { tx } from "../utils/globalize";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wordWrapper: {
      position: "relative",
      height: "calc(100% - 47px)",
      "@media (min-width: 821px)": { height: "calc(100% - 67px)" },
    },
    wrap: {
      background: "white",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      maxWidth: "700px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    circle: { color: "var(--toolbar-background)" },
    fixedcenter: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fabOn: {
      position: "absolute",
      bottom: "60px",
      right: "15px",
      transition: "all 0.2s",
      transform: "scale(1)",
      background: "#e76659",
      color: "white",
    },
    fabOff: {
      position: "absolute",
      bottom: "60px",
      right: "15px",
      transition: "all 0.2s",
      transform: "scale(0)",
      background: "#e76659",
      color: "white",
    },
    wordresult: {
      lineHeight: "130%",
      marginLeft: "auto",
      marginRight: "auto",
      userSelect: "none",
      overflowY: "auto",
      position: "absolute",
      transform: "translate3d(0,0,0)",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "var(--paper-color)",
      paddingLeft: "65px",
      paddingRight: "65px",
      opacity: 1,
      transition: "all 0.15s",
      "@media (max-width: 478px)": {
        top: "0px",
        bottom: "0px",
        left: "0px",
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
    autowordresult: {
      lineHeight: "130%",
      marginLeft: "auto",
      marginRight: "auto",
      userSelect: "none",
      overflowY: "auto",
      position: "absolute",
      transform: "translate3d(0,0,0)",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "var(--paper-color)",
      paddingLeft: "65px",
      paddingRight: "65px",
      opacity: 1,
      transition: "all 0.15s",
      "@media (max-width: 478px)": {
        top: "0px",
        bottom: "0px",
        left: "0px",
        paddingLeft: "20px",
        paddingRight: "0px",
      },
    },
    top: {
      userSelect: "none",
      overflowY: "auto",
      position: "absolute",
      top: 95,
      bottom: 48,
      left: 45,
      right: 0,
      background: "white",
      opacity: 1,
      transition: "all 0.15s",
      "@media (max-width: 478px)": {
        top: "95px",
        bottom: "48px",
        left: "20px",
      },
    },
    mp3: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      background: "lightgrey",
      radius: "3px",
      border: "1px solid grey",
    },
  })
);

const Words = (props: any) => {
  const [cardOpen, setCardOpen] = useState(false);
  const [content, setContent] = useState<any>({ cards: [] });
  const [finishSlides, setFinishSlides] = useState(false);
  const [showDone, setShowDone] = useState(false);
  const [showUndone, setShowUndone] = useState(true);
  const [results, setResults] = useState<any[]>([]);
  const [query, setQuery] = useState("");
  const [showHun] = useState(true);
  const [wordResult, setWordResult] = useState<any>({});
  const [dicList, setDicList] = useState<any[]>([]);
  const [moreOpen, setMoreOpen] = useState(false);
  const [nextBack, setNextBack] = useState("");
  const [loadingWord, setLoadingWord] = useState(false);
  const [defOpen, setDefOpen] = useState(false);

  const [popupVisible, setPopupVisible] = useState(false);
  const textTypeRef = useRef("active");

  const doneRef = useRef<any>([]);
  const editorRef = useRef<any>(null);
  const textIdRef = useRef("");
  const contRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [activeSentence, setActiveSentence] = useState<any>({
    s: "",
    sOrig: "",
    w: [0, 0],
    fileId: "",
  });

  const newTitleRef = useRef("");
  const videoPlayer = useRef<any>(null);
  const listRef = useRef<any>();
  const ownListRef = useRef<any>();
  const classes = useStyles();
  const waitRef = useRef<any>();

  // const words = useSelector<RootState, any>((state) => state.words);

  const status = useSelector<RootState, string>((state) => state.status);
  const readerId = useSelector<RootState, any>((state) => state.reader.done);

  // const wordLevel = useSelector<RootState, string>(
  //   (state) => state.settings.wordLevel
  // );

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  // useEffect(() => {
  //   if (!currentUser) {
  //     return;
  //   }

  //   phaseOut("xwords");
  //   setTimeout(async () => {
  //     await loadWords(currentUser);
  //     phaseIn("xwords");
  //   }, 100);
  // }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    setTimeout(async () => {
      phaseIn("xwords");
    }, 100);
  }, [currentUser]);

  useEffect(() => {
    if (!cardOpen) {
      textTypeRef.current = "active";
      setActiveSentence({
        s: "",
        sOrig: "",
        w: [0, 0],
        fileId: "",
      });
      setContent({});
      setMoreOpen(false);
    }
  }, [cardOpen]);

  useEffect(() => {
    // document.getElementById("ipaper")?.scrollTo(0, 0);

    if (!readerId) {
      return;
    }

    setTimeout(() => {
      phaseIn();
      phaseBodyIn();
    }, 0);
  }, [currentUser, readerId]);

  // useEffect(() => {
  //   store.dispatch(
  //     setWordbar({
  //       backToList,
  //       wordLevel,
  //       review,
  //       newRun,
  //       cardOpen,
  //       setCardOpen,
  //     })
  //   );
  // }, [review, newRun, cardOpen, wordLevel]);

  // ? Add normal word to dic function

  const addToListCustom = useCallback(
    (item: any, done: string = "") => {
      let newDate = Math.floor(Date.now() / 1000);
      const startDate = Math.floor(newDate / 86400);

      if (done === "done") {
        newDate = 86400 * 50000;
      }

      const newSentence = {
        s: activeSentence.sOrig || "",
        fileId: activeSentence.fileId || "",
        w: activeSentence.w || [-1, -1],
      };

      const newValue = {
        done: newDate,
        word: item.e.trim().replace(/\//g, "%%%"),
        id: clean(item.e.trim() + "-" + item.d.trim()),
        d: item.d.trim(),
        s: typeof item.s === "number" ? item.s : startDate,
        e: item.e.trim(),
        a: newSentence,
        ef: 2.5,
        r: 0,
        i: 0,
      };

      updateWords(clean(item.e.trim() + "-" + item.d.trim()), newValue);

      // store.dispatch(
      //   setWordsOne({
      //     id: english.trim() + "-" + translation.trim(),
      //     value: newValue,
      //   })
      // );

      // store.dispatch(
      //   setWordsOne({
      //     id: "words",
      //     value: sortWords([...words.words, newValue]),
      //   })
      // );

      // if (newSentence?.fileId === store.getState().reader.currentText.id) {
      //   store.dispatch(
      //     setHlOne([newSentence?.w[0], newSentence?.w[1], "hlactivezactive"])
      //   );
      // }
    },
    [activeSentence.fileId, activeSentence.sOrig, activeSentence.w]
  );

  // ? Add KewWord to dic function

  const addToListOfficial = useCallback(
    (item: any, done: string = "") => {
      let newDate = Math.floor(Date.now() / 1000);
      const startDate = Math.floor(newDate / 86400);

      if (done === "done") {
        newDate = 86400 * 50000;
      }

      const newSentence = {
        s: activeSentence.sOrig || "",
        fileId: activeSentence.fileId || "",
        w: activeSentence.w || [-1, -1],
      };

      const newItem = {
        done: newDate,
        id: item.id,
        s: typeof item.s === "number" ? item.s : startDate,
        a: newSentence,
        d: item.d,
        e: item.word?.split("+")[0].split("/")[0] || item.e,
        ef: 2.5,
        r: 0,
        i: 0,
      };

      if (!store.getState().words[item.id] || done) {
        updateWords(item.id, newItem, done === "done" ? 1 : 0, 1);
      }

      return { ...newItem };
    },
    [activeSentence]
  );

  const deleteItem = useCallback((item: any) => {
    updateWordsDelete(item.id);
  }, []);

  // ? GETNEXT Word function

  const getNext = useCallback(
    async (nextOne: any = -1, textId = "") => {
      setCardOpen(true);
      setFinishSlides(false);
      setMoreOpen(false);

      if (textId) {
        textIdRef.current = textId;
      }

      if (textId === "none") {
        textIdRef.current = "";
      }
      // if (!dicList) {
      //   return;
      // }

      if (nextOne === -1) {
        const thisDate = Math.floor(Date.now() / 1000 / 86400);

        switch (textTypeRef.current) {
          case "active":
            nextOne = dicList.find((item: any) => {
              const itemDone = Math.floor((item?.done || 0) / 86400);

              return (
                itemDone &&
                itemDone <= thisDate &&
                !doneRef.current.includes(item.id) &&
                (!textIdRef.current
                  ? true
                  : item?.a?.fileId === textIdRef.current)
              );
            });
            break;
          case "pending":
            nextOne = dicList.find((item: any) => {
              const itemDone = Math.floor((item?.done || 0) / 86400);
              return (
                itemDone &&
                itemDone < 50000 &&
                !doneRef.current.includes(item.id) &&
                (!textIdRef.current
                  ? true
                  : item?.a?.fileId === textIdRef.current)
              );
            });
            break;
          case "new":
            nextOne = store
              .getState()
              .words3000.words.find(
                (item: any) => !store.getState().words[item.id]
              );

            if (nextOne) {
              nextOne = addToListOfficial(nextOne);
            }
        }

        // listRef.current?.scrollToItem(nextOne, "smart");
      }

      if (nextOne === undefined || nextOne === -1) {
        if (textTypeRef.current === "new") {
          setCardOpen(false);
          setActiveSentence({
            s: "",
            sOrig: "",
            w: [0, 0],
            fileId: "",
          });
          return;
        }

        setCardOpen(false);
        setActiveSentence({
          s: "",
          sOrig: "",
          w: [0, 0],
          fileId: "",
        });
        setMoreOpen(true);
        return;
      }

      setLoadingWord(true);

      const newWord = await firestore
        .doc(`/vocab${currentUser?.language?.toUpperCase()}/` + nextOne.id)
        .get();

      doneRef.current.push(nextOne.id);

      setContent({
        ...newWord.data(),
        cards: newWord
          .data()
          ?.cards?.map((card: any) => ({ ...card, done: false })) || [
          {
            english:
              nextOne.e || nextOne.word?.split("+")[0].split("/")[0] || "",
            foreign: nextOne.d || "",
          },
        ],
        title: newWord.data()?.word || nextOne.word,
        ipa: nextOne.pron,
        id: nextOne.id,
        a: nextOne?.a,
        w: nextOne?.a?.w || [],
      });
      setTimeout(() => setLoadingWord(false), 0);
    },
    [addToListOfficial, dicList, currentUser]
  );

  // ? getPractice Word function

  const getPractice = useCallback(async (allText: any[]) => {
    setCardOpen(true);
    setFinishSlides(false);

    setLoadingWord(true);

    setContent({
      cards: allText,
      title: tx("dala12", "Practice"),
      id: "practice",
      a: "",
      w: [],
    });
    setTimeout(() => setLoadingWord(false), 0);
  }, []);

  // ? LOOKUPWORD function

  const lookupWord = useCallback(
    (result: any) => {
      result.e.persist();

      if (videoPlayer.current?.pauseVideo) {
        videoPlayer.current.pauseVideo();
      }
      if (videoPlayer.current?.pause) {
        videoPlayer.current.pause();
      }

      setActiveSentence({
        s: result.sentence,
        sOrig: result.origSentence,
        w: result.pos || [0, 0],
        fileId: store.getState().reader.currentText.id || "",
      });

      if (result.currCode) {
        if (result.currCode === "ASNAME") {
          store.dispatch(
            setCurrentWord({
              d: result.orig + " " + tx("thisisname", "This is a name."),
              word: result.orig,
            })
          );

          setTimeout(() => {
            setAnchorEl(result.e.target);
            setPopupVisible(true);
            // setTimeout(() => contRef.current.update(), 0);
          }, 50);

          return;
        }

        if (result.currCode === "ASNO") {
          store.dispatch(
            setCurrentWord({
              d: result.orig + " " + tx("sameas", "Same in [yourlang]"),
              word: result.orig,
            })
          );

          setTimeout(() => {
            setAnchorEl(result.e.target);
            setPopupVisible(true);
            // setTimeout(() => contRef.current.update(), 0);
          }, 0);

          return;
        }

        firestore
          .doc(
            `/vocab${currentUser?.language?.toUpperCase()}/` +
              (result.currGr || result.currCode)
          )
          .get()
          .then((res) => {
            if (res.data()) {
              store.dispatch(setCurrentWord(res.data()));
            } else {
              store.dispatch(
                setCurrentWord({
                  d: result.currGr + " to be written",
                  word: result.word,
                })
              );
            }

            setTimeout(() => {
              setAnchorEl(result.e.target);
              setPopupVisible(true);
              // setTimeout(() => contRef.current.update(), 0);
            }, 0);
          })
          .catch(() => {
            store.dispatch(
              setCurrentWord({ d: "not found", word: result.currCode })
            );
            setTimeout(() => {
              setAnchorEl(result.e.target);
              setPopupVisible(true);
              // setTimeout(() => contRef.current.update(), 0);
            }, 0);
          });
      } else {
        setQuery(result.word);
        store.dispatch(setStatus("wordlist"));
      }

      // setSmallPopUp(result);

      // store.dispatch(setStatus("wordlist"));
    },
    [currentUser]
  );

  useEffect(() => {
    if (waitRef.current) {
      clearTimeout(waitRef.current);
    }

    waitRef.current = setTimeout(() => {
      if (store.getState().currentUser?.language === "hu") {
        fetch(`https://gyangol.appspot.com/?word=${query}`)
          .then((resp) => resp.json())
          .then((json: any) => {
            if (
              query ===
              (document.getElementById("xinput") as HTMLInputElement)?.value
            ) {
              // console.log(json);
              setResults(json);
            }
          });
      } else {
        fetch(
          `https://gyangol.appspot.com/pons?lang=${
            store.getState().currentUser?.language
          }&word=${query}`
        )
          .then((resp) => resp.json())
          .then((json: any) => {
            if (
              query ===
              (document.getElementById("xinput") as HTMLInputElement)?.value
            ) {
              // console.log(json);
              setResults(
                json.map((value: any) => ({
                  e: value.value,
                  id: value.label,
                  card: value.card,
                }))
              );
            }
          });
      }
    }, 150);
  }, [query, setResults]);

  // ? LOADWORD function

  const loadWord = useCallback((id: any) => {
    if (store.getState().currentUser?.language === "hu") {
      fetch(`https://gyangol.appspot.com/word?id=${id}`)
        .then((resp) => resp.json())
        .then((json: any) => {
          setWordResult({
            e: json.e,
            words: json.d
              .split(",")
              .map((item: any) => ({ e: json.e, d: item })),
          });
          store.dispatch(setStatus("word"));
        });
    } else {
      fetch(
        `https://gyangol.appspot.com/ponsword?word=${id}&lang=${
          store.getState().currentUser?.language
        }`
      )
        .then((resp) => resp.json())
        .then((json: any) => {
          setWordResult({ words: json, e: id, pons: true });
          // console.log({ words: json, e: id });
          store.dispatch(setStatus("word"));
        });
    }
  }, []);

  const backToList = () => {
    // phaseOut("xownwords");
    // phaseOut("xcards");
    // phaseIn("xwordlist");
    // phaseIn("xbottom");
    setCardOpen(false);
    setActiveSentence({
      s: "",
      sOrig: "",
      w: [0, 0],
      fileId: "",
    });
  };

  const noDictionary = useCallback(() => {
    setActiveSentence({ s: "", w: [0, 0], fileId: "", sOrig: "" });
  }, []);

  useEffect(() => {
    store.dispatch(setWordbarOne({ id: "status", value: status }));

    if (status !== "reader") {
      if (videoPlayer.current?.pauseVideo) {
        videoPlayer.current.pauseVideo();
      }
      if (videoPlayer.current?.pause) {
        videoPlayer.current.pause();
      }
    }

    setDefOpen(false);
    setPopupVisible(false);

    switch (status) {
      case "word":
        changeTabs("wordresult");
        return;
      case "wordlist":
        changeTabs("xwordlist");
        const wordlist = document.getElementById("xwordlist");
        if (wordlist) {
          wordlist.scrollTop = 0;
        }
        return;
      case "ownwords":
        noDictionary();
        setNextBack(status);
        changeTabs("xownwords");
        return;
      case "cards":
        noDictionary();
        changeTabs("xcards");
        return;
      case "origlist":
        noDictionary();
        setNextBack(status);

        setTimeout(
          () =>
            store.dispatch(
              setWords3000({
                words: [...sortWords(store.getState().words3000.words)],
              })
            ),
          0
        );
        changeTabs("xoriglist");
        return;
      case "reader":
        noDictionary();
        setNextBack(status);
        changeTabs("xreader");
        return;
      case "loader":
        noDictionary();
        setNextBack(status);
        changeTabs("xloader");
        return;
      case "history":
        noDictionary();
        setNextBack(status);
        changeTabs("history");
        return;
      case "paste":
        noDictionary();
        setNextBack(status);
        changeTabs("paste");
        return;
    }
  }, [status, noDictionary]);

  const showWord = (id: any) => {
    loadWord(id);
  };

  const addWord = useCallback(() => {
    addToListOfficial(store.getState().currentWord);
    setPopupVisible(false);
    setDefOpen(false);
    doneRef.current = [];
    textIdRef.current = "";
    textTypeRef.current = "active";

    setTimeout(() => setAnchorEl(null), 250);
  }, [addToListOfficial]);

  const openBig = useCallback(() => {
    setPopupVisible(false);
    setTimeout(() => setAnchorEl(null), 250);
    setDefOpen(true);
  }, []);

  return (
    <React.Fragment>
      <div className={classes.wordWrapper}>
        <div className={classes.wordresult} id="xcards">
          {content.cards?.length ? (
            <WordCardModal
              cardOpen={cardOpen}
              setCardOpen={setCardOpen}
              doneRef={doneRef}
              content={content}
              getNext={getNext}
              backToList={backToList}
              finishSlides={finishSlides}
              setFinishSlides={setFinishSlides}
              setActiveSentence={setActiveSentence}
              loadingWord={loadingWord}
              textIdRef={textIdRef}
            />
          ) : (
            ""
          )}
        </div>
        <div className={classes.fixedcenter}>
          <CircularProgress
            color="primary"
            classes={{ colorPrimary: classes.circle }}
          />
        </div>
        <div className={classes.autowordresult} id="xownwords">
          <WordOwnWords
            showHun={showHun}
            getNext={getNext}
            dicList={dicList}
            setDicList={setDicList}
            showDone={showDone}
            showUndone={showUndone}
            textTypeRef={textTypeRef}
            doneRef={doneRef}
            textIdRef={textIdRef}
            setDefOpen={setDefOpen}
            listRef={ownListRef}
            addToListOfficial={addToListOfficial}
            addToListCustom={addToListCustom}
            deleteItem={deleteItem}
          />
        </div>
        <div className={classes.wordresult} id="wordresult">
          <WordDef
            result={wordResult}
            activeSentence={activeSentence}
            addToListOfficial={addToListOfficial}
          />
        </div>
        <div className={classes.wordresult} id="xloader">
          <TextSearch />
        </div>
        <div className={classes.wordresult} id="history">
          <WordsHistory />
        </div>
        <div className={classes.wordresult} id="xreader">
          <WordsReader
            lookupWord={lookupWord}
            videoPlayer={videoPlayer}
            getPractice={getPractice}
            status={status}
          />
        </div>
        <div className={classes.wordresult} id="xwordlist">
          <React.Fragment>
            <WordDicEntries
              showWord={showWord}
              query={query}
              setResults={setResults}
              results={results}
              activeSentence={activeSentence}
            />
          </React.Fragment>
        </div>
        <div className={classes.wordresult} id="xoriglist">
          <KeyWords
            getNext={getNext}
            addToListOfficial={addToListOfficial}
            listRef={listRef}
            setDefOpen={setDefOpen}
            doneRef={doneRef}
            textIdRef={textIdRef}
            textTypeRef={textTypeRef}
            showDone={showDone}
            showUndone={showUndone}
          />
        </div>
      </div>
      <WordsToolBar
        nextBack={nextBack}
        setWordResult={setWordResult}
        setActiveSentence={setActiveSentence}
        getNext={getNext}
        status={status}
        query={query}
        editorRef={editorRef}
        setQuery={setQuery}
        showDone={showDone}
        showUndone={showUndone}
        setShowDone={setShowDone}
        setShowUndone={setShowUndone}
        newTitleRef={newTitleRef}
        videoPlayer={videoPlayer}
        doneRef={doneRef}
        textIdRef={textIdRef}
        textTypeRef={textTypeRef}
      />
      <DictionaryButtons
        status={status}
        query={query}
        activeSentence={activeSentence}
        addToListCustom={addToListCustom}
      />
      <LevelSelectButtons status={status} />

      {/*<WordsActiveSentence activeSentence={activeSentence} />*/}

      <NoMoreDialog
        moreOpen={moreOpen}
        setMoreOpen={setMoreOpen}
        getNext={getNext}
        textTypeRef={textTypeRef}
        doneRef={doneRef}
      />

      <Popper
        id={"popi"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        popperRef={contRef}
        disablePortal={true}
        keepMounted={true}
        placement="bottom"
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
      >
        <SmallPopup
          openBig={openBig}
          addWord={addWord}
          popperRef={contRef}
          setDefOpen={setDefOpen}
          setAnchorEl={setAnchorEl}
          visible={popupVisible}
          setVisible={setPopupVisible}
        />
      </Popper>
      <FullPopup
        defOpen={defOpen}
        setDefOpen={setDefOpen}
        activeSentence={activeSentence}
        addWord={addWord}
        getNext={getNext}
      />
    </React.Fragment>
  );
};

export default Words;
