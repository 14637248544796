import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";

import { Divider, IconButton, List, Menu } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { updateSetting } from "../words.utils";
import Badge from "@material-ui/core/Badge";
import { LoopTwoTone, CheckTwoTone } from "@material-ui/icons";
import { StatsType } from "../../Redux/reducers/reducer.stats";

const levels: any = {
  A: "--",
  A1: "A1",
  A2: "A2",
  A3: "B1",
  A4: "B2",
};

const Toolbar3000 = (props: any) => {
  const { classes, showDone, showUndone, setShowDone, setShowUndone } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const status = useSelector<RootState, any>((state) => state.status);
  const wordLevel = useSelector<RootState, string>(
    (state) => state.settings.wordLevel
  );

  const stats = useSelector<RootState, StatsType>((state) => state.stats);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMenu = (e: any) => {
    setAnchorEl(e.target);
  };

  const setLevel = (newLevel: string) => {
    updateSetting("wordLevel", newLevel);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {status === "origlist" ? (
        <React.Fragment>
          <IconButton className={classes.icon1text} onClick={showMenu}>
            {levels[wordLevel]}
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{ list: classes.list }}
            disableAutoFocusItem={true}
            disableAutoFocus={true}
          >
            <List dense className={classes.list}>
              <ListItem
                selected={wordLevel === "A"}
                onClick={() => setLevel("A")}
              >
                <ListItemText
                  className={classes.listItem}
                  primary="All words"
                />
              </ListItem>
              <Divider />
              <ListItem
                selected={wordLevel === "A1"}
                onClick={() => setLevel("A1")}
              >
                <ListItemText primary="A1" />
              </ListItem>
              <ListItem
                selected={wordLevel === "A2"}
                onClick={() => setLevel("A2")}
              >
                <ListItemText primary="A2" />
              </ListItem>
              <ListItem
                selected={wordLevel === "A3"}
                onClick={() => setLevel("A3")}
              >
                <ListItemText primary="B1" />
              </ListItem>
              <ListItem
                selected={wordLevel === "A4"}
                onClick={() => setLevel("A4")}
              >
                <ListItemText primary="B2" />
              </ListItem>
            </List>
          </Menu>
          <IconButton
            className={classes.icon2 + (showUndone ? " active" : "")}
            onClick={() => {
              setShowUndone(!showUndone);
            }}
          >
            <Badge badgeContent={stats.toBeDone3000} color="primary" max={1000}>
              <LoopTwoTone />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.icon3 + (showDone ? " active" : "")}
            onClick={() => {
              setShowDone(!showDone);
            }}
          >
            <Badge badgeContent={stats.done3000} color="primary" max={1000}>
              <CheckTwoTone />
            </Badge>
          </IconButton>
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Toolbar3000;
