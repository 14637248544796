import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Input,
} from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      flex: "1 1 0px",
      marginTop: "8px",
      marginRight: "10px",
      "@media (max-width: 400px)": {
        flex: "1 1 auto",
        width: "100%",
      },
    },
  })
);

const TextForm = (props: any) => {
  const classes = useStyles();

  const date = new Date(props.data?.expiry);
  const formattedDate = date.toLocaleDateString();

  return (
    <React.Fragment>
      <FormControl fullWidth className={classes.textField}>
        <InputLabel htmlFor="component-firstName">{props.label}</InputLabel>
        <Input
          className={classes.textField}
          id={props.name}
          value={
            props.name === "expiry"
              ? formattedDate
              : props.data[props.name] || ""
          }
          onChange={props.handleChange}
          disabled={
            props.name === "expiry" || props.name === "subscriptionType"
          }
        />
      </FormControl>
    </React.Fragment>
  );
};

export default TextForm;
