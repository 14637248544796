// import loadable from "@loadable/component";
import Dexie from "dexie";

// const Dexie = loadable.lib(() => import("dexie")).;

export const dbDrills = new Dexie("drillsne");

dbDrills.version(1).stores({
  chapters: "&id, chapter, published",
  files: "id++, &origpath",
  chapterList: "id++, list",
});

// URL.createObjectURL(store.getState().pics[embedAttributes.image.filename].file))
