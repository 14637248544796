import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { index: [] };

const recordingSlice = createSlice({
  initialState,
  name: "recordings",
  reducers: {
    setRecordingFile: (state: any, action: PayloadAction<any>) => {
      if (!state.index.includes(action.payload.id)) {
        state.index.push(action.payload.id);
      }
      state[action.payload.id] = action.payload.url;
    },
    deleteRecordingFile: (state: any, action: PayloadAction<any>) => {
      state.index = state.index.filter(
        (item: string) => item !== action.payload
      );
    },
  },
});

export const { setRecordingFile, deleteRecordingFile } = recordingSlice.actions;

export default recordingSlice.reducer;
