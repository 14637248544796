import { updateSetting } from "firebaseUtil/firebaseUtil.firestore";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setProceedAction } from "Redux/reducers/reducer.proceed";
import { SettingsType } from "Redux/reducers/reducer.settings";
import store from "Redux/store";
import { tx } from "utils/globalize";
import { nextExercise } from "utils/progress/stepper";

// @material-ui/core components
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { phaseBodyIn, phaseIn } from "../utils/utils";
import SettingsFontSlider from "./settings.fontSlider";
import SettingsSlider from "./settings.slider";
import SettingsSwitch from "./settings.switch";
import SettingsThemes from "./settings.theme";

const { detect } = require("detect-browser");
const browser = detect();

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--toolbar-background)",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    greytext: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      color: "grey",
    },

    line: {
      height: "0px",
      width: "100%",
      borderBottom: "1px solid var(--line-grey)",
      marginBottom: "18px",
    },
    version: {
      fontSize: "12px",
      fontFamily: "var(--font-normal)",
    },
  })
);

export const isIOS = () => {
  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
};

// let hasMediaRecorder: boolean;

// try {
//   //@ts-ignore
//   hasMediaRecorder = !!MediaRecorder;
// } catch (error) {
//   hasMediaRecorder = false;
// }

const Settings = (props: any) => {
  const classes = useStyles();

  const authLoading = useSelector<RootState, boolean>(
    (state) => state.authLoaded
  );

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  useEffect(() => {
    store.dispatch(
      setProceedAction({
        action: nextExercise,
        visibleAction: false,
        actionIcon: "forward",
      })
    );
    document.getElementById("ipaper")?.scrollTo(0, 0);
    if (authLoading) {
      setTimeout(() => {
        phaseBodyIn();
        phaseIn();
      }, 0);
    }
  }, [authLoading]);

  const settings = useSelector<RootState, SettingsType>(
    (state) => state.settings
  );

  const changeAutoRead = () => {
    updateSetting({ autoRead: !settings.autoRead });
  };

  const changeRate = (readSpeed: number) => {
    updateSetting({ readSpeed: readSpeed });
  };

  const changeShowFirstPage = () => {
    updateSetting({ showFirstPage: !settings.showFirstPage });
  };

  const changeHighlight = () => {
    updateSetting({ highlight: !settings.highlight });
  };

  const changeHead = () => {
    updateSetting({ head: !settings.head });
  };

  const changeFontSize = (fontSize: number) => {
    updateSetting({ fontSize: fontSize });
  };

  const changeLoop = () => {
    updateSetting({ loop: !settings.loop });
  };

  // const changeTestReview = () => {
  //   updateSetting({ testReview: !settings.testReview });
  //   updatePosition({
  //     currentPosition: nextValid(store.getState().lessonStatus.step - 1, {
  //       ...settings,
  //       testReview: !settings.testReview,
  //     }),
  //   });
  //   store.dispatch(setLoading(true));
  // };

  // <SettingsSwitch
  //   status={settings.testReview}
  //   change={changeTestReview}
  //   mainText={tx("ososlwll9", "Do pretesting")}
  //   offOption={tx(
  //     "owoellls990",
  //     "All lessons are automatically done. There is no testing to decide whether you want to do the lesson."
  //   )}
  //   onOption={tx(
  //     "iiklklkl990893",
  //     "Test yourself before each lesson to decide whether you want to do it or not."
  //   )}
  // />;

  return (
    <React.Fragment>
      <SettingsThemes />
      <SettingsSwitch
        status={settings.highlight}
        change={changeHighlight}
        mainText={tx("asowlsllzl1", "Show words marked for study in Reader.")}
        offOption={tx(
          "asowlsllzl2",
          "Words marked for study are not highlighted in Reader."
        )}
        onOption={tx(
          "asowlsllzl3",
          "Words marked for study are highlighted in Reader."
        )}
      />
      <SettingsFontSlider
        value={settings.fontSize}
        change={changeFontSize}
        mainText={tx("asowlsllzl4", "Font size in the Reader")}
      />
      <SettingsSwitch
        value={settings.head}
        change={changeHead}
        mainText={tx("asowlsllzl5", "Show teacher's head on main screen")}
        offOption={tx("asowlsllzl6", "The teacher's head will not be shown")}
        onOption={tx("asowlsllzl7", "The teacher's head will be shown")}
      />

      <SettingsSwitch
        status={settings.autoRead}
        change={changeAutoRead}
        mainText={tx("ososlwllwe9sdprwwon", "Read out cards automatically")}
        offOption={tx(
          "owoellls990wewwease",
          "Practice cards are not read out when first shown."
        )}
        onOption={tx(
          "iiklklkl99ww089we3eeeeeee",
          "Practice cards are read out when first shown."
        )}
      />
      <SettingsSwitch
        status={settings.showFirstPage}
        change={changeShowFirstPage}
        mainText={tx("ososlsdwllwe9sdprwwon", "Show tutorial page on startup.")}
        offOption={tx(
          "owoellls990weasdfwwease",
          "The tutorial page is not shown when you first start the app."
        )}
        onOption={tx(
          "iiklklklweeww99ww089we3eeeeeee",
          "The tutorial page is shown when you first start the app."
        )}
      />
      {currentUser?.language === "hu" ? (
        <SettingsSwitch
          status={!!settings.loop}
          change={changeLoop}
          mainText={tx("ososlsdwllwwewe9sdprwwon", "Automatic looping.")}
          offOption={tx(
            "owoellls990weasdfwweasecwer",
            "Sound files are automatically replayed again and again."
          )}
          onOption={tx(
            "iiklklklweeww99ww089we3eweeeeeeee",
            "Sound files are only played once."
          )}
        />
      ) : null}
      <SettingsSlider
        value={settings.readSpeed}
        change={changeRate}
        mainText={tx(
          "ososlwllwe9prwwonjhkjhkj",
          "Audio playing speed (normal is 1)"
        )}
      />
      <div className={classes.line} />
      <div className={classes.version}>User: {currentUser?.email}</div>
      <div className={classes.version}>Owner: {currentUser?.ccode}</div>
      <div className={classes.version}>
        Version: {localStorage.getItem("appVersion")}
      </div>
      <div className={classes.version}>
        Browser: {browser.name + " " + browser.version}
      </div>
      <div className={classes.version}>OS: {browser.os}</div>
    </React.Fragment>
  );
};

export default Settings;
