import React from "react";
import { beginsWith } from "../utils/utils";
import store from "Redux/store";
import { setHelpSrc, setHelpOpen } from "Redux/reducers/reducer.help";
import HelpIPhone from "../help/topics/20-homescreen_iphone";
import HelpAndroid from "../help/topics/21-homescreen_android_short";
import { Device } from "@capacitor/device";
import HelpAppAndroid from "help/topics/20-homescreen_appandroid";
import HelpAppIOS from "help/topics/20-homescreen_appios";

const { detect } = require("detect-browser");
const browser = detect();
const standalone = window.matchMedia("(display-mode: standalone)").matches;

let platform = "web";

Device.getInfo().then((i) => (platform = i.platform));

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const inFrame = inIframe();

export const checkGoing = () => {
  if (document.location.href.includes("/newpw")) {
    return;
  }

  if (inFrame) {
    return;
  }

  // if (
  //   !document.location.href.includes("szv.gyakorlatias-angol.hu") &&
  //   platform === "web" &&
  //   browser.os === "iOS"
  // ) {
  //   store.dispatch(setHelpSrc(<HelpAppIOS />));
  //   store.dispatch(setHelpOpen(true));
  //   return;
  // }

  // if (
  //   !document.location.href.includes("szv.gyakorlatias-angol.hu") &&
  //   platform === "web" &&
  //   browser.os === "Android OS"
  // ) {
  //   store.dispatch(setHelpSrc(<HelpAppAndroid />));
  //   store.dispatch(setHelpOpen(true));
  //   return;
  // }

  if (
    !standalone &&
    browser.os === "Android OS" &&
    beginsWith(browser.name, ["samsung", "chrome"])
  ) {
    store.dispatch(setHelpSrc(<HelpAndroid />));
    store.dispatch(setHelpOpen(true));
    return;
  }

  if (
    !standalone &&
    browser.os === "iOS" &&
    beginsWith(browser.name, ["ios"])
  ) {
    store.dispatch(setHelpSrc(<HelpIPhone />));
    store.dispatch(setHelpOpen(true));
    return;
  }

  if (
    !standalone &&
    browser.os === "iOS" &&
    !beginsWith(browser.name, ["ios"])
  ) {
    store.dispatch(setHelpSrc(<HelpIPhone />));
    store.dispatch(setHelpOpen(true));
    return;
  }

  if (
    !standalone &&
    browser.os === "Android OS" &&
    !beginsWith(browser.name, ["chrome", "samsung"])
  ) {
    store.dispatch(setHelpSrc(<HelpIPhone />));
    store.dispatch(setHelpOpen(true));
    return;
  }
  return;
};
