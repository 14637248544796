import LanguageList from "admin/Login/header.language";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "Redux/reducers/_index";
import styled from "styled-components";

import { Device } from "@capacitor/device";
import ReactHtmlParser from "html-react-parser";

import Cookies from "./legal.cookies";
import Impressum from "./legal.impresszum";
import Privacy from "./legal.privacy";
import Subscription from "./legal.subscription";
import Terms from "./legal.terms";
import { IconButton } from "@material-ui/core";
import { firestore } from "firebaseUtil/firebaseUtil";
import { ArrowBack } from "@material-ui/icons";
import { phaseBodyIn, phaseIn } from "utils/utils";

let platform = "web";

Device.getInfo().then((res) => (platform = res.platform));

export const decodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&nbsp;/g, " ");
};

const prepareFormatTerms = (html: string) => {
  html = html.replace(/\*(.*?)\*/g, "<span class='termbold'>$1</span>");
  html = html.replace(/_(.*?)_/g, "<span class='italic'>$1</span>");
  html = html.replace(/\{(.*?)\}/g, "<span class='colored'>$1</span>");
  return ReactHtmlParser(html);
};

const isMainWeb = document.location.href.includes("?a=");

const LegalStandAlone = (props: any) => {
  const [termsTranslation, setTermsTranslation] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [language, setLanguage] = useState("en");
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      phaseBodyIn();
      phaseIn();
    }, 1000);
  }, []);
  // const language = useSelector<RootState, any>((state) => state.tempLanguage);

  const goBack = () => {
    if (platform !== "web") {
      history.goBack();
    } else {
      history.push("https://app.ininotes.com");
    }
  };

  const tempLanguage = useSelector<RootState, any>(
    (state) => state.tempLanguage
  );

  useEffect(() => {
    setLanguage(tempLanguage);
  }, [tempLanguage]);

  useEffect(() => {
    const loadTermsTranslation = async () => {
      const transColl = firestore
        .collection("translations")
        .where("type", "==", "webpages")
        .where("purpose", "==", "legal")
        .where("language", "==", language);

      let termTrans: any = [];

      try {
        termTrans = await transColl.get();
        setTermsTranslation({
          ...termTrans.docs[0].data().translations,
          ...termTrans.docs[1].data().translations,
          ...termTrans.docs[2].data().translations,
        });

        setTimeout(() => setVisible(true), 0);
      } catch (e) {
        setTimeout(() => setVisible(true), 0);
        setTermsTranslation({});
        return {};
      }
    };

    if (language) {
      loadTermsTranslation();
    } else {
      setTimeout(() => setVisible(true), 0);
    }
  }, [language]);

  const tt = useCallback(
    (uuid: string, orig: string = "", avar: string = "", bvar: string = "") => {
      orig = termsTranslation[uuid || "bibibi"] || orig;
      orig = orig.replace(/xxx/g, avar);
      orig = orig.replace(/yyy/g, bvar);
      return prepareFormatTerms(decodedStr(orig));
    },
    [termsTranslation]
  );

  const getText = () => {
    switch (props.doc) {
      case "terms":
        return <Terms tt={tt} />;
      case "privacy":
        return <Privacy tt={tt} />;
      case "subscription":
        return <Subscription tt={tt} />;

      case "cookies":
        return <Cookies tt={tt} />;
      case "impress":
        return <Impressum tt={tt} />;
    }
  };

  return (
    <div
      style={{
        background: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        id="bubu3"
        style={{
          opacity: visible ? 1 : 0,
          transition: "all 0.3s",
          height: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", top: "15px", right: "35px" }}>
          <LanguageList language={language} setLanguage={setLanguage} />
        </div>
        {getText()}
      </div>
      {!isMainWeb ? (
        <div style={{ position: "absolute", bottom: "0px", right: "35px" }}>
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
};

export default LegalStandAlone;
